import { useQuery } from "@tanstack/react-query";
import { ApiUrls } from "@pm-frontend/shared/utils/api-urls";
import { apiFetch } from "@pm-frontend/shared/utils/apiFetch";
import { LinkHelper } from "@pm-shared/utils/link";
export const useGetManagements = () => {
  return useQuery({
    queryKey: ["managements"],
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.managementList)),
    cacheTime: Infinity
  });
};
