import React from "react";
import CancelNote from "./cancel-note";
export default class TenantCancellationRequests extends React.Component {
  render() {
    let meld = this.props.meld;
    let requests = meld.get("cancellation_requests");
    if (requests && !requests.isEmpty()) {
      return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h3", null, "Cancellation Request", requests.count() > 0 ? "s" : ""), /* @__PURE__ */ React.createElement("ul", { className: "cancel-notes" }, requests.map((request, i) => {
        return /* @__PURE__ */ React.createElement("li", { className: "copy", key: i }, this.renderCancelCall(request));
      })));
    } else {
      return null;
    }
  }
  renderCancelCall(request) {
    if (this.props.onDelete) {
      return /* @__PURE__ */ React.createElement(CancelNote, { note: request, canDelete: this.props.canDelete, onDelete: this.props.onDelete });
    } else {
      return /* @__PURE__ */ React.createElement(CancelNote, { note: request, canDelete: this.props.canDelete });
    }
  }
}
