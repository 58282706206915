import React from "react";
import ReactDOM from "react-dom";
export default class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  render() {
    let classString = "custom-checkbox-container";
    if (this.props.className) {
      classString += " " + this.props.className;
    }
    return /* @__PURE__ */ React.createElement("div", { className: classString, onClick: this.onClick }, /* @__PURE__ */ React.createElement(
      "input",
      {
        type: "checkbox",
        className: "custom-checkbox",
        checked: this.props.checked,
        id: this.props.id,
        readOnly: true,
        disabled: this.props.disabled
      }
    ), /* @__PURE__ */ React.createElement("label", null));
  }
  onClick(e) {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }
  componentDidMount() {
    if (this.props.indeterminate) {
      this.updateIndeterminant();
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.indeterminate !== this.props.indeterminate) {
      this.updateIndeterminant();
    }
  }
  /**
   * Updates the indeterminate status of the checkbox input
   *
   * Although accessing the DOM isn't the way things are typically done in React
   * indeterminate can only be set through js and not as an html attribute.
   */
  updateIndeterminant() {
    const node = ReactDOM.findDOMNode(this);
    if (node instanceof HTMLElement) {
      const input = node.querySelector(".custom-checkbox");
      input.indeterminate = this.props.indeterminate ? this.props.indeterminate : false;
    }
  }
}
Checkbox.defaultProps = {
  indeterminate: false
};
