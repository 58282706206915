import { create } from "zustand";
const useBannerStore = create((set) => ({
  isVisible: true,
  setIsVisible: (newVisibility) => {
    set({ isVisible: newVisibility });
  }
}));
const useGetGlobalBannerVisibility = () => {
  return useBannerStore((state) => state.isVisible);
};
const useSetGlobalBannerVisibility = () => {
  return useBannerStore((state) => state.setIsVisible);
};
export { useBannerStore, useGetGlobalBannerVisibility, useSetGlobalBannerVisibility };
