import React from "react";
import classNames from "classnames";
export default class ListView extends React.Component {
  render() {
    let className = "list-v";
    if (this.props.className) {
      className += " " + this.props.className;
    }
    const listItems = this.props.items.map((item) => {
      const itemId = item.get("id");
      const classes = {
        "header-col": true,
        selector: true,
        selected: false
      };
      let selected = false;
      let validationErrors = [];
      let customValidationErrors = [];
      if (this.props.selectionValidator) {
        validationErrors = this.props.selectionValidator(item);
      }
      if (this.props.customSelectionValidator) {
        customValidationErrors = this.props.customSelectionValidator(item);
      }
      if (this.props.selectedItems) {
        const isIncluded = this.props.selectedItems.includes(itemId);
        selected = this.props.invertStatus ? !isIncluded : isIncluded;
      }
      classes.selected = selected && validationErrors.length + customValidationErrors.length === 0;
      const selectedClasses = classNames(classes);
      let props = {
        className: selectedClasses,
        listItem: item,
        isSelected: selected && validationErrors.length + customValidationErrors.length === 0,
        key: itemId,
        errors: validationErrors,
        customErrors: customValidationErrors
      };
      if (item.get("is_active") !== void 0) {
        props.isActive = item.get("is_active");
      }
      if (this.props.onItemSelected) {
        props.onItemSelected = validationErrors.length + customValidationErrors.length === 0 ? this.props.onItemSelected : () => {
          return;
        };
      }
      if (this.props.linkPath) {
        const suffix = this.props.linkPathSuffix ? this.props.linkPathSuffix : "";
        props.link = this.props.linkPath + `/${itemId}/` + suffix;
      }
      if (this.props.listItemProps) {
        props = Object.assign(this.props.listItemProps, props);
      }
      return React.createElement(this.props.listItemClass, props);
    });
    return /* @__PURE__ */ React.createElement("div", { className: "row" }, /* @__PURE__ */ React.createElement("div", { className: "columns small-12" }, className !== "list-v unit-list-view selectable" && /* @__PURE__ */ React.createElement("div", { className }, listItems), className === "list-v unit-list-view selectable" && /* @__PURE__ */ React.createElement(
      "div",
      {
        className,
        style: { gridTemplateColumns: "min-content min-content auto auto  auto auto auto min-content" }
      },
      listItems
    )));
  }
}
