import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";
class AgentPreferencesApi extends BaseApi {
  static fetchMe() {
    return this.get(ApiUrls.myAgentPreferences);
  }
  static updateMe(data) {
    return this.patch(ApiUrls.myAgentPreferences, data);
  }
}
export default AgentPreferencesApi;
