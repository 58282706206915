import randomColor from "randomcolor";
import MeldUtils from "@pm-assets/js/meld/utils/meld-utils";
let UserUtils = {
  getFullName(user, emptyMsg = "Blank") {
    [user] = MeldUtils.immutableMapOrObject(user);
    let fullName = `${user.get("first_name")} ${user.get("last_name")}`;
    if (fullName.replace(" ", "")) {
      return fullName;
    } else {
      return emptyMsg;
    }
  },
  calcColor(user) {
    return randomColor({ seed: user.get("created"), luminosity: "dark" });
  }
};
export default UserUtils;
