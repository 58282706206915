import CommentTemplate, { commentTemplateFields } from "../records/comment-template";
import commentTemplateAPI from "../apis/comment-template-api";
import paginateStore from "../../common/utils/paginate-store";
import Store from "../../common/stores/store";
class CommentTemplateStore extends paginateStore(Store) {
  constructor() {
    super(CommentTemplate, commentTemplateFields);
    this.initRequestFunctions({
      fetchAll: this.fetchAll,
      fetchNext: this.fetchNext,
      fetchPrevious: this.fetchPrevious,
      fetchTemplate: this.fetchTemplate
    });
  }
  fetchAll({ url } = {}) {
    return this.fetch(commentTemplateAPI.fetchAll({ url }));
  }
  fetchTemplate(id) {
    return this.fetchOne(commentTemplateAPI.fetchOne(id));
  }
  fetchNext() {
    return this.fetch(commentTemplateAPI.fetchAll({ url: this.state.next }));
  }
  fetchPrevious() {
    return this.fetch(commentTemplateAPI.fetchAll({ url: this.state.previous }));
  }
}
export default new CommentTemplateStore();
