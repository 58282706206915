import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";
class NotificationSettingsApi extends BaseApi {
  static fetchCurrent() {
    return this.get(ApiUrls.notificationSettings);
  }
  static update(data) {
    return this.put(ApiUrls.notificationSettings, data);
  }
}
export default NotificationSettingsApi;
