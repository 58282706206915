let WindowClickedMixin = {
  componentWillMount() {
    window.addEventListener("click", this.handleWindowClicked, false);
  },
  componentWillUnmount() {
    window.removeEventListener("click", this.handleWindowClicked, false);
  }
};
export default WindowClickedMixin;
export let WindowClickUtils = {
  addListener(callback) {
    window.addEventListener("click", callback, false);
  },
  removeListener(callback) {
    window.removeEventListener("click", callback, false);
  }
};
