import React from "react";
class LineItemCost extends React.Component {
  render() {
    return /* @__PURE__ */ React.createElement("div", { className: "line-item-cost line-item-field" }, /* @__PURE__ */ React.createElement("label", { className: "line-item-label" }, "Cost"), /* @__PURE__ */ React.createElement("div", { className: "line-item-cost-input-container" }, /* @__PURE__ */ React.createElement("span", { className: "vertical-center-wrapper" }, /* @__PURE__ */ React.createElement("span", { className: "invoice-currency" }, "$")), /* @__PURE__ */ React.createElement(
      "input",
      {
        type: "text",
        className: "line-item-cost-input",
        "data-test": "line-item-cost.input",
        value: this.props.currentUnitCost,
        onChange: this.props.onUnitCostChanged,
        onBlur: this.props.onFinalizeCostChanges,
        disabled: this.props.disableInput
      }
    )));
  }
}
export default LineItemCost;
