import React from "react";
import classNames from "classnames";
let MODAL_ID = "modal";
class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.onModalClicked = this.onModalClicked.bind(this);
  }
  render() {
    let modalClasses = classNames({
      in: this.props.isOpen,
      modal: true,
      active: this.props.isOpen
    });
    if (this.props.isOpen) {
      return /* @__PURE__ */ React.createElement("div", { className: "modal-open" }, /* @__PURE__ */ React.createElement(
        "div",
        {
          id: MODAL_ID,
          className: modalClasses,
          tabIndex: -1,
          role: "dialog",
          onClick: this.onModalClicked,
          "aria-labelledby": "",
          "aria-hidden": "false"
        },
        /* @__PURE__ */ React.createElement(
          "div",
          {
            className: `modal-container ${this.props.columnClasses}`,
            style: this.props.style ? this.props.style : {}
          },
          /* @__PURE__ */ React.createElement("div", { className: "modal-dialog " }, /* @__PURE__ */ React.createElement("div", { className: "modal-content" }, this.props.children))
        )
      ));
    } else {
      return /* @__PURE__ */ React.createElement("div", null);
    }
  }
  onModalClicked(e) {
    if (e.currentTarget.id === MODAL_ID && this.props.onModalClicked) {
      this.props.onModalClicked();
    }
  }
}
Modal.defaultProps = {
  columnClasses: "small-centered small-12 medium-10 large-8 columns"
};
export default Modal;
