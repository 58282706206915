import _ from "lodash";
import * as C from "../constants";
import classNames from "classnames";
import React from "react";
class PriorityLight extends React.Component {
  render() {
    let classes = classNames({
      "priority-low": this.props.priority === C.Priority.LOW,
      "priority-medium": this.props.priority === C.Priority.MEDIUM,
      "priority-high": this.props.priority === C.Priority.HIGH,
      "priority-level": true
    });
    let priorityTitle = `${_.capitalize(this.props.priority.toLowerCase())} priority`;
    return /* @__PURE__ */ React.createElement("div", { className: classes, title: priorityTitle });
  }
}
export default PriorityLight;
