import React from "react";
import classnames from "classnames";
export var HeaderSizes = /* @__PURE__ */ ((HeaderSizes2) => {
  HeaderSizes2["Medium"] = "xl";
  HeaderSizes2["Large"] = "xxl";
  return HeaderSizes2;
})(HeaderSizes || {});
function Header(props) {
  const classes = classnames("pm-headerblock", props.className, props.size);
  if (props.size === "xxl" /* Large */) {
    return /* @__PURE__ */ React.createElement("h1", { className: classes }, props.children);
  } else {
    return /* @__PURE__ */ React.createElement("h2", { className: classes }, props.children);
  }
}
export default Header;
