import React from "react";
import { Group } from "./Group";
import TextBlock, { TextBlockSizes } from "../../texts/TextBlock";
export class GroupedSelection extends React.Component {
  render() {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, this.renderSelectAll(), this.props.groups.map((group, i) => {
      return /* @__PURE__ */ React.createElement(
        Group,
        {
          key: i,
          checkValues: this.props.checkValues,
          columns: this.props.columns,
          disabledValues: this.props.disabledValues,
          items: group.get("items"),
          selectedValues: this.props.selectedValues,
          title: group.get("title"),
          toggleValue: this.props.toggleValue,
          uncheckValues: this.props.uncheckValues
        }
      );
    }));
  }
  renderSelectAll() {
    return /* @__PURE__ */ React.createElement("div", { className: "pm-select-all" }, this.props.columns.map((_, i) => {
      const values = this.props.groups.map((group) => {
        return group.get("items").map((item) => {
          return item.getIn(["values", i]);
        });
      }).flatten().filter((value) => value && !this.props.disabledValues.contains(value)).toSet();
      const unselectAll = values.every((value) => !value || this.props.selectedValues.contains(value));
      const disabled = values.every((value) => !value || this.props.disabledValues.contains(value));
      if (unselectAll) {
        return /* @__PURE__ */ React.createElement(TextBlock, { size: TextBlockSizes.MD, key: i }, /* @__PURE__ */ React.createElement("a", { className: disabled ? "disabled" : "", onClick: () => disabled || this.props.uncheckValues(values) }, this.props.columnsUnselectAll.get(i)));
      } else {
        return /* @__PURE__ */ React.createElement(TextBlock, { size: TextBlockSizes.MD, key: i }, /* @__PURE__ */ React.createElement("a", { className: disabled ? "disabled" : "", onClick: () => disabled || this.props.checkValues(values) }, this.props.columnsSelectAll.get(i)));
      }
    }));
  }
}
