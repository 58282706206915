import React from "react";
import { colors } from "@pm-frontend/styles";
export const SidebarCalendarIcon = ({ isActive = false }) => {
  const fill = isActive ? colors.brand.meldBlue : colors.neutrals.gray800;
  return /* @__PURE__ */ React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill, xmlns: "http://www.w3.org/2000/svg" }, /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip0_1378_22252)" }, /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M13.3335 2.00008H12.6668V0.666748H11.3335V2.00008H4.66683V0.666748H3.3335V2.00008H2.66683C1.9335 2.00008 1.3335 2.60008 1.3335 3.33341V14.0001C1.3335 14.7334 1.9335 15.3334 2.66683 15.3334H13.3335C14.0668 15.3334 14.6668 14.7334 14.6668 14.0001V3.33341C14.6668 2.60008 14.0668 2.00008 13.3335 2.00008ZM13.3335 14.0001H2.66683V6.66675H13.3335V14.0001ZM13.3335 5.33341H2.66683V3.33341H13.3335V5.33341Z",
      fill
    }
  )), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "clip0_1378_22252" }, /* @__PURE__ */ React.createElement("rect", { width: "16", height: "16", fill: "white" }))));
};
