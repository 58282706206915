import I from "immutable";
export function defaultContainerState() {
  return {
    errors: [],
    loading: true
  };
}
export function defaultNotLoadingContainerState() {
  return {
    errors: [],
    loading: false
  };
}
export function defaultSaveContainerState() {
  return {
    errors: [],
    loading: false
  };
}
export function startLoading() {
  this.setState({ loading: true, errors: [] });
}
export function loadingFailed(result) {
  this.setState({ errors: result.messages, loading: false });
}
export function loadingSucceeded(result) {
  this.setState({
    data: I.fromJS(result.data),
    loading: false,
    result
  });
}
export function loadingSucceededPaginated(result) {
  this.setState({
    data: I.fromJS(result.data.results),
    loading: false,
    result
  });
}
export function containerStateForChildren(component) {
  return {
    errors: component.state.errors,
    loading: component.state.loading,
    result: component.state.result
  };
}
