import { Editor, Element, Range, Transforms } from "slate";
const ALIGN_TYPES = ["center", "justify", "left", "right"];
const LIST_TYPES = ["ordered-list", "unordered-list"];
function getFormatField(formatType) {
  return ALIGN_TYPES.includes(formatType) ? "align" : "type";
}
export function isBlockActive(editor, formatType) {
  const { selection } = editor;
  if (!selection) {
    return false;
  }
  const field = getFormatField(formatType);
  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (node) => !Editor.isEditor(node) && Element.isElement(node) && node[field] === formatType
    })
  );
  return !!match;
}
export function isLinkActive(editor) {
  const [link] = Editor.nodes(editor, {
    match: (node) => !Editor.isEditor(node) && Element.isElement(node) && node.type === "link"
  });
  return !!link;
}
export function isMarkActive(editor, markType) {
  const marks = Editor.marks(editor);
  if (marks) {
    return marks[markType] === true;
  }
  return false;
}
export function toggleMark(editor, markType) {
  if (isMarkActive(editor, markType)) {
    Editor.removeMark(editor, markType);
  } else {
    Editor.addMark(editor, markType, true);
  }
}
export function toggleBlock(editor, formatType) {
  const isActive = isBlockActive(editor, formatType);
  const isList = LIST_TYPES.includes(formatType);
  Transforms.unwrapNodes(editor, {
    match: (node) => !Editor.isEditor(node) && Element.isElement(node) && LIST_TYPES.includes(node.type) && !ALIGN_TYPES.includes(formatType),
    split: true
  });
  let newProperties;
  if (ALIGN_TYPES.includes(formatType)) {
    newProperties = { align: isActive ? void 0 : formatType };
  } else {
    newProperties = {
      // @ts-ignore
      type: isActive ? "paragraph" : isList ? "list-item" : formatType
    };
  }
  Transforms.setNodes(editor, newProperties);
  if (!isActive && isList) {
    const block = { type: formatType, children: [] };
    Transforms.wrapNodes(editor, block);
  }
}
export function withInlines(editor) {
  const { isInline } = editor;
  editor.isInline = (element) => element.type === "link" || isInline(element);
  return editor;
}
export function unwrapLink(editor) {
  Transforms.unwrapNodes(editor, {
    match: (node) => !Editor.isEditor(node) && Element.isElement(node) && node.type === "link"
  });
}
export function wrapLink(editor, url) {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }
  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: "link",
    url,
    children: isCollapsed ? [{ text: url }] : []
  };
  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
}
export function getAbsoluteLink(link) {
  try {
    const url = new URL(link);
    return url.href;
  } catch (_e) {
    return `https://${link}`;
  }
}
