import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";
class IntegrationApi extends BaseApi {
  static enableIntegration(slug) {
    return this.patch(ApiUrls.integrationEnable(slug));
  }
  static create(data, slug) {
    return this.post(ApiUrls.integrationList(slug), data);
  }
  static update(integrationId, data, slug) {
    return this.patch(ApiUrls.integrationDetail(slug, integrationId), data);
  }
  static sync(slug) {
    return this.patch(ApiUrls.integrationSync(slug));
  }
  static toggleEnabled(slug, data) {
    return this.patch(ApiUrls.toggleIntegrationEnabled(slug), data);
  }
  static fetchEnabled() {
    return this.get(ApiUrls.integrationsEnabled);
  }
}
export default IntegrationApi;
