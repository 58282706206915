import { Roles } from "../../constants";
export const RoleUtils = {
  formatName(role) {
    const name = role.get("name");
    switch (name) {
      case Roles.MANAGEMENT_ADMIN:
        return "Admin";
      case Roles.MANAGEMENT_AGENT:
        return "Agent";
      case Roles.MAINTENANCE:
        return "Maintenance";
      default:
        return name;
    }
  },
  getAssignableRoles(roles) {
    return roles.filter((role) => {
      return role.get("permissions").every((permission) => {
        return window.PM.permissions.includes(permission.get("codename"));
      });
    });
  }
};
