import React from "react";
import { Global, css } from "@emotion/react";
import { colors } from "@pm-frontend/styles";
const styles = css`
  .euiFieldSearch {
    background-color: #ffffff;
  }
  .euiTable .euiTableRow:nth-child(even) {
    background-color: #fcfcfc;
  }
  .euiTableHeaderCell {
    :not(.euiTableHeaderButton-isSorted) {
      .euiTableCellContent {
        .euiTableCellContent__text {
          color: ${colors.neutrals.gray800};
          font-size: 12px;
          text-decoration: none;
        }
      }
    }
    .euiTableHeaderButton-isSorted {
      .euiTableCellContent {
        .euiTableCellContent__text {
          color: ${colors.brand.meldBlue};
          font-size: 12px;
          text-decoration: none;
        }
      }
      .euiIcon {
        color: ${colors.brand.meldBlue};
        fill: ${colors.brand.meldBlue};
      }
    }
  }
  td.euiTableRowCell {
    border-bottom-color: ${colors.neutrals.gray200};
    border-top-color: ${colors.neutrals.gray200};
  }

  td.euiTableRowCellCheckbox {
    border-bottom-color: ${colors.neutrals.gray200};
    border-top-color: ${colors.neutrals.gray200};
  }

  tr.euiTableRow:hover {
    background: inherit;
  }
  .euiTableRowCell {
    .euiTableCellContent {
      padding: 13px 8px !important;
      font-size: 14px !important;
      color: ${colors.neutrals.gray800};

      .euiText {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .euiPagination__item {
    .euiButtonEmpty {
      text-decoration: none !important;
    }
  }

  .euiSearchBar {
    &__filtersHolder {
      .euiFilterButton {
        .euiNotificationBadge {
          background: ${colors.brand.meldBlue};
        }
      }
    }
  }

  .euiFilterGroup {
    &__popoverPanel {
      .euiSelectableListItem {
        &__text {
          font-size: 12px;
          color: ${colors.neutrals.gray800};
        }

        &:hover {
          &:not([aria-disabled="true"]) {
            background-color: ${colors.brand.white};
          }
        }
      }
    }
  }

  .meldListViewTable {
    tr:nth-child(even) {
      background: ${colors.neutrals.gray50};
    }
    tr:nth-child(odd) {
      background: white;
    }
    th:nth-child(1),
    td:nth-child(1) {
      position: sticky;
      left: 0;
      background: white;
    }
    th:nth-child(2),
    td:nth-child(2) {
      position: sticky;
      left: 185px;
      background: white;
      .euiTableCellContent {
        border: 0;
        border-right: 10px solid;
        border-image-source: linear-gradient(270deg, rgba(0, 0, 0, 0), black);
        border-image-slice: 1 10 1 0; /* Slice values for top, right, bottom, left */
        border-image-width: 0 10px 0 0; /* Apply width only to the right side */
        border-image-outset: 0;
      }
    }
  }
`;
const TableStyles = () => {
  return /* @__PURE__ */ React.createElement(Global, { styles });
};
export { TableStyles };
