import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";
class VendorMeldFileApi extends BaseApi {
  static create(data, meldId) {
    return this.post(ApiUrls.vendorMeldFileList(meldId), data);
  }
  static fetchFilesForMeld(meldId) {
    return this.get(ApiUrls.vendorMeldFileList(meldId));
  }
  static deleteFile(fileId) {
    return this.delete(ApiUrls.vendorMeldFileDetail(fileId));
  }
  static getPolicyDataForFile(file) {
    return this.get(ApiUrls.vendorMeldFileGeneratePolicy, {
      params: { filename: file.name }
    });
  }
}
export default VendorMeldFileApi;
