import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";
class VendorInvoiceFileApi extends BaseApi {
  static create(data, meldId) {
    return this.post(ApiUrls.vendorInvoiceFileList(meldId), data);
  }
  static fetchFilesForMeld(meldId) {
    return this.get(ApiUrls.vendorInvoiceFileList(meldId));
  }
  static deleteFile(fileId) {
    return this.delete(ApiUrls.vendorInvoiceFileDetail(fileId));
  }
}
export default VendorInvoiceFileApi;
