import { AuthUtils } from "../../../../js/utils/auth-utils";
import * as C from "../../../constants";
import moment from "moment";
class VendorMeldUtils {
  static getFormattedStatus(meld) {
    switch (meld.get("status")) {
      case C.MeldStatuses.PENDING_VENDOR:
        return "Pending Decision";
      case C.MeldStatuses.PENDING_COMPLETION:
        return "In progress";
      case C.MeldStatuses.PENDING_MORE_VENDOR_AVAILABILITY:
        return "More availability requested";
      default:
    }
  }
  static getMyCurrentAssignment(meld) {
    let currentVendorId = AuthUtils.getActiveOrg();
    return meld.get("vendor_assignment_requests").filter((assignment) => {
      const vendor_serialized = assignment.getIn(["vendor", "id"]);
      const vendor = assignment.get("vendor");
      return vendor_serialized === currentVendorId || vendor === currentVendorId;
    }).filter((assignment) => !assignment.get("canceled") && !assignment.get("rejected")).max((assignment1, assignment2) => {
      const created1 = moment(assignment1.get("created"));
      const created2 = moment(assignment2.get("created"));
      if (created1.isBefore(created2)) {
        return -1;
      }
      if (created1.isAfter(created2)) {
        return 1;
      }
      return 0;
    });
  }
  static getSchedulerLink(meld, assignment) {
    const url = `/assignments/${assignment.get("id")}/schedule/`;
    return `${url}${meld.getIn(["unit", "id"])}/`;
  }
}
export default VendorMeldUtils;
