import I from "immutable";
import moment from "moment";
export function dtComparator(a, b) {
  if (a.dtstart_moment.isBefore(b.dtstart_moment)) {
    return -1;
  }
  if (a.dtstart_moment.isAfter(b.dtstart_moment)) {
    return 1;
  }
  if (a.dtend_moment.isBefore(b.dtend_moment)) {
    return -1;
  }
  if (a.dtend_moment.isAfter(b.dtend_moment)) {
    return 1;
  }
  return 0;
}
class EventUtils {
  static getEventAssignedAgents(event) {
    let servicers = event.getIn(["managementavailabilitysegment", "meld", "in_house_servicers"]);
    return (servicers || I.List()).map((servicer) => {
      let agent = servicer.get("agent");
      if (Number.isInteger(agent)) {
        return agent;
      }
      return agent.get("id");
    });
  }
  static getEventAssignedVendor(event) {
    let vendor = event.getIn(["vendoravailabilitysegment", "assignment_request", "vendor", "id"]);
    if (Number.isInteger(vendor)) {
      return vendor;
    }
    return vendor.get("id");
  }
  static addEventMoment(event) {
    let dtstart_moment = moment(event.get("dtstart"));
    let dtend_moment = moment(event.get("dtend"));
    return event.set("dtstart_moment", dtstart_moment).set("dtend_moment", dtend_moment);
  }
  static getDTStartMoment(event) {
    return event.get("dtstart_moment") || moment(event.get("dtstart"));
  }
  static getDTEndMoment(event) {
    return event.get("dtend_moment") || moment(event.get("dtend"));
  }
  static groupByOverlapping(events) {
    return events.shift().reduce((reduction, current) => {
      const headOfGroup = reduction.last().first();
      const dtStartMoment = EventUtils.getDTStartMoment(current);
      const headStart = EventUtils.getDTStartMoment(headOfGroup);
      const headEnd = EventUtils.getDTEndMoment(headOfGroup);
      if (dtStartMoment.isBetween(headStart, headEnd, null, "[)")) {
        return reduction.set(-1, reduction.get(-1).push(current));
      }
      return reduction.push(I.List([current]));
    }, I.List([events.slice(0, 1)]));
  }
}
export default EventUtils;
