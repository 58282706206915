import React from "react";
import DocumentTitle from "react-document-title";
import { IconButton, Text, useMediaQuery, Button, Flex, Box, Heading, Link as ChakraLink } from "@chakra-ui/react";
import { AiFillPlusCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Container, Content, Width } from "@pm-components/container";
import { hasPerm } from "../../../../assets/js/common/utils/permission-utils";
import { LinkHelper } from "@pm-shared/utils/link";
import { Perms } from "../../../../assets/js/constants";
import { track, PCPCreateServiceForm } from "@pm-app/utils/analytics";
import useQuery from "../../../apps/property-management-hub/pages/owner-services/hooks/useQuery";
import { ArrowBackIcon } from "@chakra-ui/icons";
export const OwnerServicesListView = ({ summaryNode, servicesNode }) => {
  const [isLargerThan600] = useMediaQuery("(min-width: 600px)");
  let query = useQuery();
  let addServiceButton;
  if (LinkHelper.getOrgType() === "m" && hasPerm(Perms.CAN_ADD_OWNER_SERVICES)) {
    if (isLargerThan600) {
      addServiceButton = /* @__PURE__ */ React.createElement(ChakraLink, { as: Link, to: "new/", size: "sm", height: 7, onClick: () => track(PCPCreateServiceForm()) }, /* @__PURE__ */ React.createElement(Button, { variant: "primary", id: "add-services.button" }, "Add Service"));
    } else {
      addServiceButton = /* @__PURE__ */ React.createElement(ChakraLink, { as: Link, to: "new/", onClick: () => track(PCPCreateServiceForm()) }, /* @__PURE__ */ React.createElement(
        IconButton,
        {
          "aria-label": "add service",
          color: "blue.600",
          icon: /* @__PURE__ */ React.createElement(AiFillPlusCircle, null),
          size: "sm",
          variant: "ghost",
          fontSize: 48
        }
      ));
    }
  }
  return /* @__PURE__ */ React.createElement(DocumentTitle, { title: "PropertyCare+" }, /* @__PURE__ */ React.createElement(Container, { marginTop: 4, "data-test": "service-list-container" }, /* @__PURE__ */ React.createElement(Content, { width: Width.MEDIUM }, /* @__PURE__ */ React.createElement(Box, { marginBottom: 120, maxW: 1200 }, query.get("templates") && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    ChakraLink,
    {
      "data-test": "new-service-return-to-index-button",
      as: Link,
      to: LinkHelper.normalize("owner-services/")
    },
    /* @__PURE__ */ React.createElement(Text, { fontSize: "md", fontWeight: "semibold", color: "blue.600" }, /* @__PURE__ */ React.createElement(ArrowBackIcon, null), " Back")
  ), /* @__PURE__ */ React.createElement(Box, { marginBottom: 50 })), /* @__PURE__ */ React.createElement(Flex, { justify: "space-between" }, /* @__PURE__ */ React.createElement(Heading, { id: "owner-services.main.title", fontSize: [25, 35], marginBottom: 10 }, "PropertyCare+ ", query.get("templates") ? " Templates" : ""), /* @__PURE__ */ React.createElement(Box, null), query.get("templates") ? "" : addServiceButton), summaryNode), servicesNode)));
};
