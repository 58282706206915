let ContactUtils = {
  getPrimaryPhone(contact, emptyMsg = "No phone") {
    if (!contact) {
      return emptyMsg;
    }
    if (contact.get("primary_phone_ext")) {
      return `${contact.get("home_phone")} ext ${contact.get("home_phone_ext")}`;
    }
    return `${contact.get("home_phone")}`;
  },
  getFirstFoundPhone(contact, emptyMsg = "No phone") {
    if (!contact) {
      return emptyMsg;
    }
    return contact.get("cell_phone") || contact.get("home_phone") || contact.get("business_phone") || emptyMsg;
  },
  getPrimaryEmail(contact, emptyMsg = "No Email") {
    if (!contact) {
      return emptyMsg;
    }
    return contact.get("primary_email") || emptyMsg;
  }
};
export default ContactUtils;
