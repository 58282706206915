import I from "immutable";
import createModelStore from "./create-model-store";
import RecordUtils from "./record-utils";
function paginateStore(store) {
  return class extends createModelStore(store) {
    constructor(modelClass, modelFields) {
      super();
      this.MODEL_CLASS = modelClass;
      this.MODEL_FIELDS = modelFields;
      this.state.fetchedModels = I.List();
      this.state.models = I.List();
      this.state.url = null;
      this.state.next = null;
      this.state.previous = null;
    }
    canFetchNext() {
      return !!this.state.next;
    }
    canFetchPrevious() {
      return !!this.state.previous;
    }
    getFetchedModels() {
      return this.state.fetchedModels;
    }
    fetch(xhr) {
      return xhr.then((res) => {
        let models = this._makeModels(res.data.results);
        this.setState({
          fetchedModels: models,
          models: this._receive(models),
          next: res.data.next,
          previous: res.data.previous,
          url: res.config.url
        });
      });
    }
    fetchOne(xhr) {
      return xhr.then((res) => {
        let models = this._makeModels([res.data]);
        this.setState({
          fetchedModels: models,
          models: this._receive(models),
          url: res.config.url
        });
      });
    }
    _receive(models) {
      let changedModels = models.filter((m) => {
        return !this.getAll().includes(m);
      });
      if (changedModels.size) {
        let existingUpdates = changedModels.filter((model) => {
          const mId = model.get("id");
          return this.getAll().find((m) => m.get("id") === mId);
        }).map((model) => {
          return this.getAll().find((m) => {
            return m.get("id") === model.get("id");
          }).mergeDeep(model);
        });
        let newModels = models.filter((model) => {
          return !this.getAll().find((m) => m.get("id") === model.get("id"));
        });
        let newAndUpdated = newModels.concat(existingUpdates);
        return this.getAll().filterNot(
          (model) => newAndUpdated.find((m) => m.get("id") === model.get("id"))
        ).concat(newAndUpdated);
      } else {
        return this.getAll();
      }
    }
    _makeModels(models) {
      return I.List(
        models.map((model) => {
          return RecordUtils.initFromJS(new this.MODEL_CLASS(), model, this.MODEL_FIELDS);
        })
      );
    }
  };
}
export default paginateStore;
