import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";
class RequestedSegmentAPI extends BaseApi {
  static fetchForMeld(meldId) {
    return this.get(ApiUrls.meldRequestedSegments(meldId));
  }
  static book(availabilityID) {
    if (Array.isArray(availabilityID)) {
      return this.patch(ApiUrls.bookRequestedSegments(), { ids: availabilityID });
    } else {
      return this.patch(ApiUrls.bookRequestedSegment(availabilityID));
    }
  }
}
export default RequestedSegmentAPI;
