import React from "react";
export default class HashTag extends React.Component {
  render() {
    let classes = [this.props.className, "hashtag-svg"].filter((cls) => cls).join(" ");
    return /* @__PURE__ */ React.createElement("svg", { className: classes, xmlns: "https://www.w3.org/2000/svg", viewBox: "0 0 32 32" }, /* @__PURE__ */ React.createElement(
      "path",
      {
        d: "M30 12V8h-5.004l1-8h-4l-1 8h-7.998l1-8h-4l-1 8H2v4h6.498L7.5 20H2v4h5l-1 8h4l1-8h8l-1.002 8H22l1-8h7v-4h-6.5l.996-8H30zm-10.5 8h-8l.998-8h7.998l-.996 8z",
        fill: "#030104"
      }
    ));
  }
}
