import React from "react";
import ButtonContainer from "../../../../app/components/containers/ButtonContainer";
import Button, { ButtonTypes } from "../../../../app/components/buttons/Button";
export default function ResetChangesButtons(props) {
  return /* @__PURE__ */ React.createElement(ButtonContainer, null, /* @__PURE__ */ React.createElement(
    Button,
    {
      buttonType: ButtonTypes.Cancel,
      className: "settings-reset-btn",
      onClick: props.resetClickFn,
      disabled: props.resetDisabled
    },
    "Reset Changes"
  ), /* @__PURE__ */ React.createElement(
    Button,
    {
      buttonType: ButtonTypes.Primary,
      className: "settings-save-btn",
      onClick: props.saveClickFn,
      disabled: props.saveDisabled
    },
    "Save"
  ));
}
