import createReactClass from "create-react-class";
import React from "react";
let Bell = createReactClass({
  render() {
    return /* @__PURE__ */ React.createElement(
      "svg",
      {
        className: this.props.classes,
        xmlns: "https://www.w3.org/2000/svg",
        width: "15",
        height: "18",
        viewBox: "0 0 30 36"
      },
      /* @__PURE__ */ React.createElement(
        "path",
        {
          d: "M23.93 26.98c-.53-.79-.84-1.74-.84-2.76V11.13c0-3.4-2.75-6.17-6.13-6.17h-3-.92c-3.38 0-6.13 2.77-6.13 6.17v13.09c0 1.02-.31 1.97-.84 2.76h17.86M15 36c-2.16 0-3.91-1.76-3.91-3.93h7.82c0 2.17-1.75 3.93-3.91 3.93zm13.04-6.02H1.96C.88 29.98 0 29.11 0 28.02c0-1.08.88-1.84 1.96-1.84s1.95-.88 1.95-1.96V11.13c0-5.06 4.09-9.17 9.13-9.17C13.04.88 13.92 0 15 0s1.96.88 1.96 1.96c5.04 0 9.13 4.11 9.13 9.17v13.09c0 1.08.87 1.96 1.95 1.96s1.96.76 1.96 1.84c0 1.09-.88 1.96-1.96 1.96z",
          fill: "#FFF"
        }
      )
    );
  }
});
export default Bell;
