import React from "react";
class LineItemPrice extends React.Component {
  render() {
    return /* @__PURE__ */ React.createElement("div", { className: "line-item-price line-item-field" }, /* @__PURE__ */ React.createElement("label", { className: "line-item-label" }, "Price"), /* @__PURE__ */ React.createElement("div", { className: "line-item-price-input-container" }, /* @__PURE__ */ React.createElement("span", { className: "vertical-center-wrapper" }, /* @__PURE__ */ React.createElement("span", { className: "invoice-currency" }, "$")), /* @__PURE__ */ React.createElement(
      "input",
      {
        type: "text",
        className: "line-item-price-input",
        value: this.props.currentUnitPrice,
        onChange: this.props.onUnitPriceChanged,
        onBlur: this.props.onFinalizePriceChanges
      }
    )));
  }
}
export default LineItemPrice;
