import moment from "moment";
const currentYear = (/* @__PURE__ */ new Date()).getFullYear();
let CardUtils = {
  getFormattedDayMonth(date, dayMonthFormat, emptyMsg = "") {
    if (!date) {
      return emptyMsg;
    }
    let mom = moment(date);
    let format = dayMonthFormat;
    if (!format) {
      if (mom.year() < currentYear) {
        format = "D MMMM YYYY";
      } else {
        format = "D MMMM";
      }
    }
    return mom.format(format);
  },
  getFormattedTime(date, timeFormat = "h:mma", emptyMsg = "") {
    if (!date) {
      return emptyMsg;
    }
    return moment(date).format(timeFormat);
  }
};
export default CardUtils;
