import classnames from "classnames";
import React from "react";
import QuestionMark from "../../svg/question-mark-svg";
class Tooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hover: false };
    this.handleTooltipClicked = this.handleTooltipClicked.bind(this);
    this.handleWindowClicked = this.handleWindowClicked.bind(this);
  }
  render() {
    const messageClasses = classnames({
      message: true,
      visible: this.state.hover
    });
    const iconClasses = classnames({
      "tooltip-circle": true,
      open: this.state.hover
    });
    return /* @__PURE__ */ React.createElement("div", { className: "tooltip", onClick: this.handleTooltipClicked }, /* @__PURE__ */ React.createElement("div", { className: messageClasses }, this.props.message), /* @__PURE__ */ React.createElement("div", { className: iconClasses }, this.props.children || /* @__PURE__ */ React.createElement(QuestionMark, null)));
  }
  handleTooltipClicked(e) {
    if (this.props.preventDefault) {
      e.preventDefault();
    }
    this.clicked = true;
    this.setState({ hover: !this.state.hover });
  }
  handleWindowClicked() {
    if (!this.clicked) {
      this.setState({ hover: false });
    } else {
      this.clicked = false;
    }
  }
  componentWillMount() {
    window.addEventListener("click", this.handleWindowClicked);
  }
  componentWillUnmount() {
    window.removeEventListener("click", this.handleWindowClicked);
  }
}
export default Tooltip;
