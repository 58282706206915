const filerevs = window.PM.env.filerevs;
export default {
  getStatic(fragment) {
    var _a, _b;
    let staticUrl = (_b = (_a = window.PM) == null ? void 0 : _a.env) == null ? void 0 : _b.staticUrl;
    staticUrl || (staticUrl = "/");
    if (!staticUrl.endsWith("static/")) {
      staticUrl = staticUrl + "static/";
    }
    let file = fragment;
    if (file.startsWith("static/")) {
      file = file.substring(7);
    }
    if (filerevs) {
      if (file in filerevs) {
        return staticUrl + filerevs[file];
      }
    }
    return staticUrl + file;
  }
};
