let Currency = {
  dollar(amount, decimal = true) {
    let fixedAmount = decimal ? this.toFixed(amount, 2) : this.toFixed(amount, 0);
    if (fixedAmount) {
      return `$${fixedAmount}`;
    }
  },
  toFixed(amount, fixedPlaces = 2, thousandSeparator = true) {
    let floatAmount;
    if (typeof amount === "number") {
      floatAmount = amount;
    } else {
      floatAmount = Number.parseFloat(amount);
    }
    if (!Number.isNaN(floatAmount)) {
      let fixedAmount = floatAmount.toFixed(fixedPlaces);
      if (thousandSeparator) {
        return this.commaSeparate(fixedAmount);
      }
      return fixedAmount;
    }
    return "";
  },
  commaSeparate(str) {
    let [integerPart, fractionalPart] = str.split(".");
    let integers = integerPart.split("").reverse().reduce((prev, curr, i) => {
      let isOnePastThirdDigit = i % 3 === 0;
      if (isOnePastThirdDigit) {
        return `${curr},${prev}`;
      }
      return curr + prev;
    });
    return fractionalPart ? `${integers}.${fractionalPart}` : integers;
  }
};
export default Currency;
