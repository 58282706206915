export let Keys = {
  TAB: 9,
  ENTER: 13,
  ESCAPE: 27,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  KEY_UP: 38,
  KEY_DOWN: 40
};
let KeyUtils = {
  isEnterKey(e) {
    return e.keyCode === Keys.ENTER;
  },
  isEscape(e) {
    return e.keyCode === Keys.ESCAPE;
  },
  isLeftArrow(e) {
    return e.keyCode === Keys.LEFT_ARROW;
  },
  isRightArrow(e) {
    return e.keyCode === Keys.RIGHT_ARROW;
  }
};
export default KeyUtils;
