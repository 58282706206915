import { axios } from "@pm-shared/axios";
import CommentUtils from "./utils/comment-utils";
const multitenancy = window.location.pathname.split("/");
export const APIPrefix = `/${multitenancy[1]}/${multitenancy[2]}/${multitenancy[3]}/api`;
export const postSummaryData = (comments, meldId, cache_check) => {
  const concatenatedMessages = comments.map((comment) => {
    const userType = CommentUtils.getUserType(comment);
    return comment.get("text") + " (This message was sent from " + userType + ")";
  }).join("\n");
  const messages = [
    {
      role: "user",
      content: concatenatedMessages
    }
  ];
  const postData = {
    messages,
    meldId,
    cache_check
  };
  const url = `${APIPrefix}/openai/summarize/`;
  return axios.post(url, postData).then((response) => response.data).catch((error) => {
    throw error;
  });
};
export const postTranslateToEnglishData = (text, meldId) => {
  const messages = [];
  messages.push({
    role: "user",
    content: text
  });
  const postData = {
    messages,
    meldId
  };
  const url = `${APIPrefix}/openai/translate/toenglish`;
  return axios.post(url, postData).then((response) => response.data).catch((error) => {
    throw error;
  });
};
export const postTranslateEnglishData = (text, default_language, meldId) => {
  const messages = [];
  messages.push({
    role: "user",
    content: `The language to translate to is ${default_language}. ${text}`
  });
  const postData = {
    messages,
    meldId
  };
  const url = `${APIPrefix}/openai/translate/english`;
  return axios.post(url, postData).then((response) => response.data).catch((error) => {
    throw error;
  });
};
export const postModifyData = (text, selectedTone, selectedLength, meldId) => {
  const modifiedText = `${text} SelectedTone: ${selectedTone}, SelectedLength: ${selectedLength}`;
  const messages = [];
  messages.push({
    role: "user",
    content: modifiedText
  });
  const postData = {
    messages,
    meldId
  };
  const url = `${APIPrefix}/openai/modify/`;
  return axios.post(url, postData).then((response) => response.data).catch((error) => {
    throw error;
  });
};
