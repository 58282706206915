import I from "immutable";
export let commentTemplateFields = I.Set([
  "id",
  "name",
  "text",
  "change_visibility",
  "hidden_from_vendor",
  "hidden_from_tenant",
  "hidden_from_owner"
]);
let CommentTemplateRecord = I.Record({
  id: null,
  name: "",
  text: "",
  change_visibility: false,
  hidden_from_vendor: false,
  hidden_from_tenant: false,
  hidden_from_owner: true
});
class CommentTemplate extends CommentTemplateRecord {
}
export default CommentTemplate;
