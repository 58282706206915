import React from "react";
import { EuiCard, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from "@elastic/eui";
import { colors } from "@pm-frontend/styles";
const renderContentItem = (content, isDisabled) => {
  if (typeof content === "string") {
    return /* @__PURE__ */ React.createElement(
      EuiText,
      {
        color: !isDisabled ? colors.neutrals.gray800 : colors.neutrals.gray600,
        size: "m",
        style: { lineHeight: "24px" }
      },
      content
    );
  }
  return content;
};
const SiteSearchMobileResultCard = ({ contentArray, isDisabled }) => {
  const innerContent = /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", gutterSize: "l" }, contentArray.map((item, index) => {
    if (!item) {
      return null;
    }
    if (index === 0 && isDisabled) {
      return /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, key: index }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", gutterSize: "s", responsive: false, alignItems: "center" }, /* @__PURE__ */ React.createElement(EuiIcon, { type: "eyeClosed", color: colors.neutrals.gray600 }), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, renderContentItem(item, isDisabled))));
    }
    return /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, key: index }, renderContentItem(item, !!isDisabled));
  }));
  return /* @__PURE__ */ React.createElement(
    EuiCard,
    {
      title: innerContent,
      titleElement: "span",
      textAlign: "left",
      paddingSize: "m",
      style: {
        borderRadius: "6px",
        border: `1px solid ${colors.neutrals.gray200}`,
        background: colors.brand.white,
        boxShadow: "0px 0px 24px 0px rgba(73, 80, 87, 0.03)"
      }
    }
  );
};
export { SiteSearchMobileResultCard };
