import classNames from "classnames";
import Cog from "./svg/cog-svg";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import PureRenderMixin from "react-addons-pure-render-mixin";
import React from "react";
import WindowClickedMixin from "./window-clicked-mixin";
let ActionDropdown = createReactClass({
  mixins: [WindowClickedMixin, PureRenderMixin],
  propTypes: {
    id: PropTypes.string,
    toggleButton: PropTypes.object,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    orientLeft: PropTypes.bool
  },
  getInitialState() {
    return {
      isMenuOpen: false
    };
  },
  getDefaultProps() {
    return {
      id: "action-dropdown",
      containerPositionClass: "center-container"
    };
  },
  render() {
    let toggleButton = this.props.toggleButton;
    let dropdownClasses = classNames({
      "dropdown-container": true,
      hidden: this.props.enabled === false,
      light: this.props.light
    });
    let menuClasses = classNames({
      menu: true,
      active: this.state.isMenuOpen,
      "centered-nub": this.props.containerPositionClass === "center-container"
    });
    const toggleClasses = classNames({
      "action-toggle": true,
      "default-toggle": !toggleButton
    });
    const dropdownContentContainerClasses = classNames({
      "dropdown-content-container": true,
      "drop-up": this.props.dropUp,
      [this.props.containerPositionClass]: true
    });
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        id: this.props.id,
        "data-test": "action_dropdown.action.open",
        className: dropdownClasses,
        onClick: this.onDropdownClicked
      },
      /* @__PURE__ */ React.createElement("div", { className: toggleClasses }, toggleButton || /* @__PURE__ */ React.createElement(Cog, null)),
      /* @__PURE__ */ React.createElement("div", { className: dropdownContentContainerClasses }, /* @__PURE__ */ React.createElement("ul", { className: menuClasses }, this.state.isMenuOpen && this.props.children))
    );
  },
  onDropdownClicked(e) {
    e.preventDefault();
    this.dropdownJustClicked = true;
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
    if (!this.state.isMenuOpen && this.props.onOpen) {
      this.props.onOpen();
    }
  },
  handleWindowClicked() {
    if (this.state.isMenuOpen && !this.dropdownJustClicked) {
      if (this.props.onClose) {
        this.props.onClose();
      }
      this.setState({ isMenuOpen: false });
    }
    this.dropdownJustClicked = false;
  },
  closeDropdown() {
    this.setState({ isMenuOpen: false });
  }
});
export default ActionDropdown;
