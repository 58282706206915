import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from "@elastic/eui";
import { Link } from "react-router-dom";
import { colors } from "@pm-frontend/styles";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";
const renderMenuOption = (menuOption, toggleMenu, isMobile) => {
  if (!menuOption.enabled) {
    return null;
  }
  if (menuOption.linkType === "internal") {
    return /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, key: menuOption.text }, /* @__PURE__ */ React.createElement(Link, { to: menuOption.href, onClick: toggleMenu }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, alignItems: "center", style: { gap: "8px" } }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, style: { width: "16px" } }, typeof menuOption.icon === "string" ? /* @__PURE__ */ React.createElement(EuiIcon, { type: menuOption.icon, size: "original", style: { fill: colors.brand.meldBlue } }) : menuOption.icon), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
      EuiText,
      {
        color: menuOption.active ? colors.brand.meldBlue : colors.neutrals.gray800,
        size: "s",
        style: { fontWeight: isMobile ? 600 : 400 }
      },
      menuOption.text
    )))));
  }
  if (menuOption.linkType === "external") {
    return /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, key: menuOption.text }, /* @__PURE__ */ React.createElement("a", { href: menuOption.href }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, alignItems: "center", style: { gap: "8px" } }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, style: { width: "16px" } }, typeof menuOption.icon === "string" ? /* @__PURE__ */ React.createElement(EuiIcon, { type: menuOption.icon, size: "original", style: { fill: colors.brand.meldBlue } }) : menuOption.icon), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
      EuiText,
      {
        color: menuOption.active ? colors.brand.meldBlue : colors.neutrals.gray800,
        size: "s",
        style: { fontWeight: isMobile ? 600 : 400 }
      },
      menuOption.text
    )))));
  }
};
const SettingsOptions = ({ toggleMenu, menuOptions }) => {
  const isMobile = useIsMobile();
  return /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", style: { gap: "16px" } }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiText, { color: colors.neutrals.gray900, size: "s", style: { fontWeight: 700 } }, "Account Options")), menuOptions.map((menuOption) => renderMenuOption(menuOption, toggleMenu, isMobile)));
};
export { SettingsOptions };
