import I from "immutable";
import React from "react";
import ActivityComponent from "./activity-component";
class ActivityGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
    this.onToggle = this.onToggle.bind(this);
  }
  render() {
    if (!this.props.activities.isEmpty()) {
      let latestActivities;
      let olderActivities;
      if (this.props.activities.count() > this.props.max) {
        latestActivities = this.props.activities.take(this.props.max);
        olderActivities = this.props.activities.skip(this.props.max).filter((activity) => !activity.isRead());
      } else {
        latestActivities = this.props.activities;
        olderActivities = I.List();
      }
      return /* @__PURE__ */ React.createElement("div", { className: "activity-group" }, /* @__PURE__ */ React.createElement("div", null, this.props.icon && /* @__PURE__ */ React.createElement("div", { className: "svg-container" }, /* @__PURE__ */ React.createElement(this.props.icon, null)), this.renderTitle()), latestActivities.map((activity, i) => /* @__PURE__ */ React.createElement(ActivityComponent, { activity, key: i })), this.renderExpand(olderActivities.count()), olderActivities.map((activity, i) => /* @__PURE__ */ React.createElement(ActivityComponent, { activity, key: i, hidden: !this.state.expanded })));
    } else {
      return null;
    }
  }
  renderTitle() {
    if (this.props.title) {
      let unread = this.props.activities.filter((activity) => !activity.isRead()).size;
      return /* @__PURE__ */ React.createElement("span", { className: "activity-heading-text" }, this.props.title, " ", /* @__PURE__ */ React.createElement("b", null, unread ? `(${unread} unread)` : ""));
    }
  }
  renderExpand(count) {
    if (count) {
      return /* @__PURE__ */ React.createElement("a", { className: "activity visible", onClick: this.onToggle }, this.state.expanded ? "-" : "+", " ", count, " more unread");
    }
  }
  onToggle() {
    this.setState({ expanded: !this.state.expanded });
  }
}
export default ActivityGroup;
