import React from "react";
import CardUtils from "../../utils/card-utils";
export default class CardDate extends React.Component {
  render() {
    let { date, label, emptyMessage, multi } = this.props;
    let time;
    if (this.props.timeFormat) {
      time = /* @__PURE__ */ React.createElement("span", { className: "card-accent" }, " ", "at ", CardUtils.getFormattedTime(date, this.props.timeFormat));
    }
    if (date) {
      return !multi ? /* @__PURE__ */ React.createElement("span", { className: "card-date" }, /* @__PURE__ */ React.createElement("span", { className: "card-date-label" }, label, ":"), " ", CardUtils.getFormattedDayMonth(date), " ", time) : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("span", { className: "card-date" }, CardUtils.getFormattedDayMonth(date), " ", time), /* @__PURE__ */ React.createElement("br", null));
    }
    return /* @__PURE__ */ React.createElement("span", { className: "card-date" }, /* @__PURE__ */ React.createElement("span", null, emptyMessage));
  }
}
CardDate.defaultProps = {
  emptyMessage: "",
  timeFormat: "h:mma"
};
