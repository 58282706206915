import React from "react";
import ButtonGroup from "@pm-app/components/forms/button-group/button-group";
import Button, { ButtonTypes } from "@pm-app/components/forms/button/button";
import { FormGroup } from "@pm-app/components/forms/form-group/form-group";
import { Textarea } from "@pm-app/components/forms/textarea/textarea";
import { Modal, ModalBody, ModalFooter } from "@pm-app/components/modal/modal";
export default class CancelMeldForm extends React.Component {
  constructor(props) {
    super(props);
    this.onCancelMeld = this.onCancelMeld.bind(this);
  }
  render() {
    let {
      title,
      onClose,
      serverErrors,
      loading,
      onNotesChange,
      onCancelMeld,
      body,
      label,
      primaryText,
      secondaryText
    } = this.props;
    return /* @__PURE__ */ React.createElement(Modal, { header: title, isOpen: true, onClose }, /* @__PURE__ */ React.createElement(ModalBody, null, /* @__PURE__ */ React.createElement(FormGroup, null, /* @__PURE__ */ React.createElement("div", { className: "cancel-meld-form-body" }, body)), /* @__PURE__ */ React.createElement(FormGroup, null, label, /* @__PURE__ */ React.createElement(
      Textarea,
      {
        "data-test": "notes",
        errors: Array.isArray(serverErrors) && serverErrors.join("").length ? serverErrors : null,
        onChange: onNotesChange
      }
    ))), /* @__PURE__ */ React.createElement(ModalFooter, null, /* @__PURE__ */ React.createElement(ButtonGroup, null, /* @__PURE__ */ React.createElement(Button, { buttonType: ButtonTypes.Primary, onClick: onCancelMeld, disabled: loading }, primaryText), /* @__PURE__ */ React.createElement(Button, { buttonType: ButtonTypes.Neutral, onClick: onClose, disabled: loading }, secondaryText))));
  }
  onCancelMeld(e) {
    e.preventDefault();
    this.props.onCancelMeld();
  }
}
