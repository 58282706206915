export default class Storage {
  static getItem(key) {
    return window.localStorage.getItem(key);
  }
  static removeItem(key) {
    return window.localStorage.removeItem(key);
  }
  static setItem(key, item) {
    return window.localStorage.setItem(key, item);
  }
  static userPrefix(strings) {
    return `${window.PM.user.id}:${strings.join("")}`;
  }
}
