import BaseApi from "../../utils/base-api";
import {
  estimateLineItemsURL,
  updateEstimateLineItems,
  estimateURL
} from "@pm-assets/js/meld/apis/estimates-api";
import axios from "axios";
class EstimateLineItemsApi extends BaseApi {
  static createLineItems(estimateID, lineItems) {
    let url = estimateLineItemsURL(estimateID);
    let xhrOptions = { headers: this._getAdditionalHeaders() };
    return axios.post(url, lineItems, xhrOptions);
  }
  static update(estimateID, lineItems) {
    let url = updateEstimateLineItems(estimateID);
    let xhrOptions = { headers: this._getAdditionalHeaders() };
    let payload = { line_items: lineItems };
    return axios.patch(url, payload, xhrOptions);
  }
  static updateEstimate(estimateID, data) {
    let url = estimateURL(estimateID);
    let xhrOptions = { headers: this._getAdditionalHeaders() };
    return axios.patch(url, data, xhrOptions);
  }
}
export default EstimateLineItemsApi;
