import React from "react";
import { colors } from "@pm-frontend/styles";
export const UserManagementIcon = ({ isActive = false }) => {
  const fill = isActive ? colors.brand.meldBlue : colors.neutrals.gray800;
  return /* @__PURE__ */ React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip0_2202_276767)" }, /* @__PURE__ */ React.createElement(
    "path",
    {
      d: "M2.66667 8.66667C3.4 8.66667 4 8.06667 4 7.33333C4 6.6 3.4 6 2.66667 6C1.93333 6 1.33333 6.6 1.33333 7.33333C1.33333 8.06667 1.93333 8.66667 2.66667 8.66667ZM3.42 9.4C3.17333 9.36 2.92667 9.33333 2.66667 9.33333C2.00667 9.33333 1.38 9.47333 0.813333 9.72C0.32 9.93333 0 10.4133 0 10.9533V12H3V10.9267C3 10.3733 3.15333 9.85333 3.42 9.4ZM13.3333 8.66667C14.0667 8.66667 14.6667 8.06667 14.6667 7.33333C14.6667 6.6 14.0667 6 13.3333 6C12.6 6 12 6.6 12 7.33333C12 8.06667 12.6 8.66667 13.3333 8.66667ZM16 10.9533C16 10.4133 15.68 9.93333 15.1867 9.72C14.62 9.47333 13.9933 9.33333 13.3333 9.33333C13.0733 9.33333 12.8267 9.36 12.58 9.4C12.8467 9.85333 13 10.3733 13 10.9267V12H16V10.9533ZM10.8267 9.1C10.0467 8.75333 9.08667 8.5 8 8.5C6.91333 8.5 5.95333 8.76 5.17333 9.1C4.45333 9.42 4 10.14 4 10.9267V12H12V10.9267C12 10.14 11.5467 9.42 10.8267 9.1ZM5.38 10.6667C5.44 10.5133 5.46667 10.4067 5.98667 10.2067C6.63333 9.95333 7.31333 9.83333 8 9.83333C8.68667 9.83333 9.36667 9.95333 10.0133 10.2067C10.5267 10.4067 10.5533 10.5133 10.62 10.6667H5.38ZM8 5.33333C8.36667 5.33333 8.66667 5.63333 8.66667 6C8.66667 6.36667 8.36667 6.66667 8 6.66667C7.63333 6.66667 7.33333 6.36667 7.33333 6C7.33333 5.63333 7.63333 5.33333 8 5.33333ZM8 4C6.89333 4 6 4.89333 6 6C6 7.10667 6.89333 8 8 8C9.10667 8 10 7.10667 10 6C10 4.89333 9.10667 4 8 4Z",
      fill
    }
  )), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "clip0_2202_276767" }, /* @__PURE__ */ React.createElement("rect", { width: "16", height: "16", fill: "white" }))));
};
