import createReactClass from "create-react-class";
import React from "react";
let Checkmark = createReactClass({
  render() {
    return /* @__PURE__ */ React.createElement(
      "svg",
      {
        xmlns: "https://www.w3.org/2000/svg",
        preserveAspectRatio: "xMidYMid",
        width: "30",
        height: "22",
        className: "checkmark-svg",
        viewBox: "0 0 30 22"
      },
      /* @__PURE__ */ React.createElement(
        "path",
        {
          d: "M23.88 1.016l-12.687 12.47L6.118 8.5c-1.4-1.38-3.672-1.38-5.075 0-1.4 1.376-1.4 3.61 0 4.99l7.612 7.477c1.4 1.376 3.674 1.376 5.075 0L28.956 6.003c1.4-1.377 1.403-3.61 0-4.988-1.4-1.375-3.673-1.375-5.075 0z",
          fillRule: "evenodd"
        }
      )
    );
  }
});
export default Checkmark;
