import React from "react";
import validate from "validate.js";
import _ from "lodash";
let ErrorUtils = {
  getErrorHtml(data, constraints, extraErrors) {
    let errors = this.getValidationErrorList(data, constraints);
    errors = errors.concat(extraErrors || []);
    return this.transformErrorsToHtml(errors);
  },
  getValidationErrors(data, constraints) {
    return validate(data, constraints, { fullMessages: false });
  },
  getErrorClass(attr) {
    if (this.view.state.submissionTried) {
      let errors = this.getValidationErrors();
      if (errors) {
        return errors[attr] ? "error" : "";
      }
    }
  },
  getValidationErrorList(data, constraints) {
    let validationErrors = this.getValidationErrors(data, constraints) || [];
    let errors = [];
    _.forIn(validationErrors, (value) => {
      errors = errors.concat(value);
    });
    return errors;
  },
  transformErrorsToHtml(errors, ref) {
    if (!errors) {
      return null;
    }
    let errorsHtml = errors.map(function(error, i) {
      if (_.isArray(error)) {
        return /* @__PURE__ */ React.createElement("ul", null, this.transformErrorsToHtml(error));
      }
      return /* @__PURE__ */ React.createElement("li", { className: "error", key: i }, error);
    });
    return /* @__PURE__ */ React.createElement("ul", { className: "errors", ref }, errorsHtml);
  }
};
export default ErrorUtils;
