import { ApiApi, Configuration, MApi, OApi, TApi, VApi } from "./openapi";
import { LinkHelper } from "@pm-shared/utils/link";
import { BaseApiCustomCodes } from "../../../assets/js/utils/base-api";
import { decrementXHRCount, incrementXHRCount } from "../../../assets/js/utils/xhr-counter";
import { ERROR_403, ERROR_404, ERROR_SUPPORT_MESSAGE } from "../../../assets/js/constants";
import ApiUtils from "../../../assets/js/utils/api-utils";
import { AuthUtils } from "../../../assets/js/utils/auth-utils";
export const authParams = {
  multitenant: LinkHelper.getMultitenant(),
  org: LinkHelper.getOrgType(),
  org_id: LinkHelper.getOrgId()
};
export const Api = new ApiApi(
  new Configuration({
    basePath: window.location.origin,
    baseOptions: {
      headers: { "X-CSRFToken": AuthUtils.getCsrfToken() }
    }
  })
);
export let mApi = new MApi(
  new Configuration({
    basePath: window.location.origin,
    baseOptions: {
      headers: { "X-CSRFToken": AuthUtils.getCsrfToken() }
    }
  })
);
export let vApi = new VApi(
  new Configuration({
    basePath: window.location.origin,
    baseOptions: {
      headers: { "X-CSRFToken": AuthUtils.getCsrfToken() }
    }
  })
);
export let tApi = new TApi(
  new Configuration({
    basePath: window.location.origin,
    baseOptions: {
      headers: { "X-CSRFToken": AuthUtils.getCsrfToken() }
    }
  })
);
export let oApi = new OApi(
  new Configuration({
    basePath: window.location.origin,
    baseOptions: {
      headers: { "X-CSRFToken": AuthUtils.getCsrfToken() }
    }
  })
);
export const onFulfilled = (response) => {
  decrementXHRCount();
  return response;
};
export const onRejected = (error) => {
  decrementXHRCount();
  if (error == "Cancel") {
    error.code = BaseApiCustomCodes.RequestCancelled;
    error.message = `${BaseApiCustomCodes.RequestCancelled} - Request was canceled`;
    return Promise.reject(error);
  }
  let errorsMessages;
  if (error && error.response) {
    if (error.response.status === 403) {
      errorsMessages = [ERROR_403];
    } else if (error.response.status === 404) {
      errorsMessages = [ERROR_404];
    } else {
      errorsMessages = ApiUtils.transformErrors(error.response);
    }
  }
  if (!errorsMessages || !errorsMessages.length) {
    errorsMessages = [ERROR_SUPPORT_MESSAGE];
  }
  error.messages = errorsMessages;
  error.msgs = errorsMessages;
  return Promise.reject(error);
};
export const reqOnFulfilled = (config) => {
  incrementXHRCount();
  return config;
};
export const reqOnRejected = (error) => {
  decrementXHRCount();
  return Promise.reject(error);
};
mApi.axios.interceptors.request.use(reqOnFulfilled, reqOnRejected);
mApi.axios.interceptors.response.use(onFulfilled, onRejected);
tApi.axios.interceptors.request.use(reqOnFulfilled, reqOnRejected);
tApi.axios.interceptors.response.use(onFulfilled, onRejected);
vApi.axios.interceptors.request.use(reqOnFulfilled, reqOnRejected);
vApi.axios.interceptors.response.use(onFulfilled, onRejected);
