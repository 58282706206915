import React from "react";
import { colors } from "@pm-frontend/styles";
export const SidebarResidentsIcon = ({ isActive = false }) => {
  const fill = isActive ? colors.brand.meldBlue : colors.neutrals.gray800;
  return /* @__PURE__ */ React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill, xmlns: "http://www.w3.org/2000/svg" }, /* @__PURE__ */ React.createElement("g", { id: "ResidentsIcon", clipPath: "url(#clip0_1299_22431)" }, /* @__PURE__ */ React.createElement(
    "path",
    {
      id: "Vector",
      d: "M6.00016 9.16659C4.44016 9.16659 1.3335 9.94659 1.3335 11.4999V12.6666H10.6668V11.4999C10.6668 9.94659 7.56016 9.16659 6.00016 9.16659ZM2.8935 11.3333C3.4535 10.9466 4.80683 10.4999 6.00016 10.4999C7.1935 10.4999 8.54683 10.9466 9.10683 11.3333H2.8935ZM6.00016 7.99992C7.28683 7.99992 8.3335 6.95325 8.3335 5.66659C8.3335 4.37992 7.28683 3.33325 6.00016 3.33325C4.7135 3.33325 3.66683 4.37992 3.66683 5.66659C3.66683 6.95325 4.7135 7.99992 6.00016 7.99992ZM6.00016 4.66659C6.5535 4.66659 7.00016 5.11325 7.00016 5.66659C7.00016 6.21992 6.5535 6.66659 6.00016 6.66659C5.44683 6.66659 5.00016 6.21992 5.00016 5.66659C5.00016 5.11325 5.44683 4.66659 6.00016 4.66659ZM10.6935 9.20659C11.4668 9.76659 12.0002 10.5133 12.0002 11.4999V12.6666H14.6668V11.4999C14.6668 10.1533 12.3335 9.38659 10.6935 9.20659ZM10.0002 7.99992C11.2868 7.99992 12.3335 6.95325 12.3335 5.66659C12.3335 4.37992 11.2868 3.33325 10.0002 3.33325C9.64016 3.33325 9.30683 3.41992 9.00016 3.56659C9.42016 4.15992 9.66683 4.88659 9.66683 5.66659C9.66683 6.44659 9.42016 7.17325 9.00016 7.76659C9.30683 7.91325 9.64016 7.99992 10.0002 7.99992Z",
      fill
    }
  )), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "clip0_1299_22431" }, /* @__PURE__ */ React.createElement("rect", { width: "16", height: "16", fill: "white" }))));
};
