import moment from "moment";
import qs from "qs";
import * as C from "../../constants";
import { LinkHelper } from "@pm-shared/utils/link";
import ReminderUtils from "../../meld/utils/reminder-utils";
let NotificationUtils = {
  getUrl(activity) {
    let verb = this.VERBS[activity.getVerbId()];
    if (verb) {
      return verb.url(activity.get("object_id"));
    } else {
      return "#";
    }
  },
  getText(activity) {
    let verbId = activity.getVerbId();
    if (this.VERBS[verbId].text) {
      return this.VERBS[verbId].text(activity);
    } else {
      return activity.getSimpleText();
    }
  },
  VERBS: {
    8: {
      url: (id) => ({ pathname: `/meld/${id}/summary/` }),
      text: () => "Took too long to accept meld assignment"
    },
    10: {
      url: (id) => ({ pathname: `/melds/tickets/${id}/summary/` }),
      text: (activity) => `${activity.getActor()} proposed schedule`
    },
    11: {
      url: (id) => ({ pathname: `/assignments/${id}/schedule/` }),
      text: (activity) => `${activity.getActor()} requested more availability`
    },
    12: {
      url: (id) => {
        switch (LinkHelper.getOrgType()) {
          case "m":
            return { pathname: `/meld/${id}/messages/` };
          case "v":
            return { pathname: `/meld/${id}/messages/` };
          case "t":
            return { pathname: `/melds/tickets/${id}/messages/` };
        }
      },
      text: (activity) => {
        let text = activity.getIn(["extra_context", "text"]);
        return `${activity.getActor()} said "${text}"`;
      }
    },
    13: {
      url: (id) => {
        switch (LinkHelper.getOrgType()) {
          case "m":
            return { pathname: `/meld/${id}/summary/` };
          case "t":
            return { pathname: `/melds/tickets/${id}/summary/` };
        }
      },
      text: (activity) => `${activity.getActor()} completed meld`
    },
    14: {
      url: (id) => ({ pathname: `/meld/${id}/summary/` }),
      text: (activity) => `${activity.getActor()} could not complete meld`
    },
    15: {
      url: (id) => ({ pathname: `/melds/payments/${id}/summary/` }),
      text: (activity) => {
        let amount = activity.getIn(["extra_context", "amount"]);
        return `${activity.getActor()} submitted an invoice for $${amount}`;
      }
    },
    17: {
      url: (id) => ({ pathname: `/management-appointment/${id}/` }),
      text: (activity) => `${activity.getActor()} rescheduled meld`
    },
    18: {
      url: (id) => {
        switch (LinkHelper.getOrgType()) {
          case "m":
            return { pathname: `/meld/${id}/summary/` };
          case "v":
            return { pathname: `/meld/${id}/summary/` };
          case "t":
            return { pathname: `/melds/tickets/${id}/summary/` };
        }
      },
      text: (activity) => `${activity.getActor()} canceled meld`
    },
    19: {
      url: (id) => ({ pathname: `/vendor-appointment/${id}/` }),
      text: (activity) => `${activity.getActor()} rescheduled meld`
    },
    20: {
      url: (id) => {
        switch (LinkHelper.getOrgType()) {
          case "m":
            return { pathname: `/meld/${id}/summary/` };
          case "v":
            return { pathname: `/meld/${id}/summary/` };
          case "t":
            return { pathname: `/melds/tickets/${id}/summary/` };
        }
      },
      text: (activity) => {
        let dtstart = moment(activity.getIn(["extra_context", "dtstart"])).format("MMMM Do [at] h:mm A");
        return `Scheduled for ${dtstart}`;
      }
    },
    22: {
      url: (id) => {
        switch (LinkHelper.getOrgType()) {
          case "m":
            return { pathname: `/meld/${id}/summary/` };
          case "v":
            return { pathname: `/meld/${id}/summary/` };
          case "t":
            return { pathname: `/melds/tickets/${id}/summary/` };
        }
      },
      text: (activity) => `Reminder: Appointment in ${activity.getFuture()}`
    },
    23: {
      url: (id) => ({ pathname: `/vendors/${id}/summary/` })
    },
    24: {
      url: (id) => ({ pathname: `/meld/${id}/summary/` }),
      text: (activity) => {
        let rating = activity.getIn(["extra_context", "rating"]);
        return `${activity.getActor()} rated this meld ${rating}/5`;
      }
    },
    26: {
      url: () => ({ pathname: `/tenants/requests/` })
    },
    27: {
      url: () => ({ pathname: `/melds/incoming/` }),
      text: (activity) => `${activity.getActor()} canceled the work request`
    },
    28: {
      url: () => {
        let queryString = qs.stringify({
          ordering: C.DEFAULT_MGR_TICKET_SORT,
          scheduling: C.MeldScheduling.NO_CURRENT
        });
        return { pathname: `/melds/melding`, search: `?${queryString}` };
      }
    },
    29: {
      url: (id) => {
        switch (LinkHelper.getOrgType()) {
          case "m":
            return { pathname: `/meld/${id}/messages/` };
          case "v":
            return { pathname: `/meld/${id}/messages/` };
          case "t":
            return { pathname: `/melds/tickets/${id}/messages/` };
        }
      },
      text: (activity) => {
        let text = activity.getIn(["extra_context", "text"]);
        return `${activity.getActor()} said "${text}"`;
      }
    },
    30: {
      url: (id) => {
        switch (LinkHelper.getOrgType()) {
          case "m":
            return { pathname: `/meld/${id}/messages/` };
          case "v":
            return { pathname: `/meld/${id}/messages/` };
          case "t":
            return { pathname: `/melds/tickets/${id}/messages/` };
        }
      },
      text: (activity) => {
        let text = activity.getIn(["extra_context", "text"]);
        return `${activity.getActor()} said "${text}"`;
      }
    },
    32: {
      url: (id) => {
        switch (LinkHelper.getOrgType()) {
          case "m":
            return { pathname: `/meld/${id}/summary/` };
          case "t":
            return { pathname: `/melds/tickets/${id}/summary/` };
        }
      },
      text: (activity) => `${activity.getActor()} submitted a meld request`
    },
    33: {
      url: (id) => {
        switch (LinkHelper.getOrgType()) {
          case "m":
            return { pathname: `/meld/${id}/summary/` };
          case "v":
            return { pathname: `/melds/incoming/${id}/summary/` };
        }
      },
      text: (activity) => {
        let assignee = activity.getIn(["extra_context", "assignee"]);
        if (assignee) {
          return `Assigned to ${assignee}`;
        } else {
          return `${activity.getActor()} assigned maintenance`;
        }
      }
    },
    34: {
      url: (id) => ({ pathname: `/meld/${id}/summary/` }),
      text: (activity) => `${activity.getActor()} took too long to accept meld assignment`
    },
    35: {
      url: (id) => ({ pathname: `/meld/${id}/summary/` }),
      text: (activity) => `${activity.getActor()} rejected meld`
    },
    36: {
      url: (id) => ({ pathname: `/calendar/melds/${id}/schedule/` }),
      text: (activity) => `${activity.getActor()} requested more availability`
    },
    37: {
      url: (id) => ({ pathname: `/meld/${id}/messages/` }),
      text: (activity) => {
        let text = activity.getIn(["extra_context", "text"]);
        return `${activity.getActor()} said "${text}"`;
      }
    },
    38: {
      url: (id) => ({ pathname: `/melds/tickets/${id}/summary/` }),
      text: (activity) => `${activity.getActor()} assigned maintenance`
    },
    39: {
      url: (id) => {
        switch (LinkHelper.getOrgType()) {
          case "m":
            return { pathname: `/meld/${id}/summary/` };
          case "v":
            return { pathname: `/melds/incoming/${id}/summary/` };
        }
      },
      text: (activity) => {
        let file = activity.getIn(["extra_context", "file"]);
        return `${activity.getActor()} uploaded "${file}"`;
      }
    },
    40: {
      url: (id) => {
        switch (LinkHelper.getOrgType()) {
          case "v":
            return { pathname: `/melds/incoming/${id}/summary/` };
          case "t":
            return { pathname: `/melds/tickets/${id}/summary/` };
        }
      },
      text: (activity) => `${activity.getActor()} shared files`
    },
    41: {
      url: (id) => ({ pathname: `/meld/${id}/reminders/` }),
      text: (activity) => `"${ReminderUtils.getTitle(activity.get("extra_context"))}" is due`
    },
    42: {
      url: (id) => ({ pathname: `/meld/${id}/summary/` }),
      text: (activity) => {
        let reason = activity.getIn(["extra_context", "reason"]);
        return `${activity.getActor()} requested you cancel this meld because: "${reason}"`;
      }
    },
    43: {
      url: (id) => ({ pathname: `/melds/payments/${id}/summary/` }),
      text: (activity) => {
        let amount = activity.getIn(["extra_context", "amount"]);
        return `${activity.getActor()} resubmitted an invoice for $${amount}`;
      }
    },
    44: {
      url: (id) => ({ pathname: `/meld/${id}/summary/` })
    },
    45: {
      url: (id) => ({ pathname: `/meld/${id}/summary/` }),
      text: (activity) => {
        const amount = activity.getIn(["extra_context", "owner_approval_dollar_amount"]);
        const isApproved = activity.getIn(["extra_context", "is_approved"]);
        if (isApproved) {
          return `${activity.getActor()} approved request for $${amount}`;
        }
        return `${activity.getActor()} denied request for $${amount}`;
      }
    }
  }
};
export default NotificationUtils;
