import * as C from "../../constants";
import Currency from "../../utils/currency";
export const formattedPaymentMethods = (method) => {
  switch (method) {
    case C.PaymentMethods.CC:
      return "Credit Card";
    case C.PaymentMethods.ACH:
      return "ACH";
    case C.PaymentMethods.CHECK:
      return "Check";
    case C.PaymentMethods.CASH:
      return "Cash";
  }
};
let PaymentUtils = {
  getFormattedMethod(payment) {
    if (payment) {
      return formattedPaymentMethods(payment.get("method"));
    }
  },
  formatAmount(amount) {
    return Currency.dollar(amount / 100);
  }
};
export default PaymentUtils;
