import CalendarUtils from "../utils/calendar-utils";
import Check from "../svg/check-svg";
import classnames from "classnames";
import Link from "@pm-shared/utils/link";
import moment from "moment";
import NotificationApi from "./api/notification-api";
import notificationStore from "./stores/notification-store";
import NotificationUtils from "./utils/notification-utils";
import Popout from "../svg/popout-svg";
import React from "react";
import truncate from "truncate";
class ActivityComponent extends React.Component {
  constructor(props) {
    super(props);
    this.markRead = this.markRead.bind(this);
  }
  render() {
    let url = NotificationUtils.getUrl(this.props.activity);
    const markedRead = this.props.activity.isRead();
    let activityClasses = classnames({
      activity: true,
      "unread-activity": !markedRead,
      visible: !this.props.hidden
    });
    let dayMonthTime = CalendarUtils.formatDayMonthTime(this.props.activity.get("time"), { timeFmt: "h:mma" });
    return /* @__PURE__ */ React.createElement("div", { className: activityClasses }, /* @__PURE__ */ React.createElement("div", { className: "activity-line-container" }, /* @__PURE__ */ React.createElement("div", { className: "activity-line" }, /* @__PURE__ */ React.createElement(
      Link,
      {
        to: url,
        className: "link",
        onClick: this.markRead,
        title: NotificationUtils.getText(this.props.activity)
      },
      truncate(NotificationUtils.getText(this.props.activity), 100)
    ), /* @__PURE__ */ React.createElement(
      Link,
      {
        to: url,
        target: "_blank",
        className: "notification-popout",
        onClick: this.markRead,
        title: "Open in new window"
      },
      /* @__PURE__ */ React.createElement(Popout, null)
    )), /* @__PURE__ */ React.createElement("i", { className: "copy time", title: dayMonthTime }, moment.utc(this.props.activity.get("time")).fromNow())), /* @__PURE__ */ React.createElement("div", { className: "buttons" }, markedRead || /* @__PURE__ */ React.createElement("button", { className: "mark-read", onClick: this.markRead }, /* @__PURE__ */ React.createElement(Check, null))));
  }
  markRead() {
    const activityId = this.props.activity.get("id");
    void NotificationApi.markRead([activityId]);
    notificationStore.markActivityRead(activityId);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.activity.isRead()) {
      this.setState({ markedRead: true });
    }
  }
}
export default ActivityComponent;
