import React from "react";
export default class DownArrow extends React.Component {
  render() {
    return /* @__PURE__ */ React.createElement(
      "svg",
      {
        xmlns: "https://www.w3.org/2000/svg",
        preserveAspectRatio: "xMidYMid",
        className: "down-arrow-svg",
        viewBox: "0 0 20 12"
      },
      /* @__PURE__ */ React.createElement(
        "path",
        {
          d: "M.545.612c.752-.787 1.8-.848 2.718 0L10 7.225 16.74.612c.918-.848 1.966-.787 2.712 0 .752.786.704 2.113 0 2.85-.7.738-8.095 7.945-8.095 7.945-.374.393-.865.59-1.356.59-.49 0-.982-.197-1.36-.59 0 0-7.39-7.207-8.095-7.945-.705-.737-.75-2.064 0-2.85z",
          fillRule: "evenodd"
        }
      )
    );
  }
}
