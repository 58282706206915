import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  Text,
  Box,
  Flex,
  Image,
  Button
} from "@chakra-ui/react";
import URL from "@pm-shared/utils/url";
import Storage from "@pm-assets/js/common/storage";
import { IoShareOutline } from "react-icons/io5";
import { colors } from "@pm-frontend/styles";
import { fetchCurrentVendorAgent } from "@pm-property-management-hub/src/utils/api/vendors";
export function useAddToHomescreenPrompt() {
  const [prompt, setState] = React.useState(null);
  const [requested, setRequested] = React.useState(false);
  const promptToInstall = () => {
    if (prompt) {
      return prompt.prompt();
    }
    setRequested(true);
  };
  useEffect(() => {
    const ready = (e) => {
      e.preventDefault();
      setState(e);
      if (requested) {
        e.prompt();
      }
    };
    window.addEventListener("beforeinstallprompt", ready);
    return () => {
      window.removeEventListener("beforeinstallprompt", ready);
    };
  }, []);
  return [prompt, promptToInstall];
}
function detectAndroid() {
  const toMatch = [/Android/i];
  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}
function detectApple() {
  const toMatch = [/iPhone/i, /iPad/i, /iPod/i];
  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}
function isRunningStandalone() {
  return window.matchMedia("(display-mode: standalone)").matches;
}
export const PwaInstallModal = ({
  PwaInstallClosed,
  setState
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const { data: agentDetails } = fetchCurrentVendorAgent();
  const handleClose = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    if (prompt) {
      setIsOpen(true);
    }
  }, [prompt]);
  if (PwaInstallClosed || (agentDetails == null ? void 0 : agentDetails.prompt_for_mobile)) {
    return null;
  } else if ((detectApple() || detectAndroid()) && !isRunningStandalone()) {
    return /* @__PURE__ */ React.createElement(Modal, { isOpen, onClose: handleClose, closeOnOverlayClick: false }, /* @__PURE__ */ React.createElement(ModalOverlay, null), /* @__PURE__ */ React.createElement(ModalContent, null, /* @__PURE__ */ React.createElement(ModalHeader, null, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Box, { textAlign: "center" }, /* @__PURE__ */ React.createElement(Text, null, "Add to Home Screen"), /* @__PURE__ */ React.createElement(Image, { boxSize: "100px", src: URL.getStatic("img/PM-App-Icon-v2.png") })))), detectAndroid() ? /* @__PURE__ */ React.createElement(
      ModalCloseButton,
      {
        style: {
          color: "var(--brand-meld-blue, #1175CC)",
          background: "transparent",
          cursor: "pointer"
        }
      }
    ) : null, /* @__PURE__ */ React.createElement(ModalBody, null, /* @__PURE__ */ React.createElement(Text, { textAlign: "center" }, "Please download the app to your home screen for a better mobile experience."), detectAndroid() ? /* @__PURE__ */ React.createElement(Text, { textAlign: "center" }, 'Tap Download then "Install App"') : /* @__PURE__ */ React.createElement(React.Fragment, null), detectApple() ? /* @__PURE__ */ React.createElement(Text, { textAlign: "center", size: "lg" }, "Tap ", /* @__PURE__ */ React.createElement(IoShareOutline, { size: "2em", color: colors.brand.meldBlue }), ' then "Add to Home Screen"') : null), /* @__PURE__ */ React.createElement(ModalFooter, null, /* @__PURE__ */ React.createElement(Box, { width: "100%" }, /* @__PURE__ */ React.createElement(Flex, { flexDirection: "column", alignItems: "center" }, detectAndroid() ? /* @__PURE__ */ React.createElement(
      Button,
      {
        cursor: "pointer",
        onClick: () => {
          promptToInstall();
        }
      },
      "Download"
    ) : null, detectApple() ? /* @__PURE__ */ React.createElement(
      Button,
      {
        cursor: "pointer",
        onClick: () => {
          handleClose();
        }
      },
      "Close"
    ) : null, /* @__PURE__ */ React.createElement(
      Text,
      {
        cursor: "pointer",
        textAlign: "center",
        textDecoration: "underline",
        onClick: () => {
          setState({ PwaInstallClosed: true });
          Storage.setItem("PWAInstallClosed", "true");
          handleClose();
        }
      },
      "Do not show again."
    ))))));
  }
  return null;
};
