import _ from "lodash";
let StrUtils = {
  // Example: {a: 1, b: 2} => 'a=1,b=2'
  objectToKeyValStr(obj) {
    return _.map(_.toPairs(obj), (pair) => {
      return pair.join("=");
    }).join();
  },
  capitalizeEnum(str) {
    return _.capitalize(str.toLowerCase().split("_").join(" "));
  },
  uuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      let r = Math.random() * 16 | 0;
      let v = c === "x" ? r : r & 3 | 8;
      return v.toString(16);
    });
  },
  joinWithSpace(...classNames) {
    return classNames.filter((cls) => cls).join(" ");
  },
  combineWords(...words) {
    return words.map((w) => _.words(w, /[^ ]+/g)).reduce((w1, w2) => w1.concat(w2)).join(" ");
  },
  capitalizeWord(s) {
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  }
};
export default StrUtils;
