import { OwnerHubAccessLevelEnum, OwnerHubAccessLevelEnumReadable } from "@pm-shared/owner";
export const getStatusColor = (accessLevel) => {
  if ([OwnerHubAccessLevelEnum.DIRECT_ONLY, OwnerHubAccessLevelEnum.NONE].includes(accessLevel)) {
    return "red";
  }
  if ([OwnerHubAccessLevelEnum.ALL_NOTIFICATIONS, OwnerHubAccessLevelEnum.NOTIFICATIONS_ONLY].includes(accessLevel)) {
    return "white";
  }
  if (accessLevel === OwnerHubAccessLevelEnum.INVITED_TO_HUB) {
    return "yellow";
  }
  if (accessLevel === OwnerHubAccessLevelEnum.HUB_ACCESS) {
    return "green";
  }
  return "red";
};
export const getReadableStatus = (accessLevel) => {
  if ([OwnerHubAccessLevelEnum.DIRECT_ONLY, OwnerHubAccessLevelEnum.NONE].includes(accessLevel)) {
    return OwnerHubAccessLevelEnumReadable.DIRECT_ONLY;
  }
  if ([OwnerHubAccessLevelEnum.ALL_NOTIFICATIONS, OwnerHubAccessLevelEnum.NOTIFICATIONS_ONLY].includes(accessLevel)) {
    return OwnerHubAccessLevelEnumReadable.ALL_NOTIFICATIONS;
  }
  if (accessLevel === OwnerHubAccessLevelEnum.INVITED_TO_HUB) {
    return OwnerHubAccessLevelEnumReadable.INVITED_TO_HUB;
  }
  if (accessLevel === OwnerHubAccessLevelEnum.HUB_ACCESS) {
    return OwnerHubAccessLevelEnumReadable.HUB_ACCESS;
  }
};
