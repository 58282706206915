import { Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { fetchMeldTags, fetchPropertyGroups, fetchVendors } from "@pm-property-management-hub/src/utils/api/workflows";
import { fetchAgents } from "@pm-property-management-hub/src/utils/api/agents";
export const ChoiceCondition = (props) => {
  const { value, mapped_config, error, onChange, lookup_name, operator_key } = props;
  const [options, setOptions] = useState((mapped_config == null ? void 0 : mapped_config.options) || null);
  const shouldFetchVendors = ["meldinvoice_vendor_id", "vendor_assigned_vendor"].includes(lookup_name);
  const shouldFetchAgents = ["management_assigned_internal", "meld_coordinator_assigned"].includes(lookup_name);
  if (shouldFetchAgents) {
    const { data } = fetchAgents();
    useEffect(() => {
      if (data) {
        setOptions(data.map((agent) => [agent.id, `${agent.first_name} ${agent.last_name}`]));
      }
    }, [data]);
  }
  if (shouldFetchVendors) {
    const { data } = fetchVendors();
    useEffect(() => {
      if (data) {
        setOptions(data.map((vendor) => [vendor.id, vendor.name]));
      }
    }, [data]);
  }
  const shouldFetchPropertyGroups = ["meld_property_groups"].includes(lookup_name);
  if (shouldFetchPropertyGroups) {
    const { data } = fetchPropertyGroups();
    useEffect(() => {
      if (data) {
        setOptions(data.map((pg) => [pg.id, pg.name]));
      }
    }, [data]);
  }
  const shouldFetchTags = ["meld_tags"].includes(lookup_name);
  if (shouldFetchTags) {
    const { data } = fetchMeldTags();
    useEffect(() => {
      if (data) {
        setOptions(data.map((meldTag) => [meldTag.id, meldTag.name]));
      }
    }, [data]);
  }
  if (!["missing", "present"].includes(value) && !["missing", "present"].includes(operator_key)) {
    return /* @__PURE__ */ React.createElement(
      Select,
      {
        isInvalid: !!error,
        "data-test": "workflows.form.select.block_operator.condition_type.choice",
        onChange,
        placeholder: "Select",
        value
      },
      options == null ? void 0 : options.map(([x, y]) => {
        return /* @__PURE__ */ React.createElement("option", { key: x, value: x, "data-test": `workflows.form.select.select.choice.${x}.option` }, y);
      })
    );
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null);
};
