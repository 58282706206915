import mime from "mime";
const previewableFileTypes = /* @__PURE__ */ new Set([
  "application/pdf",
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/heic",
  "image/heif"
]);
let FileUtils = {
  isOpenable(file) {
    return !!file.get("openable_signed_url");
  },
  isPreviewAvailable(file) {
    return previewableFileTypes.has(FileUtils.getMimeType(file));
  },
  isThumbnailAvailable(file) {
    return FileUtils.isPreviewAvailable(file) && !!file.get("thumbnail");
  },
  isPDF(file) {
    return mime.getType(file.get("filename")) === "application/pdf";
  },
  getMimeType(file) {
    const filename = file.get("filename") || "";
    return mime.getType(filename) || "";
  }
};
export default FileUtils;
