import React from "react";
import { Select } from "@chakra-ui/react";
export var Visibility = /* @__PURE__ */ ((Visibility2) => {
  Visibility2[Visibility2["Everyone"] = 0] = "Everyone";
  Visibility2[Visibility2["ManagersAndVendorsOnly"] = 1] = "ManagersAndVendorsOnly";
  Visibility2[Visibility2["ManagersAndTenantsOnly"] = 2] = "ManagersAndTenantsOnly";
  Visibility2[Visibility2["ManagersOnly"] = 3] = "ManagersOnly";
  Visibility2[Visibility2["ManagersAndOwnersOnly"] = 4] = "ManagersAndOwnersOnly";
  return Visibility2;
})(Visibility || {});
export default class CommentVisibilitySelector extends React.Component {
  constructor(props) {
    super(props);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
  }
  render() {
    return /* @__PURE__ */ React.createElement(
      Select,
      {
        className: "message-visibility",
        marginTop: "10px",
        onChange: this.onVisibilityChange,
        value: this.props.selectedVisibility,
        width: "auto"
      },
      /* @__PURE__ */ React.createElement("option", { value: 0 /* Everyone */ }, "Managers, Vendors, Tenants"),
      this.props.showVendors && /* @__PURE__ */ React.createElement("option", { value: 1 /* ManagersAndVendorsOnly */ }, "Managers, Vendors"),
      this.props.showTenants && /* @__PURE__ */ React.createElement("option", { value: 2 /* ManagersAndTenantsOnly */ }, "Managers, Tenants"),
      this.props.showOwners && /* @__PURE__ */ React.createElement("option", { value: 4 /* ManagersAndOwnersOnly */, disabled: this.props.disableOwners }, "Managers, Owners"),
      this.props.showManagers && /* @__PURE__ */ React.createElement("option", { value: 3 /* ManagersOnly */ }, "Managers")
    );
  }
  onVisibilityChange(e) {
    this.props.onVisibilityChange(+e.currentTarget.value);
  }
}
