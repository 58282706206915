import Combobox from "react-widgets/lib/Combobox";
import React from "react";
import AbbreviatedCommentTemplateStore from "../stores/abbreviated-comment-template-store";
import CommentTemplateStore from "../stores/comment-template-store";
import connect from "../../common/connect";
import { LinkHelper } from "@pm-shared/utils/link";
import { ErrorHandler } from "../../../../app/utils/ErrorHandler";
class CommentTemplateDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };
    this.onSelectTemplate = this.onSelectTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  render() {
    if (LinkHelper.getOrgType() === "m") {
      return /* @__PURE__ */ React.createElement(
        Combobox,
        {
          busy: (
            // @ts-ignore See common.stores.Store.initRequestFunctions()
            AbbreviatedCommentTemplateStore.fetchAllResult.loading
          ),
          data: this.props.templates ? this.props.templates.toJS() : [],
          onChange: this.handleChange,
          onBlur: this.props.onBlur,
          onSelect: this.onSelectTemplate,
          open: true,
          autoFocus: true,
          placeholder: "Select a template...",
          textField: "name",
          valueField: "id",
          dropUp: true,
          value: this.state.value,
          filter: "contains"
        }
      );
    } else {
      return null;
    }
  }
  onSelectTemplate(template) {
    let fullTemplate = CommentTemplateStore.state.models.find((t) => t.get("id") === template.id);
    if (fullTemplate) {
      this.props.onSelectTemplate(fullTemplate);
    } else {
      CommentTemplateStore.fetchTemplate(template.id).then(() => {
        this.props.onSelectTemplate(CommentTemplateStore.getFetchedModels().first());
      }).catch((error) => ErrorHandler.handleAxiosError(error));
    }
  }
  handleChange(value) {
    this.setState({ value });
  }
}
export default connect(CommentTemplateDropdown, {
  stores: [AbbreviatedCommentTemplateStore],
  props: {
    templates() {
      return AbbreviatedCommentTemplateStore.getAll();
    }
  },
  componentWillMount() {
    if (LinkHelper.getOrgType() === "m") {
      AbbreviatedCommentTemplateStore.fetchAll().catch((error) => ErrorHandler.handleAxiosError(error));
    }
  }
});
