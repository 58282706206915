import React, { Component } from "react";
import Tag from "../../svg/tag-svg";
class MeldCardTags extends Component {
  render() {
    let tags = this.props.meld.get("tags");
    if (tags.count()) {
      return /* @__PURE__ */ React.createElement("div", { className: "card-attributes" }, /* @__PURE__ */ React.createElement("span", { className: "card-attribute card-accent" }, /* @__PURE__ */ React.createElement("span", { className: "card-icon" }, /* @__PURE__ */ React.createElement(Tag, null)), /* @__PURE__ */ React.createElement("span", null, tags.map((tag) => /* @__PURE__ */ React.createElement("span", { className: "tag pilled square", key: tag.get("name") }, tag.get("name"))))));
    } else {
      return null;
    }
  }
}
export default MeldCardTags;
