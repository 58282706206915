import { getDesignator } from "@pm-shared/utils/unit";
export function getDisplayProperty(meld) {
  if (meld.unit && meld.unit.display_address) {
    return meld.unit.display_address;
  } else if (meld.unit) {
    return meld.unit.prop;
  }
  return meld.prop;
}
export function getProperty(meld) {
  if (meld.unit) {
    return meld.unit.prop;
  }
  return meld.prop;
}
export function getDisplayAddressLine1(meld) {
  const prop = getDisplayProperty(meld);
  return prop.line_1;
}
export function getDisplayCity(meld) {
  const prop = getDisplayProperty(meld);
  return prop.city;
}
export function getDisplayPostcode(meld) {
  const prop = getDisplayProperty(meld);
  return prop.postcode;
}
export function getDisplayCountyProvince(meld) {
  const prop = getDisplayProperty(meld);
  return prop.county_province;
}
export function getPropertyName(meld) {
  const property = getProperty(meld);
  if (property.property_name !== getDisplayAddressLine1(meld)) {
    return property.property_name;
  }
  return "";
}
export function getUnitDesignator(meld) {
  if (meld.unit) {
    return getDesignator(meld.unit);
  }
}
export function getFullDisplayAddress(meld) {
  const unit = getUnitDesignator(meld);
  return `${getDisplayAddressLine1(meld)} ${unit ? unit + " " : ""}${getDisplayCity(meld)}, ${getDisplayCountyProvince(meld)} ${getDisplayPostcode(meld)}`;
}
