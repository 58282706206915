import React, { useEffect } from "react";
import { EuiFlexGrid, EuiFlexItem, EuiPageTemplate } from "@elastic/eui";
import { useIsMobile } from "../hooks/useIsMobile";
import {
  InnerPageContentHeight,
  MobileInnerPageContentHeight,
  PageContentHeight,
  PageContentMaxWidth
} from "@pm-frontend/styles";
import { showRedesignNavigation } from "@pm-assets/js/utils/redesign-routing";
import Features from "@pm-assets/js/common/feature-flags";
import { GlobalBanners } from "@pm-frontend/shared/layouts/GlobalBanners";
const ApplicationContent = () => /* @__PURE__ */ React.createElement(React.Fragment, null);
const OldUI = ({ children }) => {
  const isMobile = useIsMobile();
  if (isMobile) {
    return /* @__PURE__ */ React.createElement(
      EuiPageTemplate.Section,
      {
        className: "eui-yScroll",
        contentProps: { style: { padding: "0px" } },
        restrictWidth: false
      },
      Features.isWorkLogBannerEnabled() && /* @__PURE__ */ React.createElement(GlobalBanners, null),
      children
    );
  }
  return /* @__PURE__ */ React.createElement(
    EuiPageTemplate.Section,
    {
      className: "eui-yScroll",
      contentProps: { style: { padding: "0px 16px" } },
      restrictWidth: false,
      style: {
        maxHeight: PageContentHeight,
        height: PageContentHeight,
        paddingBottom: "8px"
      },
      id: "application-content-oldui"
    },
    Features.isWorkLogBannerEnabled() && /* @__PURE__ */ React.createElement(GlobalBanners, null),
    children
  );
};
const Scrollable = ({
  alwaysShowScroll = false,
  children,
  documentTitle
}) => {
  const isMobile = useIsMobile();
  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);
  if (isMobile) {
    const paddingMobile = "4px 16px 64px 16px";
    return /* @__PURE__ */ React.createElement(
      EuiPageTemplate.Section,
      {
        className: "eui-scrollBar",
        contentProps: { style: { padding: paddingMobile } },
        restrictWidth: true,
        style: { overflowY: alwaysShowScroll ? "scroll" : "auto" }
      },
      Features.isWorkLogBannerEnabled() && /* @__PURE__ */ React.createElement(GlobalBanners, null),
      children
    );
  }
  const showUIRedesignNavigation = showRedesignNavigation();
  const paddingDesktop = "4px 0px";
  return /* @__PURE__ */ React.createElement(
    EuiPageTemplate.Section,
    {
      className: "eui-scrollBar",
      contentProps: { style: { padding: paddingDesktop } },
      restrictWidth: PageContentMaxWidth,
      style: {
        maxHeight: showUIRedesignNavigation ? PageContentHeight : void 0,
        padding: "0px 8px 0px 16px",
        // leaving this logic here in case we decide to alter later
        overflowY: alwaysShowScroll ? "scroll" : "scroll"
      },
      id: "application-content-scrollable"
    },
    /* @__PURE__ */ React.createElement(
      EuiFlexGrid,
      {
        style: {
          gap: "16px",
          height: "100%",
          maxHeight: "100%",
          gridTemplateRows: "auto 1fr",
          gridTemplateColumns: "100%"
        }
      },
      /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, Features.isWorkLogBannerEnabled() && /* @__PURE__ */ React.createElement(GlobalBanners, null)),
      /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: true }, children)
    )
  );
};
const NonScrollable = ({
  children,
  forceMobileNoScroll = false,
  documentTitle
}) => {
  const isMobile = useIsMobile();
  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);
  const paddingMobile = "4px 16px 16px 16px";
  if (isMobile && !forceMobileNoScroll) {
    return /* @__PURE__ */ React.createElement(EuiPageTemplate.Section, { className: "eui-yScroll", contentProps: { style: { padding: paddingMobile } } }, Features.isWorkLogBannerEnabled() && /* @__PURE__ */ React.createElement(GlobalBanners, null), children);
  }
  const paddingDesktop = "4px 0px 0px 0px";
  return /* @__PURE__ */ React.createElement(
    EuiPageTemplate.Section,
    {
      contentProps: {
        style: {
          height: isMobile ? MobileInnerPageContentHeight : InnerPageContentHeight,
          padding: isMobile ? paddingMobile : paddingDesktop
        }
      },
      restrictWidth: isMobile ? void 0 : PageContentMaxWidth
    },
    /* @__PURE__ */ React.createElement(
      EuiFlexGrid,
      {
        gutterSize: "m",
        style: {
          gap: isMobile ? "0px" : "16px",
          overflow: "hidden",
          height: "100%",
          maxHeight: "100%",
          gridTemplateRows: "auto 1fr",
          gridTemplateColumns: "100%"
        }
      },
      /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, Features.isWorkLogBannerEnabled() ? /* @__PURE__ */ React.createElement(GlobalBanners, null) : null),
      /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: true, style: { overflow: "hidden" } }, children)
    )
  );
};
ApplicationContent.Scrollable = Scrollable;
ApplicationContent.NonScrollable = NonScrollable;
ApplicationContent.OldUI = OldUI;
export { ApplicationContent };
