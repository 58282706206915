import React from "react";
import { EuiFlyout, EuiPopover } from "@elastic/eui";
import { css } from "@emotion/react";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";
import { SiteSearchLogo } from "./SiteSearchLogo";
import { colors } from "@pm-frontend/styles";
const searchPopoverCss = css`
  & > div {
    display: flex;
    overflow: hidden;
    flex: 1;
  }
`;
export const RIGHT_COLUMN_MIN_WIDTH = "40%";
const SiteSearchBase = ({
  children,
  setSearchIsOpen,
  isOpen,
  onClose
}) => {
  const isMobile = useIsMobile();
  const searchLogoItem = /* @__PURE__ */ React.createElement(SiteSearchLogo, { isOpen, setSearchIsOpen });
  if (isMobile && isOpen) {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, searchLogoItem, isOpen && /* @__PURE__ */ React.createElement(
      EuiFlyout,
      {
        onClose: () => setSearchIsOpen(false),
        hideCloseButton: true,
        style: { padding: "32px 16px 32px 16px", background: colors.neutrals.gray50 }
      },
      /* @__PURE__ */ React.createElement("div", { className: "eui-yScroll", style: { maxHeight: "100%", overflow: "scroll" } }, children)
    ));
  }
  return /* @__PURE__ */ React.createElement(
    EuiPopover,
    {
      button: searchLogoItem,
      isOpen,
      closePopover: onClose,
      hasArrow: false,
      attachToAnchor: false,
      panelStyle: {
        width: "min(80vw, 2000px)",
        maxHeight: "90vh",
        overflow: "hidden",
        display: "flex"
      },
      panelProps: {
        css: searchPopoverCss
      },
      initialFocus: "#site-search-text-box",
      focusTrapProps: {
        noIsolation: false,
        scrollLock: true
      },
      style: { height: "24px", cursor: "pointer" },
      display: "flex"
    },
    children
  );
};
export { SiteSearchBase };
