import I from "immutable";
function createModelStore(store) {
  return class extends store {
    constructor() {
      super();
      this.state.models = I.List();
    }
    getAll() {
      return this.state.models;
    }
    getOne(id) {
      id = Number.parseInt(id, 10);
      return this.state.models.find((m) => m.id === id);
    }
    beginTransaction() {
      this._models = this.state.models;
    }
    abortTransaction() {
      if (this._models) {
        this.setState({ models: this._models });
        this._models = null;
      }
    }
  };
}
export default createModelStore;
