import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";
class TenantApi extends BaseApi {
  static save(tenant, { options } = {}) {
    return this.post(ApiUrls.tenantList, tenant, options);
  }
  static fetchAll({ options } = {}) {
    return this.get(ApiUrls.tenantList, options);
  }
  static fetchOne(id) {
    return this.get(ApiUrls.tenantDetail(id));
  }
  static update(tenant) {
    return this.put(ApiUrls.tenantDetail(tenant.id), tenant);
  }
  static fetchAllUnits(tenantId) {
    return this.get(ApiUrls.tenantUnits(tenantId));
  }
  static deleteTenant(id) {
    return this.delete(ApiUrls.tenantDetail(id));
  }
  static sendResetPasswordTenant(id) {
    return this.post(ApiUrls.tenantSendResetPassword(id));
  }
  static search(query, {
    tenantIdExclusions,
    unregistered,
    unit
  } = {}) {
    return this.get(ApiUrls.tenantSearchList, {
      params: {
        query,
        exclude_ids: tenantIdExclusions,
        unregistered,
        unit
      }
    });
  }
  static testBulkInvite(includeIds, excludeIds) {
    return this.post(ApiUrls.tenantBulkInviteTest, {
      include_ids: includeIds,
      exclude_ids: excludeIds
    });
  }
  static sendBulkInvite(includeIds, excludeIds) {
    return this.post(ApiUrls.tenantBulkInvite, {
      include_ids: includeIds,
      exclude_ids: excludeIds
    });
  }
}
export default TenantApi;
