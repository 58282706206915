import React from "react";
import URL from "@pm-shared/utils/url";
import { ErrorHandler } from "@pm-app/utils/ErrorHandler";
export default class ErrorView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    if (!this.state.error) {
      return this.props.children || null;
    }
    return /* @__PURE__ */ React.createElement("div", { className: "_500" }, /* @__PURE__ */ React.createElement("img", { src: URL.getStatic("img/_500.png") }), /* @__PURE__ */ React.createElement("p", null, "Looks like an error. We're working on fixing it. Try refreshing your page or", " ", /* @__PURE__ */ React.createElement("a", { href: "/" }, "view your home page")));
  }
  componentDidCatch(error, info) {
    ErrorHandler.handleError(error, info);
    this.setState({ error });
  }
}
