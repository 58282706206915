import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";
class VendorAPI extends BaseApi {
  static finishRegistration(data) {
    return this.post(ApiUrls.finishVendorRegistration, data);
  }
  static fetchAll() {
    return this.get(ApiUrls.vendorList);
  }
  static fetchOne(id) {
    return this.get(ApiUrls.vendorDetail(id));
  }
  static deleteVendor(id) {
    return this.delete(ApiUrls.vendorDetail(id));
  }
  static fetchAbbreviated() {
    return this.get(ApiUrls.abbreviatedVendorList);
  }
  static updateExcludedPropertyGroups(id, excludedPropertyGroupIds) {
    return this.patch(ApiUrls.vendorExcludedPropertyGroupsList(id), {
      excluded_property_groups: excludedPropertyGroupIds
    });
  }
  static update(vendor) {
    return this.patch(ApiUrls.vendorDetail(vendor.id), vendor);
  }
  static updateNotes(vendor) {
    return this.put(ApiUrls.vendorDetail(vendor.id), vendor);
  }
}
export default VendorAPI;
