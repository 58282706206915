import React, { useRef } from "react";
import { EuiFieldText, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from "@elastic/eui";
import { css } from "@emotion/react";
import URL from "@pm-shared/utils/url";
import { colors } from "@pm-frontend/styles";
const HOVER_MOUSE_COLOR = colors.neutrals.gray300;
export const searchResultHoverCss = css`
  & .global-search-result:hover {
    background: ${HOVER_MOUSE_COLOR};
  }
`;
const SiteSearchDesktopBase = ({
  searchText,
  setSearchText,
  body,
  filter,
  checkbox,
  iconString,
  title,
  isFetching,
  isError
}) => {
  const onChange = (e) => {
    setSearchText(e.target.value);
  };
  const searchInputRef = useRef(null);
  return /* @__PURE__ */ React.createElement(EuiFlexGrid, { responsive: false, columns: 1, style: { overflow: "hidden", flex: 1 } }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
    EuiFlexGroup,
    {
      direction: "row",
      responsive: false,
      justifyContent: "spaceBetween",
      style: { padding: "0px 24px 0px 12px" }
    },
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: true }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, alignItems: "center", style: { gap: "12px" } }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiIcon, { type: URL.getStatic("icons/search-header_blue.svg"), size: "l" })), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: true }, /* @__PURE__ */ React.createElement(
      EuiFieldText,
      {
        id: "site-search-text-box",
        value: searchText,
        onChange: (e) => onChange(e),
        fullWidth: true,
        style: { color: colors.neutrals.gray700, fontSize: "16px", lineHeight: "24px" },
        isLoading: isFetching,
        isInvalid: isError,
        inputRef: searchInputRef,
        autoComplete: "off"
      }
    )))),
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, alignItems: "center", gutterSize: "s" }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
      EuiText,
      {
        style: {
          borderRadius: "6px",
          border: `1.5px solid ${colors.neutrals.gray600}`,
          padding: "4px 8px",
          fontWeight: 600
        },
        size: "xs",
        color: colors.neutrals.gray600
      },
      "ESC"
    )), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiText, { size: "s", color: colors.neutrals.gray600 }, "to exit"))))
  )), /* @__PURE__ */ React.createElement(EuiFlexGrid, { responsive: false, style: { gridTemplateColumns: "min-content 1fr", overflow: "hidden" } }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, filter), /* @__PURE__ */ React.createElement(EuiFlexGrid, { columns: 1, style: { padding: "24px 0px 24px 0px", overflow: "hidden", alignContent: "baseline" } }, title && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
    EuiFlexGroup,
    {
      direction: "row",
      justifyContent: "spaceBetween",
      responsive: false,
      style: { padding: "0px 32px 0px 32px" },
      alignItems: "center"
    },
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", alignItems: "center", gutterSize: "s" }, iconString && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiIcon, { type: iconString })), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiText, { color: colors.neutrals.gray800, style: { fontWeight: 700, whiteSpace: "nowrap" } }, title)))),
    checkbox && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, checkbox)
  )), /* @__PURE__ */ React.createElement(
    EuiFlexItem,
    {
      grow: false,
      className: "eui-yScroll",
      "data-testid": "global-search-results",
      css: searchResultHoverCss
    },
    body
  ))));
};
export { SiteSearchDesktopBase };
