import Axios from "axios";
import { onFulfilled, onRejected, reqOnFulfilled, reqOnRejected } from "@pm-assets/js/common/api";
import { AuthUtils } from "@pm-assets/js/utils/auth-utils";
export const axios = Axios.create({
  headers: {
    "X-CSRFToken": AuthUtils.getCsrfToken()
  }
});
axios.interceptors.response.use(onFulfilled, onRejected);
axios.interceptors.request.use(reqOnFulfilled, reqOnRejected);
