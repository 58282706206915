import React from "react";
import classnames from "classnames";
import ReactDOM from "react-dom";
export default class DropdownButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false
    };
    this.onButtonClicked = this.onButtonClicked.bind(this);
    this.onWindowClick = this.onWindowClick.bind(this);
  }
  componentDidUpdate() {
    if (this.state.isActive) {
      window.addEventListener("click", this.onWindowClick, false);
    }
  }
  componentWillUnmount() {
    window.removeEventListener("click", this.onWindowClick);
  }
  onWindowClick(e) {
    let target = e.target;
    const domNode = ReactDOM.findDOMNode(this);
    if (!(domNode && target instanceof Node && domNode.contains(target))) {
      if (this.state.isActive) {
        this.setState({ isActive: false });
      }
      window.removeEventListener("click", this.onWindowClick, false);
      return;
    }
    const orig = target;
    let el = orig;
    while (el) {
      el = el.parentElement;
      let cls = el && el.getAttribute("class");
      if (cls && cls.search("pm-dropdown-button-container") !== -1) {
        if (this.state.isActive && orig && orig.getAttribute && orig.getAttribute("class") && orig.getAttribute("class") === "action" && orig.tagName === "A") {
          this.setState({ isActive: false });
          window.removeEventListener("click", this.onWindowClick, false);
          return;
        }
        break;
      }
    }
  }
  render() {
    const classes = classnames(
      "pm-dropdown-button-container pm-button",
      { "pm-content-left": this.props.contentLeft },
      this.props.className
    );
    const buttonContainerClasses = classnames("pm-dropdown-buttons-content-button-container");
    return /* @__PURE__ */ React.createElement("div", { className: classes, "data-testid": this.props.testId }, /* @__PURE__ */ React.createElement("div", { className: "pm-dropdown-button-btn", onClick: this.onButtonClicked }, this.props.renderButton()), this.state.isActive && /* @__PURE__ */ React.createElement("div", { className: "pm-dropdown-button-content-container" }, /* @__PURE__ */ React.createElement("div", { onClick: this.onButtonClicked, className: buttonContainerClasses }, this.props.renderButton()), /* @__PURE__ */ React.createElement("div", { className: "pm-dropdown-button-content" }, this.props.renderContents())));
  }
  onButtonClicked() {
    this.setState({
      isActive: !this.state.isActive
    });
  }
}
