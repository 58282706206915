import React from "react";
class LineItemDescription extends React.Component {
  render() {
    let { lineItem } = this.props;
    return /* @__PURE__ */ React.createElement("div", { style: { width: "100%", marginTop: "7px" } }, /* @__PURE__ */ React.createElement("label", { className: "line-item-label" }, "Description"), /* @__PURE__ */ React.createElement(
      "textarea",
      {
        rows: 1,
        "data-test": "line-item-description.input",
        className: "line-item-description-input",
        placeholder: "Line item description",
        value: lineItem.get("description"),
        onChange: this.props.onDescriptionChanged,
        disabled: this.props.disabled,
        style: { maxWidth: "100%", minHeight: "2.9rem" },
        "data-testid": "expenditure-description-input"
      }
    ));
  }
}
export default LineItemDescription;
