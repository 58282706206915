import CalendarUtils from "../../utils/calendar-utils";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import moment from "moment";
import PropTypes from "prop-types";
import createReactClass from "create-react-class";
import React from "react";
let DateUnits = {
  MONTHS: "months",
  DAYS: "days",
  WEEKS: "weeks"
};
let SubChoices = {
  BETWEEN: "BETWEEN",
  IN_THE_PAST: "IN_THE_PAST",
  OFFSET_LTE: "OFFSET_LTE"
};
const SUB_CHOICE = "SubChoice";
const GTE_SUFFIX = "__gte";
const LTE_SUFFIX = "__lte";
const INTERVAL_SUFFIX = "__interval";
const OFFSET_LTE_SUFFIX = "__offset_lte";
let DateFilter = createReactClass({
  propTypes: {
    filterName: PropTypes.string.isRequired,
    filterUpdated: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    isFilterOpen: PropTypes.bool.isRequired,
    maxFromDate: PropTypes.instanceOf(Date),
    maxToDate: PropTypes.instanceOf(Date),
    minFromDate: PropTypes.instanceOf(Date),
    minToDate: PropTypes.instanceOf(Date)
  },
  getInitialState() {
    return {
      [this.props.filterName + SUB_CHOICE]: null,
      intervalUnit: this.dateUnit(),
      offsetUnit: this.offsetUnit()
    };
  },
  render() {
    let { filterName, isFilterOpen } = this.props;
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: "filter-type" }, /* @__PURE__ */ React.createElement(
      "input",
      {
        type: "checkbox",
        id: `date-filter-${filterName}`,
        checked: isFilterOpen,
        onChange: () => this.props.onToggleFilter(filterName)
      }
    ), /* @__PURE__ */ React.createElement("label", { htmlFor: `date-filter-${filterName}` }, this.props.children)), isFilterOpen ? this.renderChoices() : null);
  },
  renderChoices() {
    let { filterName } = this.props;
    let subChoice = this.state[filterName + SUB_CHOICE] || this.calcSubChoice();
    return /* @__PURE__ */ React.createElement("div", { className: "filter-choices" }, /* @__PURE__ */ React.createElement("div", { className: "filter-choice" }, /* @__PURE__ */ React.createElement("select", { className: "filter-select", onChange: this.onSubChoiceSelected, value: subChoice }, /* @__PURE__ */ React.createElement("option", { value: SubChoices.BETWEEN }, "Date Range"), /* @__PURE__ */ React.createElement("option", { value: SubChoices.IN_THE_PAST }, "In the past"), this.renderBeforeOption()), this.renderSubChoices()));
  },
  renderBeforeOption() {
    if (this.props.offsetLTELabel) {
      return /* @__PURE__ */ React.createElement("option", { value: SubChoices.OFFSET_LTE }, this.props.offsetLTELabel);
    }
  },
  renderSubChoices() {
    let { filterName } = this.props;
    let subChoice = this.state[filterName + SUB_CHOICE] || this.calcSubChoice();
    if (subChoice === SubChoices.BETWEEN) {
      return /* @__PURE__ */ React.createElement("span", { className: "date-filter-sub-choices" }, /* @__PURE__ */ React.createElement(
        DateTimePicker,
        {
          time: false,
          defaultValue: this.getDateFilterFieldValue(filterName + GTE_SUFFIX),
          placeholder: "After",
          parse: this.parseDate,
          max: this.props.maxFromDate,
          min: this.props.minFromDate,
          onChange: this.handleGTEChange
        }
      ), /* @__PURE__ */ React.createElement(
        DateTimePicker,
        {
          time: false,
          placeholder: "Before",
          defaultValue: this.getDateFilterFieldValue(filterName + LTE_SUFFIX),
          max: this.props.maxToDate,
          min: this.props.minToDate,
          parse: this.parseDate,
          onChange: this.handleLTEChange
        }
      ));
    } else if (subChoice === SubChoices.IN_THE_PAST) {
      return /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(
        "input",
        {
          type: "text",
          placeholder: "# of",
          className: "time-in-past",
          value: this.getDateUnitValueForCategory(),
          onChange: this.handleIntervalChanged
        }
      ), /* @__PURE__ */ React.createElement(
        "select",
        {
          className: "time-in-past-unit",
          value: this.state.intervalUnit,
          onChange: this.handleIntervalValueChanged
        },
        /* @__PURE__ */ React.createElement("option", { value: DateUnits.DAYS }, "Days"),
        /* @__PURE__ */ React.createElement("option", { value: DateUnits.MONTHS }, "Months")
      ));
    } else if (subChoice === SubChoices.OFFSET_LTE) {
      return /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(
        "input",
        {
          type: "text",
          placeholder: "0",
          className: "time-in-past",
          value: this.getBeforeValueForInput(),
          onChange: this.handleOffsetChanged
        }
      ), /* @__PURE__ */ React.createElement(
        "select",
        {
          className: "time-in-past-unit",
          value: this.state.offsetUnit,
          onChange: this.handleOffsetThanUnitChanged
        },
        /* @__PURE__ */ React.createElement("option", { value: DateUnits.DAYS }, "Days"),
        /* @__PURE__ */ React.createElement("option", { value: DateUnits.WEEKS }, "Weeks"),
        /* @__PURE__ */ React.createElement("option", { value: DateUnits.MONTHS }, "Months")
      ));
    }
  },
  calcSubChoice() {
    let subChoice = SubChoices.BETWEEN;
    let { filterName, filters } = this.props;
    if (filters[filterName + INTERVAL_SUFFIX]) {
      subChoice = SubChoices.IN_THE_PAST;
    } else if (filters[filterName + OFFSET_LTE_SUFFIX]) {
      subChoice = SubChoices.OFFSET_LTE;
    }
    return subChoice;
  },
  parseDate(dateStr) {
    let date = new Date(dateStr);
    if (CalendarUtils.isValidDate(date)) {
      return date;
    }
  },
  onSubChoiceSelected(e) {
    this.setState({ [this.props.filterName + SUB_CHOICE]: e.target.value });
  },
  handleLTEChange(date) {
    let newDate = date ? moment(date).add(1, "days").startOf("day").toDate().getTime() : null;
    this.updateFilters({ lte: newDate });
  },
  handleGTEChange(date) {
    let newDate = date ? moment(date).startOf("day").toDate().getTime() : null;
    this.updateFilters({ gte: newDate });
  },
  handleIntervalValueChanged(e) {
    this.setState({ intervalUnit: e.target.value });
    this.updateFilters({ interval: "" });
  },
  handleIntervalChanged(e) {
    let dateUnit = this.state.intervalUnit;
    let val;
    let unitQuantity = parseInt(e.target.value, 10);
    if (unitQuantity > 9999) {
      unitQuantity = 9999;
    }
    if (Number.isInteger(unitQuantity)) {
      val = moment.duration({ [dateUnit]: unitQuantity }).toISOString();
    } else {
      val = "";
    }
    this.updateFilters({ interval: val });
  },
  handleOffsetThanUnitChanged(e) {
    const newUnit = e.target.value;
    this.setState({ offsetUnit: newUnit });
    let offsetVal = this.props.filters[this.props.filterName + OFFSET_LTE_SUFFIX];
    offsetVal = this.stripAllButIntegers(offsetVal);
    const val = `-${offsetVal}${newUnit.slice(0, 1)}`;
    this.props.filterUpdated(this.props.filterName + OFFSET_LTE_SUFFIX, val);
  },
  handleOffsetChanged(e) {
    let val = this.stripAllButIntegers(e.target.value);
    if (val) {
      val = `-${val}${this.state.offsetUnit.slice(0, 1)}`;
    }
    this.updateFilters({ offset: val });
  },
  getDateFilterFieldValue(filterName) {
    let filterValue = this.props.filters[filterName];
    if (filterValue) {
      let date = /* @__PURE__ */ new Date();
      date.setTime(filterValue);
      if (["created__lte", "scheduled__lte", "completed__lte"].includes(filterName)) {
        date.setDate(date.getDate() - 1);
      }
      return date;
    } else {
      return null;
    }
  },
  getBeforeValueForInput() {
    let offsetVal = this.props.filters[this.props.filterName + OFFSET_LTE_SUFFIX] || "";
    return this.stripAllButIntegers(offsetVal);
  },
  stripAllButIntegers(val) {
    return val ? val.replace(/[^0-9]+/g, "") : "";
  },
  getDateUnitValueForCategory() {
    let unitValue = this.state.intervalUnit;
    let interval = this.props.filters[this.props.filterName + INTERVAL_SUFFIX];
    if (interval) {
      interval = moment.duration(interval);
      if (unitValue === DateUnits.DAYS) {
        return interval.asDays();
      } else {
        return interval.asMonths();
      }
    }
  },
  offsetUnit() {
    let offsetVal = this.props.filters[this.props.filterName + OFFSET_LTE_SUFFIX];
    if (offsetVal) {
      if (offsetVal.includes("d")) {
        return DateUnits.DAYS;
      } else if (offsetVal.includes("w")) {
        return DateUnits.WEEKS;
      } else if (offsetVal.includes("m")) {
        return DateUnits.MONTHS;
      }
    }
    return DateUnits.DAYS;
  },
  dateUnit() {
    let interval = this.props.filters[this.props.filterName + INTERVAL_SUFFIX];
    if (interval) {
      interval = moment.duration(interval);
      if (interval.days()) {
        return DateUnits.DAYS;
      }
      return DateUnits.MONTHS;
    }
    return DateUnits.DAYS;
  },
  updateFilters({ offset, interval, gte, lte }) {
    if (offset !== void 0) {
      this.props.filterUpdated(this.props.filterName + GTE_SUFFIX, "");
      this.props.filterUpdated(this.props.filterName + LTE_SUFFIX, "");
      this.props.filterUpdated(this.props.filterName + OFFSET_LTE_SUFFIX, offset);
      this.props.filterUpdated(this.props.filterName + INTERVAL_SUFFIX, "");
    } else if (interval !== void 0) {
      this.props.filterUpdated(this.props.filterName + GTE_SUFFIX, "");
      this.props.filterUpdated(this.props.filterName + LTE_SUFFIX, "");
      this.props.filterUpdated(this.props.filterName + OFFSET_LTE_SUFFIX, "");
      this.props.filterUpdated(this.props.filterName + INTERVAL_SUFFIX, interval);
    } else if (gte !== void 0) {
      this.props.filterUpdated(this.props.filterName + GTE_SUFFIX, gte);
      this.props.filterUpdated(this.props.filterName + OFFSET_LTE_SUFFIX, "");
      this.props.filterUpdated(this.props.filterName + INTERVAL_SUFFIX, "");
    } else if (lte !== void 0) {
      this.props.filterUpdated(this.props.filterName + LTE_SUFFIX, lte);
      this.props.filterUpdated(this.props.filterName + OFFSET_LTE_SUFFIX, "");
      this.props.filterUpdated(this.props.filterName + INTERVAL_SUFFIX, "");
    }
    if (interval !== void 0) {
      this.setState({
        [this.props.filterName + SUB_CHOICE]: SubChoices.IN_THE_PAST
      });
    } else if (offset !== void 0) {
      this.setState({
        [this.props.filterName + SUB_CHOICE]: SubChoices.OFFSET_LTE
      });
    }
  }
});
export default DateFilter;
