import Currency from "../../utils/currency";
import Delete from "../../svg/delete-svg";
import LineItem from "../../payment/components/line-item";
import LineItemCost from "../../payment/components/line-item-cost";
import LineItemDescription from "../../payment/components/line-item-description";
import React from "react";
import { BaseLineItemCostTypeEnum } from "../../common/openapi";
class EstimateLineItem extends LineItem {
  render() {
    let { lineItem } = this.props;
    return /* @__PURE__ */ React.createElement("div", { className: "row invoice-line-items-row" }, /* @__PURE__ */ React.createElement("div", { className: "line-item-field columns small-12 large-4" }, !this.props.disableInput && /* @__PURE__ */ React.createElement("span", { className: "line-item-delete-icon", onClick: () => this.props.onDeleteRequested(lineItem) }, /* @__PURE__ */ React.createElement(Delete, null)), /* @__PURE__ */ React.createElement(
      LineItemDescription,
      {
        lineItem,
        onDescriptionChanged: this.onDescriptionChanged,
        disabled: this.props.disableInput
      }
    )), /* @__PURE__ */ React.createElement("div", { className: "line-item-quantity columns small-12 large-2" }, /* @__PURE__ */ React.createElement("label", { className: "line-item-label" }, "Quantity"), /* @__PURE__ */ React.createElement(
      "input",
      {
        type: "text",
        value: this.state.currentQuantity,
        onChange: this.onUnitQuantityChanged,
        onBlur: this.finalizeQuantityChanges,
        disabled: this.props.disableInput
      }
    )), /* @__PURE__ */ React.createElement("div", { className: "columns small-12 large-2" }, /* @__PURE__ */ React.createElement(
      LineItemCost,
      {
        currentUnitCost: this.state.currentUnitCost,
        onUnitCostChanged: this.onUnitCostChanged,
        onFinalizeCostChanges: this.finalizeCostChanges,
        disableInput: this.props.disableInput
      }
    )), /* @__PURE__ */ React.createElement("div", { className: "columns small-12 large-2" }, /* @__PURE__ */ React.createElement(
      "select",
      {
        onChange: this.onCostTypeChanged,
        value: lineItem.get("line_item_type"),
        disabled: this.props.disableInput
      },
      /* @__PURE__ */ React.createElement("option", { value: BaseLineItemCostTypeEnum.FEE }, "Fee"),
      /* @__PURE__ */ React.createElement("option", { value: BaseLineItemCostTypeEnum.LABOR }, "Labor"),
      /* @__PURE__ */ React.createElement("option", { value: BaseLineItemCostTypeEnum.MATERIALS }, "Materials")
    )), /* @__PURE__ */ React.createElement("div", { className: "line-item-field columns small-12 large-2" }, /* @__PURE__ */ React.createElement("label", { className: "line-item-label" }, "Item Total"), /* @__PURE__ */ React.createElement("div", { className: "line-item-total" }, Currency.dollar(this.calcTotalEstimate()))));
  }
}
export default EstimateLineItem;
