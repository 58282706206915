let urls = {
  announcementsList: "/api/announcements/",
  abbreviatedCommentTemplateList: "/api/comment-templates/abbrev/",
  abbreviatedMeldList: "/api/melds/abbrev/",
  abbreviatedPropertyGroupList: "/api/properties/groups/abbrev/",
  abbreviatedPWVendorList: "/api/pw/vendors/abbrev/",
  abbreviatedUnitList: "/api/units/abbrev/",
  abbreviatedVendorList: "/api/vendors/abbrev/",
  agentAbbrevList: "/api/abbrev-agents/",
  agentDetail: (id) => `/api/agents/${id}/`,
  agentInviteDetail: (inviteId) => `/api/agent-invites/${inviteId}/`,
  agentInviteList: "/api/agent-invites/",
  agentList: "/api/agents/",
  agentMeDetail: `/api/agents/me/`,
  agentPropertyGroupsList: (id) => `/api/agents/${id}/property-groups/`,
  agentSearchList: "/api/agents/search/",
  agentUserGroupsList: (id) => `/api/agents/${id}/user-groups/`,
  appliedPropertyGroupsList: "/api/properties/groups/applied/",
  appointmentRequestDetail: (id) => `api/appointment-requests/${id}/`,
  appointmentRequests: "api/appointment-requests/",
  assignMaintenance: (id) => `/api/melds/${id}/assign-maintenance/`,
  assignCoordinator: (id) => `/api/melds/${id}/assign-coordinator/`,
  assignmentAppointmentRequestDetail: (assignmentId) => `api/assignments/${assignmentId}/appointment-request/`,
  assignmentRequestDetail: (id) => `/api/assignments/${id}/`,
  assignmentRequestList: "/api/assignments/",
  availabilitySegmentCreate: `/api/vendor-availability-segment/`,
  bookRequestedSegment: (id) => `api/requested-segments/${id}/book/`,
  bookRequestedSegments: () => `api/requested-segments/multiple/book/`,
  buildingDetail: (id) => `/api/buildings/${id}/`,
  buildingList: "/api/buildings/",
  calendarMeldDetail: (id) => `/api/calendar-melds/${id}/`,
  calendarMelds: "/api/calendar-melds/",
  canSubscribe: "/api/can-subscribe/",
  citySearchList: "/api/cities/search/",
  clientReload: "/api/client-reload/",
  commentDetail: (id) => `/api/comments/${id}/`,
  commentList: "/api/comments/",
  commentTemplateDetail: (id) => `/api/comment-templates/${id}/`,
  commentTemplateList: "/api/comment-templates/",
  companySettings: "/api/company-settings/",
  completeMeld: (id) => `/api/melds/${id}/complete/`,
  contactSettings: "/api/contact-settings/",
  couponDetail: (id) => `/api/coupons/${id}/`,
  countyProvinceSearchList: `/api/county-provinces/search/`,
  currentUser: "/api/users/current/",
  defaultCard: "/api/default-card/",
  emergencyContactSettings: "/api/emergency-contact-settings/",
  eventUnitDetail: (id) => `/api/event-unit/${id}/`,
  finishableTicketList: (id) => `/api/melds/${id}/melds/finishable/`,
  finishVendorRegistration: "/api/vendors/finish-registration/",
  floorBuildingsList: (propertyId) => `/api/properties/${propertyId}/floors/`,
  floorDetail: (id) => `/api/floors/${id}/`,
  floorList: "/api/floors/",
  graphQl: "/graphql",
  integrationDetail: (slug, id) => `/api/integration-partners/${slug}/integrations/${id}/`,
  integrationEnable: (slug) => `/api/integration-partners/${slug}/enable/`,
  integrationList: (slug) => `/api/integration-partners/${slug}/integrations/`,
  integrationPartnerDetail: (slug) => `/api/integration-partners/${slug}/`,
  integrationPartnerList: `/api/integration-partners/`,
  integrationsEnabled: "/api/integrations-enabled/",
  integrationSync: (slug) => `/api/integration-partners/${slug}/sync/`,
  invoiceFilterGroupDetail: (id) => `/api/invoice-filter-groups/${id}/`,
  invoiceFilterGroupDetails: (id) => `/api/invoice-filter-groups/${id}/details/`,
  invoiceFilterGroupFilters: (id) => `/api/invoice-filter-groups/${id}/filters/`,
  invoiceFilterGroupList: "/api/invoice-filter-groups/",
  invoiceFilterGroupReorder: (id) => `/api/invoice-filter-groups/${id}/reorder/`,
  maintenanceList: "/api/all-maintenance/",
  managementAppointmentDetail: (id) => `/api/management-appointments/${id}/`,
  managementAppointmentRequestMoreAvailability: (id) => `/api/management-appointments/${id}/request-more-availability/`,
  managementAppointmentSchedule: (id) => `/api/management-appointments/${id}/schedule/`,
  managementEventList: `/api/management-events/`,
  managementList: "/api/managements/",
  managerScheduleMeld: (id) => `/api/melds/${id}/accept/`,
  managerTenantCancellationRequest: (id) => `/api/tenant-cancellation-requests/${id}/`,
  managerTicketCompletionTimes: "/api/metrics/manager-ticket-completion-times/",
  managerTicketRatings: "/api/metrics/manager-ticket-ratings/",
  meldAppointmentRequestDetail: (meldId) => `api/melds/${meldId}/appointment-request/`,
  meldCancel: (id) => `/api/melds/${id}/cancel/`,
  meldCoordinator: (id) => `/api/melds/${id}/coordinator/`,
  meldExpendituresApprove: (expendituresId) => `/api/meld-expenditures/${expendituresId}/approve/`,
  meldExpendituresBill: (expendituresId) => `/api/meld-expenditures/${expendituresId}/bill/`,
  meldExpendituresDetail: (expendituresId) => `/api/meld-expenditures/${expendituresId}/`,
  meldExpendituresDraft: (expendituresId) => `/api/meld-expenditures/${expendituresId}/draft/`,
  meldExpendituresHold: (expendituresId) => `/api/meld-expenditures/${expendituresId}/hold/`,
  meldExpendituresList: "/api/meld-expenditures/",
  meldExpendituresReview: (expendituresId) => `/api/meld-expenditures/${expendituresId}/review/`,
  meldFileDetail: (fileId) => `/api/melds/files/${fileId}/`,
  meldFileGeneratePolicy: "/api/melds/files/generate-policy/",
  meldFileList: (meldId) => `/api/melds/${meldId}/files/`,
  meldFilterGroupDetail: (id) => `/api/meld-filter-groups/${id}/`,
  meldFilterGroupDetails: (id) => `/api/meld-filter-groups/${id}/details/`,
  meldFilterGroupFilters: (id) => `/api/meld-filter-groups/${id}/filters/`,
  meldFilterGroupList: "/api/meld-filter-groups/",
  meldFilterGroupReorder: (id) => `/api/meld-filter-groups/${id}/reorder/`,
  meldFilterGroupStatList: "/api/dashboard-meld-filter-groups/",
  meldFilterGroupSwap: (id) => `/api/meld-filter-groups/${id}/swap/`,
  meldInvoiceApprove: (invoiceId) => `/api/meld-invoices/${invoiceId}/approve/`,
  meldInvoiceDecline: (invoiceId) => `/api/meld-invoices/${invoiceId}/decline/`,
  meldInvoiceReview: (invoiceId) => `/api/meld-invoices/${invoiceId}/review/`,
  meldInvoiceDetail: (invoiceId) => `/api/meld-invoices/${invoiceId}/`,
  meldInvoiceHold: (invoiceId) => `/api/meld-invoices/${invoiceId}/hold/`,
  meldInvoiceList: "/api/meld-invoices/",
  meldInvoicesPay: "/api/meld-invoices/pay/",
  createReport: "/api/generate-report/",
  createMeldExportCSV: "/api/export-meld-csv",
  meldRequestedSegments: (meldId) => `api/melds/${meldId}/requested-segments/`,
  meldRescheduleSegments: (id) => `/api/melds/${id}/segments/reschedule/`,
  meldSegments: (id) => `/api/melds/${id}/segments/`,
  meldsList: "/api/melds/",
  meldTagDetail: (id) => `/api/melds/tags/${id}/`,
  meldTagList: "/api/melds/tags/",
  meldTags: (id) => `/api/melds/${id}/tags/`,
  meldTagSearchList: "/api/melds/tags/search/",
  meldTenants: (id) => `/api/melds/${id}/tenants/`,
  meldUserGroups: (id) => `/api/melds/${id}/user-groups/`,
  meldWatchSearch: `/api/melds/manager-watchlist-search/`,
  mgrAppSettings: "/api/app-settings/",
  myAgentPreferences: "/api/agent-preferences/me/",
  notificationList: "/api/notifications/",
  notificationMarkAllRead: "/api/notifications/mark-all-read/",
  notificationMarkRead: "/api/notifications/mark-read/",
  notificationSettings: "/api/notification-settings/",
  notificationsUnread: "/api/notifications/unread/",
  notificationTemplatePreview: "/api/notification-template-preview/",
  ownerMeldApprovalDetail: (multitenantId, meldId, secret) => `/api/melds/approval/${multitenantId}/${meldId}/${secret}/`,
  ownerMeldApprovalFinish: (multitenantId, meldId, secret) => `/api/melds/approval/finish/${multitenantId}/${meldId}/${secret}/`,
  messageTemplatePreview: "/api/message-template-preview/",
  ownerDetail: (id) => `/api/owners/${id}/`,
  ownerImportDetail: (id) => `/api/import/owners/${id}/`,
  ownerImportFile: "/api/import/owners/",
  ownerImportFinalize: (id) => `/api/import/owners/${id}/finalize/`,
  ownerList: "/api/owners/",
  ownerNotificationSettings: "/api/owners/notification-settings/",
  ownerPropertiesList: (ownerId) => `/api/owners/${ownerId}/properties/`,
  ownerPropertyUnlink: (ownerId, propertyId) => `/api/owners/${ownerId}/properties/${propertyId}/`,
  ownerReinvite: (id) => `/api/owners/${id}/reinvite`,
  ownerSearchList: "/api/owners/search/",
  ownerApprovalMessage: (meldId) => `/api/melds/${meldId}/owner-approval-message/`,
  ownerApprovalRequest: (meldId) => `/api/melds/${meldId}/owner-approval-request/`,
  ownerApprovalMessagePreview: (meldId) => `/api/melds/${meldId}/owner-approval-message-preview/`,
  ownerApprovalFinish: (meldId) => `/api/melds/${meldId}/owner-approval-finish/`,
  acknowledgeOwnerApproval: (meldId) => `/api/melds/${meldId}/owner-approval-acknowledge/`,
  plans: "/api/plans/",
  planSearch: (id) => `/api/plans/search/${id}/`,
  pmCouponDetail: (id) => `/api/pm-coupons/${id}/`,
  pmInvoicePay: "/api/pm-invoices/pay/",
  pmInvoicesList: "/api/pm-invoices/",
  projectsDetail: (id) => `/api/projects/${id}/`,
  productBoardJWT: `/api/users/current/productboard-jwt/`,
  propertiesAssign: "/api/properties/assign-property-groups/",
  propertiesDelete: "/api/properties/delete/",
  postcodeSearchList: `/api/postcodes/search/`,
  propertyBuildingsList: (propertyId) => `/api/properties/${propertyId}/buildings/`,
  propertyDetail: (id) => `/api/properties/${id}/`,
  propertyGroupPropertiesList: (id) => `/api/property-groups/${id}/properties/`,
  propertyGroupDetail: (id) => `/api/properties/groups/${id}/`,
  propertyGroupList: "/api/properties/groups/",
  propertyImportDetail: (id) => `/api/import/properties/${id}/`,
  propertyImportFile: "/api/import/properties/",
  propertyImportFinalize: (id) => `/api/import/properties/${id}/finalize/`,
  propertyReactivate: (id) => `/api/properties/${id}/reactivate/`,
  propertyList: "/api/properties/",
  propertySearchList: "/api/properties/search/",
  pwBuildings: "/api/pw/buildings/",
  pwBuildingsSync: "/api/pw/sync/buildings/",
  pwBuildingsSyncUpdate: (id) => `/api/pw/sync/buildings/${id}/`,
  pwLinkSearchBuildings: "/api/pw/link-search/buildings/",
  pwReverseLinkSearchBuildings: "/api/pw/link-search/buildings/reverse/",
  pwOwners: "/api/pw/owners/",
  pwOwnersSync: "/api/pw/sync/owners/",
  pwOwnersSyncUpdate: (id) => `/api/pw/sync/owners/${id}/`,
  pwLinkSearchOwners: "/api/pw/link-search/owners/",
  pwReverseLinkSearchOwners: "/api/pw/link-search/owners/reverse/",
  pwTenants: "/api/pw/tenants/",
  pwTenantsSync: "/api/pw/sync/tenants/",
  pwTenantsSyncUpdate: (id) => `/api/pw/sync/tenants/${id}/`,
  pwLinkSearchTenants: "/api/pw/link-search/tenants/",
  pwReverseLinkSearchTenants: "/api/pw/link-search/tenants/reverse/",
  pwUnits: "/api/pw/units/",
  pwUnitsSync: "/api/pw/sync/units/",
  pwUnitsSyncUpdate: (id) => `/api/pw/sync/units/${id}/`,
  pwLinkSearchUnits: "/api/pw/link-search/units/",
  pwReverseLinkSearchUnits: "/api/pw/link-search/units/reverse/",
  pwVendors: "/api/pw/vendors/",
  pwVendorsSync: "/api/pw/sync/vendors/",
  pwVendorsSyncUpdate: (id) => `/api/pw/sync/vendors/${id}/`,
  pwLinkSearchVendors: "/api/pw/link-search/vendors/",
  pwVendorUsersSync: "/api/pw/sync/vendor-users/",
  pwVendorUsersSyncUpdate: (id) => `/api/pw/sync/vendor-users/${id}/`,
  pwLinkSearchVendorUsers: "/api/pw/link-search/vendor-users/",
  quickbooks: "/quickbooks/",
  recurringMeldsDetail: (meldId) => `/api/melds/recurring/${meldId}/`,
  recurringMeldsList: "/api/melds/recurring/",
  relatedMeldsList: (meldId) => `/api/melds/${meldId}/related/`,
  reminderComplete: (id) => `/api/reminders/${id}/complete/`,
  reminderDetail: (id) => `/api/reminders/${id}/`,
  reminderList: `/api/reminders/`,
  estimatesList: `/api/estimates/`,
  rentManagerLinkSearchUser: "/api/rm/link-search/users/",
  rentManagerLinkSearchVendor: "/api/rm/link-search/vendors/",
  reviewMeld: (id) => `/api/melds/${id}/review/`,
  rmDisconnectedUnits: "/api/rm/disconnected/units/",
  rmInventoryItemSearch: "/api/rm/inventory-item-search/",
  pwAccountList: "/api/pw/accounts/",
  rmLeases: "/api/rm/leases/",
  rmLeasesSync: "/api/rm/sync/leases/",
  rmLinkSearchLease: "/api/rm/link-search/leases/",
  rmLinkSearchProperty: "/api/rm/link-search/properties/",
  rmLinkSearchUnit: "/api/rm/link-search/units/",
  rmProperties: "/api/rm/properties/",
  rmPropertiesSync: "/api/rm/sync/properties/",
  rmPropertiesSyncUpdate: (id) => `/api/rm/sync/properties/${id}/`,
  rmServiceManagerStatuses: "/api/rm/service-manager-statuses/",
  rmServiceManagerPriorities: "/api/rm/service-manager-priorities/",
  rmServiceManagerCategories: "/api/rm/service-manager-categories/",
  rmTenantsSync: "/api/rm/sync/tenants/",
  rmTenantsSyncUpdate: (id) => `/api/rm/sync/tenants/${id}/`,
  rmUnits: "/api/rm/units/",
  rmUnitsSync: "/api/rm/sync/units/",
  rmUnitsSyncUpdate: (id) => `/api/rm/sync/units/${id}/`,
  rmUsers: "/api/rm/users/",
  rmUsersSync: "/api/rm/sync/users/",
  rmUsersSyncUpdate: (id) => `/api/rm/sync/users/${id}/`,
  rmOwnersSync: "/api/rm/sync/owners/",
  rmOwnersSyncUpdate: (id) => `/api/rm/sync/owners/${id}/`,
  rmVendors: "/api/rm/vendors/",
  rmVendorsSync: "/api/rm/sync/vendors/",
  rmVendorsSyncUpdate: (id) => `/api/rm/sync/vendors/${id}/`,
  roleList: "/api/roles/",
  roleDetail: (id) => `/api/roles/${id}/`,
  searchAll: "/api/search/",
  shareMeldFiles: (id) => `/api/melds/${id}/files/share/`,
  downloadMeldFiles: (id) => `/api/melds/${id}/files/download/`,
  subscription: "/api/subscription/",
  subscriptionCards: "/api/subscription-cards/",
  tenantBulkInvite: `/api/tenants/invite/`,
  tenantBulkInviteTest: `/api/tenants/invite-test/`,
  tenantDetail: (id) => `/api/tenants/${id}/`,
  tenantFileDetail: (tenantId, fileId) => `/api/tenants/${tenantId}/files/${fileId}/`,
  tenantFileGeneratePolicy: "/api/tenants/files/generate-policy/",
  tenantFileList: (id) => `/api/tenants/${id}/files/`,
  tenantImportDetail: (id) => `/api/import/tenants/${id}/`,
  tenantImportFile: "/api/import/tenants/",
  tenantImportFinalize: (id) => `/api/import/tenants/${id}/finalize/`,
  tenantInvite: (id) => `/api/tenants/${id}/invite/`,
  tenantInviteDetail: (id) => `/api/tenants/invites/${id}/`,
  tenantInviteList: "/api/tenants/invites/",
  tenantInviteRequestDetail: (id) => `/api/tenants/invite-requests/${id}/`,
  tenantInviteRequestList: "/api/tenants/invite-requests/",
  tenantList: "/api/tenants/",
  tenantMeldFileDetail: (id) => `/api/melds/tenant-files/${id}/`,
  tenantMeldFileGeneratePolicy: "/api/melds/tenant-files/generate-policy/",
  tenantMeldFileList: (id) => `/api/melds/${id}/tenant-files/`,
  tenantMeldFinish: (id) => `/api/tenant-requests/${id}/finish/`,
  tenantMeldList: (id) => `/api/melds/tenant/${id}/`,
  tenantRequestCancel: (id) => `/api/tenant-requests/${id}/cancel/`,
  tenantRequestDetail: (id) => `/api/tenant-requests/${id}/`,
  tenantRequestFileGeneratePolicy: "/api/teannt-requests/tenant-files/generate-policy/",
  tenantRequestFileList: (id) => `/api/tenant-requests/${id}/tenant-files/`,
  tenantRequestList: "/api/tenant-requests/",
  tenantSendResetPassword: (id) => `/api/tenants/send-reset-password/${id}/`,
  tenantSearchList: "/api/tenants/search/",
  tenantSettings: "/api/current-tenant/",
  tenantUnits: (id) => `/api/tenants/${id}/units/`,
  testDeleteUnits: "/api/units/test-delete/",
  toggleIntegrationEnabled: (slug) => `/api/integration-partners/${slug}/toggle-enabled/`,
  unitDetail: (id) => `/api/units/${id}/`,
  unitFileDetail: (fileId) => `/api/units/files/${fileId}/`,
  unitFileGeneratePolicy: "/api/units/files/generate-policy/",
  unitFileList: (unitId) => `/api/units/${unitId}/files/`,
  unitList: "/api/units/",
  unitMeldInvoiceList: (unitId) => `/api/meld-invoices/unit/${unitId}/`,
  unitNotesImportDetail: (id) => `/api/import/unit-notes/${id}/`,
  unitNotesImportFile: "/api/import/unit-notes/",
  unitNotesImportFinalize: (id) => `/api/import/unit-notes/${id}/finalize/`,
  vendorImportDetail: (id) => `/api/import/vendors/${id}/`,
  vendorImportFile: "/api/import/vendors/",
  vendorImportFinalize: (id) => `/api/import/vendors/${id}/finalize/`,
  unitReactivate: (unitId) => `/api/units/${unitId}/reactivate/`,
  unitSearchList: "/api/units/search/",
  unitTenantDetail: (unitId, tenantId) => `/api/units/${unitId}/tenants/${tenantId}/`,
  unitTenantsList: (unitId) => `/api/units/${unitId}/tenants/`,
  unitsDelete: "/api/units/delete/",
  updateMeldUserGroups: (id) => `/api/melds/${id}/update-user-groups/`,
  userAvailability: "/api/user-availability/",
  userDetail: (id) => `/api/users/${id}/`,
  userGroupDetail: (id) => `/api/user-groups/${id}`,
  userGroupList: "/api/user-groups/",
  vendorAcceptAssignment: (id) => `/api/assignments/${id}/accept/`,
  vendorAgentList: (vendorId) => `/api/vendors/${vendorId}/agents/`,
  vendorAppointmentDetail: (id) => `/api/vendor-appointments/${id}/`,
  vendorAppointmentDetailAlt: (assignmentId) => `/api/assignments/${assignmentId}/appointment/`,
  vendorAppointmentRequestMoreAvailability: (id) => `/api/vendor-appointments/${id}/request-more-availability/`,
  vendorAppointmentSchedule: (id) => `/api/vendor-appointments/${id}/schedule/`,
  vendorCardDetail: (id) => `/api/vendor-cards/${id}/`,
  vendorCards: "/api/vendor-cards/",
  vendorDetail: (id) => `/api/vendors/${id}/`,
  vendorEventList: `/api/vendor-events/`,
  vendorExcludedPropertyGroupsList: (id) => `/api/vendors/${id}/excluded-property-groups/`,
  vendorFileDetail: (fileId) => `/api/vendors/files/${fileId}/`,
  vendorFileGeneratePolicy: "/api/vendors/files/generate-policy/",
  vendorFileList: (vendorId) => `/api/vendors/${vendorId}/files/`,
  vendorInvoiceFileList: (meldId) => `/api/melds/${meldId}/vendor-invoice-file/`,
  vendorInvoiceFileDetail: (id) => `/api/melds/vendor-invoice-file/${id}/`,
  vendorInvite: "/api/vendors/invite/",
  vendorInviteDetail: (id) => `/api/vendors/invite/${id}/`,
  vendorList: "/api/vendors/",
  vendorMeldList: (vendorId) => `/api/vendors/${vendorId}/melds/`,
  vendorMeldFileDetail: (id) => `/api/melds/vendor-files/${id}/`,
  vendorMeldFileGeneratePolicy: "/api/melds/vendor-files/generate-policy/",
  vendorInvoiceFileGeneratePolicy: "/api/melds/vendor-invoice-file/generate-policy/",
  vendorMeldFileList: (meldId) => `/api/melds/${meldId}/vendor-files/`,
  vendorSettings: "/api/settings/",
  vendorSpecialtiesList: "/api/specialties/",
  vendorTicketCompletionTimes: "/api/metrics/vendor-ticket-completion-times/",
  vendorTicketRatings: "/api/metrics/vendor-ticket-ratings/",
  vendorUpdateSegments: (id) => `/api/assignments/${id}/segments/`,
  addNewVendorAppointment: (id) => `/api/add-new-vendor-appointment/meld/${id}/`,
  rescheduleVendorAppointment: (id) => `/api/reschedule-vendor-segment/${id}/`,
  workEntryDetail: (id) => `/api/melds/work-entries/${id}/`,
  workEntryList: (id) => `/api/melds/${id}/work-entries/`
};
export default urls;
