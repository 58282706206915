import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSuperSelect, EuiText } from "@elastic/eui";
import { css } from "@emotion/react";
import { colors } from "@pm-frontend/styles";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";
const labelCss = css`
  & {
    div {
      label.euiFormLabel {
        font-size: 14px;
        color: ${colors.neutrals.gray800};
      }
    }
  }
`;
const getDisplay = (text) => {
  return /* @__PURE__ */ React.createElement(EuiText, { size: "s", color: colors.neutrals.gray800, style: { fontFamily: "Open Sans" } }, text.charAt(0).toUpperCase() + text.slice(1));
};
const getMobileFilterOptions = (items) => {
  return items.map((item) => ({
    inputDisplay: getDisplay(item),
    dropdownDisplay: getDisplay(item),
    value: item
  }));
};
function SiteSearchDesktopFiltersBase({
  searchFilterType,
  setSearchFilterType,
  filtersToShow,
  appType = "management"
}) {
  const isMobile = useIsMobile();
  if (appType === "management") {
    if (isMobile) {
      return /* @__PURE__ */ React.createElement(EuiFormRow, { label: "Filter Results", fullWidth: true, style: { color: colors.neutrals.gray800 }, css: labelCss }, /* @__PURE__ */ React.createElement(
        EuiSuperSelect,
        {
          options: getMobileFilterOptions(filtersToShow),
          valueOfSelected: searchFilterType,
          onChange: (value) => setSearchFilterType(value)
        }
      ));
    }
    return /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", style: { padding: "24px", gap: "32px" } }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiText, { color: colors.neutrals.gray800, style: { fontWeight: 700, whiteSpace: "nowrap" }, size: "s" }, "Narrow your search")), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", style: { gap: "12px" } }, filtersToShow.map((filter) => {
      return /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, key: filter }, /* @__PURE__ */ React.createElement(
        EuiText,
        {
          size: "s",
          color: searchFilterType === filter ? colors.brand.meldBlue : colors.neutrals.gray700,
          style: {
            padding: "8px 0px",
            cursor: "pointer",
            fontWeight: searchFilterType === filter ? 700 : 400
          },
          onClick: () => setSearchFilterType(filter),
          "data-testid": `search-filter-${filter}`
        },
        filter.charAt(0).toUpperCase() + filter.slice(1)
      ));
    }))));
  } else {
    return /* @__PURE__ */ React.createElement(React.Fragment, null);
  }
}
export { SiteSearchDesktopFiltersBase };
