import BaseApi from "../../utils/base-api";
export let OPTIONS = {
  PROPERTY_MANAGER: "PROPERTY_MANAGER",
  VENDOR: "VENDOR"
};
class RegistrationApi extends BaseApi {
  static resolveLocations(data) {
    return this.get("/api/location/resolutions/", {
      params: data,
      isPublicUrl: true
    });
  }
  static getDeal(data) {
    return this.get("/api/manager/deal/", {
      params: data,
      isPublicUrl: true
    });
  }
  static register(data) {
    return this.post("/api/manager/registration/", data, {
      isPublicUrl: true
    });
  }
}
export default RegistrationApi;
