export var OwnerHubAccessLevelEnum = /* @__PURE__ */ ((OwnerHubAccessLevelEnum2) => {
  OwnerHubAccessLevelEnum2["DIRECT_ONLY"] = "DIRECT_ONLY";
  OwnerHubAccessLevelEnum2["ALL_NOTIFICATIONS"] = "ALL_NOTIFICATIONS";
  OwnerHubAccessLevelEnum2["INVITED_TO_HUB"] = "INVITED_TO_HUB";
  OwnerHubAccessLevelEnum2["HUB_ACCESS"] = "HUB_ACCESS";
  OwnerHubAccessLevelEnum2["NONE"] = "NONE";
  OwnerHubAccessLevelEnum2["NOTIFICATIONS_ONLY"] = "NOTIFICATIONS_ONLY";
  return OwnerHubAccessLevelEnum2;
})(OwnerHubAccessLevelEnum || {});
export var OwnerHubAccessLevelEnumReadable = /* @__PURE__ */ ((OwnerHubAccessLevelEnumReadable2) => {
  OwnerHubAccessLevelEnumReadable2["DIRECT_ONLY"] = "Direct Only";
  OwnerHubAccessLevelEnumReadable2["ALL_NOTIFICATIONS"] = "All Notifications";
  OwnerHubAccessLevelEnumReadable2["INVITED_TO_HUB"] = "Invited To Hub";
  OwnerHubAccessLevelEnumReadable2["HUB_ACCESS"] = "Hub Access";
  OwnerHubAccessLevelEnumReadable2["NONE"] = "None";
  OwnerHubAccessLevelEnumReadable2["NOTIFICATIONS_ONLY"] = "Notifications Only";
  return OwnerHubAccessLevelEnumReadable2;
})(OwnerHubAccessLevelEnumReadable || {});
