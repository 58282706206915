import AddPropertyOwnerForm from "./add-property-owner-form";
import Modal from "../modal";
import ModalHeader from "../common/components/modal-header";
import OwnerStore from "./stores/owner-store";
import React from "react";
import { LargeLoader } from "../loaders";
class OwnerAddModal extends React.Component {
  render() {
    return /* @__PURE__ */ React.createElement(Modal, { isOpen: this.props.isModalOpen }, /* @__PURE__ */ React.createElement(ModalHeader, null, "Add Owner"), /* @__PURE__ */ React.createElement("div", { className: "modal-body" }, this.renderBody()));
  }
  renderBody() {
    if (OwnerStore.isLoading()) {
      return /* @__PURE__ */ React.createElement(LargeLoader, null);
    }
    return /* @__PURE__ */ React.createElement(
      AddPropertyOwnerForm,
      {
        data: this.props.data,
        propertyId: this.props.propertyId,
        ownerIdExclusions: this.props.ownerIdExclusions,
        close: this.props.close,
        onOwnerPropertiesUpdated: this.props.close,
        onOwnerCreated: this.props.close
      }
    );
  }
}
export default OwnerAddModal;
