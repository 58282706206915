import React, { forwardRef } from "react";
import { EuiFilePicker } from "@elastic/eui";
import { colors } from "@pm-frontend/styles";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";
const cssStyles = {
  "& + .euiFilePicker__prompt > svg.euiFilePicker__icon": {
    color: colors.brand.meldBlue
  },
  "& + .euiFilePicker__prompt": {
    border: "none",
    background: `${colors.neutrals.gray50} !important`
  },
  "& + .euiFilePicker__prompt > .euiFilePicker__promptText": {
    fontSize: "14px",
    lineHeight: "18px",
    color: `${colors.brand.meldBlue}`
  },
  "& + .euiFilePicker__prompt > .euiProgress::before": {
    backgroundColor: `${colors.brand.meldBlue}`
  }
};
const PmFileUploader = forwardRef(
  ({
    id,
    display = "large",
    onChange,
    prompt = "Drop files here or upload files from your device",
    isLoading,
    multiple = true
  }, ref) => {
    const isMobile = useIsMobile();
    return /* @__PURE__ */ React.createElement(
      EuiFilePicker,
      {
        fullWidth: true,
        id,
        multiple,
        ref,
        initialPromptText: isMobile ? "Upload files from your device" : prompt,
        display,
        onChange,
        compressed: display === "large",
        isLoading,
        css: cssStyles
      }
    );
  }
);
export { PmFileUploader };
