import classnames from "classnames";
import blockWithBaseClass from "../utils/withBaseClass";
export var TextBlockSizes = /* @__PURE__ */ ((TextBlockSizes2) => {
  TextBlockSizes2["XS"] = "pm-text-size-xs";
  TextBlockSizes2["SM"] = "pm-text-size-sm";
  TextBlockSizes2["MD"] = "pm-text-size-md";
  TextBlockSizes2["LG"] = "pm-text-size-lg";
  TextBlockSizes2["XL"] = "pm-text-size-xl";
  TextBlockSizes2["XXL"] = "pm-text-size-xxl";
  TextBlockSizes2["XXXL"] = "pm-text-size-xxxl";
  return TextBlockSizes2;
})(TextBlockSizes || {});
const TextBlock = (props) => {
  return blockWithBaseClass(props, classnames("pm-textblock", props.size));
};
export default TextBlock;
