import _ from "lodash";
import React from "react";
import { ErrorHandler } from "../../../../app/utils/ErrorHandler";
import Meld from "../../svg/meld-svg";
import Pin from "../../svg/pin-svg";
import Tenant from "../../svg/tenant-svg";
import TenantUtils from "../../tenant/utils/tenant-utils";
import TruckSvg from "@pm-assets/js/svg/truck-svg";
import { IoEyeOffSharp } from "react-icons/io5";
import Features from "@pm-assets/js/common/feature-flags";
function label(strings, value) {
  let lab = strings[0];
  if (value) {
    return `${lab}: ${value}`;
  }
}
const PropGroupVisible = ({ visible }) => /* @__PURE__ */ React.createElement(
  "span",
  {
    style: {
      display: "table-cell",
      verticalAlign: "middle",
      marginTop: "10px",
      float: "right"
    }
  },
  !visible && /* @__PURE__ */ React.createElement("div", { style: { marginLeft: "32px", color: "#8a95a5" } }, /* @__PURE__ */ React.createElement(IoEyeOffSharp, null))
);
class SearchResultRow extends React.Component {
  render() {
    let { _source: source, _index: index } = this.props.hit;
    switch (index) {
      case "tenant":
        return /* @__PURE__ */ React.createElement("div", { className: "search-row-content" }, /* @__PURE__ */ React.createElement(PropGroupVisible, { visible: this.props.propGroupsVisible }), /* @__PURE__ */ React.createElement("span", { className: "search-row-icon" }, /* @__PURE__ */ React.createElement(Tenant, null)), /* @__PURE__ */ React.createElement("span", { className: "search-row-text" }, /* @__PURE__ */ React.createElement("span", { className: "primary-text" }, TenantUtils.formatFullName(source.first_name, source.last_name, source.middle_name)), Features.isPropertyNameEnabled() && source.unit && source.unit.property_name !== "" && /* @__PURE__ */ React.createElement("div", { className: "ancillary-text" }, source.unit.property_name), /* @__PURE__ */ React.createElement("div", { className: "ancillary-text" }, _.get(source, "unit.display_address.line_1")), /* @__PURE__ */ React.createElement("div", { className: "ancillary-text" }, this.getUnitSubFieldsString(source.unit))));
      case "unit":
        return /* @__PURE__ */ React.createElement("div", { className: "search-row-content" }, /* @__PURE__ */ React.createElement(PropGroupVisible, { visible: this.props.propGroupsVisible }), /* @__PURE__ */ React.createElement("span", { className: "search-row-icon" }, /* @__PURE__ */ React.createElement(Pin, null)), /* @__PURE__ */ React.createElement("span", { className: "search-row-text" }, Features.isPropertyNameEnabled() && source.prop && source.prop.property_name !== "" && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("span", { className: "primary-text" }, source.prop.property_name), /* @__PURE__ */ React.createElement("div", { className: "ancillary-text" }, source.display_address.line_1)), Features.isPropertyNameEnabled() && source.prop && source.prop.property_name === "" && /* @__PURE__ */ React.createElement("div", { className: "primary-text" }, source.display_address.line_1), !Features.isPropertyNameEnabled() && /* @__PURE__ */ React.createElement("div", { className: "primary-text" }, source.display_address.line_1), /* @__PURE__ */ React.createElement("div", { className: "ancillary-text" }, this.getUnitSubFieldsString(source))));
      case "meld":
        return /* @__PURE__ */ React.createElement("div", { className: "search-row-content" }, /* @__PURE__ */ React.createElement(PropGroupVisible, { visible: this.props.propGroupsVisible }), /* @__PURE__ */ React.createElement("span", { className: "search-row-icon" }, /* @__PURE__ */ React.createElement(Meld, null)), /* @__PURE__ */ React.createElement("span", { className: "search-row-text" }, /* @__PURE__ */ React.createElement("span", { className: "primary-text" }, source.brief_description), Features.isPropertyNameEnabled() && source.unit.prop && source.unit.prop.property_name !== "" && /* @__PURE__ */ React.createElement("div", { className: "ancillary-text" }, source.unit.prop.property_name), /* @__PURE__ */ React.createElement("div", { className: "ancillary-text" }, source.unit.display_address.line_1)));
      case "owner":
        return /* @__PURE__ */ React.createElement("div", { className: "search-row-content" }, /* @__PURE__ */ React.createElement(PropGroupVisible, { visible: this.props.propGroupsVisible }), /* @__PURE__ */ React.createElement("span", { className: "search-row-icon" }, /* @__PURE__ */ React.createElement(Tenant, null)), /* @__PURE__ */ React.createElement("span", { className: "search-row-text" }, /* @__PURE__ */ React.createElement("span", { className: "primary-text" }, source.first_name, " ", source.last_name), /* @__PURE__ */ React.createElement("div", { className: "ancillary-text" }, "Owner")));
      case "vendor":
        return /* @__PURE__ */ React.createElement("div", { className: "search-row-content" }, /* @__PURE__ */ React.createElement(PropGroupVisible, { visible: this.props.propGroupsVisible }), /* @__PURE__ */ React.createElement("span", { className: "search-row-icon" }, /* @__PURE__ */ React.createElement(TruckSvg, null)), /* @__PURE__ */ React.createElement("span", { className: "search-row-text" }, /* @__PURE__ */ React.createElement("span", { className: "primary-text" }, source.name), /* @__PURE__ */ React.createElement("div", { className: "ancillary-text" }, "Vendor")));
      default:
        ErrorHandler.handleError(new Error(`Unable to find index to render search row result: ${index}`));
    }
    return /* @__PURE__ */ React.createElement("div", null);
  }
  getUnitSubFieldsString(unit) {
    if (!unit) {
      return "";
    }
    let labels = [];
    labels.push(label`Apartment${unit.apartment}`);
    labels.push(label`Suite${unit.suite}`);
    labels.push(label`Unit${unit.unit}`);
    labels.push(label`Floor${_.get(unit, "floor.number")}`);
    labels.push(label`Building${_.get(unit, "building.number")}`);
    labels.push(label`Room${unit.room}`);
    labels.push(label`Department${unit.department}`);
    return labels.filter((lab) => lab).join(" | ");
  }
}
export default SearchResultRow;
