var _a;
import { create } from "zustand";
import { colors } from "@pm-frontend/styles";
export const currentlyAssignedBorder = `2px solid ${colors.brand.darkHover}`;
const doesSegmentMatchAppt = (segment, appointment) => {
  var _a2;
  if (segment.event.dtstart && segment.event.dtend && ((_a2 = appointment.availability_segment) == null ? void 0 : _a2.event.dtstart) && appointment.availability_segment.event.dtend) {
    return segment.event.dtstart === appointment.availability_segment.event.dtstart && segment.event.dtend === appointment.availability_segment.event.dtend;
  }
  return false;
};
export const isSegmentScheduled = (segment, meld) => {
  if (meld.managementappointment.length > 0) {
    return meld.managementappointment.some((appt) => doesSegmentMatchAppt(segment, appt));
  } else if (meld.vendorappointment.length > 0) {
    return meld.vendorappointment.some((appt) => doesSegmentMatchAppt(segment, appt));
  }
  return false;
};
const CALENDAR_REDESIGN_TOGGLE_SESSION_STORAGE_KEY = `ui-calendar-redesign-enabled-${((_a = window.PM.user) == null ? void 0 : _a.id) || "karma-test-fix"}`;
const readCalendarStateFromLocalStorage = () => {
  try {
    const rawKey = window.localStorage.getItem(CALENDAR_REDESIGN_TOGGLE_SESSION_STORAGE_KEY);
    if (rawKey !== null) {
      return !!JSON.parse(rawKey);
    }
  } catch (e) {
    return false;
  }
  return false;
};
const writeCalendarStateToLocalStorage = (value) => {
  try {
    window.localStorage.setItem(CALENDAR_REDESIGN_TOGGLE_SESSION_STORAGE_KEY, JSON.stringify(value));
    return;
  } catch (e) {
    return;
  }
};
export const useCalendarRedesignToggleStore = create((set) => ({
  enabled: readCalendarStateFromLocalStorage(),
  actions: {
    toggleCalendarRedesignEnabled: () => {
      set((state) => {
        writeCalendarStateToLocalStorage(!state.enabled);
        location.reload();
        return { enabled: !state.enabled };
      });
    }
  }
}));
export const isEventAvailability = (event) => {
  var _a2, _b;
  return !(typeof ((_a2 = event.managementavailabilitysegment) == null ? void 0 : _a2.scheduled_management_appointment) === "number" || typeof ((_b = event.vendoravailabilitysegment) == null ? void 0 : _b.scheduled_vendor_appointment) === "number");
};
export const getCellBackgroundColor = (isInPast) => {
  if (isInPast) {
    return colors.neutrals.gray200;
  } else {
    return colors.brand.white;
  }
};
export const getScheduleFormMinStartTime = (event, now) => {
  if (event.date.isBefore(now, "day")) {
    return event.date.clone().endOf("day");
  } else if (event.date.isSame(now, "day")) {
    const result = event.date.clone();
    result.hours(now.hours());
    result.minutes(now.minutes());
    result.seconds(0);
    return result;
  } else {
    return event.date.clone().startOf("day");
  }
};
export const getScheduleFormMaxEndTime = (event, now) => {
  if (event.date.isBefore(now, "day")) {
    return event.date.clone().endOf("day");
  }
  if (event.startTime) {
    const result = event.startTime.clone().add(8, "hours");
    if (result.isSame(event.date, "day")) {
      return result;
    }
  }
  return event.date.clone().endOf("day");
};
export const getScheduleFormMinEndTime = (event, now) => {
  if (event.date.isBefore(now, "day")) {
    return event.date.clone().endOf("day");
  }
  if (event.startTime) {
    return event.startTime.clone();
  }
  return event.date.clone().startOf("day");
};
