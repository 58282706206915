import classnames from "classnames";
import React from "react";
import Checkmark from "../../svg/checkmark";
import Button, { ButtonTypes } from "../../../../app/components/buttons/Button";
import { FlexJustifyContent } from "../../../../app/components/utils/constants";
const mql = window.matchMedia(`(max-width: 490px)`);
export default class TimeSlotCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  render() {
    let { start, end, selected, unavailable } = this.props;
    let timeEventClasses = classnames({
      "time-slot-checkbox": true,
      selected,
      unavailable
    });
    const buttonType = (() => {
      if (selected) {
        return ButtonTypes.Primary;
      } else if (unavailable) {
        return ButtonTypes.Danger;
      }
    })();
    return /* @__PURE__ */ React.createElement(
      Button,
      {
        buttonType,
        disabled: unavailable,
        className: timeEventClasses,
        onClick: this.handleClick,
        justifyContent: FlexJustifyContent.SpaceBetween,
        style: {
          padding: mql.matches ? "1em 0.5em" : "1em 3em"
        }
      },
      /* @__PURE__ */ React.createElement("div", { className: "valign-middle-columns" }, /* @__PURE__ */ React.createElement("span", { className: "start-to-end-times" }, /* @__PURE__ */ React.createElement("div", null, start.format("h:mm A")), /* @__PURE__ */ React.createElement("div", null, end.format("h:mm A")))),
      /* @__PURE__ */ React.createElement("div", { className: "valign-middle-columns" }, /* @__PURE__ */ React.createElement(Checkmark, null))
    );
  }
  handleClick(e) {
    if (!this.props.unavailable) {
      this.props.onClick(e);
    }
  }
}
