import {
  InputGroup,
  InputRightAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Tag,
  TagLabel,
  TagRightIcon,
  Text,
  VStack,
  Tooltip
} from "@chakra-ui/react";
import { Datepicker } from "@pmeld/components";
import { formatISODuration, isValid, parseISO, eachMinuteOfInterval, format } from "date-fns";
import React, { useEffect, useState } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { colors } from "@pm-app/components/theme";
import { AuthUtils } from "@pm-assets/js/utils/auth-utils";
const DURATION_REGEX = /^(-?)P(?=\d|T\d)(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)([DW]))?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:\.\d+)?)S)?)?$/;
let times = [];
const start = /* @__PURE__ */ new Date();
start.setHours(6, 30, 0, 0);
const end = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 24);
eachMinuteOfInterval({ start, end }, { step: 15 }).forEach((date) => {
  let time_ap = format(date, "h:mm aa");
  let time_twentyfour = format(date, "HH:mm:ss");
  let time = { ap: time_ap, key: time_twentyfour };
  times.push(time);
});
export const TimestampCondition = ({ operator_key, value, error, onChange }) => {
  if (["before", "after", "equal", "in_range", "not_in_range"].includes(operator_key)) {
    let parsedValue;
    if (value && isValid(parseISO(value))) {
      parsedValue = parseISO(value);
    }
    return /* @__PURE__ */ React.createElement(
      Datepicker,
      {
        "data-test": "workflows.form.select.block_operator.condition_type.timestamp",
        isInvalid: !!error,
        onChange: (date) => onChange(date),
        testPrefix: "workflows.form.select.block_operator.condition_type.timestamp",
        value: parsedValue,
        width: "100%"
      }
    );
  }
  if (["in_recurring_range"].includes(operator_key)) {
    const DaysOfWeek = [
      {
        key: 1,
        day: "Monday"
      },
      {
        key: 2,
        day: "Tuesday"
      },
      {
        key: 3,
        day: "Wednesday"
      },
      {
        key: 4,
        day: "Thursday"
      },
      {
        key: 5,
        day: "Friday"
      },
      {
        key: 6,
        day: "Saturday"
      },
      {
        key: 7,
        day: "Sunday"
      }
    ];
    let [startDay, setSelectedStartDay] = useState(value == null ? void 0 : value.start_day);
    let [endDay, setSelectedEndDay] = useState(value == null ? void 0 : value.end_day);
    let [startTime, setSelectedStartTime] = useState(value == null ? void 0 : value.start_time);
    let [endTime, setSelectedEndTime] = useState(value == null ? void 0 : value.end_time);
    useEffect(() => {
      setSelectedStartDay(value == null ? void 0 : value.start_day);
      setSelectedEndDay(value == null ? void 0 : value.end_day);
      setSelectedStartTime(value == null ? void 0 : value.start_time);
      setSelectedEndTime(value == null ? void 0 : value.end_time);
    }, [value]);
    const handleStartDayChange = (event) => {
      setSelectedStartDay(Number(event.target.value));
      onChange({
        start_day: event.target.value,
        start_time: startTime,
        end_day: endDay,
        end_time: endTime
      });
    };
    const handleEndDayChange = (event) => {
      setSelectedEndDay(Number(event.target.value));
      onChange({
        start_day: startDay,
        start_time: startTime,
        end_day: event.target.value,
        end_time: endTime
      });
    };
    const handleStartTimeChange = (event) => {
      setSelectedStartTime(event.target.value);
      onChange({ start_day: startDay, start_time: event.target.value, end_day: endDay, end_time: endTime });
    };
    const handleEndTimeChange = (event) => {
      setSelectedEndTime(event.target.value);
      onChange({
        start_day: startDay,
        start_time: startTime,
        end_day: endDay,
        end_time: event.target.value
      });
    };
    return /* @__PURE__ */ React.createElement(VStack, { alignItems: "left" }, /* @__PURE__ */ React.createElement(Text, { fontSize: "lg", as: "b" }, "Day"), /* @__PURE__ */ React.createElement(
      Select,
      {
        "data-test": "workflows.form.select.block_operator.condition_type.timestamp.select",
        value: startDay ? startDay : "",
        onChange: handleStartDayChange
      },
      /* @__PURE__ */ React.createElement(
        "option",
        {
          key: "",
          value: "",
          disabled: true,
          "data-test": "workflows.form.select.block_operator.condition_type.timestamp.select.option.0"
        },
        "Start Day"
      ),
      DaysOfWeek.map((day, idx) => /* @__PURE__ */ React.createElement(
        "option",
        {
          "data-test": `workflows.form.select.block_operator.condition_type.timestamp.select.option.${idx + 1}`,
          key: day.key,
          value: day.key
        },
        day.day
      ))
    ), /* @__PURE__ */ React.createElement(Text, { fontSize: "lg", as: "b" }, "Time"), /* @__PURE__ */ React.createElement(
      Select,
      {
        "data-test": "workflows.form.select.block_operator.condition_type.timestamp.select",
        value: startTime ? startTime : "",
        onChange: handleStartTimeChange
      },
      /* @__PURE__ */ React.createElement(
        "option",
        {
          "data-test": "workflows.form.select.block_operator.condition_type.timestamp.select.option.0",
          key: "",
          value: "",
          disabled: true
        },
        "Start Time"
      ),
      times.map((time, idx) => /* @__PURE__ */ React.createElement(
        "option",
        {
          "data-test": `workflows.form.select.block_operator.condition_type.timestamp.select.option.${idx + 1}`,
          key: time.key,
          value: time.key
        },
        time.ap
      ))
    ), /* @__PURE__ */ React.createElement(Text, { fontSize: "md", as: "b", color: colors.pmGreyMedium }, "And"), /* @__PURE__ */ React.createElement(Text, { fontSize: "lg", as: "b" }, "Day"), /* @__PURE__ */ React.createElement(
      Select,
      {
        "data-test": "workflows.form.select.block_operator.condition_type.timestamp.select",
        value: endDay ? endDay : "",
        onChange: handleEndDayChange
      },
      /* @__PURE__ */ React.createElement(
        "option",
        {
          "data-test": "workflows.form.select.block_operator.condition_type.timestamp.select.option.0",
          key: "",
          value: "",
          disabled: true
        },
        "End Day"
      ),
      DaysOfWeek.map((day, idx) => /* @__PURE__ */ React.createElement(
        "option",
        {
          "data-test": `workflows.form.select.block_operator.condition_type.timestamp.select.option.${idx + 1}`,
          key: day.key,
          value: day.key
        },
        day.day
      ))
    ), /* @__PURE__ */ React.createElement(Text, { fontSize: "lg", as: "b" }, "Time"), /* @__PURE__ */ React.createElement(
      Select,
      {
        "data-test": "workflows.form.select.block_operator.condition_type.timestamp.select",
        value: endTime ? endTime : "",
        onChange: handleEndTimeChange
      },
      /* @__PURE__ */ React.createElement(
        "option",
        {
          "data-test": "workflows.form.select.block_operator.condition_type.timestamp.select.option.0",
          key: "",
          value: "",
          disabled: true
        },
        "End Time"
      ),
      times.map((time, idx) => /* @__PURE__ */ React.createElement(
        "option",
        {
          "data-test": `workflows.form.select.block_operator.condition_type.timestamp.select.option.${idx + 1}`,
          key: time.key,
          value: time.key
        },
        time.ap
      ))
    ), /* @__PURE__ */ React.createElement(Tooltip, { label: "To change your time zone go to Account Settings > Company" }, /* @__PURE__ */ React.createElement(Tag, { variant: "outline" }, /* @__PURE__ */ React.createElement(TagLabel, null, /* @__PURE__ */ React.createElement(React.Fragment, null, "Timezone set to ", AuthUtils.getManagementTimezone(), " ")), /* @__PURE__ */ React.createElement(TagRightIcon, { as: AiOutlineQuestionCircle, color: colors.pmBlue }))));
  }
  if (["greater_than_past", "lesser_than_past", "greater_than_future", "lesser_than_future"].includes(operator_key)) {
    let parsedValue;
    if (value) {
      const matches = value.match(DURATION_REGEX);
      if (matches) {
        parsedValue = Number(matches[4]);
      }
    }
    return /* @__PURE__ */ React.createElement(InputGroup, null, /* @__PURE__ */ React.createElement(
      NumberInput,
      {
        "data-test": "workflows.form.select.block_operator.condition_type.timestamp",
        isInvalid: !!error,
        onChange: (newValue) => onChange(formatISODuration({ days: Number(newValue) })),
        value: parsedValue,
        width: "100%"
      },
      /* @__PURE__ */ React.createElement(NumberInputField, { "data-test": "workflows.form.select.block_operator.condition_type.timestamp.input" }),
      /* @__PURE__ */ React.createElement(NumberInputStepper, null, /* @__PURE__ */ React.createElement(NumberIncrementStepper, null), /* @__PURE__ */ React.createElement(NumberDecrementStepper, null))
    ), /* @__PURE__ */ React.createElement(InputRightAddon, { children: parsedValue === 1 ? "day" : "days" }));
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null);
};
