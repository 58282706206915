import React from "react";
import { EuiHeaderSectionItem, EuiIcon } from "@elastic/eui";
import { Link } from "react-router-dom";
import URL from "@pm-shared/utils/url";
import { RouteUrls } from "@pm-frontend/shared/utils/route-urls";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
const HeaderNotificationItem = ({ hasUnreadNotifications }) => {
  if (hasUnreadNotifications) {
    return /* @__PURE__ */ React.createElement(EuiHeaderSectionItem, { "aria-label": "Notifications" }, /* @__PURE__ */ React.createElement(Link, { to: LinkHelper.normalize(RouteUrls.notifications), style: { padding: "0px" } }, /* @__PURE__ */ React.createElement(EuiIcon, { type: URL.getStatic("icons/notification-dot.svg"), size: "l" })));
  }
  return /* @__PURE__ */ React.createElement(EuiHeaderSectionItem, { "aria-label": "Notifications" }, /* @__PURE__ */ React.createElement(Link, { to: LinkHelper.normalize(RouteUrls.notifications), style: { padding: "0px" } }, /* @__PURE__ */ React.createElement(EuiIcon, { type: URL.getStatic("icons/notification-none.svg"), size: "l" })));
};
export { HeaderNotificationItem };
