import React from "react";
import Button, { ButtonTypes } from "./Button";
import ButtonIcon from "./ButtonIcon";
import LinkButton from "./LinkButton";
const downSvg = require("../../assets/icons/ArrowDown.svg");
export default class MultiButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dropdownActive: false };
    this.showDropdown = this.showDropdown.bind(this);
    this.onWindowClick = this.onWindowClick.bind(this);
  }
  componentDidUpdate() {
    if (this.state.dropdownActive) {
      window.addEventListener("click", this.onWindowClick, false);
    }
  }
  onWindowClick() {
    if (this.state.dropdownActive) {
      this.setState({ dropdownActive: false });
    }
    window.removeEventListener("click", this.onWindowClick, false);
  }
  showDropdown(e) {
    if (!this.state.dropdownActive) {
      e.stopPropagation();
      this.setState({
        dropdownActive: true
      });
    }
  }
  renderMainButton() {
    const { onMainClicked, buttonType, mainLabel, linkQuery } = this.props;
    if (typeof onMainClicked === "function") {
      return /* @__PURE__ */ React.createElement(Button, { buttonType, className: "pm-multi-button-main-btn", onClick: onMainClicked }, mainLabel);
    }
    const type = buttonType ? buttonType : ButtonTypes.Neutral;
    return /* @__PURE__ */ React.createElement(LinkButton, { buttonType: type, className: "pm-multi-button-main-btn", to: onMainClicked, query: linkQuery }, mainLabel);
  }
  render() {
    const children = React.Children.map(this.props.children, (child) => {
      if (child) {
        return React.cloneElement(child, {
          buttonType: this.props.buttonType
        });
      }
    });
    return /* @__PURE__ */ React.createElement("div", { className: "pm-button pm-multi-button" }, /* @__PURE__ */ React.createElement("div", { className: "pm-multi-button-main-container" }, this.renderMainButton(), /* @__PURE__ */ React.createElement(
      Button,
      {
        buttonType: this.props.buttonType,
        className: "pm-mulit-button-dropwdown-toggle",
        onClick: this.showDropdown
      },
      /* @__PURE__ */ React.createElement(ButtonIcon, { className: "pm-multi-button-toggle-icon", svg: downSvg })
    )), this.state.dropdownActive && /* @__PURE__ */ React.createElement("div", { className: "pm-mutli-button-dropdown" }, children));
  }
}
