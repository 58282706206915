import React, { useState } from "react";
import {
  EuiButtonEmpty,
  EuiContextMenu,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiImage,
  EuiLoadingSpinner,
  EuiNotificationBadge,
  EuiPopover,
  EuiText
} from "@elastic/eui";
import { colors } from "@pm-frontend/styles";
import URL from "@pm-shared/utils/url";
import { PmCheckCircleOutline } from "@pm-frontend/assets/icons/components/PmCheckCircleOutline";
const MESSAGE_TIMEOUT_IN_MS = 3e3;
const colorMap = {
  success: {
    badge: {
      backgroundColor: colors.interface.green.default,
      textColor: colors.brand.white
    },
    borderColor: colors.interface.green.default,
    textColor: colors.interface.green.default
  },
  danger: {
    badge: {
      backgroundColor: colors.interface.red.default,
      textColor: colors.brand.white
    },
    borderColor: colors.interface.red.default,
    textColor: colors.interface.red.default
  },
  warning: {
    badge: {
      backgroundColor: colors.brand.meldBlue,
      textColor: colors.brand.white
    },
    borderColor: colors.brand.meldBlue,
    textColor: colors.brand.meldBlue
  }
};
const BulkActionButton = ({
  onClick,
  innerBody,
  color
}) => {
  return /* @__PURE__ */ React.createElement(
    EuiButtonEmpty,
    {
      style: {
        border: `1px solid ${colorMap[color].borderColor}`,
        borderRadius: "6px",
        textDecoration: "none",
        width: "fit-content"
      },
      contentProps: { style: { width: "fit-content", padding: "0px" } },
      onClick
    },
    /* @__PURE__ */ React.createElement(EuiText, { color: colorMap[color].textColor, size: "s", style: { lineHeight: "14px" } }, innerBody)
  );
};
const usePmBulkActionButton = (props = { initialColor: "success", initialBody: "" }) => {
  const [displayState, setDisplayState] = useState("button");
  const [successText, setSuccessText] = useState("");
  const [color, setColor] = useState(props.initialColor || "success");
  const [body, setBody] = useState(props.initialBody || "");
  const setToButtonState = () => setDisplayState("button");
  const setToLoadingState = () => setDisplayState("loading");
  const setToSuccessState = () => {
    setDisplayState("success");
    setTimeout(() => {
      setToButtonState();
    }, MESSAGE_TIMEOUT_IN_MS);
  };
  const setToErrorState = () => {
    setDisplayState("error");
    setTimeout(() => {
      setToButtonState();
    }, MESSAGE_TIMEOUT_IN_MS);
  };
  return {
    displayState,
    successText,
    color,
    body,
    setColor,
    setBody,
    setToButtonState,
    setToLoadingState,
    setToSuccessState,
    setToErrorState,
    setSuccessText
  };
};
const bulkActionIconMap = {
  default: URL.getStatic("icons/gray_exclamation.svg"),
  download: URL.getStatic("icons/save_alt.svg"),
  delete: URL.getStatic("icons/remove_circle_outline_blue.svg"),
  share: URL.getStatic("icons/share.svg"),
  preview: URL.getStatic("icons/preview.svg")
};
const cssContextMenu = {
  padding: "0px",
  "& > .euiContextMenuPanelTitle": {
    padding: "0px"
  }
};
const cssPopoverPanelStyle = {
  minWidth: "171px"
};
const cssPopoverStyle = {
  width: "fit-content"
};
const cssPanelProps = {
  backgroundColor: `${colors.brand.white}`,
  "& > div::before": {
    borderTopColor: `${colors.brand.white}`
  }
};
const renderMenuItems = ({
  menuItems,
  additionalOnClick
}) => menuItems.filter((action) => !action.hasOwnProperty("enabled") || action.enabled).map((menuItem) => /* @__PURE__ */ React.createElement(
  EuiContextMenuItem,
  {
    key: menuItem.text,
    onClick: () => {
      menuItem.onClick();
      additionalOnClick();
    },
    style: { padding: "0px" },
    "data-testid": menuItem && menuItem.dataTestId ? menuItem.dataTestId : void 0
  },
  /* @__PURE__ */ React.createElement(
    EuiFlexGroup,
    {
      direction: "row",
      gutterSize: "s",
      alignItems: "center",
      style: { padding: "10px 12px" },
      wrap: false,
      responsive: false
    },
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, style: { padding: "2px" } }, /* @__PURE__ */ React.createElement(
      EuiImage,
      {
        src: menuItem.icon ? bulkActionIconMap[menuItem.icon] : bulkActionIconMap.default,
        alt: "",
        style: { width: "20px", height: "20px" }
      }
    )),
    /* @__PURE__ */ React.createElement(
      EuiFlexItem,
      {
        grow: true,
        style: {
          fontFamily: "Open Sans",
          fontSize: "16px",
          lineHeight: "16px"
        }
      },
      /* @__PURE__ */ React.createElement(EuiText, { size: "s", color: colors.neutrals.gray800 }, menuItem.text)
    )
  )
));
const PmBulkActionButton = ({
  body,
  numFilters,
  color,
  bulkActions,
  alwaysShowBulkActions,
  displayState,
  successText,
  "data-testid": dataTestId = "bulk-action-button",
  panels,
  setIsPopoverOpen: controlledSetIsPopoverOpen,
  isPopoverOpen: controlledIsPopoverOpen
}) => {
  const [uncontrolledIsPopoverOpen, uncontrolledSetIsPopoverOpen] = useState(false);
  const innerBody = /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, gutterSize: "s", alignItems: "center" }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, "data-testid": dataTestId }, body), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, displayState === "loading" ? /* @__PURE__ */ React.createElement(EuiLoadingSpinner, { size: "s" }) : /* @__PURE__ */ React.createElement(
    EuiNotificationBadge,
    {
      style: {
        color: colorMap[color].badge.textColor,
        background: colorMap[color].badge.backgroundColor,
        padding: "0px 4px",
        borderRadius: "3px",
        height: "17px"
      }
    },
    numFilters
  )), (alwaysShowBulkActions || bulkActions && bulkActions.length > 1 || panels) && /* @__PURE__ */ React.createElement(EuiFlexItem, null, /* @__PURE__ */ React.createElement(EuiIcon, { type: "arrowDown", color })));
  if (displayState === "error") {
    return /* @__PURE__ */ React.createElement(
      EuiFlexGroup,
      {
        alignItems: "center",
        direction: "row",
        responsive: false,
        gutterSize: "s",
        wrap: false,
        style: { height: "40px" }
      },
      /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(PmCheckCircleOutline, null)),
      /* @__PURE__ */ React.createElement(EuiFlexItem, null, /* @__PURE__ */ React.createElement(
        EuiText,
        {
          color: colors.interface.red.dark,
          style: { fontSize: "14px", fontWeight: 600, whiteSpace: "nowrap" }
        },
        "Something went wrong."
      ))
    );
  } else if (displayState === "success") {
    return /* @__PURE__ */ React.createElement(
      EuiFlexGroup,
      {
        alignItems: "center",
        direction: "row",
        wrap: false,
        responsive: false,
        gutterSize: "s",
        style: { height: "40px" }
      },
      /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(PmCheckCircleOutline, null)),
      /* @__PURE__ */ React.createElement(EuiFlexItem, null, /* @__PURE__ */ React.createElement(
        EuiText,
        {
          color: colors.interface.green.statusText,
          style: { fontSize: "14px", fontWeight: 600, whiteSpace: "nowrap" }
        },
        successText
      ))
    );
  } else if (!alwaysShowBulkActions && (!bulkActions || bulkActions.length === 1) && panels === void 0) {
    const onClick = bulkActions ? bulkActions[0].onClick : void 0;
    return /* @__PURE__ */ React.createElement(BulkActionButton, { onClick, innerBody, color });
  } else if (panels && controlledSetIsPopoverOpen) {
    return /* @__PURE__ */ React.createElement(
      EuiPopover,
      {
        button: /* @__PURE__ */ React.createElement(
          BulkActionButton,
          {
            innerBody,
            color,
            onClick: () => controlledSetIsPopoverOpen((state) => !state)
          }
        ),
        isOpen: controlledIsPopoverOpen,
        closePopover: () => controlledSetIsPopoverOpen(false),
        panelPaddingSize: "none",
        anchorPosition: "downLeft",
        panelStyle: cssPopoverPanelStyle,
        repositionOnScroll: true,
        style: cssPopoverStyle
      },
      /* @__PURE__ */ React.createElement(EuiContextMenu, { panels, initialPanelId: (panels == null ? void 0 : panels[0].id) || 0, size: "s" })
    );
  } else {
    return /* @__PURE__ */ React.createElement(
      EuiPopover,
      {
        id: "memory-table-bulk-actions-popover",
        button: /* @__PURE__ */ React.createElement(
          BulkActionButton,
          {
            innerBody,
            color,
            onClick: () => uncontrolledSetIsPopoverOpen((state) => !state)
          }
        ),
        isOpen: uncontrolledIsPopoverOpen,
        onClick: () => uncontrolledSetIsPopoverOpen((state) => !state),
        closePopover: () => uncontrolledSetIsPopoverOpen(false),
        panelPaddingSize: "none",
        anchorPosition: "downLeft",
        panelStyle: cssPopoverPanelStyle,
        panelProps: { css: cssPanelProps },
        repositionOnScroll: true,
        style: cssPopoverStyle
      },
      /* @__PURE__ */ React.createElement(
        EuiContextMenuPanel,
        {
          title: /* @__PURE__ */ React.createElement(
            EuiText,
            {
              color: colors.neutrals.gray800,
              style: { fontSize: "14px", fontWeight: 700, lineHeight: "20px", padding: "16px" }
            },
            /* @__PURE__ */ React.createElement("b", null, "Bulk Actions")
          ),
          size: "s",
          items: renderMenuItems({
            menuItems: bulkActions || [],
            additionalOnClick: () => uncontrolledSetIsPopoverOpen(false)
          }),
          style: { padding: "0px" },
          css: cssContextMenu
        }
      )
    );
  }
};
export { PmBulkActionButton, usePmBulkActionButton };
