import React from "react";
import PureCheckbox from "../../common/components/pure-checkbox";
import classNames from "classnames";
export default class SelectAllSelector extends React.Component {
  shouldRenderCheck() {
    return this.props.selectedAll && this.props.numberOfSelectedItems > 0;
  }
  shouldRenderMinus() {
    return this.props.numberOfSelectedItems >= 1 && !this.shouldRenderCheck();
  }
  getItemString(count) {
    if (count === 1) {
      return this.props.itemName;
    }
    return this.props.nonSingularItemName;
  }
  renderNumberOfSelected() {
    const selectedCount = this.props.numberOfSelectedItems;
    const meldString = this.getItemString(selectedCount);
    const allSelected = selectedCount === this.props.totalItemCount ? "All " : "";
    return allSelected + selectedCount + " " + meldString + " selected";
  }
  renderSelectAcrossPages() {
    if (this.props.numberOfSelectedItems < this.props.totalItemCount && this.props.numberOfSelectedItems !== 0) {
      const selectString = "Select all " + this.props.totalItemCount + " " + this.getItemString(this.props.totalItemCount);
      return /* @__PURE__ */ React.createElement("div", { onClick: this.props.onSelectedAllPages }, selectString);
    }
  }
  render() {
    let tags;
    let classes = classNames({
      "select-all-container": true,
      "filter-sort-panel": !!this.props.filterSortPanelClass
    });
    if (!!this.props.filterSortPanelClass) {
      tags = /* @__PURE__ */ React.createElement("span", { className: classes }, this.renderRow());
    } else {
      tags = /* @__PURE__ */ React.createElement("div", { className: classes }, this.renderRow());
    }
    return tags;
  }
  renderRow() {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "header-col" }, /* @__PURE__ */ React.createElement(
      PureCheckbox,
      {
        type: "checkbox",
        className: "select-all-selector",
        checked: this.shouldRenderCheck(),
        indeterminate: this.shouldRenderMinus(),
        onClick: this.props.onSelectedAll
      }
    )), /* @__PURE__ */ React.createElement("div", { className: "select-all-label", onClick: this.props.onSelectedAll }, this.renderNumberOfSelected()), this.props.enableMultiplePageSelection && /* @__PURE__ */ React.createElement("div", { className: "select-all-pages" }, this.renderSelectAcrossPages()));
  }
}
SelectAllSelector.defaultProps = {
  enableMultiplePageSelection: true
};
