export function getTranslatedUIPrompt(input, language) {
  const translations = /* @__PURE__ */ new Map([
    ["ES", { toEnglish: "traducir al ingl\xE9s", toNative: "traducir al espa\xF1ol", cancel: "cancelar" }],
    ["ZH", { toEnglish: "\u7FFB\u8BD1\u6210\u82F1\u6587", toNative: "\u7FFB\u8BD1\u6210\u4E2D\u6587", cancel: "\u53D6\u6D88" }],
    ["KO", { toEnglish: "\uC601\uC5B4\uB85C \uBC88\uC5ED", toNative: "\uD55C\uAD6D\uC5B4\uB85C \uBC88\uC5ED", cancel: "\uCDE8\uC18C" }],
    ["PT", { toEnglish: "traduzir para o ingl\xEAs", toNative: "traduzir para o portugu\xEAs", cancel: "cancelar" }],
    ["FR", { toEnglish: "traduire en anglais", toNative: "traduire en fran\xE7ais", cancel: "annuler" }],
    ["RU", { toEnglish: "\u043F\u0435\u0440\u0435\u0432\u0435\u0441\u0442\u0438 \u043D\u0430 \u0430\u043D\u0433\u043B\u0438\u0439\u0441\u043A\u0438\u0439", toNative: "\u043F\u0435\u0440\u0435\u0432\u0435\u0441\u0442\u0438 \u043D\u0430 \u0440\u0443\u0441\u0441\u043A\u0438\u0439", cancel: "\u043E\u0442\u043C\u0435\u043D\u0430" }],
    ["JA", { toEnglish: "\u82F1\u8A9E\u306B\u7FFB\u8A33", toNative: "\u65E5\u672C\u8A9E\u306B\u7FFB\u8A33", cancel: "\u30AD\u30E3\u30F3\u30BB\u30EB" }]
  ]);
  const translation = translations.get(language);
  if (!translation) {
    return "Translate";
  }
  switch (input) {
    case "translate to english":
      return translation.toEnglish;
    case "cancel":
      return translation.cancel;
    default:
      return translation.toNative;
  }
}
