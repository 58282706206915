import _ from "lodash";
import qs from "qs";
export function parseQuery(location) {
  let search = location.search;
  if (search && search[0] === "?") {
    search = search.slice(1);
  }
  return qs.parse(search);
}
export function stringify(params) {
  return qs.stringify(params, { arrayFormat: "brackets" });
}
export function arePropsLocationsEqual(props, otherProps) {
  let queryParams = parseQuery(otherProps.location);
  return !_.isEqual(queryParams, parseQuery(props.location));
}
