import React from "react";
import { EuiSpacer } from "@elastic/eui";
import { AuthUtils } from "@pm-frontend/shared/utils/auth-utils";
import { ManagementGlobalBanners } from "@pm-frontend/apps/management/subcomponents/ManagementGlobalBanners";
const GlobalBannerEmptySpace = () => {
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(EuiSpacer, { style: { height: "12px" } }));
};
const GlobalBanners = () => {
  if (AuthUtils.getActiveOrgType() === "m") {
    return /* @__PURE__ */ React.createElement(ManagementGlobalBanners, null);
  }
  return /* @__PURE__ */ React.createElement(GlobalBannerEmptySpace, null);
};
export { GlobalBanners, GlobalBannerEmptySpace };
