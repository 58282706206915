import { Select } from "@chakra-ui/react";
import React from "react";
export const BooleanCondition = ({ onChange, operator_key, value, error }) => {
  if (["is_value"].includes(operator_key)) {
    return /* @__PURE__ */ React.createElement(
      Select,
      {
        isInvalid: !!error,
        "data-test": "workflows.form.select.block_operator.condition_type.boolean",
        onChange,
        placeholder: "Select",
        value
      },
      /* @__PURE__ */ React.createElement("option", { value: "true", "data-test": "workflows.form.select.block_operator.condition_type.boolean.option.true" }, "True"),
      /* @__PURE__ */ React.createElement("option", { value: "false", "data-test": "workflows.form.select.block_operator.condition_type.boolean.option.false" }, "False")
    );
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null);
};
