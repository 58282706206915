import React from "react";
import { EuiHeaderSectionItem, EuiIcon } from "@elastic/eui";
import { Link } from "react-router-dom";
import URL from "@pm-shared/utils/url";
const HeaderLogoItem = ({ logoLinkUrl }) => {
  return /* @__PURE__ */ React.createElement(EuiHeaderSectionItem, { "aria-label": "PropertyMeld Icon" }, /* @__PURE__ */ React.createElement(Link, { to: logoLinkUrl, style: { alignSelf: "center", padding: "4px 16px" } }, /* @__PURE__ */ React.createElement(
    EuiIcon,
    {
      type: URL.getStatic("icons/propertymeld-logo.png"),
      "aria-label": "Go to home page",
      style: { cursor: "pointer", minWidth: "0px" },
      size: "l"
    }
  )));
};
export { HeaderLogoItem };
