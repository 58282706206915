import createReactClass from "create-react-class";
import React from "react";
export default createReactClass({
  render() {
    return /* @__PURE__ */ React.createElement(
      "svg",
      {
        className: this.props.classes,
        xmlns: "https://www.w3.org/2000/svg",
        preserveAspectRatio: "xMidYMid",
        width: "20",
        height: "20",
        viewBox: "0 0 40 40"
      },
      /* @__PURE__ */ React.createElement(
        "path",
        {
          d: "M20 40C8.955 40 0 31.046 0 20S8.955 0 20 0s20 8.954 20 20-8.955 20-20 20zm0-36.364C10.963 3.636 3.636 10.963 3.636 20S10.963 36.364 20 36.364 36.363 29.037 36.363 20 29.037 3.636 20 3.636zM27 22H13c-1.105 0-2-.896-2-2 0-1.105.895-2 2-2h14c1.105 0 2 .895 2 2 0 1.104-.895 2-2 2z",
          id: "path-1",
          fillRule: "evenodd"
        }
      )
    );
  }
});
