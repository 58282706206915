import PopoutSvg from "../../svg/popout-svg";
import React from "react";
export default class FileViewerNewWindow extends React.Component {
  render() {
    let { file } = this.props;
    return /* @__PURE__ */ React.createElement(
      "a",
      {
        className: "file-viewer-open-file file-action",
        title: "Open in new window",
        href: file.get("openable_signed_url"),
        target: "_blank"
      },
      /* @__PURE__ */ React.createElement(PopoutSvg, null)
    );
  }
}
