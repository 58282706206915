import React from "react";
import Checkbox from "../../../../assets/js/common/components/checkbox";
import Panel from "../../containers/Panel";
import TextBlock, { TextBlockSizes } from "../../texts/TextBlock";
import Tooltip from "../../../../assets/js/common/components/tooltip";
export class Group extends React.Component {
  render() {
    return /* @__PURE__ */ React.createElement("div", { className: "row" }, /* @__PURE__ */ React.createElement("div", { className: "columns" }, /* @__PURE__ */ React.createElement(Panel, { className: "pm-selection-group" }, /* @__PURE__ */ React.createElement(TextBlock, { size: TextBlockSizes.LG }, this.props.title), /* @__PURE__ */ React.createElement("div", { className: "pm-group-multiselect" }, this.props.columns.map((columnName, i) => {
      const values = this.props.items.map((item) => item.getIn(["values", i])).filter((value) => value).toSet();
      const checked = values.every((value) => {
        return !value || this.props.selectedValues.contains(value);
      });
      const indeterminate = !checked && values.some((value) => {
        return !value || this.props.selectedValues.contains(value);
      });
      const disabled = values.every((value) => {
        return !value || this.props.disabledValues.contains(value);
      });
      return /* @__PURE__ */ React.createElement("div", { key: i, className: values.isEmpty() ? "hidden" : "" }, /* @__PURE__ */ React.createElement(
        Checkbox,
        {
          checked,
          disabled,
          indeterminate,
          onClick: () => {
            if (checked || indeterminate) {
              this.props.uncheckValues(values.subtract(this.props.disabledValues));
            } else {
              this.props.checkValues(values.subtract(this.props.disabledValues));
            }
          }
        }
      ), /* @__PURE__ */ React.createElement(TextBlock, { size: TextBlockSizes.MD }, columnName));
    }))), this.props.items.map((item, i) => {
      const description = item.get("description");
      return /* @__PURE__ */ React.createElement(Panel, { key: i, className: "pm-selection-item" }, /* @__PURE__ */ React.createElement("div", { className: "pm-selection-item-text" }, /* @__PURE__ */ React.createElement(TextBlock, { className: "title-container", size: TextBlockSizes.LG }, /* @__PURE__ */ React.createElement("span", { className: "title" }, item.get("title")), description && /* @__PURE__ */ React.createElement(Tooltip, { message: description })), /* @__PURE__ */ React.createElement(TextBlock, { size: TextBlockSizes.MD, className: "description" }, description)), /* @__PURE__ */ React.createElement("div", { className: "pm-item-multiselect" }, item.get("values").map((value, j) => {
        const disabled = this.props.disabledValues.contains(value);
        return /* @__PURE__ */ React.createElement(React.Fragment, { key: j }, /* @__PURE__ */ React.createElement(
          Checkbox,
          {
            checked: this.props.selectedValues.contains(value),
            className: value ? "" : "hidden",
            disabled,
            onClick: () => disabled || this.props.toggleValue(value)
          }
        ), /* @__PURE__ */ React.createElement(TextBlock, { size: TextBlockSizes.MD, className: value ? "" : "hidden" }, this.props.columns.get(j)));
      })));
    })));
  }
}
