import React from "react";
import SVGInline from "react-svg-inline";
import classnames from "classnames";
function getRotationClass(rotation) {
  if (rotation === 90 || rotation === 180) {
    return `rotate-${rotation}`;
  }
  return "";
}
function SvgButton(props, icon, rotate = 0) {
  const classes = classnames("pm-svgbutton", "pm-button", props.className, {
    "with-border": props.withBorder
  });
  return /* @__PURE__ */ React.createElement("button", { type: "button", className: classes, onClick: props.onClick, disabled: props.disabled }, /* @__PURE__ */ React.createElement(SVGInline, { className: `svg-${getRotationClass(rotate)}`, svg: icon }));
}
export default SvgButton;
