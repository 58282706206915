import classNames from "classnames";
import PropTypes from "prop-types";
import createReactClass from "create-react-class";
import React from "react";
import WindowClickedMixin from "../../window-clicked-mixin";
import { Keys } from "../../utils/key-utils";
let TypeAhead = createReactClass({
  propTypes: {
    onItemChosen: PropTypes.func.isRequired,
    source: PropTypes.func.isRequired,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    isOpen: PropTypes.bool,
    multiselect: PropTypes.bool,
    placeholderText: PropTypes.string,
    inputId: PropTypes.string,
    minSearchLength: PropTypes.number
  },
  mixins: [WindowClickedMixin],
  getInitialState() {
    return {
      results: [],
      loading: false,
      showResultList: false,
      activeItem: null,
      currentSearchValue: ""
    };
  },
  render() {
    let inputClasses = classNames({
      "options-available": (this.state.showResultList || this.props.isOpen) && this.state.results.length,
      "typeahead-input": true
    });
    return /* @__PURE__ */ React.createElement("div", { className: "typeahead", onKeyDown: this.onMenuKeyDown }, /* @__PURE__ */ React.createElement(
      "input",
      {
        type: "text",
        autoComplete: "off",
        id: this._inputId(),
        ref: (node) => this.typeaheadInput = node,
        value: this.state.currentSearchValue,
        onChange: this.onInputChange,
        onFocus: this.onFocus,
        onBlur: this.closeDropdown,
        className: inputClasses,
        placeholder: this.props.placeholderText,
        disabled: this.props.disabled
      }
    ), /* @__PURE__ */ React.createElement("ul", { className: "options" }, this.renderResults()));
  },
  renderResults() {
    let { showResultList, results, loading, activeItem } = this.state;
    if (showResultList && results.length) {
      return results.map((model, i) => {
        let classes = classNames({
          option: true,
          focused: i === activeItem
        });
        let DisplayComponent = this.props.displayComponent;
        return /* @__PURE__ */ React.createElement(
          "li",
          {
            className: classes,
            ref: this._refOfMenuItem(i),
            onMouseEnter: () => this.setState({ activeItem: i }),
            key: model.id,
            onMouseDown: this.onItemClicked
          },
          /* @__PURE__ */ React.createElement(DisplayComponent, { item: model })
        );
      });
    } else if (showResultList && !results.length && !loading && this.typeaheadInput.value.length >= 2) {
      return /* @__PURE__ */ React.createElement("li", { className: "option" }, /* @__PURE__ */ React.createElement("span", null, "No results"));
    }
  },
  onInputChange(e) {
    let query = e.target.value;
    if (this.props.onSearchChange) {
      this.props.onSearchChange(query);
    }
    this.updateResults(query);
    e.preventDefault();
  },
  updateResults(query) {
    this.setState({ currentSearchValue: query });
    if (query.length >= this.props.minSearchLength || 2) {
      this.setState({ loading: true });
      this.props.source(query, (data) => {
        this.setState({
          loading: false,
          results: data,
          showResultList: true,
          activeItem: 0
        });
      });
    } else {
      this.setState({ showResultList: false });
    }
  },
  onMenuKeyDown(e) {
    let { results, activeItem } = this.state;
    if (e.keyCode === Keys.KEY_DOWN) {
      if (activeItem !== null && results[activeItem + 1] && results.length > activeItem) {
        this.setState({ activeItem: activeItem + 1 });
      }
      e.preventDefault();
    } else if (e.keyCode === Keys.KEY_UP) {
      if (activeItem !== null && results[activeItem - 1]) {
        this.setState({ activeItem: activeItem - 1 });
      }
      e.preventDefault();
    }
    if (e.keyCode === Keys.ENTER) {
      e.preventDefault();
      if (this.state.results.length) {
        this.onItemClicked(e);
      } else {
        this.closeDropdown();
      }
    }
  },
  onItemClicked(e) {
    e.stopPropagation();
    if (!this.props.multiselect) {
      this.setState(this.getInitialState());
    }
    if (this.state.results.length) {
      if (this.props.onSearchChange) {
        this.props.onSearchChange("");
      }
      this.props.onItemChosen(this.state.results[this.state.activeItem]);
    }
    if (this.props.multiselect) {
      this.updateResults(this.state.currentSearchValue);
    }
  },
  focusInput() {
    this.typeaheadInput.focus();
  },
  onFocus() {
    this.setState({ showResultList: true });
    if (this.props.onOpen) {
      this.props.onOpen();
    }
  },
  _refOfMenuItem(index) {
    return `item_${index}`;
  },
  _inputId() {
    return this.props.inputId || "typeahead-search-input";
  },
  handleWindowClicked(e) {
    if (e.target.id !== this._inputId()) {
      this.closeDropdown();
    }
  },
  closeDropdown() {
    if (this.state.showResultList && !this.state.loading) {
      this.setState({ showResultList: false });
      if (this.props.onClose) {
        this.props.onClose(this.state.currentSearchValue);
      }
    }
  },
  componentWillReceiveProps(nextProps) {
    if (this.state.currentSearchValue && this.props.selections !== nextProps.selections) {
      this.updateResults(this.state.currentSearchValue);
    }
  }
});
export default TypeAhead;
