import moment from "moment";
import * as C from "../constants";
function momentDatePart(datePart) {
  return (mom) => {
    if (mom && mom.get(datePart)) {
      return mom.get(datePart);
    } else if (mom) {
      return moment(mom).get(datePart);
    }
  };
}
let CalendarUtils = {
  renderFormattedDay(date, format = "MMMM Do") {
    if (date) {
      return moment(date).format(format);
    }
  },
  renderFormattedDayMonthYear(date, format = "MMMM Do, YYYY") {
    if (date) {
      return moment(date).format(format);
    }
  },
  renderFormattedTime(date) {
    return moment(date).format(C.TIME_FORMAT).toUpperCase();
  },
  formatDayMonthTime(date, { separator = ", at ", dayMonthFmt, timeFmt } = {}) {
    return `${this.renderFormattedDay(date, dayMonthFmt)}${separator}${this.renderFormattedTime(date, timeFmt)}`;
  },
  dateComparator(d1, d2) {
    return d2.getTime() - d1.getTime();
  },
  getDaysUntil(date) {
    return moment(date).diff(moment(), "days");
  },
  parseOrGet(dt) {
    if (!dt) {
      return dt;
    }
    if (!(dt instanceof Date)) {
      return moment(dt).toDate();
    }
    return dt;
  },
  isValidDate(d) {
    if (Object.prototype.toString.call(d) !== "[object Date]") {
      return false;
    }
    return !isNaN(d.getTime());
  },
  roundUpMoment(mom, minutes = 15) {
    let toRound = (mom || moment()).startOf("minute");
    return toRound.add(minutes - toRound.minutes() % minutes, "minutes");
  },
  roundDownMoment(mom, minutes = 15) {
    let toRound = (mom || moment()).startOf("minute");
    return toRound.subtract(toRound.minutes() % minutes, "minutes");
  },
  roundNearestMoment(mom, minutes = 15) {
    let m = mom || moment();
    let offset = m.minutes() % minutes;
    if (offset < minutes / 2) {
      return CalendarUtils.roundDownMoment(m, minutes);
    } else {
      return CalendarUtils.roundUpMoment(m, minutes);
    }
  },
  computeMinutesSince(fromDate, toDate) {
    let from = moment(fromDate);
    let to = toDate ? moment(toDate) : moment();
    return to.diff(from, "minutes");
  },
  computeSecondsSince(fromDate, toDate) {
    let from = moment(fromDate);
    let to = toDate ? moment(toDate) : moment();
    return to.diff(from, "seconds");
  },
  dateFromTime(time, defalt = null) {
    if (time) {
      let date = /* @__PURE__ */ new Date();
      date.setTime(Number(time));
      return date;
    }
    return defalt;
  },
  oneYearAgo() {
    return moment().subtract(1, "year").toDate();
  },
  isToday(mom) {
    return mom.isSame(moment(), "day");
  },
  isBeforeToday(mom) {
    let comparisonDate = mom;
    if (typeof mom === "string") {
      comparisonDate = moment(mom);
    }
    return moment().startOf("day").isAfter(comparisonDate);
  },
  isBeforeNow(dateString) {
    return moment().isAfter(moment(dateString));
  },
  year: momentDatePart("year"),
  month: momentDatePart("month"),
  day: momentDatePart("day")
};
export default CalendarUtils;
