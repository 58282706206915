import { AxiosHeaders } from "axios";
import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";
class NotificationApi extends BaseApi {
  static fetchAll(options) {
    options = options || {};
    if (options.url) {
      return this.get(options.url, { normalizeUrl: false });
    }
    return this.get(ApiUrls.notificationList);
  }
  static markRead(ids) {
    return this.post(ApiUrls.notificationMarkRead, { ids });
  }
  static markAllRead() {
    return this.post(ApiUrls.notificationMarkAllRead);
  }
  static fetchUnread() {
    const headers = new AxiosHeaders({ "Cache-Control": "max-age=60" });
    return this.get(ApiUrls.notificationsUnread, { headers });
  }
}
export default NotificationApi;
