import BackArrow from "./svg/back-arrow-svg";
import createReactClass from "create-react-class";
import ForwardArrow from "./svg/forward-arrow-svg";
import Link from "@pm-shared/utils/link";
import PureRenderMixin from "react-addons-pure-render-mixin";
import React from "react";
import classNames from "classnames";
let Pagination = createReactClass({
  mixins: [PureRenderMixin],
  render() {
    return /* @__PURE__ */ React.createElement("div", { className: "pagination" }, /* @__PURE__ */ React.createElement("div", { className: "pagination-controls" }, this.renderPrev(), this.renderNext()));
  },
  renderPrev() {
    if (this.props.prevPage) {
      return /* @__PURE__ */ React.createElement(Link, { to: this.props.prevPage, className: "pagination-control prev", normalize: false }, /* @__PURE__ */ React.createElement(BackArrow, null));
    }
  },
  renderNext() {
    if (this.props.nextPage) {
      return /* @__PURE__ */ React.createElement(Link, { to: this.props.nextPage, className: "pagination-control next", normalize: false }, /* @__PURE__ */ React.createElement(ForwardArrow, null));
    }
  }
});
export default Pagination;
let NonLinkPagination = createReactClass({
  mixins: [PureRenderMixin],
  render() {
    return /* @__PURE__ */ React.createElement("div", { className: "pagination" }, /* @__PURE__ */ React.createElement("div", { className: "pagination-controls" }, this.renderPrev(), this.renderPageNumbers(), this.renderNext()));
  },
  renderPageNumbers() {
    const pageNumberElements = [];
    const firstPage = 1;
    const lastPage = this.props.pageCount;
    const margin = 2;
    const maxBubbles = margin * 2 + 3;
    for (let i = 0; i < this.props.pageCount; i++) {
      const num = i + 1;
      const classes = classNames({
        "pagination-control": true,
        active: this.props.currentPage === num
      });
      let element;
      if (this.props.pageCount <= maxBubbles || num === firstPage || num === lastPage || num <= this.props.currentPage + margin && num >= this.props.currentPage - margin) {
        element = /* @__PURE__ */ React.createElement(
          "span",
          {
            className: classes,
            key: i,
            onClick: () => {
              this.props.onPageSelect(i);
            }
          },
          num
        );
      } else if (num === this.props.currentPage + margin + 1 || num === this.props.currentPage - margin - 1) {
        element = /* @__PURE__ */ React.createElement("span", { className: "pagination-control pagination-ellipses" }, "...");
      }
      if (element) {
        pageNumberElements.push(element);
      }
    }
    return pageNumberElements;
  },
  renderPrev() {
    const classes = classNames({
      "pagination-control": true,
      disabled: !this.props.hasPrev,
      prev: true
    });
    return /* @__PURE__ */ React.createElement("span", { onClick: this.props.onPrev, className: classes }, /* @__PURE__ */ React.createElement(BackArrow, null));
  },
  renderNext() {
    const classes = classNames({
      "pagination-control": true,
      disabled: !this.props.hasNext,
      next: true
    });
    return /* @__PURE__ */ React.createElement("span", { onClick: this.props.onNext, className: classes }, /* @__PURE__ */ React.createElement(ForwardArrow, null));
  }
});
export { NonLinkPagination };
