import ActivityGroup from "./activity-group";
import Calendar from "../svg/calendar-svg";
import Cash from "../svg/cash-svg";
import Chat from "../svg/chat-svg";
import classnames from "classnames";
import Document from "../svg/document-svg";
import moment from "moment";
import React from "react";
import Star from "../svg/star-svg";
import ClockSVG from "../svg/clock-svg";
import StampSVG from "../svg/stamp-svg";
let ACTIVITY_GROUPS = {
  MESSAGES: [12, 29, 30, 37],
  SCHEDULING: [8, 10, 11, 13, 14, 17, 18, 19, 20, 22, 27, 32, 33, 34, 35, 36, 38, 42],
  FILES: [39, 40],
  FEEDBACK: [24],
  INVOICE: [15, 43, 44],
  TENANT_INVITE_REQUESTS: [26],
  EXPIRING_FILE: [23],
  MELDS_NEED_AVAILABILITY: [28],
  MELD_REMINDERS: [41],
  OWNER_APPROVALS: [45]
};
class NotificationComponent extends React.Component {
  render() {
    let activities = this.props.notification.get("activities");
    let messageActivities = activities.filter((activity) => {
      return ACTIVITY_GROUPS.MESSAGES.includes(activity.getVerbId());
    });
    let schedulingActivities = activities.filter((activity) => {
      return ACTIVITY_GROUPS.SCHEDULING.includes(activity.getVerbId());
    });
    let fileActivities = activities.filter((activity) => {
      return ACTIVITY_GROUPS.FILES.includes(activity.getVerbId());
    });
    let feedbackActivities = activities.filter((activity) => {
      return ACTIVITY_GROUPS.FEEDBACK.includes(activity.getVerbId());
    });
    let invoiceActivities = activities.filter((activity) => {
      return ACTIVITY_GROUPS.INVOICE.includes(activity.getVerbId());
    });
    let tenantInviteRequestActivities = activities.filter((activity) => {
      return ACTIVITY_GROUPS.TENANT_INVITE_REQUESTS.includes(activity.getVerbId());
    });
    let expiringFileActivities = activities.filter((activity) => {
      return ACTIVITY_GROUPS.EXPIRING_FILE.includes(activity.getVerbId());
    });
    let meldsNeedAvailabilityActivities = activities.filter((activity) => {
      return ACTIVITY_GROUPS.MELDS_NEED_AVAILABILITY.includes(activity.getVerbId());
    });
    let meldReminderActivities = activities.filter((activity) => {
      return ACTIVITY_GROUPS.MELD_REMINDERS.includes(activity.getVerbId());
    });
    let ownerApprovalActivities = activities.filter((activity) => {
      return ACTIVITY_GROUPS.OWNER_APPROVALS.includes(activity.getVerbId());
    });
    let Icon = this.props.notification.getIcon();
    const indicatorClasses = classnames({
      "new-indicator": true,
      "new-indicator-has-new": !this.props.notification.isRead()
    });
    if (Icon) {
      let unread = null;
      if (!tenantInviteRequestActivities.isEmpty() || !expiringFileActivities.isEmpty() || !meldsNeedAvailabilityActivities.isEmpty()) {
        let unreadCount = activities.filter((activity) => !activity.isRead()).size;
        unread = unreadCount ? /* @__PURE__ */ React.createElement("b", null, `(${unreadCount} unread)`) : "";
      }
      return /* @__PURE__ */ React.createElement("div", { className: "notification" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: "activity-heading" }, /* @__PURE__ */ React.createElement("span", { className: indicatorClasses }), /* @__PURE__ */ React.createElement("div", { className: "notification-content" }, /* @__PURE__ */ React.createElement("span", null, this.props.notification.getTitle(), " ", unread), /* @__PURE__ */ React.createElement("i", { className: "copy time" }, moment.utc(this.props.notification.getTime()).fromNow()))), /* @__PURE__ */ React.createElement(ActivityGroup, { icon: Chat, title: "Messages", activities: messageActivities, max: 5 }), /* @__PURE__ */ React.createElement(ActivityGroup, { icon: ClockSVG, title: "Reminders", activities: meldReminderActivities, max: 3 }), /* @__PURE__ */ React.createElement(ActivityGroup, { icon: StampSVG, title: "Owner Approval", activities: ownerApprovalActivities, max: 5 }), /* @__PURE__ */ React.createElement(ActivityGroup, { icon: Calendar, title: "Scheduling", activities: schedulingActivities, max: 5 }), /* @__PURE__ */ React.createElement(ActivityGroup, { icon: Document, title: "Files", activities: fileActivities, max: 3 }), /* @__PURE__ */ React.createElement(ActivityGroup, { icon: Star, title: "Feedback", activities: feedbackActivities, max: 1 }), /* @__PURE__ */ React.createElement(ActivityGroup, { icon: Cash, title: "Invoice", activities: invoiceActivities, max: 1 }), /* @__PURE__ */ React.createElement(ActivityGroup, { activities: tenantInviteRequestActivities, max: 3 }), /* @__PURE__ */ React.createElement(ActivityGroup, { activities: expiringFileActivities, max: 3 }), /* @__PURE__ */ React.createElement(ActivityGroup, { activities: meldsNeedAvailabilityActivities, max: 1 })));
    }
  }
}
export default NotificationComponent;
