import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";
class InvoiceApi extends BaseApi {
  static createMeldInvoice(invoice) {
    return this.post(ApiUrls.meldInvoiceList, invoice);
  }
  static createInvoiceFile(vendorId, data) {
    return this.post(ApiUrls.vendorInvoiceFileList(vendorId), data);
  }
  static deleteInvoiceFile(vendorId, data) {
    return this.delete(ApiUrls.vendorInvoiceFileDetail(vendorId), data);
  }
  static fetchAll(options) {
    return this.get(ApiUrls.meldInvoiceList, options);
  }
  static fetchOne(invoiceId) {
    return this.get(ApiUrls.meldInvoiceDetail(invoiceId));
  }
  static update(invoiceId, data) {
    return this.patch(ApiUrls.meldInvoiceDetail(invoiceId), data);
  }
  static deleteDraft(invoiceId) {
    return this.delete(ApiUrls.meldInvoiceDetail(invoiceId));
  }
  static fetchInvoicesByUnit(unitId) {
    return this.get(ApiUrls.unitMeldInvoiceList(unitId));
  }
  static hold(invoiceId, data) {
    return this.patch(ApiUrls.meldInvoiceHold(invoiceId), data);
  }
  static decline(invoiceId, data) {
    return this.patch(ApiUrls.meldInvoiceDecline(invoiceId), data);
  }
  static approve(invoiceId) {
    return this.patch(ApiUrls.meldInvoiceApprove(invoiceId));
  }
  static review(invoiceId) {
    return this.patch(ApiUrls.meldInvoiceReview(invoiceId));
  }
}
export default InvoiceApi;
