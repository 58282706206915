import useSWR from "swr";
import { APIPrefix } from "../api";
import { axios } from "@pm-shared/axios";
export const fetchCommentTemplates = () => {
  return useSWR(
    `${APIPrefix}/comment-templates/`,
    () => axios.get(`${APIPrefix}/comment-templates/`).then((response) => response.data)
  );
};
export const fetchAbbreviatedCommentTemplates = () => {
  return useSWR(
    `${APIPrefix}/comment-templates/`,
    () => axios.get(`${APIPrefix}/comment-templates/abbrev/`).then((response) => response.data)
  );
};
