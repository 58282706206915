import UserUtils from "../../authentication/utils/user-utils";
import ContactUtils from "../../utils/contact-utils";
let AgentUtils = {
  getFullName(agent, emptyMsg = "No Name") {
    if (!agent || !agent.get("user")) {
      return emptyMsg;
    }
    return UserUtils.getFullName(agent.get("user"), emptyMsg);
  },
  getPrimaryPhone(agent, emptyMsg = "No phone") {
    return ContactUtils.getFirstFoundPhone(agent.get("contact"), emptyMsg);
  },
  getEmail(agent, emptyMsg = "No email") {
    return agent.getIn(["user", "email"]) || emptyMsg;
  }
};
export default AgentUtils;
