import classNames from "classnames";
import React from "react";
import ButtonContainer from "../../app/components/containers/ButtonContainer";
import { FlexJustifyContent } from "../../app/components/utils/constants";
import Button, { ButtonTypes } from "../../app/components/buttons/Button";
class DialogBox extends React.Component {
  render() {
    const modalClasses = classNames({
      in: true,
      modal: true,
      active: true,
      "dialog-box": true
    });
    const stacked = this.props.dangerBtnText && this.props.safeBtnText && this.props.cancelBtnText;
    const actionBtnClasses = classNames({
      stacked: !!stacked,
      row: true,
      last: true,
      "action-btns": true
    });
    return /* @__PURE__ */ React.createElement("div", { className: "modal-open" }, /* @__PURE__ */ React.createElement("div", { className: modalClasses, role: "dialog", "aria-labelledby": "", "aria-hidden": "false", style: { display: "block" } }, /* @__PURE__ */ React.createElement("div", { className: `modal-container ${this.props.columnClasses}` }, /* @__PURE__ */ React.createElement("div", { className: "modal-dialog" }, /* @__PURE__ */ React.createElement("div", { className: "modal-content" }, this.renderHeader(), /* @__PURE__ */ React.createElement("div", { className: "modal-body" }, /* @__PURE__ */ React.createElement("div", { className: "row" }, /* @__PURE__ */ React.createElement("div", { className: "columns small-centered" }, this.renderTextOrComponent())), this.renderErrors(), /* @__PURE__ */ React.createElement("div", { className: actionBtnClasses }, /* @__PURE__ */ React.createElement(ButtonContainer, { justify: FlexJustifyContent.Center }, this.renderCloseBtn(), this.renderDangerBtn(), this.renderSafeBtn()))))))));
  }
  renderCloseBtn() {
    return /* @__PURE__ */ React.createElement(
      Button,
      {
        "data-test": "dialog_box.button.action.cancel",
        buttonType: ButtonTypes.Cancel,
        className: "dialog-cancel-btn",
        disabled: this.props.isLoading,
        onClick: this.props.closeClick
      },
      this.props.cancelBtnText || "Cancel"
    );
  }
  renderDangerBtn() {
    if (this.props.dangerBtnText && this.props.dangerClick) {
      return /* @__PURE__ */ React.createElement(
        Button,
        {
          "data-test": "dialog_box.button.action.danger",
          buttonType: ButtonTypes.Danger,
          className: "dialog-danger-btn",
          disabled: this.props.isLoading,
          onClick: this.props.dangerClick
        },
        this.props.dangerBtnText
      );
    }
  }
  renderSafeBtn() {
    if (this.props.safeBtnText && this.props.onSafeClick) {
      return /* @__PURE__ */ React.createElement(
        Button,
        {
          "data-test": "dialog_box.button.action.primary",
          buttonType: ButtonTypes.Primary,
          className: `${this.props.safeBtnClasses || ""} dialog-safe-btn`,
          disabled: this.props.isLoading,
          onClick: this.props.onSafeClick
        },
        this.props.isLoading ? this.props.loadingText || "Loading..." : this.props.safeBtnText
      );
    }
  }
  renderTextOrComponent() {
    if (!this.props.confirmText) {
      return this.props.children;
    } else {
      return /* @__PURE__ */ React.createElement("p", { className: "heading" }, this.props.confirmText);
    }
  }
  renderErrors() {
    if (this.props.serverErrors) {
      return /* @__PURE__ */ React.createElement("div", { className: "row" }, /* @__PURE__ */ React.createElement("div", { className: "columns small-centered" }, /* @__PURE__ */ React.createElement("div", { className: "error" }, this.props.serverErrors)));
    }
  }
  renderHeader() {
    if (this.props.headerText) {
      return /* @__PURE__ */ React.createElement("div", { className: "modal-header" }, /* @__PURE__ */ React.createElement("div", { className: "modal-header-content" }, /* @__PURE__ */ React.createElement("h2", { className: "modal-title" }, this.props.headerText)));
    }
  }
}
DialogBox.defaultProps = {
  cancelBtnText: "Cancel",
  columnClasses: "small-centered small-12 medium-7 large-5 columns"
};
export default DialogBox;
