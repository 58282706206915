import { stringify } from "./location-utils";
import { ErrorHandler } from "../../../../app/utils/ErrorHandler";
import { LinkHelper } from "@pm-shared/utils/link";
import RouteUtils from "./route-utils";
const HistoryUtils = {
  push(history, path, { query, normalize = true, state } = {}) {
    if (!history) {
      ErrorHandler.handleError(new Error(`History not provided for path: ${path}`));
    }
    let pathname = path;
    if (query) {
      pathname = `${pathname}?${stringify(query)}`;
    }
    if (normalize || normalize === void 0) {
      pathname = LinkHelper.normalize(pathname);
    }
    history.push(pathname, state);
  },
  replace(history, path, { query, normalize = true, state } = {}) {
    if (!history) {
      ErrorHandler.handleError(new Error(`History not provided for path: ${path}`));
    }
    let pathname = path;
    if (query) {
      pathname = `${pathname}?${stringify(query)}`;
    }
    if (normalize || normalize === void 0) {
      pathname = LinkHelper.normalize(pathname);
    }
    history.replace(pathname, state);
  },
  replaceOrGoBack(history, path, { normalize = true } = {}) {
    if (RouteUtils.isPrevRoutePathEqualTo(normalize ? LinkHelper.normalize(path) : path)) {
      HistoryUtils.goBack(history);
    } else {
      HistoryUtils.replace(history, path, { normalize });
    }
  },
  goBack(history) {
    history.goBack();
  }
};
export default HistoryUtils;
