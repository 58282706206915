import React from "react";
import classnames from "classnames";
export default function ButtonContainer(props) {
  const classes = classnames(
    "pm-button-container",
    {
      [props.justify]: !!props.justify,
      "pm-inline": props.inline,
      "full-buttons": props.fullButtons,
      "force-inline-buttons": !!props.forceInlineButtons,
      [props.align]: !!props.align,
      "force-size": props.forceSize
    },
    props.className
  );
  return /* @__PURE__ */ React.createElement("div", { className: classes }, props.children);
}
