import React from "react";
import { EuiPageTemplate } from "@elastic/eui";
import { ApplicationToastList } from "./ApplicationToastList";
import { MobileInnerPageContentHeight, PageContentHeight } from "@pm-frontend/styles";
import { useIsMobile } from "../hooks/useIsMobile";
const ApplicationLayout = ({
  children,
  header,
  sidebar,
  sidebarNavIsOpen,
  globalFlyouts,
  globalModals,
  showOldOrNewUiRoute
}) => {
  const isMobile = useIsMobile();
  if (isMobile) {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, header, /* @__PURE__ */ React.createElement(
      EuiPageTemplate,
      {
        grow: true,
        panelled: false,
        paddingSize: "none",
        style: { minBlockSize: `max(460px, ${MobileInnerPageContentHeight})` }
      },
      /* @__PURE__ */ React.createElement(EuiPageTemplate.Sidebar, { sticky: false, paddingSize: "none" }, sidebar),
      children,
      /* @__PURE__ */ React.createElement(ApplicationToastList, null),
      globalFlyouts,
      globalModals
    ));
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, header, /* @__PURE__ */ React.createElement(
    EuiPageTemplate,
    {
      grow: true,
      style: {
        height: showOldOrNewUiRoute === "new" ? PageContentHeight : void 0,
        minBlockSize: 0
      },
      panelled: false
    },
    /* @__PURE__ */ React.createElement(EuiPageTemplate.Sidebar, { sticky: false, paddingSize: "none", minWidth: sidebarNavIsOpen ? "240px" : "64px" }, sidebar),
    children,
    /* @__PURE__ */ React.createElement(ApplicationToastList, null),
    globalFlyouts,
    globalModals
  ));
};
export { ApplicationLayout };
