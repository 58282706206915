var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import Axios from "axios";
const axios = Axios.create({
  headers: {
    "X-CSRFToken": window.PM.csrf_token
  }
});
const apiFetch = (_0, ..._1) => __async(void 0, [_0, ..._1], function* (url, config = {}) {
  return axios.get(url, config).then((res) => res.data);
});
const apiDelete = (url) => __async(void 0, null, function* () {
  return axios.delete(url).then((res) => res.data);
});
const apiPatch = (url, data) => __async(void 0, null, function* () {
  return axios.patch(url, data).then((res) => res.data);
});
const apiPut = (url, data) => __async(void 0, null, function* () {
  return axios.put(url, data).then((res) => res.data);
});
const apiPost = (_0, _1, ..._2) => __async(void 0, [_0, _1, ..._2], function* (url, data, config = {}) {
  return axios.post(url, data).then((res) => res.data);
});
export { apiFetch, apiDelete, apiPatch, apiPost, apiPut };
