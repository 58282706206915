import React from "react";
export const inActiveText = "(Inactive) ";
export var MgmtOnlyWorkCategories = /* @__PURE__ */ ((MgmtOnlyWorkCategories2) => {
  MgmtOnlyWorkCategories2["FOR_RENT_SIGN"] = "For Rent Sign";
  MgmtOnlyWorkCategories2["EVALUATION"] = "Evaluation";
  MgmtOnlyWorkCategories2["TURNOVER"] = "Turnover";
  MgmtOnlyWorkCategories2["VIOLATIONS"] = "Violations";
  return MgmtOnlyWorkCategories2;
})(MgmtOnlyWorkCategories || {});
export var UpdateVendorToastMessages = /* @__PURE__ */ ((UpdateVendorToastMessages2) => {
  UpdateVendorToastMessages2["ACCEPTED"] = "Your company information has been updated";
  UpdateVendorToastMessages2["CANCELED"] = "Changes to your company information have been canceled";
  UpdateVendorToastMessages2["NO_CHANGE"] = "No changes were made";
  return UpdateVendorToastMessages2;
})(UpdateVendorToastMessages || {});
export var ListViewPreferences = /* @__PURE__ */ ((ListViewPreferences2) => {
  ListViewPreferences2["CARD"] = "CARD";
  ListViewPreferences2["LIST"] = "LIST";
  return ListViewPreferences2;
})(ListViewPreferences || {});
export var TenantWorkCategories = /* @__PURE__ */ ((TenantWorkCategories2) => {
  TenantWorkCategories2["APPLIANCES"] = "Appliances";
  TenantWorkCategories2["BLINDS_WINDOW_TREATMENTS"] = "Blinds/Window treatments";
  TenantWorkCategories2["CARPENTRY"] = "Carpentry";
  TenantWorkCategories2["CIRCUIT_BREAKER"] = "Circuit Breaker";
  TenantWorkCategories2["CLEANING"] = "Cleaning";
  TenantWorkCategories2["DOORS"] = "Doors";
  TenantWorkCategories2["DRIVEWAY"] = "Driveway";
  TenantWorkCategories2["DRYWALL"] = "Drywall";
  TenantWorkCategories2["ELECTRICAL"] = "Electrical";
  TenantWorkCategories2["EXTERIOR"] = "Exterior";
  TenantWorkCategories2["FIREPLACE"] = "Fireplace";
  TenantWorkCategories2["FLOORING"] = "Flooring";
  TenantWorkCategories2["GARAGE_DOOR"] = "Garage Door";
  TenantWorkCategories2["GARBAGE_DISPOSAL"] = "Garbage Disposal";
  TenantWorkCategories2["GENERAL"] = "General";
  TenantWorkCategories2["HARDWARE"] = "Hardware";
  TenantWorkCategories2["HVAC"] = "Heating/AC";
  TenantWorkCategories2["INTERIOR"] = "Interior";
  TenantWorkCategories2["LANDSCAPING"] = "Landscaping";
  TenantWorkCategories2["LOCKS"] = "Locks";
  TenantWorkCategories2["OTHER_WORK_CATEGORY"] = "Other";
  TenantWorkCategories2["OUTSIDE_WATER_SPIGOT"] = "Outside Water Spigot";
  TenantWorkCategories2["PAINTING"] = "Painting";
  TenantWorkCategories2["PEST_CONTROL"] = "Pest control";
  TenantWorkCategories2["PLUMBING"] = "Plumbing";
  TenantWorkCategories2["POOL"] = "Pool";
  TenantWorkCategories2["ROOFING"] = "Roofing";
  TenantWorkCategories2["SEWER"] = "Sewer";
  TenantWorkCategories2["SHOWER"] = "Shower";
  TenantWorkCategories2["SIDING"] = "Siding";
  TenantWorkCategories2["SMOKE_DETECTOR_CO_DETECTORS"] = "Smoke Detector/CO detectors";
  TenantWorkCategories2["SOFFIT_FASCIA"] = "Soffit/Fascia";
  TenantWorkCategories2["STAIRS"] = "Stairs";
  TenantWorkCategories2["TOILET"] = "Toilet";
  TenantWorkCategories2["TOWEL_BARS"] = "Towel bars";
  TenantWorkCategories2["WASHER_DRYER"] = "Washer/Dryer";
  TenantWorkCategories2["WATER_DAMAGE"] = "Water Damage";
  TenantWorkCategories2["WATER_HEATER"] = "Water Heater";
  TenantWorkCategories2["WATER_SOFTENER"] = "Water Softener";
  TenantWorkCategories2["WINDOWS"] = "Windows";
  return TenantWorkCategories2;
})(TenantWorkCategories || {});
export const workCategory = Object.assign({}, MgmtOnlyWorkCategories, TenantWorkCategories);
export var Priority = /* @__PURE__ */ ((Priority2) => {
  Priority2["LOW"] = "LOW";
  Priority2["MEDIUM"] = "MEDIUM";
  Priority2["HIGH"] = "HIGH";
  return Priority2;
})(Priority || {});
export var PriorityReadable = /* @__PURE__ */ ((PriorityReadable2) => {
  PriorityReadable2["LOW"] = "Low";
  PriorityReadable2["MEDIUM"] = "Medium";
  PriorityReadable2["HIGH"] = "High";
  return PriorityReadable2;
})(PriorityReadable || {});
export var ProjectType = /* @__PURE__ */ ((ProjectType2) => {
  ProjectType2["MITIGATION"] = "MITIGATION";
  ProjectType2["TURN"] = "TURN";
  ProjectType2["RENOVATION"] = "RENOVATION";
  ProjectType2["OTHER"] = "OTHER";
  return ProjectType2;
})(ProjectType || {});
export var ProjectTypeReadable = /* @__PURE__ */ ((ProjectTypeReadable2) => {
  ProjectTypeReadable2["MITIGATION"] = "Mitigation";
  ProjectTypeReadable2["OTHER"] = "Other";
  ProjectTypeReadable2["RENOVATION"] = "Renovation";
  ProjectTypeReadable2["TURN"] = "Turn";
  return ProjectTypeReadable2;
})(ProjectTypeReadable || {});
export const rrule = {
  freq: {
    SECONDLY: "SECONDLY",
    MINUTELY: "MINUTELY",
    HOURLY: "HOURLY",
    DAILY: "DAILY",
    WEEKLY: "WEEKLY",
    MONTHLY: "MONTHLY",
    YEARLY: "YEARLY"
  }
};
export var TenantMeldStatus = /* @__PURE__ */ ((TenantMeldStatus2) => {
  TenantMeldStatus2["OPEN"] = "OPEN";
  TenantMeldStatus2["TENANT_CANCELED"] = "TENANT_CANCELED";
  TenantMeldStatus2["MANAGER_REJECTED"] = "MANAGER_REJECTED";
  return TenantMeldStatus2;
})(TenantMeldStatus || {});
export var VendorSpecialtiesText = /* @__PURE__ */ ((VendorSpecialtiesText2) => {
  VendorSpecialtiesText2["APPLIANCE"] = "Appliance Repair";
  VendorSpecialtiesText2["FENCING"] = "Fencing";
  VendorSpecialtiesText2["FLOORING"] = "Flooring";
  VendorSpecialtiesText2["GUTTER_CONTRACTOR"] = "Gutter Contractor";
  VendorSpecialtiesText2["HVAC"] = "HVAC";
  VendorSpecialtiesText2["INSULATION"] = "Insulation";
  VendorSpecialtiesText2["MASON"] = "Mason/Concrete";
  VendorSpecialtiesText2["PLUMBER"] = "Plumber";
  VendorSpecialtiesText2["REMODELER"] = "Remodeler";
  VendorSpecialtiesText2["ROOFING"] = "Roofing";
  VendorSpecialtiesText2["SIDING"] = "Siding";
  VendorSpecialtiesText2["TREE_SERVICE"] = "Tree Service";
  VendorSpecialtiesText2["WINDOW_DOOR"] = "Window/Door";
  VendorSpecialtiesText2["CABINETS_COUNTERTOPS"] = "Cabinets/Countertops";
  VendorSpecialtiesText2["CARPENTER"] = "Carpenter";
  VendorSpecialtiesText2["CLEANING_EXTERIOR"] = "Cleaning - Exterior";
  VendorSpecialtiesText2["CLEANINGS_INTERIOR_MAID_SERVICE"] = "Cleaning - Interior/Maid";
  VendorSpecialtiesText2["DECK_BUILDER"] = "Deck Builder";
  VendorSpecialtiesText2["DRYWALL_PLASTER"] = "Drywall/Plaster";
  VendorSpecialtiesText2["ELECTRICIAN"] = "Electrician";
  VendorSpecialtiesText2["GARAGE_DOOR"] = "Garage Door";
  VendorSpecialtiesText2["HANDYMAN"] = "Handyman";
  VendorSpecialtiesText2["HOME_INSPECTOR"] = "Home Inspector";
  VendorSpecialtiesText2["HOME_MEDIA"] = "Home media";
  VendorSpecialtiesText2["PEST_CONTROL"] = "Pest Control";
  VendorSpecialtiesText2["LANDSCAPER"] = "Landscaper";
  VendorSpecialtiesText2["OTHER"] = "Other";
  VendorSpecialtiesText2["PAINTER"] = "Painter";
  VendorSpecialtiesText2["POOLS"] = "Pools";
  VendorSpecialtiesText2["SOLAR"] = "Solar";
  VendorSpecialtiesText2["TILE"] = "Tile";
  return VendorSpecialtiesText2;
})(VendorSpecialtiesText || {});
export var Filters = /* @__PURE__ */ ((Filters2) => {
  Filters2["WITH_TENANTS"] = "WITH_TENANTS";
  Filters2["WITHOUT_TENANTS"] = "WITHOUT_TENANTS";
  return Filters2;
})(Filters || {});
export var IsActiveFilters = /* @__PURE__ */ ((IsActiveFilters2) => {
  IsActiveFilters2["IS_ACTIVE"] = "True";
  IsActiveFilters2["IS_NOT_ACTIVE"] = "False";
  return IsActiveFilters2;
})(IsActiveFilters || {});
export const MeldBaseRoutes = ["melds", "meld", "owner-services", "projects", "workflows"];
export var MeldStatuses = /* @__PURE__ */ ((MeldStatuses2) => {
  MeldStatuses2["OPEN"] = "OPEN";
  MeldStatuses2["MANAGER_CANCELED"] = "MANAGER_CANCELED";
  MeldStatuses2["TENANT_CANCELED"] = "TENANT_CANCELED";
  MeldStatuses2["PENDING_ESTIMATES"] = "PENDING_ESTIMATES";
  MeldStatuses2["PENDING_TENANT_AVAILABILITY"] = "PENDING_TENANT_AVAILABILITY";
  MeldStatuses2["PENDING_MORE_VENDOR_AVAILABILITY"] = "PENDING_MORE_VENDOR_AVAILABILITY";
  MeldStatuses2["PENDING_MORE_MANAGEMENT_AVAILABILITY"] = "PENDING_MORE_MANAGEMENT_AVAILABILITY";
  MeldStatuses2["PENDING_COMPLETION"] = "PENDING_COMPLETION";
  MeldStatuses2["COMPLETED"] = "COMPLETED";
  MeldStatuses2["VENDOR_COULD_NOT_COMPLETE"] = "VENDOR_COULD_NOT_COMPLETE";
  MeldStatuses2["MAINTENANCE_COULD_NOT_COMPLETE"] = "MAINTENANCE_COULD_NOT_COMPLETE";
  MeldStatuses2["PENDING_VENDOR"] = "PENDING_VENDOR";
  MeldStatuses2["PENDING_ASSIGNMENT"] = "PENDING_ASSIGNMENT";
  return MeldStatuses2;
})(MeldStatuses || {});
export var MeldType = /* @__PURE__ */ ((MeldType2) => {
  MeldType2["MELD"] = "MELD";
  MeldType2["ESTIMATE"] = "ESTIMATE";
  return MeldType2;
})(MeldType || {});
export var EstimateStatuses = /* @__PURE__ */ ((EstimateStatuses2) => {
  EstimateStatuses2["ESTIMATE_PENDING_ACCEPTANCE"] = "ESTIMATE_PENDING_ACCEPTANCE";
  EstimateStatuses2["ESTIMATE_IN_PROGRESS"] = "ESTIMATE_IN_PROGRESS";
  EstimateStatuses2["ESTIMATE_SUBMITTED"] = "ESTIMATE_SUBMITTED";
  EstimateStatuses2["ESTIMATE_VENDOR_REJECTED"] = "ESTIMATE_VENDOR_REJECTED";
  EstimateStatuses2["ESTIMATE_OWNER_REJECTED"] = "ESTIMATE_OWNER_REJECTED";
  EstimateStatuses2["ESTIMATE_CLOSED"] = "ESTIMATE_CLOSED";
  EstimateStatuses2["ESTIMATE_PENDING_APPROVAL"] = "ESTIMATE_PENDING_APPROVAL";
  EstimateStatuses2["ESTIMATE_APPROVED"] = "ESTIMATE_APPROVED";
  return EstimateStatuses2;
})(EstimateStatuses || {});
export var EstimateStatusesReadable = /* @__PURE__ */ ((EstimateStatusesReadable2) => {
  EstimateStatusesReadable2["ESTIMATE_PENDING_ACCEPTANCE"] = "Pending Vendor Acceptance";
  EstimateStatusesReadable2["ESTIMATE_IN_PROGRESS"] = "In Progress";
  EstimateStatusesReadable2["ESTIMATE_SUBMITTED"] = "Submitted";
  EstimateStatusesReadable2["ESTIMATE_VENDOR_REJECTED"] = "Vendor Rejected";
  EstimateStatusesReadable2["ESTIMATE_OWNER_REJECTED"] = "Owner Rejected";
  EstimateStatusesReadable2["ESTIMATE_CLOSED"] = "Closed";
  EstimateStatusesReadable2["ESTIMATE_PENDING_APPROVAL"] = "Pending Approval";
  EstimateStatusesReadable2["ESTIMATE_APPROVED"] = "Approved";
  return EstimateStatusesReadable2;
})(EstimateStatusesReadable || {});
export var ClosedMeldStatuses = /* @__PURE__ */ ((ClosedMeldStatuses2) => {
  ClosedMeldStatuses2["TENANT_CANCELED"] = "TENANT_CANCELED";
  ClosedMeldStatuses2["MANAGER_CANCELED"] = "MANAGER_CANCELED";
  ClosedMeldStatuses2["COMPLETED"] = "COMPLETED";
  ClosedMeldStatuses2["MAINTENANCE_COULD_NOT_COMPLETE"] = "MAINTENANCE_COULD_NOT_COMPLETE";
  ClosedMeldStatuses2["VENDOR_COULD_NOT_COMPLETE"] = "VENDOR_COULD_NOT_COMPLETE";
  return ClosedMeldStatuses2;
})(ClosedMeldStatuses || {});
export const MeldOpenStatuses = [
  "PENDING_ESTIMATES",
  "PENDING_TENANT_AVAILABILITY",
  "PENDING_MORE_VENDOR_AVAILABILITY",
  "PENDING_MORE_MANAGEMENT_AVAILABILITY",
  "PENDING_COMPLETION",
  "PENDING_VENDOR",
  "PENDING_ASSIGNMENT"
];
export var MeldScheduling = /* @__PURE__ */ ((MeldScheduling2) => {
  MeldScheduling2["NO_CURRENT"] = "NO_CURRENT";
  return MeldScheduling2;
})(MeldScheduling || {});
export const LOADING_GIF_SRC = `${window.PM.env.staticUrl}static/img/loader.gif`;
export const COMPRESSED_CALENDAR_MORNING_START_HOUR = 6;
export const COMPRESSED_CALENDAR_EVENING_END_HOUR = 19;
export const MESSAGE_TIMEOUT = 5500;
export const DEFAULT_EVENT_TYPE = "default";
export var ExpenditureStatuses = /* @__PURE__ */ ((ExpenditureStatuses2) => {
  ExpenditureStatuses2["DRAFT"] = "DRAFT";
  ExpenditureStatuses2["IN_REVIEW"] = "IN_REVIEW";
  ExpenditureStatuses2["HOLD"] = "HOLD";
  ExpenditureStatuses2["APPROVED"] = "APPROVED";
  ExpenditureStatuses2["BILLED"] = "BILLED";
  return ExpenditureStatuses2;
})(ExpenditureStatuses || {});
export var ExpenditureSelections = /* @__PURE__ */ ((ExpenditureSelections2) => {
  ExpenditureSelections2["DRAFT"] = "Place in draft";
  ExpenditureSelections2["REVIEW"] = "Place in review";
  ExpenditureSelections2["HOLD"] = "Place on hold";
  ExpenditureSelections2["APPROVE"] = "Approve";
  ExpenditureSelections2["BILL"] = "Mark billed";
  return ExpenditureSelections2;
})(ExpenditureSelections || {});
export var ExpenditureSelectionsReadable = /* @__PURE__ */ ((ExpenditureSelectionsReadable2) => {
  ExpenditureSelectionsReadable2["DRAFT"] = "Draft";
  ExpenditureSelectionsReadable2["REVIEW"] = "Review";
  ExpenditureSelectionsReadable2["HOLD"] = "Hold";
  ExpenditureSelectionsReadable2["APPROVE"] = "Approve";
  ExpenditureSelectionsReadable2["BILL"] = "Bill";
  return ExpenditureSelectionsReadable2;
})(ExpenditureSelectionsReadable || {});
export const ExpenditureStatusesReadable = {
  DRAFT: "Draft",
  IN_REVIEW: "In Review",
  HOLD: "Hold",
  APPROVED: "Approved",
  BILLED: "Billed"
};
export var ExpenditureStatusesFullReadable = /* @__PURE__ */ ((ExpenditureStatusesFullReadable2) => {
  ExpenditureStatusesFullReadable2["DRAFT"] = "Place in draft";
  ExpenditureStatusesFullReadable2["IN_REVIEW"] = "Place in review";
  ExpenditureStatusesFullReadable2["HOLD"] = "Place on hold";
  ExpenditureStatusesFullReadable2["APPROVED"] = "Approve";
  ExpenditureStatusesFullReadable2["BILLED"] = "Mark billed";
  return ExpenditureStatusesFullReadable2;
})(ExpenditureStatusesFullReadable || {});
export var InvoiceStatuses = /* @__PURE__ */ ((InvoiceStatuses2) => {
  InvoiceStatuses2["DRAFT"] = "DRAFT";
  InvoiceStatuses2["PAID"] = "PAID";
  InvoiceStatuses2["SUBMITTED"] = "SUBMITTED";
  InvoiceStatuses2["IN_REVIEW"] = "IN_REVIEW";
  InvoiceStatuses2["HOLD"] = "HOLD";
  InvoiceStatuses2["DECLINED"] = "DECLINED";
  InvoiceStatuses2["APPROVED"] = "APPROVED";
  return InvoiceStatuses2;
})(InvoiceStatuses || {});
export const InvoiceStatusesReadable = {
  DRAFT: "Draft",
  PAID: "Paid",
  SUBMITTED: "Submitted",
  IN_REVIEW: "In Review",
  HOLD: "Hold",
  DECLINED: "Declined",
  APPROVED: "Approved"
};
export var OwnerApprovalStatuses = /* @__PURE__ */ ((OwnerApprovalStatuses2) => {
  OwnerApprovalStatuses2["OWNER_APPROVAL_NOT_REQUESTED"] = "OWNER_APPROVAL_NOT_REQUESTED";
  OwnerApprovalStatuses2["OWNER_APPROVAL_REQUESTED"] = "OWNER_APPROVAL_REQUESTED";
  OwnerApprovalStatuses2["OWNER_APPROVAL_APPROVED"] = "OWNER_APPROVAL_APPROVED";
  OwnerApprovalStatuses2["OWNER_APPROVAL_NOT_APPROVED"] = "OWNER_APPROVAL_NOT_APPROVED";
  return OwnerApprovalStatuses2;
})(OwnerApprovalStatuses || {});
export var OwnerApprovalStatusesReadable = /* @__PURE__ */ ((OwnerApprovalStatusesReadable2) => {
  OwnerApprovalStatusesReadable2["OWNER_APPROVAL_NOT_REQUESTED"] = "Not Requested";
  OwnerApprovalStatusesReadable2["OWNER_APPROVAL_REQUESTED"] = "Requested";
  OwnerApprovalStatusesReadable2["OWNER_APPROVAL_APPROVED"] = "Approved";
  OwnerApprovalStatusesReadable2["OWNER_APPROVAL_NOT_APPROVED"] = "Not Approved";
  return OwnerApprovalStatusesReadable2;
})(OwnerApprovalStatusesReadable || {});
export var PaymentMethods = /* @__PURE__ */ ((PaymentMethods2) => {
  PaymentMethods2["CASH"] = "CASH";
  PaymentMethods2["CHECK"] = "CHECK";
  PaymentMethods2["CC"] = "CC";
  PaymentMethods2["ACH"] = "ACH";
  return PaymentMethods2;
})(PaymentMethods || {});
export const DEFAULT_MGR_TICKET_SORT = "-created";
export const DEFAULT_VENDOR_TICKET_SORT = "scheduled";
export const DEFAULT_INVOICE_SORT = "-payment_requested";
export const DEFAULT_MELD_SORT = "-created";
export const DEFAULT_TENANT_SORT = ["last_name", "first_name"];
export const DEFAULT_TENANT_INVITE_SORT = "name";
export var BillingTypes = /* @__PURE__ */ ((BillingTypes2) => {
  BillingTypes2["PER_UNIT_BILLING"] = "PER_UNIT_BILLING";
  BillingTypes2["FLAT_BILLING"] = "FLAT_BILLING";
  return BillingTypes2;
})(BillingTypes || {});
export var SubscriptionStatuses = /* @__PURE__ */ ((SubscriptionStatuses2) => {
  SubscriptionStatuses2["TRIALING"] = "trialing";
  SubscriptionStatuses2["ACTIVE"] = "active";
  SubscriptionStatuses2["PAST_DUE"] = "past_due";
  SubscriptionStatuses2["CANCELED"] = "canceled";
  SubscriptionStatuses2["UNPAID"] = "unpaid";
  return SubscriptionStatuses2;
})(SubscriptionStatuses || {});
export var Roles = /* @__PURE__ */ ((Roles2) => {
  Roles2["MANAGEMENT_ADMIN"] = "MANAGEMENT_ADMIN";
  Roles2["MANAGEMENT_AGENT"] = "MANAGEMENT_AGENT";
  Roles2["MAINTENANCE"] = "MAINTENANCE";
  return Roles2;
})(Roles || {});
export var Titles = /* @__PURE__ */ ((Titles2) => {
  Titles2["ACCOUNTANT"] = "Accountant";
  Titles2["SUPERVISOR"] = "Supervisor";
  Titles2["OTHER"] = "Other";
  Titles2["PROPERTY_MANAGER"] = "Property Manager";
  Titles2["ADMINISTRATIVE_ASSISTANT"] = "Administrative Assistant";
  Titles2["COORDINATOR"] = "Coordinator";
  Titles2["TECHNICIAN"] = "Technician";
  Titles2["PROJECT_COORDINATOR"] = "Project Coordinator";
  Titles2["INSPECTOR"] = "Inspector";
  Titles2["LEASING_AGENT"] = "Leasing Agent";
  Titles2["BROKER_OWNER"] = "Broker/Owner";
  Titles2["EXECUTIVE"] = "Executive";
  return Titles2;
})(Titles || {});
export var Departments = /* @__PURE__ */ ((Departments2) => {
  Departments2["ACCOUNTING"] = "Accounting";
  Departments2["OPERATIONS"] = "Operations";
  Departments2["MAINTENANCE"] = "Maintenance";
  Departments2["LEASING_MARKETING"] = "Leasing/Marketing";
  Departments2["LEADERSHIP"] = "Leadership";
  Departments2["OTHER"] = "Other";
  return Departments2;
})(Departments || {});
export const MIN_UNITS_IN_SUBSCRIPTION = 40;
export var Perms = /* @__PURE__ */ ((Perms2) => {
  Perms2["CAN_MANAGE_SUBSCRIPTION"] = "can_manage_subscription";
  Perms2["CAN_EDIT_MANAGEMENT_AGENTS"] = "edit_managementagent";
  Perms2["CAN_DELETE_MANAGEMENT_AGENTS"] = "delete_managementagent";
  Perms2["CAN_CHANGE_MANAGEMENTAGENTPROPERTYGROUPS"] = "change_managementagentpropertygroup";
  Perms2["CAN_VIEW_AGENTS"] = "view_managementagent";
  Perms2["CAN_ADD_AGENTS"] = "add_managementagent";
  Perms2["CAN_VIEW_TENANTS"] = "view_tenant";
  Perms2["CAN_ADD_EDIT_DEACTIVATE_UNITS"] = "add_edit_deactivate_unit";
  Perms2["CAN_VIEW_UNITS"] = "view_unit";
  Perms2["CAN_VIEW_VENDORS"] = "view_vendor";
  Perms2["CAN_ADD_VENDORS"] = "add_vendor";
  Perms2["CAN_DELETE_VENDORS"] = "delete_vendor";
  Perms2["CAN_CHANGE_INTEGRATIONS"] = "change_integrations";
  Perms2["CAN_ADD_MELDREPORT"] = "add_meldreport";
  Perms2["CAN_EDIT_APPSETTINGS"] = "edit_appsettings";
  Perms2["CAN_EDIT_MANAGEMENTS"] = "edit_management";
  Perms2["CAN_EDIT_EMERGENCYCONTACTSETTINGS"] = "edit_emergencycontactsettings";
  Perms2["CAN_CHANGE_RECURRINGMELDS"] = "change_recurringmeld";
  Perms2["CAN_ADD_RECURRINGMELDS"] = "add_recurringmeld";
  Perms2["CAN_CHANGE_MELDINVOICES"] = "change_meldinvoice";
  Perms2["CAN_VIEW_MELDEXPENDITURES"] = "view_meldexpenditure";
  Perms2["CAN_EDIT_MELDEXPENDITURES"] = "edit_meldexpenditure";
  Perms2["CAN_UPDATE_STATUS_MELDEXPENDITURES"] = "update_status_meldexpenditure";
  Perms2["CAN_DELETE_MELDEXPENDITURES"] = "delete_meldexpenditure";
  Perms2["CAN_VIEW_MELDS"] = "view_meld";
  Perms2["CAN_ADD_MELDS"] = "add_meld";
  Perms2["CAN_CHANGE_MELDS"] = "change_meld";
  Perms2["CAN_SCHEDULE_MELDS"] = "schedule_meld";
  Perms2["CAN_EDIT_TENANTS"] = "edit_tenant";
  Perms2["CAN_ADD_EDIT_DELETE_PROPERTY_GROUPS"] = "add_edit_delete_property_group";
  Perms2["CAN_VIEW_PROPERTY_GROUPS"] = "view_property_group";
  Perms2["CAN_ADD_EDIT_DEACTIVATE_PROPERTIES"] = "add_edit_deactivate_property";
  Perms2["CAN_VIEW_PROPERTIES"] = "view_property";
  Perms2["CAN_ADD_TAGS"] = "add_tag";
  Perms2["CAN_CHANGE_TAGS"] = "change_tag";
  Perms2["CAN_DELETE_TAGS"] = "delete_tag";
  Perms2["CAN_ADD_COORDINATOR"] = "add_coordinator";
  Perms2["CAN_MANAGE_WORKFLOWS"] = "manage_workflows";
  Perms2["CAN_ADD_MELD_TAGS"] = "add_meldtag";
  Perms2["CAN_DELETE_MELD_TAGS"] = "delete_meldtag";
  Perms2["CAN_ADD_MELD_COORDINATOR"] = "add_meldcoordinator";
  Perms2["CAN_DELETE_MELD_COORDINATOR"] = "add_meldcoordinator";
  Perms2["CAN_ADD_ROLES"] = "add_role";
  Perms2["CAN_CHANGE_ROLES"] = "change_role";
  Perms2["CAN_DELETE_ROLES"] = "delete_role";
  Perms2["CAN_VIEW_WORK_ENTRY"] = "view_workentry";
  Perms2["CAN_ADD_WORK_ENTRY"] = "add_workentry";
  Perms2["CAN_EDIT_WORK_ENTRY"] = "edit_workentry";
  Perms2["CAN_DELETE_WORK_ENTRY"] = "delete_workentry";
  Perms2["CAN_VIEW_TENANTINVITEREQUEST"] = "view_tenantinviterequest";
  Perms2["CAN_BULK_DELETE_UNITS"] = "bulk_delete_units";
  Perms2["CAN_CHANGE_OTHER_USERS_WORKLOG"] = "change_other_users_worklog";
  Perms2["CAN_CHANGE_PROJECT_TEMPLATES"] = "change_project_template";
  Perms2["CAN_VIEW_PROJECT_TEMPLATES"] = "view_project_template";
  Perms2["CAN_VIEW_PROJECTS"] = "view_project";
  Perms2["CAN_CHANGE_PROJECTS"] = "change_project";
  Perms2["CAN_ADD_EDIT_DEACTIVATE_OWNERS"] = "add_edit_deactivate_owner";
  Perms2["CAN_VIEW_OWNERS"] = "view_owner";
  Perms2["CAN_BULK_DELETE_OWNERS"] = "bulk_delete_owners";
  Perms2["CAN_ADD_OWNER_SERVICES"] = "add_owner_service";
  Perms2["CAN_EDIT_WHITE_LABELING"] = "edit_white_labeling";
  Perms2["CAN_CREATE_EDIT_DELETE_SHARED_FILTERS"] = "create_edit_delete_shared_filters";
  Perms2["CAN_VIEW_INSIGHTS"] = "view_insights";
  return Perms2;
})(Perms || {});
export var EmailInviteStatuses = /* @__PURE__ */ ((EmailInviteStatuses2) => {
  EmailInviteStatuses2["SENT"] = "SENT";
  EmailInviteStatuses2["DELIVERY_FAILED"] = "DELIVERY_FAILED";
  EmailInviteStatuses2["OPENED"] = "OPENED";
  return EmailInviteStatuses2;
})(EmailInviteStatuses || {});
export var EmailInviteStatusText = /* @__PURE__ */ ((EmailInviteStatusText2) => {
  EmailInviteStatusText2["SENT"] = "Sent";
  EmailInviteStatusText2["DELIVERY_FAILED"] = "Invite failed";
  EmailInviteStatusText2["OPENED"] = "Opened";
  return EmailInviteStatusText2;
})(EmailInviteStatusText || {});
export var IntegrationSlugs = /* @__PURE__ */ ((IntegrationSlugs2) => {
  IntegrationSlugs2["GATHER_KUDOS"] = "gather-kudos";
  IntegrationSlugs2["RENT_MANAGER"] = "rent-manager";
  IntegrationSlugs2["GRADE_US"] = "grade-us";
  IntegrationSlugs2["PROPERTYWARE"] = "propertyware";
  IntegrationSlugs2["RESMAN"] = "resman";
  IntegrationSlugs2["REPUTATION"] = "reputation";
  IntegrationSlugs2["APPFOLIO_API"] = "appfolio-api";
  IntegrationSlugs2["BUILDIUM"] = "buildium";
  return IntegrationSlugs2;
})(IntegrationSlugs || {});
export var LinkableIntegrationServices = /* @__PURE__ */ ((LinkableIntegrationServices2) => {
  LinkableIntegrationServices2["Propertyware"] = "propertyware";
  LinkableIntegrationServices2["RentManager"] = "rent-manager";
  LinkableIntegrationServices2["AppFolio"] = "appfolio";
  LinkableIntegrationServices2["AppFolioAPI"] = "appfolio-api";
  LinkableIntegrationServices2["Buildium"] = "buildium";
  return LinkableIntegrationServices2;
})(LinkableIntegrationServices || {});
export var LinkableTypes = /* @__PURE__ */ ((LinkableTypes2) => {
  LinkableTypes2["Tenant"] = "tenant";
  LinkableTypes2["Unit"] = "unit";
  LinkableTypes2["Owner"] = "owner";
  LinkableTypes2["Property"] = "prop";
  LinkableTypes2["Vendor"] = "vendor";
  LinkableTypes2["User"] = "user";
  return LinkableTypes2;
})(LinkableTypes || {});
export var IntegrationStatuses = /* @__PURE__ */ ((IntegrationStatuses2) => {
  IntegrationStatuses2["ENABLED"] = "ENABLED";
  return IntegrationStatuses2;
})(IntegrationStatuses || {});
export var SyncStatuses = /* @__PURE__ */ ((SyncStatuses2) => {
  SyncStatuses2["LINK"] = "LINK";
  SyncStatuses2["NOT_SYNCED"] = "NOT_SYNCED";
  SyncStatuses2["IMPORT"] = "IMPORT";
  return SyncStatuses2;
})(SyncStatuses || {});
export var ImportPersistence = /* @__PURE__ */ ((ImportPersistence2) => {
  ImportPersistence2["CREATE"] = "CREATE";
  ImportPersistence2["UPDATE"] = "UPDATE";
  ImportPersistence2["UPDATE_CREATE"] = "UPDATE_CREATE";
  return ImportPersistence2;
})(ImportPersistence || {});
export var PersistenceOperation = /* @__PURE__ */ ((PersistenceOperation2) => {
  PersistenceOperation2[PersistenceOperation2["CREATE"] = 1] = "CREATE";
  PersistenceOperation2[PersistenceOperation2["UPDATE"] = 2] = "UPDATE";
  PersistenceOperation2[PersistenceOperation2["NO_CHANGE"] = 3] = "NO_CHANGE";
  return PersistenceOperation2;
})(PersistenceOperation || {});
export var ImportProviders = /* @__PURE__ */ ((ImportProviders2) => {
  ImportProviders2["NONE"] = "NONE";
  ImportProviders2["APPFOLIO"] = "APPFOLIO";
  ImportProviders2["APPFOLIO_INEXACT"] = "APPFOLIO_INEXACT";
  ImportProviders2["PROPERTY_WARE"] = "PROPERTY_WARE";
  ImportProviders2["PROPERTY_BOSS"] = "PROPERTY_BOSS";
  ImportProviders2["BUILDIUM"] = "BUILDIUM";
  return ImportProviders2;
})(ImportProviders || {});
export const ImportProviderLabels = {
  ["NONE" /* NONE */]: "None",
  ["APPFOLIO" /* APPFOLIO */]: "Appfolio",
  ["APPFOLIO_INEXACT" /* APPFOLIO_INEXACT */]: "Appfolio (old)",
  ["PROPERTY_WARE" /* PROPERTY_WARE */]: "Propertyware",
  ["PROPERTY_BOSS" /* PROPERTY_BOSS */]: "Property Boss",
  ["BUILDIUM" /* BUILDIUM */]: "Buildium"
};
export var ImportStatus = /* @__PURE__ */ ((ImportStatus2) => {
  ImportStatus2["GENERATING_OPS"] = "GENERATING_OPS";
  ImportStatus2["OPS_GENERATED"] = "OPS_GENERATED";
  ImportStatus2["EXECUTING_OPS"] = "EXECUTING_OPS";
  ImportStatus2["OPS_EXECUTED"] = "OPS_EXECUTED";
  return ImportStatus2;
})(ImportStatus || {});
export const SMALL_BREAKPOINT = 640;
export const MEDIUM_BREAKPOINT = 1025;
export const emptyPlaceholder = String.fromCharCode(8212);
export var MaintTypes = /* @__PURE__ */ ((MaintTypes2) => {
  MaintTypes2["VENDOR"] = "Vendor";
  MaintTypes2["MANAGEMENT_AGENT"] = "ManagementAgent";
  MaintTypes2["MANAGEMENT"] = "Management";
  MaintTypes2["VENDOR_PREFERENCE_LIST"] = "VendorPreferenceList";
  MaintTypes2["INVITED_VENDOR"] = "VendorInvite";
  return MaintTypes2;
})(MaintTypes || {});
export var MaintenanceClasses = /* @__PURE__ */ ((MaintenanceClasses2) => {
  MaintenanceClasses2["INTERNAL"] = "1";
  MaintenanceClasses2["EXTERNAL"] = "2";
  return MaintenanceClasses2;
})(MaintenanceClasses || {});
export var CalendarDuration = /* @__PURE__ */ ((CalendarDuration2) => {
  CalendarDuration2["DAY"] = "DAY";
  CalendarDuration2["FOUR_DAY"] = "FOUR_DAY";
  CalendarDuration2["WEEK"] = "WEEK";
  return CalendarDuration2;
})(CalendarDuration || {});
export const CAL_QUARTER_HOUR_HEIGHT = 13;
export const COMPRESSED_CAL_NUM_QUARTER_HOURS = 13 * 4;
export const COMPRESSED_CAL_START_HOUR = 6;
export const DEFAULT_MELD_FILTER_GROUP_NAME = "default";
export var ReportType = /* @__PURE__ */ ((ReportType2) => {
  ReportType2["MELDS_CUSTOM"] = "melds_custom";
  ReportType2["MELDS"] = "melds";
  ReportType2["UNITS"] = "units";
  ReportType2["TENANTS"] = "tenants";
  ReportType2["INVOICES"] = "invoices";
  ReportType2["WORK_LOGS"] = "work_logs";
  ReportType2["OWNERS"] = "owners";
  ReportType2["DETAILED_EXPENDITURES"] = "detailed_expenditures";
  ReportType2["EXPENDITURES"] = "expenditures";
  ReportType2["TECHNICIAN"] = "technician";
  ReportType2["DOWNLOAD_BILLS"] = "download_bills";
  return ReportType2;
})(ReportType || {});
export const TIME_FORMAT = "h:mma";
export const RTE_DEFAULT_TOOLBAR_CONFIG = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "LINK_BUTTONS", "HISTORY_BUTTONS"],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Monospace", style: "CODE" },
    { label: "Underline", style: "UNDERLINE" }
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
    { label: "Blockquote", style: "blockquote" }
  ]
};
export const RTE_MINI_TOOLBAR_CONFIG = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "LINK_BUTTONS", "HISTORY_BUTTONS"],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Monospace", style: "CODE" },
    { label: "Underline", style: "UNDERLINE" }
  ],
  BLOCK_TYPE_DROPDOWN: [],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
    { label: "Blockquote", style: "blockquote" }
  ]
};
export const OWNER_APPROVAL_CONTEXT_VARS = {
  owner_first_name: "Jordan",
  owner_last_name: "Smith",
  management_name: "Essex Management Company",
  brief_description: "Meld brief description",
  description: "Description",
  estimate: "123.00",
  addr_lines: "45 Hudson St",
  addr_designators: "Unit 123",
  addr_city_state_zip: "Rochelle Park, NJ 07662"
};
export const ERROR_SUPPORT_MESSAGE = "Sorry, it looks like we are having a problem. If the problem persists please contact us at support@propertymeld.com";
export const ERROR_NETWORK_MESSAGE = "Oops, we could not complete your request. Check your internet connection and try again.";
export const ERROR_403 = "Oops, we could not complete your request. It looks like you are signed out. Try refreshing the page.";
export const ERROR_404 = "Oops, we could not complete your request. Try refreshing the page.";
export const ERROR_NOT_COMPLETE_REQUEST = "Oops, we could not complete your request. Please contact support via support@propertymeld.com.";
export function getDeleteError(itemName) {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, "Oops, we could not delete", /* @__PURE__ */ React.createElement("strong", null, " ", itemName), ". Please try again or contact support via support@propertymeld.com.");
}
export const REQUIRED_APPOINTMENT_WINDOWS = 5;
