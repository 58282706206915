import React from "react";
import { Box, Flex, Icon, Link as ChakraLink, Spacer, Text, VStack, Divider } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { IoCheckmark } from "react-icons/io5";
import { SeverityBadge } from "./severity-badge";
import { H2, RichTextEditor } from "@pmeld/components";
import { LinkHelper } from "@pm-shared/utils/link";
import { deserialize } from "@pm-shared/utils/slate";
import { SERVICE_FREQUENCIES } from "../../utils/owner-services/constants";
import Currency from "@pm-assets/js/utils/currency";
import { track, PCPServiceEditFormOpened } from "@pm-app/utils/analytics";
export const Details = ({
  ownerServiceData,
  backQuery
}) => {
  return /* @__PURE__ */ React.createElement(VStack, { "data-test": "details-container", spacing: 8, align: "stretch" }, /* @__PURE__ */ React.createElement(Flex, { justifyContent: "space-between" }, /* @__PURE__ */ React.createElement(
    SeverityBadge,
    {
      "data-test": "details-severity",
      severity: ownerServiceData.severity,
      alignItems: "center",
      display: "flex",
      fontWeight: "semibold",
      fontSize: "sm",
      justifyContent: "center",
      borderRadius: 32,
      padding: 3,
      height: 7,
      color: "white"
    }
  ), /* @__PURE__ */ React.createElement(Spacer, null), LinkHelper.getOrgType() === "m" && /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(
    ChakraLink,
    {
      onClick: () => track(PCPServiceEditFormOpened()),
      as: Link,
      to: backQuery ? {
        pathname: LinkHelper.normalize(`owner-services/${ownerServiceData.id}/edit`),
        state: {
          backQuery
        }
      } : {
        pathname: LinkHelper.normalize(`owner-services/${ownerServiceData.id}/edit`)
      }
    },
    /* @__PURE__ */ React.createElement(Text, { fontSize: "md", fontWeight: "semibold", color: "blue.600" }, "Edit")
  ))), /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Text, { color: "gray.400", "data-test": "details-frequency", fontWeight: "400", marginBottom: 1, mt: 4 }, SERVICE_FREQUENCIES[ownerServiceData.frequency]), /* @__PURE__ */ React.createElement(H2, { id: "owner-services.title", as: "h3", size: "md", marginBottom: 2 }, ownerServiceData.title), /* @__PURE__ */ React.createElement(Flex, null, /* @__PURE__ */ React.createElement(Box, { display: "flex" }, /* @__PURE__ */ React.createElement(Box, { "data-test": "details-price", fontWeight: "700", fontSize: 20 }, Currency.dollar(Number(ownerServiceData.price))), /* @__PURE__ */ React.createElement(Box, { color: "gray.400", fontWeight: "semibold", letterSpacing: "wide", fontSize: "xs", ml: "2", marginTop: 2 }, "Estimated Per Unit")), /* @__PURE__ */ React.createElement(Spacer, null), (ownerServiceData == null ? void 0 : ownerServiceData.owner_opted_in) && /* @__PURE__ */ React.createElement(Box, { color: "green.500", fontSize: "xs", ml: "2", marginTop: 2 }, /* @__PURE__ */ React.createElement(Icon, { as: IoCheckmark }), " Opted-In"))), /* @__PURE__ */ React.createElement(Divider, { borderColor: "blue.50" }), /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Box, { fontWeight: "bold", fontSize: "sm", marginBottom: 2 }, "Description"), /* @__PURE__ */ React.createElement(RichTextEditor, { readOnly: true, initialValue: deserialize(ownerServiceData.description), testPrefix: "test" })));
};
