import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";
class MeldOwnerApprovalAPI extends BaseApi {
  static getApprovalMessage(meldId) {
    return this.get(ApiUrls.ownerApprovalMessage(meldId));
  }
  static createRequest(meldId, data) {
    return this.patch(ApiUrls.ownerApprovalRequest(meldId), data);
  }
  static finishRequest(meldId, approval_status, ownerId, message) {
    return this.patch(ApiUrls.ownerApprovalFinish(meldId), {
      approval_status: approval_status.toString(),
      owner: ownerId,
      message
    });
  }
  static markAcknowledged(meldId, acknowledged) {
    return this.patch(ApiUrls.acknowledgeOwnerApproval(meldId), {
      is_acknowledged: acknowledged
    });
  }
}
export default MeldOwnerApprovalAPI;
