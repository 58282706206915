import React, { useEffect, useState } from "react";
import { MdArrowDropDown } from "react-icons/md";
import { Box, Button, CSSReset, Flex, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { colors } from "@pm-app/components/theme";
import { getReadableStatus, getStatusColor } from "@pm-property-management-hub/src/utils/owners";
import { StatusBadge } from "@pm-components/status-badge";
import { OwnerHubAccessLevelEnum } from "@pm-shared/owner";
import { hasPerm } from "@pm-assets/js/common/utils/permission-utils";
import * as C from "@pm-assets/js/constants";
export const HubAccessDropdown = ({
  accessLevel = OwnerHubAccessLevelEnum.DIRECT_ONLY,
  onChange,
  disabled,
  parentPage = ""
}) => {
  const [initialValue, setInitialValue] = useState();
  useEffect(() => {
    setInitialValue(accessLevel);
  }, [accessLevel]);
  return /* @__PURE__ */ React.createElement(
    Flex,
    {
      flexWrap: "wrap",
      onClick: (e) => {
        e.stopPropagation();
      }
    },
    /* @__PURE__ */ React.createElement(CSSReset, null),
    /* @__PURE__ */ React.createElement(Flex, null, /* @__PURE__ */ React.createElement(Flex, { w: "142px", justifyContent: "end" }, /* @__PURE__ */ React.createElement(
      StatusBadge,
      {
        margin: "auto",
        colorScheme: getStatusColor(accessLevel),
        "data-test": `${parentPage}hub_access_level`
      },
      getReadableStatus(accessLevel)
    )), accessLevel !== OwnerHubAccessLevelEnum.INVITED_TO_HUB && !disabled && hasPerm(C.Perms.CAN_ADD_EDIT_DEACTIVATE_OWNERS) && /* @__PURE__ */ React.createElement(Menu, null, /* @__PURE__ */ React.createElement(MenuButton, { as: Button, color: colors.pmBlue, marginLeft: [0, 2, 2, 2, 2], variant: "ghost" }, /* @__PURE__ */ React.createElement(Box, { display: "flex", lineHeight: "1.7em", fontSize: "16px" }, /* @__PURE__ */ React.createElement(MdArrowDropDown, { fontSize: "24px" }), " Change access")), /* @__PURE__ */ React.createElement(MenuList, null, Object.keys(OwnerHubAccessLevelEnum).filter(
      (key) => ![
        OwnerHubAccessLevelEnum.INVITED_TO_HUB,
        OwnerHubAccessLevelEnum.NONE,
        OwnerHubAccessLevelEnum.NOTIFICATIONS_ONLY,
        initialValue
      ].includes(key)
    ).map((key) => /* @__PURE__ */ React.createElement(MenuItem, { key, onClick: () => onChange(key) }, getReadableStatus(key))))))
  );
};
HubAccessDropdown.displayName = "HubAccessDropdown";
