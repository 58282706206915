import React from "react";
import moment from "moment";
import { PmText } from "@pm-frontend/shared/components/Text/PmText";
import { colors } from "@pm-frontend/styles";
import { PmBanner } from "@pm-frontend/shared/components/Banners/PmBanner";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { RouteUrls } from "@pm-frontend/shared/utils/route-urls";
import { GlobalBannerEmptySpace } from "@pm-frontend/shared/layouts/GlobalBanners";
const ManagementWorkLogCheckoutBanner = ({ workEntries, onCloseWorkLog }) => {
  if (workEntries.length === 0) {
    return /* @__PURE__ */ React.createElement(GlobalBannerEmptySpace, null);
  }
  const currentOpenWorkEntry = workEntries[0];
  const now = moment();
  const checkinTime = moment(currentOpenWorkEntry.checkin);
  const openWorkEntryDuration = moment.duration(now.diff(checkinTime));
  const hours = Math.floor(openWorkEntryDuration.asHours());
  const minutes = openWorkEntryDuration.minutes();
  const hourText = hours === 1 ? "hour" : "hours";
  const minuteText = minutes === 1 ? "minute" : "minutes";
  const workLogCheckOutReminderBannerBody = /* @__PURE__ */ React.createElement(PmText, { fontSize: "p2", color: colors.neutrals.gray50 }, "A work log has been open for", " ", /* @__PURE__ */ React.createElement("b", null, " ", hours, " ", hourText), ",", " ", /* @__PURE__ */ React.createElement("b", null, minutes, " ", minuteText), ". Be sure to check out when work is complete.");
  const worklogUrl = LinkHelper.normalize(RouteUrls.meldDetailsWorkLog({ id: currentOpenWorkEntry.meld })) + `?worklog=${currentOpenWorkEntry.id}`;
  const handleOnClose = () => {
    onCloseWorkLog(currentOpenWorkEntry.id);
  };
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
    PmBanner,
    {
      "data-testid": "open-work-log-banner-management",
      bannerButtonProps: {
        text: "Check Out",
        isLoading: false,
        href: worklogUrl,
        dataTestId: "open-work-log-banner-button",
        internalLink: true
      },
      showMultipleBanners: workEntries.length > 1,
      onClose: handleOnClose
    },
    workLogCheckOutReminderBannerBody
  ));
};
export { ManagementWorkLogCheckoutBanner };
