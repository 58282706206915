import * as C from "../../constants";
import PhoneUtils from "../utils/phone-utils";
import React from "react";
class PhoneAnchor extends React.Component {
  render() {
    if (this.props.number && PhoneUtils.isValid(this.props.number)) {
      return /* @__PURE__ */ React.createElement("a", { href: `tel:${PhoneUtils.format(this.props.number)}` }, this.props.children);
    }
    return /* @__PURE__ */ React.createElement("span", null, this.props.emptyText);
  }
}
PhoneAnchor.defaultProps = {
  emptyText: C.emptyPlaceholder
};
export default PhoneAnchor;
