import React from "react";
export default class BackArrowSvg extends React.Component {
  render() {
    return /* @__PURE__ */ React.createElement(
      "svg",
      {
        xmlns: "https://www.w3.org/2000/svg",
        preserveAspectRatio: "xMidYMid",
        className: "back-arrow-svg",
        viewBox: "0 0 12 20"
      },
      /* @__PURE__ */ React.createElement(
        "path",
        {
          d: "M11.387 19.455c.788-.752.85-1.8 0-2.717l-6.612-6.74 6.612-6.737c.85-.918.788-1.966 0-2.712-.785-.752-2.112-.704-2.85 0C7.8 1.248.593 8.643.593 8.643.2 9.017.003 9.508.003 10c0 .49.197.982.59 1.36 0 0 7.207 7.39 7.944 8.095.738.705 2.065.75 2.85 0z",
          fillRule: "evenodd"
        }
      )
    );
  }
}
