import React, { useEffect } from "react";
import { EuiFieldSearch, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from "@elastic/eui";
import URL from "@pm-shared/utils/url";
import { colors } from "@pm-frontend/styles";
import { useSwipe } from "@pm-frontend/shared/hooks/useSwipe";
const SiteSearchMobileBase = ({
  searchText,
  setSearchText,
  body,
  filter,
  checkbox,
  iconString,
  title,
  onClose,
  isFetching
}) => {
  useSwipe({ handleSwipeLeft: () => {
  }, handleSwipeRight: onClose });
  useEffect(() => {
    const inputElement = document.getElementById("site-search-text-box");
    if (inputElement) {
      inputElement.focus();
    }
  }, []);
  const onChange = (e) => {
    setSearchText(e.target.value);
  };
  return /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", responsive: false, style: { overflow: "hidden", flex: 1 } }, /* @__PURE__ */ React.createElement(
    EuiFlexGroup,
    {
      direction: "row",
      responsive: false,
      justifyContent: "spaceBetween",
      alignItems: "center",
      style: { flexGrow: 0 }
    },
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiText, { color: colors.neutrals.gray800, style: { fontSize: "24px", fontWeight: 700 } }, "Meld Search")),
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiIcon, { type: URL.getStatic("icons/close.svg"), size: "l", onClick: onClose }))
  ), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
    EuiFieldSearch,
    {
      id: "site-search-text-box",
      fullWidth: true,
      style: { color: colors.neutrals.gray700, fontSize: "16px", lineHeight: "24px" },
      incremental: true,
      isLoading: isFetching,
      isClearable: false,
      value: searchText,
      onChange: (e) => onChange(e)
    }
  )), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", gutterSize: "s" }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, filter), checkbox && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, style: { marginLeft: "16px" } }, checkbox))), /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", style: { gap: "16px" } }, title && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", alignItems: "center", gutterSize: "s", responsive: false }, iconString && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiIcon, { type: iconString })), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiText, { color: colors.neutrals.gray800, style: { fontWeight: 700, whiteSpace: "nowrap" } }, title)))), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, "data-testid": "global-search-results" }, body)));
};
export { SiteSearchMobileBase };
