import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";
class MeldFileApi extends BaseApi {
  static create(data, meldId) {
    return this.post(ApiUrls.meldFileList(meldId), data);
  }
  static fetchFilesForMeld(meldId) {
    return this.get(ApiUrls.meldFileList(meldId));
  }
  static deleteFile(fileId) {
    return this.delete(ApiUrls.meldFileDetail(fileId));
  }
  static getPolicyDataForFile(file) {
    return this.get(ApiUrls.meldFileGeneratePolicy, {
      params: { filename: file.name }
    });
  }
}
export default MeldFileApi;
