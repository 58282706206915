import I from "immutable";
import produce from "immer";
export function asImmer() {
  return (_target, _propertyKey, descriptor) => {
    const originalFn = descriptor.value;
    descriptor.value = (...args) => {
      const immerArgs = args.map((arg) => {
        if (I.Iterable.isIterable(arg)) {
          return produce(arg, (immutable) => immutable.toJS());
        } else {
          return arg;
        }
      });
      return originalFn(...immerArgs);
    };
    return descriptor;
  };
}
