import useSWR from "swr";
import { APIPrefix } from "../api";
import { axios } from "@pm-shared/axios";
export const fetchAgents = (options) => {
  return useSWR(
    `${APIPrefix}/agents/`,
    () => axios.get(`${APIPrefix}/abbrev-agents/`).then((response) => response.data),
    options
  );
};
