export var FlexJustifyContent = /* @__PURE__ */ ((FlexJustifyContent2) => {
  FlexJustifyContent2["FlexStart"] = "justify-flex-start";
  FlexJustifyContent2["FlexEnd"] = "justify-flex-end";
  FlexJustifyContent2["Center"] = "justify-center";
  FlexJustifyContent2["SpaceAround"] = "justify-space-around";
  FlexJustifyContent2["SpaceBetween"] = "justify-space-between";
  FlexJustifyContent2["SpaceEvenly"] = "justify-space-evenly";
  return FlexJustifyContent2;
})(FlexJustifyContent || {});
export var FlexAlignItems = /* @__PURE__ */ ((FlexAlignItems2) => {
  FlexAlignItems2["FlexStart"] = "align-items-flex-start";
  FlexAlignItems2["FlexEnd"] = "align-items-flex-end";
  FlexAlignItems2["Center"] = "align-items-center";
  FlexAlignItems2["Baseline"] = "align-items-baseline";
  FlexAlignItems2["Stretch"] = "align-itmes-stretch";
  return FlexAlignItems2;
})(FlexAlignItems || {});
export const DebounceTime = 200;
