import useSWR from "swr";
import { APIPrefix } from "../api";
import { axios } from "@pm-shared/axios";
export const fetchHistoricalPerformanceMetrics = () => {
  const url = `${APIPrefix}/metrics/historical-performance/`;
  return {
    response: useSWR(
      url,
      () => axios.get(url).then((response) => response.data),
      { revalidateOnFocus: false }
    ),
    url
  };
};
