import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiPageHeader, EuiPageHeaderSection } from "@elastic/eui";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";
import { HeaderLogoItem } from "./items/HeaderLogoItem";
import { HeaderMobileMenuItem } from "./items/HeaderMobileMenuItem";
import { BorderColor, colors } from "@pm-frontend/styles";
const ApplicationHeader = ({
  toggleSidebar,
  desktopRightItems,
  mobileRightItems,
  logoLinkUrl
}) => {
  const isMobile = useIsMobile();
  const filteredDesktopRightItems = desktopRightItems.filter((item) => !item.hasOwnProperty("enabled") || item.enabled);
  const filteredMobileRightItems = mobileRightItems.filter((item) => !item.hasOwnProperty("enabled") || item.enabled);
  const mobileSections = [
    [
      {
        item: /* @__PURE__ */ React.createElement(HeaderMobileMenuItem, { toggleSidebar, key: "header-mobile-menu-item" }),
        key: "menu"
      }
    ],
    [
      {
        item: /* @__PURE__ */ React.createElement(HeaderLogoItem, { logoLinkUrl, key: "header-logo-item" }),
        key: "logo"
      }
    ],
    filteredMobileRightItems
  ];
  if (isMobile) {
    return /* @__PURE__ */ React.createElement(
      EuiPageHeader,
      {
        style: { background: colors.neutrals.gray50, boxShadow: `0px -1px 0px 0px ${BorderColor} inset` },
        paddingSize: "none",
        responsive: false,
        bottomBorder: "extended",
        "data-testid": "management-hub-application-header"
      },
      /* @__PURE__ */ React.createElement(
        EuiFlexGroup,
        {
          style: { padding: "4px 16px" },
          responsive: false,
          direction: "row",
          justifyContent: "spaceBetween",
          alignItems: "center"
        },
        mobileSections.map((section, sectionIndex) => /* @__PURE__ */ React.createElement(
          EuiPageHeaderSection,
          {
            key: sectionIndex,
            style: {
              flexGrow: 0,
              display: "flex",
              alignItems: "center",
              position: sectionIndex === 1 ? "absolute" : void 0,
              left: sectionIndex === 1 ? "calc(50vw - 32px)" : void 0,
              gap: "6px"
            }
          },
          section.map((item) => /* @__PURE__ */ React.createElement("div", { key: item.key, "data-testid": item.dataTestId || "" }, item.item))
        ))
      )
    );
  }
  return /* @__PURE__ */ React.createElement(
    EuiPageHeader,
    {
      style: {
        padding: "0px 24px",
        height: "64px",
        boxShadow: `0px -1px 0px 0px ${BorderColor} inset`,
        background: colors.brand.white
      },
      alignItems: "center"
    },
    /* @__PURE__ */ React.createElement(
      EuiFlexGroup,
      {
        direction: "row",
        responsive: false,
        alignItems: "center",
        justifyContent: "spaceBetween",
        className: "eui-fullHeight",
        style: { minHeight: "64px" }
      },
      /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(HeaderLogoItem, { logoLinkUrl })),
      /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, alignItems: "center", gutterSize: "l" }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, alignItems: "center", style: { gap: "16px" } }, filteredDesktopRightItems && filteredDesktopRightItems.map((item) => /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, key: item.key }, item.item)))))
    )
  );
};
export { ApplicationHeader };
