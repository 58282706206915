import React from "react";
import classnames from "classnames";
import { Link as ReactRouterLink } from "react-router-dom";
export const Link = ({ path, onLinkClick, inactive, buttonStyle, className, children }) => {
  let classes;
  if (buttonStyle) {
    classes = classnames(
      "pm-forms-button",
      `pm-forms-button-${buttonStyle}`,
      {
        "pm-link-inactive": inactive
      },
      className ? className.split(" ") : ""
    );
  } else {
    classes = classnames(
      "pm-link",
      {
        "pm-link-inactive": inactive
      },
      className ? className.split(" ") : ""
    );
  }
  if (inactive) {
    return /* @__PURE__ */ React.createElement("div", { className: classes }, children);
  }
  if (path) {
    return /* @__PURE__ */ React.createElement(ReactRouterLink, { to: path, className: classes }, children);
  }
  return /* @__PURE__ */ React.createElement("a", { href: "#", onClick: onLinkClick, className: classes }, children);
};
Link.displayName = "Link";
export default Link;
