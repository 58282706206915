import DocumentTitle from "react-document-title";
import I from "immutable";
import React from "react";
import Button, { ButtonTypes } from "../../../app/components/buttons/Button";
import ButtonContainer from "../../../app/components/containers/ButtonContainer";
import connect from "../common/connect";
import EmptyView from "@pm-shared/components/empty/empty-view";
import { LargeLoader } from "../loaders";
import MultiButton from "../../../app/components/buttons/MultiButton";
import { NonLinkPagination } from "../pagination";
import NotificationApi from "./api/notification-api";
import NotificationComponent from "./notification-component";
import NotificationStore from "./stores/notification-store";
import NotificationsUnreadStore from "./stores/notifications-unread-store";
import { ErrorHandler } from "../../../app/utils/ErrorHandler";
class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.fetch = this.fetch.bind(this);
    this.markPageRead = this.markPageRead.bind(this);
  }
  render() {
    if (NotificationStore.fetchAllResult.loading) {
      return /* @__PURE__ */ React.createElement(LargeLoader, null);
    }
    if (this.state.failed) {
      return /* @__PURE__ */ React.createElement(EmptyView, { message: "There was an issue retrieving your notifications" }, /* @__PURE__ */ React.createElement(Button, { buttonType: ButtonTypes.Primary, onClick: this.state.retry }, "Click here to retry"));
    }
    let notifications = this.props.notifications.map((notification) => {
      return /* @__PURE__ */ React.createElement(NotificationComponent, { notification, key: notification.get("id") });
    });
    if (!this.props.notifications.size) {
      notifications = /* @__PURE__ */ React.createElement("div", { className: "notification message" }, "There's no activity yet.");
    }
    return /* @__PURE__ */ React.createElement(DocumentTitle, { title: "Notifications" }, /* @__PURE__ */ React.createElement("div", { className: "main row" }, /* @__PURE__ */ React.createElement("div", { className: "columns notification-heading-container" }, /* @__PURE__ */ React.createElement("h1", { className: "notifications-header" }, "Notifications"), /* @__PURE__ */ React.createElement(ButtonContainer, { inline: true }, /* @__PURE__ */ React.createElement(
      MultiButton,
      {
        buttonType: ButtonTypes.Primary,
        mainLabel: "Mark All as Read",
        onMainClicked: this.markAllRead
      },
      /* @__PURE__ */ React.createElement(Button, { onClick: this.markPageRead }, "Mark Page as Read")
    ))), /* @__PURE__ */ React.createElement("div", { className: "columns" }, notifications), /* @__PURE__ */ React.createElement(
      NonLinkPagination,
      {
        hasPrev: NotificationStore.canFetchPrevious(),
        hasNext: NotificationStore.canFetchNext(),
        onPrev: () => this.fetch(NotificationStore.fetchPrevious),
        onNext: () => this.fetch(NotificationStore.fetchNext)
      }
    )));
  }
  fetch(fetcher) {
    this.setState({ failed: false, retry: () => this.fetch(fetcher) });
    fetcher().catch(() => this.setState({ failed: true }));
  }
  markAllRead() {
    NotificationApi.markAllRead().then(NotificationStore.refetch).then(NotificationsUnreadStore.fetchUnread).catch((error) => ErrorHandler.handleAxiosError(error));
  }
  markPageRead() {
    let notificationIds = this.props.notifications.reduce((ids, n) => {
      return ids.concat(n.get("activities").map((a) => a.get("id")));
    }, I.List()).toJS();
    void NotificationApi.markRead(notificationIds);
    NotificationStore.markActivitiesRead(I.Set(notificationIds));
  }
  componentWillMount() {
    this.fetch(NotificationStore.fetchAll);
  }
}
export default connect(Notifications, {
  stores: [NotificationStore],
  props: {
    notifications() {
      return NotificationStore.getFetchedModels();
    }
  }
});
