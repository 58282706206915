import createReactClass from "create-react-class";
import React from "react";
export default createReactClass({
  render() {
    return /* @__PURE__ */ React.createElement(
      "svg",
      {
        className: this.props.classes,
        xmlns: "https://www.w3.org/2000/svg",
        preserveAspectRatio: "xMidYMid",
        viewBox: "0 0 32 31"
      },
      /* @__PURE__ */ React.createElement(
        "path",
        {
          d: "M4.987 9.704c1.5-1.226 2.744-.38 4.404 1.643.187.228.438-.04.58-.17.142-.13 2.333-2.207 2.44-2.3.106-.1.235-.287.066-.495-.172-.208-.794-1.057-1.194-1.61C8.377 2.773 19.23.06 17.564.02c-.85-.024-4.252-.066-4.762-.007-2.06.23-4.65 2.258-5.953 3.203C5.142 4.44 4.506 5.16 4.402 5.26c-.483.444-.077 1.468-.952 2.274-.923.85-1.5.207-2.035.7C1.15 8.48.408 9.067.195 9.26c-.213.2-.252.532-.033.798 0 0 2.03 2.36 2.2 2.57.17.207.63.386.912.12.285-.263 1.016-.935 1.138-1.053.126-.11-.08-1.456.575-1.99zm9.173.875c-.192-.236-.432-.243-.638-.05l-2.304 2.117c-.182.17-.207.48-.044.677l13.33 15.965c.31.376.85.414 1.206.088l1.56-1.375c.356-.33.392-.902.082-1.282L14.16 10.58zm17.772-6.795c-.12-.836-.53-.662-.744-.308-.213.357-1.158 1.864-1.547 2.547-.385.677-1.336 2.014-3.107.693-1.845-1.37-1.204-2.33-.882-2.973.323-.648 1.315-2.464 1.458-2.69.143-.228-.024-.89-.596-.613-.574.278-4.055 1.735-4.537 3.823-.493 2.125.413 4.024-1.366 5.91l-2.16 2.368 2.17 2.65 2.657-2.656c.633-.67 1.986-1.32 3.21-1.027 2.625.625 4.057-.413 4.92-2.128.774-1.533.646-4.76.525-5.595zM4.352 26.893c-.335.354-.335.93 0 1.283l1.527 1.572c.334.354.864.205 1.198-.148l7.886-8.16-2.416-2.898-8.197 8.35z",
          id: "path-1",
          fillRule: "evenodd"
        }
      )
    );
  }
});
