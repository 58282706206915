import { APIPrefix } from "@pm-property-management-hub/src/utils/api";
import useSWR from "swr";
import { axios } from "@pm-shared/axios";
export const workflowLookupNames = [
  "meld_assigned",
  "meld_brief_description",
  "meld_completion_date",
  "meld_created",
  "meld_description",
  "meld_due_date",
  "meld_owner_approval_comment",
  "meld_owner_approval_completed_date",
  "meld_owner_approval_dollar_amount",
  "meld_owner_approval_request_date",
  "meld_priority",
  "meld_status",
  "meld_tenant_presence_required",
  "meld_tenant_rating",
  "meld_tenant_review",
  "meld_work_category",
  "meld_work_location",
  "meldexpenditures_amount",
  "meldexpenditures_created",
  "meldexpenditures_notes",
  "meldexpenditures_status",
  "meldinvoice_amount",
  "meldinvoice_notes",
  "meldinvoice_payment_requested",
  "meldinvoice_status",
  "meldinvoice_vendor_id",
  "vendor_assigned_vendor",
  "workentry_checkin",
  "workentry_checkout",
  "workentry_created",
  "workentry_description",
  "workentry_hours",
  "workentry_long_description"
];
export const fetchMeldWorkflows = () => {
  return useSWR(
    `${APIPrefix}/workflows/list/`,
    () => axios.get(`${APIPrefix}/workflows/list/`).then((response) => response.data)
  );
};
export const fetchWorkflowTemplates = () => {
  const url = `${APIPrefix}/workflows/templates/list/`;
  return useSWR(url, () => axios.get(url).then((response) => response.data));
};
export const fetchMeldWorkflow = (id) => {
  const url = `${APIPrefix}/workflows/retrieve/${id}/`;
  return useSWR(url, () => axios.get(`${APIPrefix}/workflows/retrieve/${id}/`).then((response) => response.data), {
    revalidateOnFocus: false
  });
};
export const createMeldWorkflow = (data) => {
  return axios.post(`${APIPrefix}/workflows/create/`, data);
};
export const updateMeldWorkflows = (data) => {
  return axios.post(`${APIPrefix}/workflows/update/`, data).then((response) => response.data);
};
export const fetchWorkflowConfig = () => {
  return useSWR(
    `${APIPrefix}/workflow-config/`,
    () => axios.get(`${APIPrefix}/workflow-config/`).then((response) => response.data)
  );
};
export const fetchVendors = () => {
  return useSWR(
    `${APIPrefix}/vendors/`,
    () => axios.get(`${APIPrefix}/vendors/`).then((response) => response.data)
  );
};
export const fetchPropertyGroups = () => {
  return useSWR(
    `${APIPrefix}/properties/groups/`,
    () => axios.get(`${APIPrefix}/properties/groups/`).then((response) => response.data)
  );
};
export const fetchMeldTags = () => {
  return useSWR(
    `${APIPrefix}/melds/tags/abbrev/`,
    () => axios.get(`${APIPrefix}/melds/tags/abbrev/`).then((response) => response.data)
  );
};
export const getEmptyWorkflowBlockCondition = (why) => {
  return { id: null, lookup_name: null, operator: null, options: null, type: null, value: [""], why };
};
