import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";
class AppointmentRequestAPI extends BaseApi {
  static fetchAll() {
    return this.get(ApiUrls.appointmentRequests);
  }
  static fetchForMeld(meldId) {
    return this.get(ApiUrls.meldAppointmentRequestDetail(meldId));
  }
  static fetchForAssignment(assignmentId) {
    return this.get(ApiUrls.assignmentAppointmentRequestDetail(assignmentId));
  }
  static create(data) {
    return this.post(ApiUrls.appointmentRequests, data);
  }
  static update(data, id) {
    return this.patch(ApiUrls.appointmentRequestDetail(id), data);
  }
}
export default AppointmentRequestAPI;
