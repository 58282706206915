import mitt from "mitt";
class Store {
  constructor() {
    this.emitter = new mitt();
    this.state = {};
    this.setState = this.setState.bind(this);
  }
  on(handler) {
    this.emitter.on("change", handler);
  }
  onChange(handler) {
    return this.on(handler);
  }
  off(handler) {
    this.emitter.off("change", handler);
  }
  setState(state) {
    this.state = Object.assign(this.state, state);
    this.emitter.emit("change");
  }
  clear() {
    this.state = {};
    this.emitter.emit("change");
  }
  initRequestFunctions(functionsObj) {
    Object.keys(functionsObj).forEach((functionName) => {
      this[functionName] = (...args) => {
        this.setState({
          [`${functionName}Loading`]: true,
          [`${functionName}Errors`]: []
        });
        return new Promise((resolve, reject) => {
          return functionsObj[functionName].call(this, ...args).catch((res) => {
            this[`${functionName}Errors`] = res.messages;
            reject(res);
            return res;
          }).then((res) => {
            this.setState({
              [`${functionName}Loading`]: false,
              [`${functionName}Response`]: res
            });
            resolve(res);
          });
        });
      };
      this.setState({
        [`${functionName}Loading`]: false,
        [`${functionName}Errors`]: []
      });
      Object.defineProperties(this, {
        [`${functionName}Result`]: {
          get() {
            return {
              loading: this.state[`${functionName}Loading`],
              errors: this.state[`${functionName}Errors`],
              response: this.state[`${functionName}Response`]
            };
          }
        }
      });
    });
  }
}
export default Store;
