import React, { Component } from "react";
class DetailView extends Component {
  render() {
    let { smallClass, mediumClass, largeClass } = this.props;
    return /* @__PURE__ */ React.createElement("div", { className: `main row ${this.props.className}` }, /* @__PURE__ */ React.createElement("div", { className: "detail-view" }, /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `small-centered small-${smallClass || 12} medium-${mediumClass || 11} large-${largeClass || 9} columns detail-content`
      },
      this.props.children
    )));
  }
}
export default DetailView;
