import ApiUtils from "./api-utils";
import axios, { AxiosHeaders } from "axios";
import { LinkHelper } from "@pm-shared/utils/link";
import { ERROR_SUPPORT_MESSAGE, ERROR_403, ERROR_404 } from "../constants";
import { decrementXHRCount, incrementXHRCount } from "./xhr-counter";
import { AuthUtils } from "./auth-utils";
export var BaseApiCustomCodes = /* @__PURE__ */ ((BaseApiCustomCodes2) => {
  BaseApiCustomCodes2["RequestCancelled"] = "pm499";
  return BaseApiCustomCodes2;
})(BaseApiCustomCodes || {});
axios.interceptors.response.use(
  (response) => {
    decrementXHRCount();
    return response;
  },
  (error) => {
    decrementXHRCount();
    if (error == "Cancel") {
      error.code = "pm499" /* RequestCancelled */;
      error.message = `${"pm499" /* RequestCancelled */} - Request was canceled`;
      return Promise.reject(error);
    }
    let errorsMessages;
    if (error && error.response) {
      if (error.response.status === 403) {
        errorsMessages = [ERROR_403];
      } else if (error.response.status === 404) {
        errorsMessages = [ERROR_404];
      } else {
        errorsMessages = ApiUtils.transformErrors(error.response);
      }
    }
    if (!errorsMessages || !errorsMessages.length) {
      errorsMessages = [ERROR_SUPPORT_MESSAGE];
    }
    error.messages = errorsMessages;
    error.msgs = errorsMessages;
    return Promise.reject(error);
  }
);
axios.interceptors.request.use(
  (config) => {
    incrementXHRCount();
    return config;
  },
  (error) => {
    decrementXHRCount();
    return Promise.reject(error);
  }
);
class BaseApi {
  static get(url, options) {
    let xhrOptions = Object.assign({ normalizeUrl: true }, options || {});
    let realUrl = url;
    if (xhrOptions.normalizeUrl && !xhrOptions.isPublicUrl) {
      realUrl = this._getRealUrl(url);
    }
    return axios.get(realUrl, xhrOptions);
  }
  static post(url, data, options) {
    let xhrOptions = Object.assign({}, options || {});
    let xhrData = Object.assign({}, data || {});
    xhrOptions.headers = this._getAdditionalHeaders();
    let realUrl = url;
    if (!xhrOptions.isPublicUrl) {
      realUrl = this._getRealUrl(url);
    }
    return axios.post(realUrl, xhrData, xhrOptions);
  }
  static put(url, data, options) {
    let xhrOptions = Object.assign({}, options || {});
    let xhrData = Object.assign({}, data || {});
    xhrOptions.headers = this._getAdditionalHeaders();
    return axios.put(this._getRealUrl(url), xhrData, xhrOptions);
  }
  static patch(url, data, options) {
    let xhrOptions = Object.assign({}, options || {});
    let xhrData = Object.assign({}, data || {});
    xhrOptions.headers = this._getAdditionalHeaders();
    return axios.patch(this._getRealUrl(url), xhrData, xhrOptions);
  }
  static delete(url, options) {
    let xhrOptions = Object.assign({}, options || {});
    xhrOptions.headers = this._getAdditionalHeaders();
    return axios.delete(this._getRealUrl(url), xhrOptions);
  }
  static _getAdditionalHeaders() {
    let headers = new AxiosHeaders({
      "X-CSRFToken": AuthUtils.getCsrfToken()
    });
    return headers;
  }
  static _getRealUrl(url) {
    return LinkHelper.normalize(url);
  }
}
export default BaseApi;
