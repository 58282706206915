import React from "react";
export default class SelectedCalendar extends React.Component {
  render() {
    return /* @__PURE__ */ React.createElement(
      "svg",
      {
        xmlns: "https://www.w3.org/2000/svg",
        preserveAspectRatio: "xMidYMid",
        className: "selected-calendar-svg",
        viewBox: "0 0 33 32"
      },
      /* @__PURE__ */ React.createElement(
        "path",
        {
          d: "M30.808 32H2.178c-1.21 0-2.192-.98-2.192-2.19V2.19C-.014.98.968 0 2.178 0h.82v5.967h11.977V0h3.036v5.967h12.01V0h.788C32.018 0 33 .98 33 2.19v27.62c0 1.21-.98 2.19-2.192 2.19zm-.785-23.03H2.998v20h27.025v-20zM11.008 25H5.975v-4.033h5.033V25zm7.988-7.97h-5.002v-4.063h5.002v4.064zm.03 7.97h-5.002v-4.033h5.003V25zm7.987-7.97H22.01v-4.063h5.003v4.064zM26.98 25h-4.97v-4.033h4.97V25zM6.008 12.967h5v4.064h-5v-4.063zM20.075 0h7.947v3.97h-7.947V0zM5 0h8.007v3.97H5V0z",
          fillRule: "evenodd"
        }
      )
    );
  }
}
