import React from "react";
import { colors } from "@pm-frontend/styles";
export const SidebarOwnersIcon = ({ isActive = false }) => {
  const fill = isActive ? colors.brand.meldBlue : colors.neutrals.gray800;
  return /* @__PURE__ */ React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill, xmlns: "http://www.w3.org/2000/svg" }, /* @__PURE__ */ React.createElement("g", { id: "vpn_key", clipPath: "url(#clip0_1299_22467)" }, /* @__PURE__ */ React.createElement(
    "path",
    {
      id: "Vector",
      d: "M14.6667 12.6666H10.6667V9.99992H8.88C8.12 11.6133 6.48 12.6666 4.66667 12.6666C2.09333 12.6666 0 10.5733 0 7.99992C0 5.42659 2.09333 3.33325 4.66667 3.33325C6.48 3.33325 8.11333 4.38659 8.88 5.99992H16V9.99992H14.6667V12.6666ZM12 11.3333H13.3333V8.66659H14.6667V7.33325H7.96L7.80667 6.88659C7.34 5.55992 6.07333 4.66659 4.66667 4.66659C2.82667 4.66659 1.33333 6.15992 1.33333 7.99992C1.33333 9.83992 2.82667 11.3333 4.66667 11.3333C6.07333 11.3333 7.34 10.4399 7.80667 9.11325L7.96 8.66659H12V11.3333ZM4.66667 9.99992C3.56667 9.99992 2.66667 9.09992 2.66667 7.99992C2.66667 6.89992 3.56667 5.99992 4.66667 5.99992C5.76667 5.99992 6.66667 6.89992 6.66667 7.99992C6.66667 9.09992 5.76667 9.99992 4.66667 9.99992ZM4.66667 7.33325C4.3 7.33325 4 7.63325 4 7.99992C4 8.36659 4.3 8.66659 4.66667 8.66659C5.03333 8.66659 5.33333 8.36659 5.33333 7.99992C5.33333 7.63325 5.03333 7.33325 4.66667 7.33325Z",
      fill
    }
  )), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "clip0_1299_22467" }, /* @__PURE__ */ React.createElement("rect", { width: "16", height: "16", fill: "white" }))));
};
