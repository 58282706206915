import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import StrUtils from "../../utils/str-utils";
class Card extends React.Component {
  render() {
    const containerClasses = StrUtils.joinWithSpace(this.props.sizeClassName, "card-container");
    const cardClasses = classNames({
      card: true,
      "card-selected": this.props.isSelected
    });
    return /* @__PURE__ */ React.createElement("div", { className: containerClasses }, /* @__PURE__ */ React.createElement("div", { className: cardClasses }, this.props.children));
  }
}
Card.propTypes = {
  sizeClassName: PropTypes.string.isRequired,
  isSelected: PropTypes.bool
};
Card.defaultProps = {
  sizeClassName: "",
  isSelected: false
};
export default Card;
