import useSWR from "swr";
import { APIPrefix } from "../api";
import { axios } from "@pm-shared/axios";
export const createOwnerService = (data) => {
  return axios.post(`${APIPrefix}/owner-services/`, data);
};
export const updateOwnerService = (id, data) => {
  return axios.put(`${APIPrefix}/owner-services/${id}/`, data);
};
export const deleteOwnerService = (id) => {
  return axios.delete(`${APIPrefix}/owner-services/${id}/`);
};
export const fetchOwnerService = (id) => {
  const url = `${APIPrefix}/owner-services/${id}/`;
  return useSWR(url, () => axios.get(url).then((response) => response.data), {
    revalidateOnFocus: false
  });
};
export const publishModifyOwnerService = (id, data) => {
  return axios.put(`${APIPrefix}/owner-services/${id}/`, data);
};
export const fetchOwnerServices = () => {
  const url = `${APIPrefix}/owner-services/`;
  return useSWR(url, () => axios.get(url).then((response) => response.data));
};
export const fetchUsersOptedInToService = (id) => {
  return useSWR(
    `${APIPrefix}/owner-services/?search=${id}`,
    () => axios.get(`${APIPrefix}/owner-services-opt-in/?search=${id}`).then((response) => response.data)
  );
};
export const deleteOptedInUserService = (id) => {
  return axios.delete(`${APIPrefix}/owner-services-opt-in/${id}/`);
};
export const ownerServiceOptIn = (data) => {
  return axios.post(`${APIPrefix}/owner-services-opt-in/`, data);
};
