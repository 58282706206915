import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";
class VendorAppointmentAPI extends BaseApi {
  static fetchOne(id, options = {}) {
    return this.get(ApiUrls.vendorAppointmentDetail(id), options);
  }
  static schedule(id, data, options = {}) {
    return this.patch(ApiUrls.vendorAppointmentSchedule(id), data, options);
  }
  static requestMoreAvailability(id, data, options = {}) {
    return this.patch(ApiUrls.vendorAppointmentRequestMoreAvailability(id), data, options);
  }
  static fetchByAssignment(assignmentId) {
    return this.get(ApiUrls.vendorAppointmentDetailAlt(assignmentId));
  }
}
export default VendorAppointmentAPI;
