import React from "react";
import ButtonContainer from "../../../../app/components/containers/ButtonContainer";
import Button, { ButtonTypes } from "../../../../app/components/buttons/Button";
import classnames from "classnames";
export default function CancelSaveButtons(props) {
  const saveText = props.saveText || "Save";
  const cancelText = props.cancelText || "Cancel";
  const saveClasses = classnames("save", props.saveClassName);
  const cancelClasses = classnames(props.cancelClassName);
  const cancel = /* @__PURE__ */ React.createElement(
    Button,
    {
      buttonType: ButtonTypes.Cancel,
      onClick: props.onCancelFn,
      disabled: props.cancelDisabled,
      className: cancelClasses
    },
    cancelText
  );
  const save = /* @__PURE__ */ React.createElement(
    Button,
    {
      buttonType: ButtonTypes.Primary,
      submit: true,
      onClick: props.onSaveFn,
      disabled: props.saveDisabled,
      className: saveClasses
    },
    saveText
  );
  let buttons = /* @__PURE__ */ React.createElement(React.Fragment, null, cancel, save);
  if (props.swapButtonOrder) {
    buttons = /* @__PURE__ */ React.createElement(React.Fragment, null, save, cancel);
  }
  return /* @__PURE__ */ React.createElement(ButtonContainer, { justify: props.justifyContainer }, buttons);
}
