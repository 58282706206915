import React from "react";
import ButtonGroup from "@pm-app/components/forms/button-group/button-group";
import Button, { ButtonTypes } from "@pm-app/components/forms/button/button";
import FormGroup from "@pm-app/components/forms/form-group/form-group";
import Modal, { ModalFooter } from "@pm-app/components/modal/modal";
import { ErrorHandler } from "../../../../app/utils/ErrorHandler";
import messageActions from "../../common/actions/message-actions";
import CalendarUtils from "../../utils/calendar-utils";
import TenantMeldCancellationRequestAPI from "../apis/manager-tenant-cancellation-request-api";
export var CancelNoteModes = /* @__PURE__ */ ((CancelNoteModes2) => {
  CancelNoteModes2["DEFAULT"] = "DEFAULT";
  CancelNoteModes2["DELETE"] = "DELETE";
  return CancelNoteModes2;
})(CancelNoteModes || {});
export default class CancelNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "DEFAULT" /* DEFAULT */,
      deleting: false,
      errors: []
    };
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", null, this.props.note.get("cancel_notes"), /* @__PURE__ */ React.createElement("br", null), CalendarUtils.formatDayMonthTime(this.props.note.get("updated")), /* @__PURE__ */ React.createElement("br", null), this.renderDelete(), this.renderMode());
  }
  renderDelete() {
    if (this.props.canDelete) {
      return /* @__PURE__ */ React.createElement("a", { onClick: () => this.setMode("DELETE" /* DELETE */) }, "Delete");
    }
  }
  renderMode() {
    if ("DELETE" /* DELETE */ === this.state.mode) {
      return /* @__PURE__ */ React.createElement(
        Modal,
        {
          className: "cancel-notes-modal",
          header: "Delete Cancellation Request",
          isOpen: true,
          onClose: () => this.setMode("DEFAULT" /* DEFAULT */)
        },
        /* @__PURE__ */ React.createElement(FormGroup, null, /* @__PURE__ */ React.createElement("div", { className: "cancel-notes-body" }, "Are you sure you want to delete this request to cancel from the Tenant?")),
        /* @__PURE__ */ React.createElement(ModalFooter, null, /* @__PURE__ */ React.createElement(ButtonGroup, null, /* @__PURE__ */ React.createElement(
          Button,
          {
            buttonType: ButtonTypes.Primary,
            "data-test": "delete-button",
            onClick: () => {
              this.setState({ deleting: true });
              TenantMeldCancellationRequestAPI.deleteNote(this.props.note.get("id")).then(() => {
                if (this.props.onDelete) {
                  this.props.onDelete();
                }
              }).catch((result) => {
                messageActions.storeError({
                  body: result.messages[0]
                });
              }).then(() => {
                this.setState({ deleting: false });
                this.setMode("DEFAULT" /* DEFAULT */);
              }).catch((error) => ErrorHandler.handleAxiosError(error));
            },
            disabled: this.state.deleting
          },
          "Delete"
        ), /* @__PURE__ */ React.createElement(
          Button,
          {
            buttonType: ButtonTypes.Neutral,
            onClick: () => this.setMode("DEFAULT" /* DEFAULT */),
            disabled: this.state.deleting
          },
          "Cancel"
        )))
      );
    }
  }
  setMode(mode) {
    this.setState({ mode });
  }
}
