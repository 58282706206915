import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";
class CommentAPI extends BaseApi {
  static fetchComments(options) {
    return this.get(ApiUrls.commentList, options);
  }
  static fetchComment(id, clazz) {
    return this.get(ApiUrls.commentDetail(id) + `?clazz=${clazz}`);
  }
  static createComment(comment) {
    return this.post(ApiUrls.commentList, comment);
  }
  static updateComment(comment) {
    return this.patch(ApiUrls.commentDetail(comment.id), comment);
  }
  static deleteComment(id) {
    return this.delete(ApiUrls.commentDetail(id));
  }
}
export default CommentAPI;
