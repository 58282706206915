import Currency from "../../utils/currency";
import LineItem from "./line-item";
import LineItemPrice from "./line-item-price";
import React from "react";
class InvoiceLineItem extends LineItem {
  render() {
    return /* @__PURE__ */ React.createElement("div", { className: "row invoice-line-items-row" }, this.renderDescription(), /* @__PURE__ */ React.createElement("div", { className: "line-item-quantity columns small-12 large-2" }, /* @__PURE__ */ React.createElement("label", { className: "line-item-label" }, "Quantity"), /* @__PURE__ */ React.createElement(
      "input",
      {
        type: "text",
        className: "line-item-quantity-input",
        value: this.state.currentQuantity,
        onChange: this.onUnitQuantityChanged,
        onBlur: this.finalizeQuantityChanges
      }
    )), /* @__PURE__ */ React.createElement("div", { className: "columns small-12 large-2" }, /* @__PURE__ */ React.createElement(
      LineItemPrice,
      {
        currentUnitPrice: this.state.currentUnitPrice,
        onUnitPriceChanged: this.onUnitPriceChanged,
        onFinalizePriceChanges: this.finalizePriceChanges
      }
    )), /* @__PURE__ */ React.createElement("div", { className: "line-item-field columns small-12 large-2" }, /* @__PURE__ */ React.createElement("label", { className: "line-item-label" }, "Item Total"), /* @__PURE__ */ React.createElement("div", { className: "line-item-total" }, Currency.dollar(this.calcTotal()))));
  }
}
export default InvoiceLineItem;
