import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiInMemoryTable, EuiText } from "@elastic/eui";
import { css } from "@emotion/react";
import URL from "@pm-shared/utils/url";
import { colors } from "@pm-frontend/styles";
const tableCss = css`
  & {
    thead {
      display: none;
    }
    tbody {
      tr {
        td {
          .euiTableCellContent {
            padding: 6px 0px;
          }
        }
      }
    }
  }
`;
const removeSpacerCss = css`
  & {
    div {
      div.euiSpacer {
        max-height: 8px;
      }
    }
  }
`;
const renderOption = (option) => {
  if (option.currentlySelected) {
    return /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, key: option.id }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, alignItems: "center", style: { gap: "8px" } }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, style: { width: "16px" } }, /* @__PURE__ */ React.createElement(EuiIcon, { type: URL.getStatic("icons/heading-menu-check.svg"), size: "original" })), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiText, { color: colors.neutrals.gray800, size: "s", style: { fontWeight: 600 } }, option.text))));
  }
  return /* @__PURE__ */ React.createElement("a", { href: option.href, key: option.id }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, style: { paddingLeft: "24px" } }, /* @__PURE__ */ React.createElement(EuiText, { color: colors.neutrals.gray800, size: "s" }, option.text)));
};
const AccountSelector = ({ options, isLoading }) => {
  let accountSelectorBody = null;
  const columns = [
    {
      field: "name",
      name: "Account",
      render: (_, account) => renderOption(account)
    }
  ];
  if (options.length <= 1) {
    return null;
  } else if (options.length <= 5) {
    accountSelectorBody = /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", style: { gap: "12px" } }, options.map((option) => renderOption(option)));
  } else {
    accountSelectorBody = /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, css: removeSpacerCss }, /* @__PURE__ */ React.createElement(
      EuiInMemoryTable,
      {
        items: options,
        columns,
        search: {
          box: {
            placeholder: "Search for an account",
            incremental: true,
            compressed: true
          }
        },
        compressed: true,
        loading: isLoading,
        rowHeader: "none",
        css: tableCss,
        responsive: false,
        rowProps: { style: { background: colors.brand.white } },
        cellProps: { style: { border: "none", flexGrow: 0 } }
      }
    ));
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, className: "eui-yScroll" }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", alignItems: "flexStart", style: { gap: "12px" } }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiText, { size: "s", color: colors.neutrals.gray900, style: { fontWeight: 700 } }, "Select Segment")), accountSelectorBody && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, accountSelectorBody))));
};
export { AccountSelector };
