const ApiUrls = {
  enterprise: {
    enterpriseList: "/api/enterprises/",
    enterpriseAgentList: "/api/enterprise/agents/",
    agentDeactivate: `/api/enterprise/agents/deactivate/`,
    agentMeDetail: `/api/agents/me/`,
    agentInvite: `/api/agents/invite/`
  },
  nexus: {
    nexusRequestsList: "/api/nexus/requests/",
    nexusRequestsDetail: (id) => `/api/nexus/requests/${id}/`,
    nexusDetails: "/api/nexus/",
    nexusAvailableList: "/api/available-nexus/",
    nexusRangeAvailableList: "/api/available-nexus-range/",
    nexusAvailableDetail: (id) => `/api/available-nexus/${id}/`,
    nexusAvailablePartnersList: "/api/available-nexus-partners/",
    nexusConnect: (id) => `/api/available-nexus/${id}/connect/`,
    nexusConnectionCancel: (id) => `/api/connection-request/${id}/cancel/`,
    nexusAPIKeys: "/api/nexus/api-keys/",
    nexusAPIKeyRevoke: (id) => `/api/nexus/api-keys/${id}/revoke/`,
    nexusSettings: "/api/nexus/settings/",
    nexusSettingsUpdate: (id) => `/api/nexus/settings/${id}/`,
    nexusServices: "/api/nexus/settings/services/",
    nexusFiles: "/api/nexus/settings/files/",
    nexusFileRemove: (id) => `/api/nexus/settings/file/${id}/remove/`
  },
  recommend: {
    recommendMeldHistoryDetail: (meldId) => `/api/recommend/history/meld/${meldId}`,
    recommendAgentHistoryDetail: (agentId) => `/api/recommend/history/agent/${agentId}`,
    recommendCreate: `/api/recommend/create/`,
    recommendUsersList: (meldId) => `/api/recommend/${meldId}/users/`,
    recommendMeldsList: (agentId) => `/api/recommend/${agentId}/melds/`
  },
  googleCalendarApi: {
    googleCalendarEventsList: `/api/google-calendar-events/`
  },
  VendorApi: {
    qbConnected: "/api/qbConnected/",
    qbDisconnect: "api/qbDisconnect/",
    qbInvoices: "/api/qbInvoices/",
    qbSelectedInvoicePdf: (id) => `/api/qbSelectedInvoicePdf/${id}/`,
    qbSelectedInvoiceData: (id) => `/api/qbSelectedInvoiceData/${id}/`,
    qbSuggestedInvoice: (referenceId) => `/api/qbSuggestedInvoice/${referenceId}/`,
    qbSubmitInvoice: (id) => `/api/qbSubmitInvoice/${id}/`
  },
  alternativeEventDetail: (altEventId) => `/api/alternative-events/${altEventId}/`,
  alternativeEventEventDetail: (altEventId) => `/api/alternative-event/${altEventId}/`,
  alternativeEventList: "/api/alternative-events/",
  alternativeEvenCreate: "/api/alternative-events/create/",
  alternativeEventRescheduleSingle: (altEventId) => `/api/alternative-event/${altEventId}/single/`,
  alternativeEventRescheduleFollowing: (altEventId) => `/api/alternative-event/${altEventId}/following/`,
  alternativeEventRescheduleAll: (altEventId) => `/api/alternative-event/${altEventId}/all/`,
  announcementsList: "/api/announcements/",
  abbreviatedCommentTemplateList: "/api/comment-templates/abbrev/",
  abbreviatedMeldList: "/api/melds/abbrev/",
  abbreviatedPropertyGroupList: "/api/properties/groups/abbrev/",
  abbreviatedPWVendorList: "/api/pw/vendors/abbrev/",
  abbreviatedUnitList: "/api/units/abbrev/",
  abbreviatedVendorList: "/api/vendors/abbrev/",
  afapiVendor: (afapiId) => `/api/afapi/vendors/${afapiId}/`,
  afapiVendors: "/api/afapi/vendors/",
  afapiVendorSync: "/api/afapi/vendor/sync/",
  agentAbbrevList: "/api/abbrev-agents/",
  agentDetail: (id) => `/api/agents/${id}/`,
  agentInviteDetail: (inviteId) => `/api/agent-invites/${inviteId}/`,
  agentInviteList: "/api/agent-invites/",
  agentList: "/api/agents/",
  agentMeDetail: `/api/agents/me/`,
  agentPropertyGroupsList: (id) => `/api/agents/${id}/property-groups/`,
  agentRegistrationTrueList: "/api/agents/?registeredOnly=true",
  agentSearchList: "/api/agents/search/",
  agentUserGroupsList: (id) => `/api/agents/${id}/user-groups/`,
  appliedPropertyGroupsList: "/api/properties/groups/applied/",
  appointmentRequestDetail: (id) => `api/appointment-requests/${id}/`,
  appointmentRequests: "api/appointment-requests/",
  assignMaintenance: (id) => `/api/melds/${id}/assign-maintenance/`,
  assignmentAppointmentRequestDetail: (assignmentId) => `api/assignments/${assignmentId}/appointment-request/`,
  meldRequestedSegmentsList: (meldId) => `api/melds/${meldId}/appointment-request/`,
  assignmentRequestDetail: (id) => `/api/assignments/${id}/`,
  assignmentRequestList: "/api/assignments/",
  availabilitySegmentCreate: `/api/vendor-availability-segment/`,
  bldmVendorsList: `/api/buildium/vendors/`,
  bldmVendor: (buildiumVendorId) => `/api/buildium/vendors/${buildiumVendorId}/`,
  bldmVendorSync: `/api/buildium/vendor/sync/`,
  bookRequestedSegment: (id) => `api/requested-segments/${id}/book/`,
  buildingDetail: (id) => `/api/buildings/${id}/`,
  buildingList: "/api/buildings/",
  calendarMeldDetail: (id) => `/api/calendar-melds/${id}/`,
  calendarMelds: "/api/calendar-melds/",
  calendarRecommendMelds: "/api/recommend/calendar-melds/",
  calendarMeldsProximityList: `/api/calendar-melds/proximity/`,
  canSubscribe: "/api/can-subscribe/",
  citySearchList: "/api/cities/search/",
  clientReload: "/api/client-reload/",
  commentDetail: (id) => `/api/comments/${id}/`,
  commentList: "/api/comments/",
  commentTemplateDetail: (id) => `/api/comment-templates/${id}/`,
  commentTemplateList: "/api/comment-templates/",
  companySettings: "/api/company-settings/",
  completeMeld: (id) => `/api/melds/${id}/complete/`,
  contactSettings: "/api/contact-settings/",
  couponDetail: (id) => `/api/coupons/${id}/`,
  countyProvinceSearchList: `/api/county-provinces/search/`,
  currentUser: "/api/users/current/",
  defaultCard: "/api/default-card/",
  emergencyContactSettings: "/api/emergency-contact-settings/",
  estimateDetail: (estimateId) => `/api/estimates/${estimateId}`,
  estimateRequestOwnerApproval: `/api/estimates/request-owner-approval/`,
  estimatesList: `/api/estimates/`,
  eventUnitDetail: (id) => `/api/event-unit/${id}/`,
  finishableTicketList: (id) => `/api/melds/${id}/melds/finishable/`,
  finishVendorRegistration: "/api/vendors/finish-registration/",
  floorBuildingsList: (propertyId) => `/api/properties/${propertyId}/floors/`,
  floorDetail: (id) => `/api/floors/${id}/`,
  floorList: "/api/floors/",
  generateUnitsReport: "/api/generate-reportV2/units/",
  generateAllMeldsReport: "/api/generate-reportV2/all-melds/",
  generateWorkLogsReport: "/api/generate-reportV2/work-logs/",
  generateInvoicesReport: "/api/generate-reportV2/invoices/",
  generateExpendituresReport: "/api/generate-reportV2/expenditures/",
  generateOwnersReport: "/api/generate-reportV2/owners/",
  generateTechniciansReport: "/api/generate-reportV2/technicians/",
  generateResidentsReport: "/api/generate-reportV2/residents/",
  generateExpendituresLineItemReport: "/api/generate-reportV2/expenditures-line-items/",
  generateInvoiceLineItemsReport: "/api/generate-reportV2/invoice-line-items/",
  generateDownloadInvAndExpReport: "/api/generate-reportV2/download-invoices-and-expenditures/",
  glRule: "api/appfolio-api/gl-rule/",
  glRuleUpdate: "api/appfolio-api/gl-rule/update/",
  graphQl: "/graphql",
  integrationGlAccounts: (slug) => `/api/${slug}/gl-accounts/`,
  integrationDetail: (slug, id) => `/api/integration-partners/${slug}/integrations/${id}/`,
  integrationEnable: (slug) => `/api/integration-partners/${slug}/enable/`,
  integrationList: (slug) => `/api/integration-partners/${slug}/integrations/`,
  integrationPartnerDetail: (slug) => `/api/integration-partners/${slug}/`,
  integrationPartnerList: `/api/integration-partners/`,
  integrationsEnabled: "/api/integrations-enabled/",
  integrationSync: (slug) => `/api/integration-partners/${slug}/sync/`,
  invoiceFilterGroupDetail: (id) => `/api/invoice-filter-groups/${id}/`,
  invoiceFilterGroupDetails: (id) => `/api/invoice-filter-groups/${id}/details/`,
  invoiceFilterGroupFilters: (id) => `/api/invoice-filter-groups/${id}/filters/`,
  invoiceFilterGroupList: "/api/invoice-filter-groups/",
  invoiceFilterGroupReorder: (id) => `/api/invoice-filter-groups/${id}/reorder/`,
  maintenanceList: "/api/all-maintenance/",
  managementAppointmentDetail: (id) => `/api/management-appointments/${id}/`,
  managementAppointmentRequestMoreAvailability: (id) => `/api/management-appointments/${id}/request-more-availability/`,
  managementAppointmentSchedule: (id) => `/api/management-appointments/${id}/schedule/`,
  managementAppointmentCancel: (id) => `/api/management-appointments/${id}/cancel/`,
  managementEventList: `/api/management-events/`,
  managementEventDetail: (id) => `/api/management-event/${id}/`,
  managementList: "/api/managements/",
  managerScheduleMeld: (id) => `/api/melds/${id}/accept/`,
  managerTenantCancellationRequest: (id) => `/api/tenant-cancellation-requests/${id}/`,
  managerTicketCompletionTimes: "/api/metrics/manager-ticket-completion-times/",
  managerTicketRatings: "/api/metrics/manager-ticket-ratings/",
  meldActivities: (id) => `/api/activities/melds/${id}/`,
  meldAppointmentRequestDetail: (meldId) => `api/melds/${meldId}/appointment-request/`,
  meldAssignedCoordinator: (id) => `/api/melds/${id}/assign-coordinator/`,
  meldCancel: (id) => `/api/melds/${id}/cancel/`,
  meldDetail: (id) => `/api/v2/melds/${id}/`,
  meldEstimates: (id) => `/api/estimates/meld/${id}/`,
  meldExpendituresApprove: (expendituresId) => `/api/meld-expenditures/${expendituresId}/approve/`,
  meldExpendituresBill: (expendituresId) => `/api/meld-expenditures/${expendituresId}/bill/`,
  meldExpendituresDetail: (expendituresId) => `/api/meld-expenditures/${expendituresId}/`,
  meldExpendituresDraft: (expendituresId) => `/api/meld-expenditures/${expendituresId}/draft/`,
  meldExpendituresHold: (expendituresId) => `/api/meld-expenditures/${expendituresId}/hold/`,
  meldExpendituresList: "/api/meld-expenditures/",
  meldExpendituresReview: (expendituresId) => `/api/meld-expenditures/${expendituresId}/review/`,
  meldFileDetail: (fileId) => `/api/melds/files/${fileId}/`,
  meldFileGeneratePolicy: "/api/melds/files/generate-policy/",
  meldFileList: (meldId) => `/api/melds/${meldId}/files/`,
  meldFilterGroupDetail: (id) => `/api/meld-filter-groups/${id}/`,
  meldFilterGroupDetails: (id) => `/api/meld-filter-groups/${id}/details/`,
  meldFilterGroupFilters: (id) => `/api/meld-filter-groups/${id}/filters/`,
  meldFilterGroupList: "/api/meld-filter-groups/",
  meldFilterGroupReorder: (id) => `/api/meld-filter-groups/${id}/reorder/`,
  meldFilterGroupStatList: "/api/dashboard-meld-filter-groups/",
  meldFilterGroupSwap: (id) => `/api/meld-filter-groups/${id}/swap/`,
  meldInvoiceApprove: (invoiceId) => `/api/meld-invoices/${invoiceId}/approve/`,
  meldInvoiceDecline: (invoiceId) => `/api/meld-invoices/${invoiceId}/decline/`,
  meldInvoiceReview: (invoiceId) => `/api/meld-invoices/${invoiceId}/review/`,
  meldInvoiceDetail: (invoiceId) => `/api/meld-invoices/${invoiceId}/`,
  meldInvoiceHold: (invoiceId) => `/api/meld-invoices/${invoiceId}/hold/`,
  meldInvoiceList: "/api/meld-invoices/",
  meldInvoicesPay: "/api/meld-invoices/pay/",
  meldMentions: (id) => `/api/melds/${id}/mentions/`,
  meldMerge: (id) => `/api/melds/${id}/merge/`,
  mergedMeldDetail: (id) => `/api/melds/merged-melds/${id}/`,
  meldProximity: (id) => `/api/melds/${id}/proximity/`,
  createReport: "/api/generate-report/",
  createMeldExportCSV: "/api/export-meld-csv",
  meldRequestedSegments: (meldId) => `api/melds/${meldId}/requested-segments/`,
  meldRescheduleSegments: (id) => `/api/melds/${id}/segments/reschedule/`,
  meldRescheduleAppointmentSegment: (id) => `/api/melds/${id}/segments/appointment/reschedule/`,
  meldRescheduleVendorAppointmentSegment: (id) => `/api/reschedule-vendor-segment/${id}/`,
  meldAddSegments: (id) => `/api/melds/${id}/segments/add-management-appointment/`,
  addNewVendorAppointment: (id) => `/api/add-new-vendor-appointment/meld/${id}/`,
  meldSegments: (id) => `/api/melds/${id}/segments/`,
  meldsList: "/api/melds/",
  meldTagDetail: (id) => `/api/melds/tags/${id}/`,
  meldTagList: "/api/melds/tags/",
  meldTags: (id) => `/api/melds/${id}/tags/`,
  meldTagSearchList: "/api/melds/tags/search/",
  meldTenants: (id) => `/api/melds/${id}/tenants/`,
  meldUserGroups: (id) => `/api/melds/${id}/user-groups/`,
  meldWatch: (id) => `/api/melds/${id}/manager-watch/`,
  meldWatchlist: (id) => `/api/melds/${id}/manager-watchlist/`,
  meldWatchSearch: (id) => `/api/melds/${id}/manager-watchlist-search/`,
  meldWatchSearchAll: `/api/melds/manager-watchlist-search/`,
  meldWatchlistDelete: (meldId, agentId) => `/api/melds/${meldId}/manager-watchlist/${agentId}/`,
  mgrAppSettings: "/api/app-settings/",
  modify: "/api/openai/modify/",
  myAgentPreferences: "/api/agent-preferences/me/",
  notificationList: "/api/notifications/",
  notificationMarkAllRead: "/api/notifications/mark-all-read/",
  notificationMarkRead: "/api/notifications/mark-read/",
  notificationSettings: "/api/notification-settings/",
  notificationsUnread: "/api/notifications/unread/",
  notificationTemplatePreview: "/api/notification-template-preview/",
  ownerMeldApprovalDetail: (multitenantId, meldId, secret) => `/api/melds/approval/${multitenantId}/${meldId}/${secret}/`,
  ownerMeldApprovalFinish: (multitenantId, meldId, secret) => `/api/melds/approval/finish/${multitenantId}/${meldId}/${secret}/`,
  messageTemplatePreview: "/api/message-template-preview/",
  ownerBulkUpdate: "/owners/bulk/",
  ownerDetail: (id) => `/api/owners/${id}/`,
  ownerImportDetail: (id) => `/api/import/owners/${id}/`,
  ownerImportFile: "/api/import/owners/",
  ownerImportFinalize: (id) => `/api/import/owners/${id}/finalize/`,
  ownerList: "/api/owners/",
  ownerNotificationSettings: "/api/owners/notification-settings/",
  ownerPropertiesList: (ownerId) => `/api/owners/${ownerId}/properties/`,
  ownerPropertyUnlink: (ownerId, propertyId) => `/api/owners/${ownerId}/properties/${propertyId}/`,
  ownerReinvite: (id) => `/api/owners/${id}/reinvite`,
  ownerSearchList: "/api/owners/search/",
  ownerApprovalMessage: (meldId) => `/api/melds/${meldId}/owner-approval-message/`,
  ownerApprovalRequest: (meldId) => `/api/melds/${meldId}/owner-approval-request/`,
  ownerApprovalMessagePreview: (meldId) => `/api/melds/${meldId}/owner-approval-message-preview/`,
  ownerApprovalFinish: (meldId) => `/api/melds/${meldId}/owner-approval-finish/`,
  acknowledgeOwnerApproval: (meldId) => `/api/melds/${meldId}/owner-approval-acknowledge/`,
  plans: "/api/plans/",
  planSearch: (id) => `/api/plans/search/${id}/`,
  pmCouponDetail: (id) => `/api/pm-coupons/${id}/`,
  pmInvoicePay: "/api/pm-invoices/pay/",
  pmInvoicesList: "/api/pm-invoices/",
  projectsDetail: (id) => `/api/projects/${id}/`,
  productBoardJWT: `/api/users/current/productboard-jwt/`,
  propertiesAssign: "/api/properties/assign-property-groups/",
  propertiesDelete: "/api/properties/delete/",
  postcodeSearchList: `/api/postcodes/search/`,
  propertyBuildingsList: (propertyId) => `/api/properties/${propertyId}/buildings/`,
  propertyDetail: (id) => `/api/properties/${id}/`,
  propertyGroupPropertiesList: (id) => `/api/property-groups/${id}/properties/`,
  propertyGroupDetail: (id) => `/api/properties/groups/${id}/`,
  propertyGroupList: "/api/properties/groups/",
  propertyImportDetail: (id) => `/api/import/properties/${id}/`,
  propertyImportFile: "/api/import/properties/",
  propertyImportFinalize: (id) => `/api/import/properties/${id}/finalize/`,
  propertyReactivate: (id) => `/api/properties/${id}/reactivate/`,
  propertyList: "/api/properties/",
  propertySearchList: "/api/properties/search/",
  pwBuildings: "/api/pw/buildings/",
  pwBuildingsSync: "/api/pw/sync/buildings/",
  pwBuildingsSyncUpdate: (id) => `/api/pw/sync/buildings/${id}/`,
  pwLinkSearchBuildings: "/api/pw/link-search/buildings/",
  pwReverseLinkSearchBuildings: "/api/pw/link-search/buildings/reverse/",
  pwOwners: "/api/pw/owners/",
  pwOwnersSync: "/api/pw/sync/owners/",
  pwOwnersSyncUpdate: (id) => `/api/pw/sync/owners/${id}/`,
  pwLinkSearchOwners: "/api/pw/link-search/owners/",
  pwReverseLinkSearchOwners: "/api/pw/link-search/owners/reverse/",
  pwTenants: "/api/pw/tenants/",
  pwTenantsSync: "/api/pw/sync/tenants/",
  pwTenantsSyncUpdate: (id) => `/api/pw/sync/tenants/${id}/`,
  pwLinkSearchTenants: "/api/pw/link-search/tenants/",
  pwReverseLinkSearchTenants: "/api/pw/link-search/tenants/reverse/",
  pwUnits: "/api/pw/units/",
  pwUnitsSync: "/api/pw/sync/units/",
  pwUnitsSyncUpdate: (id) => `/api/pw/sync/units/${id}/`,
  pwLinkSearchUnits: "/api/pw/link-search/units/",
  pwReverseLinkSearchUnits: "/api/pw/link-search/units/reverse/",
  pwVendors: "/api/pw/vendors/",
  pwVendorsSync: "/api/pw/sync/vendors/",
  pwVendorsSyncUpdate: (id) => `/api/pw/sync/vendors/${id}/`,
  pwLinkSearchVendors: "/api/pw/link-search/vendors/",
  pwVendorUsersSync: "/api/pw/sync/vendor-users/",
  pwVendorUsersSyncUpdate: (id) => `/api/pw/sync/vendor-users/${id}/`,
  pwLinkSearchVendorUsers: "/api/pw/link-search/vendor-users/",
  recurringMeldsDetail: (meldId) => `/api/melds/recurring/${meldId}/`,
  recurringMeldsList: "/api/melds/recurring/",
  relatedMeldsList: (meldId) => `/api/melds/${meldId}/related/`,
  reminderComplete: (id) => `/api/reminders/${id}/complete/`,
  reminderDetail: (id) => `/api/reminders/${id}/`,
  reminderMeldsList: (meldId) => `/api/reminders/?meld=${meldId}`,
  reminderList: `/api/reminders/`,
  rentManagerLinkSearchUser: "/api/rm/link-search/users/",
  rentManagerLinkSearchVendor: "/api/rm/link-search/vendors/",
  reviewMeld: (id) => `/api/melds/${id}/review/`,
  rmDisconnectedUnits: "/api/rm/disconnected/units/",
  rmInventoryItemSearch: "/api/rm/inventory-item-search/",
  pwAccountList: "/api/pw/accounts/",
  rmLeases: "/api/rm/leases/",
  rmLeasesSync: "/api/rm/sync/leases/",
  rmLinkSearchLease: "/api/rm/link-search/leases/",
  rmLinkSearchProperty: "/api/rm/link-search/properties/",
  rmLinkSearchUnit: "/api/rm/link-search/units/",
  rmProperties: "/api/rm/properties/",
  rmPropertiesSync: "/api/rm/sync/properties/",
  rmPropertiesSyncUpdate: (id) => `/api/rm/sync/properties/${id}/`,
  rmServiceManagerStatuses: "/api/rm/service-manager-statuses/",
  rmServiceManagerPriorities: "/api/rm/service-manager-priorities/",
  rmServiceManagerCategories: "/api/rm/service-manager-categories/",
  rmTenantsSync: "/api/rm/sync/tenants/",
  rmTenantsSyncUpdate: (id) => `/api/rm/sync/tenants/${id}/`,
  rmUnits: "/api/rm/units/",
  rmUnitsSync: "/api/rm/sync/units/",
  rmUnitsSyncUpdate: (id) => `/api/rm/sync/units/${id}/`,
  rmUsers: "/api/rm/users/",
  rmUsersSync: "/api/rm/sync/users/",
  rmUsersSyncUpdate: (id) => `/api/rm/sync/users/${id}/`,
  rmOwnersSync: "/api/rm/sync/owners/",
  rmOwnersSyncUpdate: (id) => `/api/rm/sync/owners/${id}/`,
  rmVendors: "/api/rm/vendors/",
  rmVendorsSync: "/api/rm/sync/vendors/",
  rmVendorsSyncUpdate: (id) => `/api/rm/sync/vendors/${id}/`,
  roleList: "/api/roles/",
  roleDetail: (id) => `/api/roles/${id}/`,
  searchAll: "/api/search/",
  searchAllV2: "/api/v2/search/",
  shareMeldFiles: (id) => `/api/melds/${id}/files/share/`,
  downloadMeldFiles: (id) => `/api/melds/${id}/files/download/`,
  subscription: "/api/subscription/",
  subscriptionCards: "/api/subscription-cards/",
  summarize: "/api/openai/summarize/",
  tenantBulkInvite: `/api/tenants/invite/`,
  tenantBulkInviteTest: `/api/tenants/invite-test/`,
  tenantDetail: (id) => `/api/tenants/${id}/`,
  tenantFileDetail: (tenantId, fileId) => `/api/tenants/${tenantId}/files/${fileId}/`,
  tenantFileGeneratePolicy: "/api/tenants/files/generate-policy/",
  tenantFileList: (id) => `/api/tenants/${id}/files/`,
  tenantFileBulkDownload: (id) => `/api/tenants/${id}/bulk-file-download/`,
  tenantImportDetail: (id) => `/api/import/tenants/${id}/`,
  tenantImportFile: "/api/import/tenants/",
  tenantImportFinalize: (id) => `/api/import/tenants/${id}/finalize/`,
  tenantInvite: (id) => `/api/tenants/${id}/invite/`,
  tenantInviteDetail: (id) => `/api/tenants/invites/${id}/`,
  tenantInviteList: "/api/tenants/invites/",
  tenantInviteRequestDetail: (id) => `/api/tenants/invite-requests/${id}/`,
  tenantInviteRequestList: "/api/tenants/invite-requests/",
  tenantList: "/api/tenants/",
  tenantMeldFileDetail: (id) => `/api/melds/tenant-files/${id}/`,
  tenantMeldFileGeneratePolicy: "/api/melds/tenant-files/generate-policy/",
  tenantMeldFileList: (id) => `/api/melds/${id}/tenant-files/`,
  tenantMeldFinish: (id) => `/api/tenant-requests/${id}/finish/`,
  tenantMeldList: (id) => `/api/melds/tenant/${id}/`,
  tenantRequestCancel: (id) => `/api/tenant-requests/${id}/cancel/`,
  tenantRequestDetail: (id) => `/api/tenant-requests/${id}/`,
  tenantRequestFileGeneratePolicy: "/api/teannt-requests/tenant-files/generate-policy/",
  tenantRequestFileList: (id) => `/api/tenant-requests/${id}/tenant-files/`,
  tenantRequestList: "/api/tenant-requests/",
  tenantSendResetPassword: (id) => `/api/tenants/send-reset-password/${id}/`,
  tenantSearchList: "/api/tenants/search/",
  tenantSettings: "/api/current-tenant/",
  tenantUnits: (id) => `/api/tenants/${id}/units/`,
  testDeleteUnits: "/api/units/test-delete/",
  toggleIntegrationEnabled: (slug) => `/api/integration-partners/${slug}/toggle-enabled/`,
  unitDetail: (id) => `/api/units/${id}/`,
  unitMelds: (id) => `/api/melds/unit/${id}/`,
  unitFileDetail: (fileId) => `/api/units/files/${fileId}/`,
  unitFileGeneratePolicy: "/api/units/files/generate-policy/",
  unitFileList: (unitId) => `/api/units/${unitId}/files/`,
  unitFileBulkDownload: (unitId) => `/api/units/${unitId}/bulk-file-download/`,
  unitList: "/api/units/",
  unitMeldInvoiceList: (unitId) => `/api/meld-invoices/unit/${unitId}/`,
  unitNotesImportDetail: (id) => `/api/import/unit-notes/${id}/`,
  unitNotesImportFile: "/api/import/unit-notes/",
  unitNotesImportFinalize: (id) => `/api/import/unit-notes/${id}/finalize/`,
  vendorImportDetail: (id) => `/api/import/vendors/${id}/`,
  vendorImportFile: "/api/import/vendors/",
  vendorImportFinalize: (id) => `/api/import/vendors/${id}/finalize/`,
  unitReactivate: (unitId) => `/api/units/${unitId}/reactivate/`,
  unitSearchList: "/api/units/search/",
  unitTenantDetail: (unitId, tenantId) => `/api/units/${unitId}/tenants/${tenantId}/`,
  unitTenantsList: (unitId) => `/api/units/${unitId}/tenants/`,
  unitsDelete: "/api/units/delete/",
  updateMeldUserGroups: (id) => `/api/melds/${id}/update-user-groups/`,
  userAvailability: "/api/user-availability/",
  userDetail: (id) => `/api/users/${id}/`,
  userGroupDetail: (id) => `/api/user-groups/${id}`,
  userGroupList: "/api/user-groups/",
  vendorAcceptAssignment: (id) => `/api/assignments/${id}/accept/`,
  vendorAgentList: (vendorId) => `/api/vendors/${vendorId}/agents/`,
  vendorAppointmentDetail: (id) => `/api/vendor-appointments/${id}/`,
  vendorAppointmentDetailAlt: (assignmentId) => `/api/assignments/${assignmentId}/appointment/`,
  vendorAppointmentRequestMoreAvailability: (id) => `/api/vendor-appointments/${id}/request-more-availability/`,
  vendorAppointmentSchedule: (id) => `/api/vendor-appointments/${id}/schedule/`,
  vendorCardDetail: (id) => `/api/vendor-cards/${id}/`,
  vendorCards: "/api/vendor-cards/",
  vendorsAll: "/api/vendors",
  vendorDetail: (id) => `/api/vendors/${id}/`,
  vendorEventList: `/api/vendor-events/`,
  vendorExcludedPropertyGroupsList: (id) => `/api/vendors/${id}/excluded-property-groups/`,
  vendorFileDetail: (fileId) => `/api/vendors/files/${fileId}/`,
  vendorFileGeneratePolicy: "/api/vendors/files/generate-policy/",
  vendorFileList: (vendorId) => `/api/vendors/${vendorId}/files/`,
  vendorFileBulkDownload: (vendorId) => `/api/vendors/${vendorId}/bulk-file-download/`,
  vendorInvoiceFileList: (meldId) => `/api/melds/${meldId}/vendor-invoice-file/`,
  vendorInvoiceFileDetail: (id) => `/api/melds/vendor-invoice-file/${id}/`,
  vendorInvite: "/api/vendors/invite/",
  vendorInviteDetail: (id) => `/api/vendors/invite/${id}/`,
  vendorList: "/api/vendors/",
  vendorMeldList: (vendorId) => `/api/vendors/${vendorId}/melds/`,
  vendorMeldFileDetail: (id) => `/api/melds/vendor-files/${id}/`,
  vendorMeldFileGeneratePolicy: "/api/melds/vendor-files/generate-policy/",
  vendorInvoiceFileGeneratePolicy: "/api/melds/vendor-invoice-file/generate-policy/",
  vendorMeldFileList: (meldId) => `/api/melds/${meldId}/vendor-files/`,
  vendorRequestChange: "/api/request-vendor-change-info/",
  vendorSettings: "/api/settings/",
  vendorSpecialtiesList: "/api/specialties/",
  vendorTicketCompletionTimes: "/api/metrics/vendor-ticket-completion-times/",
  vendorTicketRatings: "/api/metrics/vendor-ticket-ratings/",
  vendorUpdateSegments: (id) => `/api/assignments/${id}/segments/`,
  workEntryDetail: (id) => `/api/melds/work-entries/${id}/`,
  workEntryList: (id) => `/api/melds/${id}/work-entries/`,
  workEntryOpenList: () => `/api/work-entries-open/`
};
export { ApiUrls };
