import { parseQuery } from "./location-utils";
let prevRoute;
let RouteUtils = {
  savePrevRoute(route) {
    prevRoute = route;
  },
  isPrevRoutePathEqualTo(path) {
    return prevRoute && prevRoute.pathname === path;
  },
  getBackLinkState(config) {
    let pathname = "";
    if (config.to && typeof config.to !== "string" && config.to.pathname) {
      pathname = config.to.pathname;
    } else if (config.to && typeof config.to === "string") {
      pathname = config.to;
    }
    if (prevRoute && prevRoute.pathname && prevRoute.pathname.endsWith(pathname)) {
      return { to: config.to, query: parseQuery(prevRoute) };
    }
    return config;
  }
};
export default RouteUtils;
