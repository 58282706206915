import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiLink } from "@elastic/eui";
import { css } from "@emotion/react";
import { getRowStyle, HIGHLIGHT_KEYBOARD_COLOR } from "@pm-frontend/shared/utils/search-utils";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";
import { useGlobalSearchKeyboardActions } from "./hooks";
function SiteSearchDesktopBaseResults({
  items,
  Component,
  onResultClicked,
  hasNextPage,
  fetchNextPage,
  isFetching,
  highlightedIndex,
  setHighlightedIndex,
  appType
}) {
  const isMobile = useIsMobile();
  useGlobalSearchKeyboardActions(
    items,
    highlightedIndex,
    setHighlightedIndex,
    (_) => onResultClicked(void 0)
  );
  const element = document.getElementById(`search-result-index-${highlightedIndex}`);
  if (element && highlightedIndex) {
    element.scrollIntoView({ behavior: "auto", block: "center" });
  }
  if (items.length === 0) {
    return null;
  }
  const fetchMoreResults = () => {
    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  };
  const resultsContainerCSS = isMobile ? css`
        gap: 12px;
      ` : css`
        border-radius: 6px;
        border: 1px solid #ffffff;
        gap: 0px;
        #search-result-index-${highlightedIndex} {
          background-color: ${HIGHLIGHT_KEYBOARD_COLOR};
        }
      `;
  return /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", css: resultsContainerCSS }, items.map((item, index, arr) => {
    return /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, key: item._id + item._index }, /* @__PURE__ */ React.createElement(
      Component,
      {
        onClick: onResultClicked,
        item,
        style: getRowStyle(item, index, arr, appType),
        searchResultIndex: index
      }
    ));
  }), hasNextPage && /* @__PURE__ */ React.createElement(
    EuiFlexItem,
    {
      grow: false,
      style: {
        height: "48px",
        padding: "0px 0px 0px 44px",
        justifyContent: "center",
        alignSelf: "flex-start"
      }
    },
    /* @__PURE__ */ React.createElement(EuiLink, { onClick: fetchMoreResults }, "View More")
  ));
}
export { SiteSearchDesktopBaseResults };
