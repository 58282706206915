import classnames from "classnames";
import React from "react";
import Label from "./Label";
export var UserTypes = /* @__PURE__ */ ((UserTypes2) => {
  UserTypes2["Manager"] = "manager";
  UserTypes2["Owner"] = "owner";
  UserTypes2["Tenant"] = "tenant";
  UserTypes2["Vendor"] = "vendor";
  return UserTypes2;
})(UserTypes || {});
const UserTypeLabel = (props) => {
  const classes = classnames("pm-user-type-label", props.userType, props.className);
  return /* @__PURE__ */ React.createElement(Label, { className: classes }, props.children);
};
export default UserTypeLabel;
