import I from "immutable";
import jstz from "jstz";
import { Prompt } from "react-router-dom";
import React from "react";
import SVGInline from "react-svg-inline";
import Checkbox from "../common/components/checkbox";
import { EmbeddedErrorView } from "../common/components/embedded-error-view";
import { ErrorList } from "../common/components/errors";
import { GroupedSelection } from "../../../app/components/prefabs/grouped-selection/GroupedSelection";
import { LargeLoader } from "../loaders";
import NotificationSettingsApi from "./apis/notification-settings-api";
import ResetChangesButtons from "./components/reset-changes-buttons";
import TextBlock, { TextBlockSizes } from "../../../app/components/texts/TextBlock";
import Tooltip from "../common/components/tooltip";
import { AuthUtils } from "../utils/auth-utils";
const PopoutIcon = require("../../../assets/svg/Popout.svg");
class AccountNotificationSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dirty: false,
      errors: [],
      saving: false,
      selectedValues: I.Set([]),
      valueList: I.fromJS(this.props.notificationGroups).map((group) => {
        return group.get("items").map((item) => {
          return item.get("values").filter((value) => !!value);
        }).flatten();
      }).flatten().toSet()
    };
    this.toggleValue = this.toggleValue.bind(this);
    this.checkValues = this.checkValues.bind(this);
    this.uncheckValues = this.uncheckValues.bind(this);
    this.initForm = this.initForm.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }
  render() {
    if (this.props.errors) {
      return /* @__PURE__ */ React.createElement(EmbeddedErrorView, null);
    }
    if (this.props.loading && !this.props.settings) {
      return /* @__PURE__ */ React.createElement(LargeLoader, null);
    }
    if (this.props.settings) {
      const cellPhone = this.props.settings.get("cell_phone");
      const groups = I.fromJS(this.props.notificationGroups);
      const disabled = cellPhone && this.state.smsNotifications ? I.Set() : groups.map((group) => {
        return group.get("items").map((item) => {
          return item.get("values").filter((value) => value && value.endsWith("_text"));
        }).flatten();
      }).flatten();
      return /* @__PURE__ */ React.createElement("div", { className: "notification-settings" }, /* @__PURE__ */ React.createElement(
        Prompt,
        {
          when: this.state.dirty,
          message: () => "You have made changes to your settings. Are you sure you want to leave? Any unsaved changes will be lost."
        }
      ), /* @__PURE__ */ React.createElement(TextBlock, { size: TextBlockSizes.XL, className: "section-header" }, "Notifications"), /* @__PURE__ */ React.createElement(TextBlock, { size: TextBlockSizes.LG }, "You can use the following options to enable or disable each of the email or text notifications you want to receive."), /* @__PURE__ */ React.createElement(TextBlock, { size: TextBlockSizes.LG, className: "learn-more" }, /* @__PURE__ */ React.createElement("a", { href: "https://propertymeld.zendesk.com/hc/en-us/articles/115004845474", target: "_blank" }, "Learn more about notifications", /* @__PURE__ */ React.createElement(SVGInline, { svg: PopoutIcon, className: "popout-icon" }))), /* @__PURE__ */ React.createElement("hr", { className: "settings-divider" }), /* @__PURE__ */ React.createElement(TextBlock, { size: TextBlockSizes.LG, className: "section-header" }, "Destination and timezone"), /* @__PURE__ */ React.createElement(TextBlock, { size: TextBlockSizes.LG }, "Text: ", cellPhone || "No cell phone", /* @__PURE__ */ React.createElement(Tooltip, { message: "Update your cell phone number by selecting the User tab under Account Settings" })), cellPhone && /* @__PURE__ */ React.createElement("div", { className: "indented" }, /* @__PURE__ */ React.createElement(
        Checkbox,
        {
          checked: this.state.smsNotifications,
          onClick: () => this.setState({
            dirty: true,
            smsNotifications: !this.state.smsNotifications
          })
        }
      ), /* @__PURE__ */ React.createElement(TextBlock, { size: TextBlockSizes.LG, className: "checkbox-label" }, "Receive text notifications")), /* @__PURE__ */ React.createElement(TextBlock, { size: TextBlockSizes.LG, className: "email" }, "Email: ", AuthUtils.getUserEmail()), /* @__PURE__ */ React.createElement(TextBlock, { size: TextBlockSizes.LG }, "Select your timezone for email notifications"), /* @__PURE__ */ React.createElement(
        "select",
        {
          value: this.state.timezone,
          onChange: (e) => this.setState({
            dirty: true,
            timezone: e.target.value
          })
        },
        I.OrderedMap(jstz.olson.timezones).toOrderedSet().sort().map((timezone) => /* @__PURE__ */ React.createElement("option", { key: timezone, value: timezone }, timezone))
      ), /* @__PURE__ */ React.createElement("hr", { className: "settings-divider" }), /* @__PURE__ */ React.createElement(TextBlock, { size: TextBlockSizes.LG, className: "section-header" }, "Customized settings"), /* @__PURE__ */ React.createElement(TextBlock, { size: TextBlockSizes.LG }, "Toggle on or off each of the following email notification settings to opt in or out of receiving each type of email notification."), /* @__PURE__ */ React.createElement(
        GroupedSelection,
        {
          checkValues: this.checkValues,
          columns: I.List(["Email", "Text"]),
          columnsSelectAll: I.List(["Select all email", "Select all text"]),
          columnsUnselectAll: I.List(["Unselect all email", "Unselect all text"]),
          disabledValues: disabled,
          groups,
          selectedValues: this.state.selectedValues,
          toggleValue: this.toggleValue,
          uncheckValues: this.uncheckValues
        }
      ), /* @__PURE__ */ React.createElement(ErrorList, { errors: this.state.errors }), this.state.dirty && /* @__PURE__ */ React.createElement(
        ResetChangesButtons,
        {
          resetClickFn: this.initForm,
          saveClickFn: this.submitForm,
          resetDisabled: this.state.saving,
          saveDisabled: this.state.saving
        }
      ));
    }
  }
  toggleValue(value) {
    if (this.state.selectedValues.contains(value)) {
      this.setState({
        dirty: true,
        selectedValues: this.state.selectedValues.remove(value)
      });
    } else {
      this.setState({
        dirty: true,
        selectedValues: this.state.selectedValues.add(value)
      });
    }
  }
  checkValues(values) {
    this.setState({
      dirty: true,
      selectedValues: this.state.selectedValues.union(values)
    });
  }
  uncheckValues(values) {
    this.setState({
      dirty: true,
      selectedValues: this.state.selectedValues.subtract(values)
    });
  }
  initForm() {
    if (this.props.settings) {
      this.setState({
        dirty: false,
        selectedValues: this.state.valueList.filter((value) => this.props.settings.get(value)).toSet(),
        smsNotifications: this.props.settings.get("sms_notifications"),
        timezone: this.props.settings.get("timezone")
      });
    }
  }
  submitForm() {
    this.setState({ saving: true });
    let data = {
      sms_notifications: this.state.smsNotifications,
      timezone: this.state.timezone
    };
    this.state.valueList.map((value) => data[value] = this.state.selectedValues.contains(value));
    NotificationSettingsApi.update(data).then(() => {
      this.setState({ dirty: false, errors: [], saving: false });
      this.props.refresh();
    }).catch((err) => this.setState({ errors: [err.message], saving: false }));
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.settings && this.props.settings || prevProps.settings && this.props.settings && prevProps.settings.get("sms_notifications_changed") !== this.props.settings.get("sms_notifications_changed")) {
      this.initForm();
    }
  }
}
export class AccountNotificationSettingsTab extends React.Component {
  constructor(props) {
    super(props);
    this.setState({
      errors: []
    });
    this.refresh = this.refresh.bind(this);
  }
  render() {
    return /* @__PURE__ */ React.createElement(
      AccountNotificationSettings,
      {
        errors: this.state.errors,
        loading: this.state.loading,
        notificationGroups: this.props.notificationGroups,
        refresh: this.refresh,
        settings: this.state.settings
      }
    );
  }
  refresh() {
    this.setState({ loading: true });
    NotificationSettingsApi.fetchCurrent().then((res) => {
      this.setState({ loading: false, settings: I.fromJS(res.data) });
    }).catch((err) => {
      this.setState({ errors: [err.message], loading: false });
    });
  }
  componentWillMount() {
    this.refresh();
  }
}
