import _ from "lodash";
import { parseQuery } from "./location-utils";
let SavedFilterUtils = {
  getFiltersFromObject(configObj, paramKeys) {
    let updatedConfig = {};
    Object.keys(configObj).forEach((key) => {
      const filterValue = configObj[key];
      if (Array.isArray(filterValue) && filterValue.length === 0) {
        return;
      }
      if (filterValue || filterValue === null) {
        updatedConfig[key] = filterValue;
      }
    });
    return _.pick(updatedConfig, paramKeys);
  },
  haveFiltersChanged(curProps, nextProps) {
    return !_.isEqual(curProps.filterGroup, nextProps.filterGroup) || !_.isEqual(parseQuery(curProps.location), parseQuery(nextProps.location));
  }
};
export default SavedFilterUtils;
