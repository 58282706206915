import BackArrowSvg from "../../svg/back-arrow-svg";
import CloseSVG from "../../svg/close-svg";
import DocumentSvg from "../../svg/document-svg";
import FileUtils from "../utils/file-utils";
import ForwardArrow from "../../svg/forward-arrow-svg";
import KeyUtils from "../../utils/key-utils";
import React from "react";
function ResponsiveImg(props) {
  const srcSet = props.file.get("variable_sizes") ? props.file.get("variable_sizes").toJS().map((sizes) => {
    return sizes.map((size) => {
      return `${size.url} ${size.width}w`;
    });
  }).reduce((acc, val) => acc.concat(val), []).join(", ") : null;
  return /* @__PURE__ */ React.createElement("img", { src: props.file.get("full_compressed"), sizes: "100vw", srcSet, className: "image-preview" });
}
export default class FilePreview extends React.Component {
  constructor(props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.navNextFile = this.navNextFile.bind(this);
    this.navPrevFile = this.navPrevFile.bind(this);
  }
  render() {
    const { file } = this.props;
    return /* @__PURE__ */ React.createElement("div", { className: "file-previewer" }, /* @__PURE__ */ React.createElement("div", { className: "preview-header" }, /* @__PURE__ */ React.createElement("div", { className: "filename" }, file.get("filename")), /* @__PURE__ */ React.createElement("div", { className: "close-previewer", onClick: this.props.onClose }, /* @__PURE__ */ React.createElement(CloseSVG, null))), /* @__PURE__ */ React.createElement("div", { className: "file-preview" }, this.renderFilePreview()), /* @__PURE__ */ React.createElement("div", { className: "file-footer" }, /* @__PURE__ */ React.createElement("div", { className: "nav-buttons" }, this.canNavPrev && /* @__PURE__ */ React.createElement("div", { onClick: this.navPrevFile, role: "button", className: "nav-button nav-prev" }, /* @__PURE__ */ React.createElement(BackArrowSvg, null)), this.canNavNext && /* @__PURE__ */ React.createElement("div", { onClick: this.navNextFile, role: "button", className: "nav-button nav-next" }, /* @__PURE__ */ React.createElement(ForwardArrow, null))), /* @__PURE__ */ React.createElement("div", { className: "file-actions" }, this.props.renderActions && this.props.renderActions(file))));
  }
  renderFilePreview() {
    let { file } = this.props;
    if (file.get("pdf_file_url")) {
      return /* @__PURE__ */ React.createElement(
        "object",
        {
          height: "100%",
          width: "100%",
          data: file.get("pdf_file_url"),
          type: "application/pdf",
          className: "pdf-file-preview"
        },
        /* @__PURE__ */ React.createElement(DocumentSvg, null)
      );
    } else if (FileUtils.isPDF(file)) {
      return /* @__PURE__ */ React.createElement(
        "object",
        {
          height: "100%",
          width: "100%",
          data: file.get("openable_signed_url"),
          type: "application/pdf",
          className: "pdf-file-preview"
        },
        /* @__PURE__ */ React.createElement(DocumentSvg, null)
      );
    } else if (FileUtils.isPreviewAvailable(file)) {
      return /* @__PURE__ */ React.createElement("div", { className: "image-preview-container" }, /* @__PURE__ */ React.createElement(ResponsiveImg, { file }));
    } else {
      return /* @__PURE__ */ React.createElement("div", { className: "image-preview-container" }, /* @__PURE__ */ React.createElement(DocumentSvg, null));
    }
  }
  handleKeyDown(e) {
    if (KeyUtils.isEscape(e)) {
      this.props.onClose();
    } else if (KeyUtils.isRightArrow(e)) {
      this.navNextFile();
    } else if (KeyUtils.isLeftArrow(e)) {
      this.navPrevFile();
    }
  }
  navNextFile() {
    if (this.canNavNext) {
      this.props.navFile(this.props.files.get(this.curFileIndex + 1));
    }
  }
  navPrevFile() {
    if (this.canNavPrev) {
      this.props.navFile(this.props.files.get(this.curFileIndex - 1));
    }
  }
  componentWillMount() {
    window.document.addEventListener("keydown", this.handleKeyDown);
    this.open();
  }
  componentWillUnmount() {
    window.document.removeEventListener("keydown", this.handleKeyDown);
    this.close();
  }
  open() {
    let body = document.querySelector("body");
    if (body) {
      body.className = body.className + " modal-open";
    }
  }
  close() {
    let body = document.querySelector("body");
    if (body) {
      body.className = body.className.replace(/ modal-open/g, "");
    }
  }
  get canNavNext() {
    return this.curFileIndex + 1 < this.props.files.size;
  }
  get canNavPrev() {
    return this.curFileIndex !== 0;
  }
  get curFileIndex() {
    return this.props.files.findIndex((file) => file.get("id") === this.props.file.get("id"));
  }
}
