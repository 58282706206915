import React from "react";
import { colors } from "@pm-frontend/styles";
export const SidebarProjectIcon = ({ isActive = false }) => {
  const fill = isActive ? colors.brand.meldBlue : colors.neutrals.gray800;
  return /* @__PURE__ */ React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill, xmlns: "http://www.w3.org/2000/svg" }, /* @__PURE__ */ React.createElement("g", { id: "list_alt", clipPath: "url(#clip0_1299_22277)" }, /* @__PURE__ */ React.createElement(
    "path",
    {
      id: "Vector",
      d: "M7.33333 4.66667H11.3333V6H7.33333V4.66667ZM7.33333 7.33333H11.3333V8.66667H7.33333V7.33333ZM7.33333 10H11.3333V11.3333H7.33333V10ZM4.66667 4.66667H6V6H4.66667V4.66667ZM4.66667 7.33333H6V8.66667H4.66667V7.33333ZM4.66667 10H6V11.3333H4.66667V10ZM13.4 2H2.6C2.26667 2 2 2.26667 2 2.6V13.4C2 13.6667 2.26667 14 2.6 14H13.4C13.6667 14 14 13.6667 14 13.4V2.6C14 2.26667 13.6667 2 13.4 2ZM12.6667 12.6667H3.33333V3.33333H12.6667V12.6667Z",
      fill
    }
  )), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "clip0_1299_22277" }, /* @__PURE__ */ React.createElement("rect", { width: "16", height: "16", fill: "white" }))));
};
