import I from "immutable";
import Activity from "../records/activity";
import Notification, { notificationFields } from "../records/notification";
import notificationApi from "../api/notification-api";
import paginateStore from "../../common/utils/paginate-store";
import Store from "../../common/stores/store";
class NotificationStore extends paginateStore(Store) {
  constructor() {
    super(Notification, notificationFields);
    this.initRequestFunctions({
      fetchAll: this.fetchAll,
      refetch: this.refetch,
      fetchNext: this.fetchNext,
      fetchPrevious: this.fetchPrevious
    });
  }
  fetchAll() {
    return this.fetch(notificationApi.fetchAll());
  }
  refetch() {
    return this.fetch(notificationApi.fetchAll({ url: this.state.url }));
  }
  fetchNext() {
    return this.fetch(notificationApi.fetchAll({ url: this.state.next }));
  }
  fetchPrevious() {
    return this.fetch(notificationApi.fetchAll({ url: this.state.previous }));
  }
  markActivitiesRead(idSet) {
    const fetchedModels = this.state.fetchedModels.map((notification) => {
      return notification.set(
        "activities",
        notification.get("activities").map((activity) => {
          if (idSet.has(activity.get("id"))) {
            return markActivityRead(activity);
          }
          return activity;
        })
      );
    });
    this.setState({ fetchedModels });
  }
  markActivityRead(id) {
    this.markActivitiesRead(I.Set([id]));
  }
  _makeModels(notifications) {
    return I.List(
      notifications.map((notification) => {
        notification.activities = I.List(
          notification.activities.map((activity) => {
            return new Activity(I.fromJS(activity));
          })
        ).sort((a1, a2) => {
          return new Date(a2.get("time")).getTime() - new Date(a1.get("time")).getTime();
        });
        return new Notification(notification);
      })
    );
  }
}
function markActivityRead(activity) {
  return activity.set("read", true);
}
export default new NotificationStore();
