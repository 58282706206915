import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";
class SubscriptionApi extends BaseApi {
  static fetch() {
    return this.get(ApiUrls.subscription);
  }
  static payOutstanding(data) {
    return this.post(ApiUrls.pmInvoicePay, data);
  }
  static canSubscribe() {
    return this.get(ApiUrls.canSubscribe);
  }
}
export default SubscriptionApi;
