import React from "react";
import { useToasts, useDismissToast } from "../store/toast";
import { EuiGlobalToastList } from "@elastic/eui";
import { Global, css } from "@emotion/react";
import { colors } from "@pm-frontend/styles";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";
import { useGetGlobalBannerVisibility } from "@pm-frontend/shared/store/globalBanner";
const toastCloseIconColor = {
  success: colors.interface.green.default,
  primary: colors.brand.meldBlue,
  warning: colors.brand.meldBlue,
  danger: colors.interface.red.default
};
const getTopValue = (length, isGlobalBannerVisible, isMobile) => {
  if (isGlobalBannerVisible) {
    return isMobile ? "28% !important" : "17% !important";
  } else {
    return length > 0 ? "10% !important" : "110% !important";
  }
};
const ApplicationToastList = () => {
  const toasts = useToasts();
  const dismissToast = useDismissToast();
  const isGlobalBannerVisible = useGetGlobalBannerVisibility();
  const isMobile = useIsMobile();
  let toastCloseColor = colors.brand.meldBlue;
  if (toasts[0] !== void 0) {
    toastCloseColor = toastCloseIconColor[toasts[0].color || "success"];
  }
  const toastCss = css`
    .pmToastList {
      .euiToast {
        padding: 14px 16px 16px;
        width: ${isMobile ? "310px" : "413px"};
        div {
          margin: 0px !important;
        }
        top: 20px;
        .euiText {
          font-weight: 600;
          font-size: 16px;
          padding-right: ${isMobile ? "22px" : "70px"};
        }
        .euiButtonIcon {
          align-items: flex-start;
        }
        button.euiButtonIcon > svg.euiButtonIcon__icon > path {
          fill: ${toastCloseColor} !important;
        }
      }
    }
  `;
  const styles = css`
    position: fixed;
    top: ${getTopValue(toasts.length, isGlobalBannerVisible, isMobile)};
    left: 50% !important;
    transform: translate(-50%, -50%);
    width: auto;
    z-index: 9999;
  `;
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Global, { styles: toastCss }), /* @__PURE__ */ React.createElement(
    EuiGlobalToastList,
    {
      css: styles,
      className: "pmToastList",
      toasts,
      dismissToast,
      toastLifeTimeMs: 6e3
    }
  ));
};
export { ApplicationToastList };
