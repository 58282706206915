import React from "react";
import DeleteFilesModal from "@pm-app/components/modal/delete-files-modal";
import Trash from "../../svg/trash-svg";
export default class FileViewerDeleteFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldDelete: false
    };
  }
  render() {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      "a",
      {
        className: "file-viewer-delete-file file-action",
        title: "Delete",
        onClick: () => this.setState({ shouldDelete: true })
      },
      /* @__PURE__ */ React.createElement(Trash, null)
    ), /* @__PURE__ */ React.createElement(
      DeleteFilesModal,
      {
        files: [this.props.file.toJS()],
        isOpen: this.state.shouldDelete,
        onClose: () => {
          this.setState({ shouldDelete: false });
        },
        onDelete: this.props.onDelete.bind(this, this.props.file)
      }
    ));
  }
}
