import _ from "lodash";
import moment from "moment";
import validate from "validate.js";
import PhoneUtils from "./utils/phone-utils";
validate.validators.minAvailabilties = function(segments, options) {
  let meld = options.getMeld();
  if (meld.get("tenant_presence_required") && meld.get("has_registered_tenant") && segments.length < 2) {
    return "At least 2 time slots must be chosen.";
  }
};
let US = new RegExp("^\\d{5}(-{0,1}\\d{4})?$");
let CA = new RegExp(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/i);
validate.validators.zipCode = function(zip) {
  let toTest = zip.replace(/\W+/g, "");
  if (!US.test(toTest) && !CA.test(toTest)) {
    return "Postal code is invalid";
  }
};
validate.validators.lineItems = function(lineItems, options) {
  let errors = /* @__PURE__ */ new Set();
  let lineItemConstraints = {
    description: {
      length: {
        maximum: 1e3,
        tooLong: "^Line item descriptions cannot be more than 1000 characters"
      },
      presence: {
        message: "^Line item descriptions cannot be blank",
        allowEmpty: false
      }
    },
    unit_price: {
      presence: {
        message: "^Line item value cannot be blank",
        allowEmpty: false
      }
    }
  };
  let lineItemExpenditureConstraints = {
    description: {
      length: {
        maximum: 1e3,
        tooLong: "^Line item descriptions cannot be more than 1000 characters"
      },
      presence: {
        message: "^Line item descriptions cannot be blank",
        allowEmpty: false
      }
    },
    unit_price: {
      presence: {
        message: "^Line item value cannot be blank",
        allowEmpty: false
      }
    },
    line_item_type: {
      presence: {
        message: "^Please select a type for all line items.",
        allowEmpty: false
      }
    }
  };
  let estimateLineItemConstraints = {
    description: {
      length: {
        maximum: 1e3,
        tooLong: "^Line item descriptions can't be more than 1000 characters"
      },
      presence: {
        message: "^Line item descriptions can't be blank",
        allowEmpty: false
      }
    },
    unit_cost: {
      presence: {
        message: "^Line item value can't be blank",
        allowEmpty: false
      }
    }
  };
  if (options.getInvoiceType && options.getInvoiceType() === "upload") {
    const parsedUnitPrice = Number.parseFloat(lineItems[0].unit_price);
    if (!_.isArray(lineItems) || lineItems.length === 0 || Number.isNaN(parsedUnitPrice) || parsedUnitPrice < 0) {
      errors.add("Enter the total amount for the invoice");
    }
  } else if (!_.isArray(lineItems) || lineItems.length === 0) {
    errors.add("At least one line is required");
  } else {
    for (let lineItem of lineItems) {
      let selectedConstraints;
      if (lineItem.hasOwnProperty("line_item_type") && !lineItem.cost_estimate) {
        selectedConstraints = lineItemExpenditureConstraints;
      } else {
        selectedConstraints = lineItem.cost_estimate ? estimateLineItemConstraints : lineItemConstraints;
      }
      let error = validate.validate(lineItem, selectedConstraints);
      if (error) {
        for (let errorKey of Object.keys(error)) {
          for (let err of error[errorKey]) {
            errors.add(err);
          }
        }
      }
    }
    if (options.getCurrentTotal && options.getCurrentTotal() >= 1e5) {
      errors.add("Total cannot be greater than 7 digits long in total.");
    }
  }
  return errors.size ? errors : null;
};
validate.validators.vendorInvoiceFile = function(vendorInvoiceFile, options) {
  let errors = [];
  let invoiceType = options.getInvoiceType();
  if (invoiceType === "upload" && (!vendorInvoiceFile || !vendorInvoiceFile.file)) {
    errors = errors.concat(["No invoice has been uploaded"]);
  }
  return errors.length ? errors : null;
};
validate.validators.syncItem = function(_sync_status, options, _key, attributes) {
  let chosenLinkDupes = /* @__PURE__ */ new Set();
  let errors = [];
  return attributes.reduce((prev, curr) => {
    if (curr.sync_status === "LINK" && curr.chosen_link && !chosenLinkDupes.has(curr.chosen_link.id)) {
      let dupes = attributes.filter((item) => item.chosen_link && item.chosen_link.id === curr.chosen_link.id);
      if (dupes.length > 1) {
        chosenLinkDupes.add(curr.chosen_link.id);
        return prev.concat(options.duplicateLink(dupes));
      }
    }
    return prev;
  }, errors);
};
validate.validators.hasPetInput = function(pets, options, _key, attributes) {
  if (attributes.has_pets) {
    if (!pets || pets.length < 1) {
      return options.message;
    }
  }
};
validate.validators.estimateDays = function(days, options) {
  if (days && days < 0) {
    return options.message;
  }
};
validate.validators.estimatePMFee = function(fee, options) {
  if (fee && fee < 0) {
    return options.message;
  }
};
validate.validators.phoneNumber = function(number, options) {
  if (!PhoneUtils.isValid(number)) {
    return options.message;
  }
};
validate.validators.availabilities = function(availabilities, options) {
  if (options.checkAvailabilities) {
    if (!availabilities || availabilities.length < options.minimum) {
      return options.message;
    }
  }
};
validate.validators.presenceToggled = function(presenceToggled, options) {
  if (!presenceToggled) {
    return options.message;
  }
};
validate.extend(validate.validators.datetime, {
  parse: (value) => {
    return +moment.utc(value);
  },
  format: (value, options) => {
    let format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
    return moment.utc(value).format(format);
  }
});
export function validateOptionalEmail(email, options, key, attributes) {
  if (email) {
    let errors = validate.validate(attributes, { [key]: { email: options } }, { fullMessages: false });
    if (errors) {
      return errors[key];
    }
  }
}
validate.validators.optionalEmail = validateOptionalEmail;
export default {};
