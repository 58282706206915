import React from "react";
import ShareSVG from "../../svg/share-svg";
export default class FileViewerShareFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openShare: false
    };
  }
  render() {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      "a",
      {
        className: "file-viewer-share-file file-action",
        title: "Share",
        onClick: this.props.onShare.bind(this, this.props.file)
      },
      /* @__PURE__ */ React.createElement(ShareSVG, null)
    ));
  }
}
