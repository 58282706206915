import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toastMessages, useAddToast } from "@pm-frontend/shared/store/toast";
import { apiPatch, apiPost } from "@pm-frontend/shared/utils/apiFetch";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { ApiUrls } from "@pm-frontend/shared/utils/api-urls";
import { meldKeys } from "@pm-frontend/routes/Melds/queries";
const estimateKeys = {
  all: ["estimates"],
  detail: (id) => [...estimateKeys.all, id]
};
const useApproveMeldEstimate = (estimateId, meldId) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  return useMutation({
    mutationFn: () => apiPatch(LinkHelper.normalize(ApiUrls.estimateDetail(estimateId.toString())), {
      estimate_status: "ESTIMATE_APPROVED"
    }),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldApproveEstimateSuccess,
        color: "success"
      });
      return Promise.all([
        queryClient.invalidateQueries(estimateKeys.detail(estimateId.toString())),
        queryClient.invalidateQueries(meldKeys.detail(meldId.toString()))
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldApproveEstimateFailure,
        color: "danger"
      });
    }
  });
};
const useCancelMeldEstimate = (estimateId, meldId) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  return useMutation({
    mutationFn: () => apiPatch(LinkHelper.normalize(ApiUrls.estimateDetail(estimateId.toString())), {
      estimate_status: "ESTIMATE_CLOSED"
    }),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldCloseEstimateSuccess,
        color: "success"
      });
      return Promise.all([
        queryClient.invalidateQueries(estimateKeys.detail(estimateId.toString())),
        queryClient.invalidateQueries(meldKeys.detail(meldId.toString()))
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldCloseEstimateFailure,
        color: "danger"
      });
    }
  });
};
const useRequestOwnerApprovalMeldEstimate = (estimateId, meldId) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  return useMutation({
    mutationFn: (showLineItems) => apiPost(LinkHelper.normalize(ApiUrls.estimateRequestOwnerApproval), {
      approvalEstimates: [{ id: estimateId }],
      showLineItems
    }),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldRequestOwnerApprovalEstimateSuccess,
        color: "success"
      });
      return Promise.all([
        queryClient.invalidateQueries(estimateKeys.detail(estimateId.toString())),
        queryClient.invalidateQueries(meldKeys.detail(meldId.toString()))
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldRequestOwnerApprovalEstimateFailure,
        color: "danger"
      });
    }
  });
};
const useCancelRequestOwnerApprovalMeldEstimate = (estimateId, meldId) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  return useMutation({
    mutationFn: () => apiPatch(LinkHelper.normalize(ApiUrls.estimateDetail(estimateId)), {
      estimate_status: "ESTIMATE_SUBMITTED"
    }),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldCancelRequestOwnerApprovalEstimateSuccess,
        color: "success"
      });
      return Promise.all([
        queryClient.invalidateQueries(estimateKeys.detail(estimateId.toString())),
        queryClient.invalidateQueries(meldKeys.detail(meldId.toString()))
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldCancelRequestOwnerApprovalEstimateFailure,
        color: "danger"
      });
    }
  });
};
export {
  useApproveMeldEstimate,
  useCancelMeldEstimate,
  useRequestOwnerApprovalMeldEstimate,
  useCancelRequestOwnerApprovalMeldEstimate,
  estimateKeys
};
