import _ from "lodash";
import I from "immutable";
const RecordUtils = {
  initFromJS(record, data, recordFields) {
    return recordFields.reduce((reduction, fieldName) => {
      const datum = data[fieldName];
      if (!_.isUndefined(datum) && datum !== null) {
        return reduction.set(fieldName, I.fromJS(datum));
      }
      return reduction;
    }, record);
  }
};
export default RecordUtils;
