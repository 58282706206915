import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";
class OwnerApi extends BaseApi {
  static fetchAll(xhrOptions) {
    return this.get(ApiUrls.ownerList, xhrOptions);
  }
  static fetchOne(id) {
    return this.get(ApiUrls.ownerDetail(id));
  }
  static saveOwner(owner) {
    return this.post(ApiUrls.ownerList, owner);
  }
  static updateOwner(owner) {
    return this.patch(ApiUrls.ownerDetail(owner.id), owner);
  }
  static deleteOwner(id) {
    return this.delete(ApiUrls.ownerDetail(id));
  }
  static updateProperties(ownerId, propertyIds) {
    return this.patch(ApiUrls.ownerPropertiesList(ownerId), {
      properties: propertyIds
    });
  }
  static unlinkProperty(ownerId, propertyId) {
    return this.delete(ApiUrls.ownerPropertyUnlink(ownerId, propertyId));
  }
  static addProperty(ownerId, propertyId) {
    return this.patch(ApiUrls.ownerPropertyUnlink(ownerId, propertyId), {});
  }
  static reinvite(id) {
    return this.post(ApiUrls.ownerReinvite(id));
  }
  static search(query, { ownerIdExclusions } = {}) {
    return this.get(ApiUrls.ownerSearchList, {
      params: { query, exclude_ids: ownerIdExclusions }
    });
  }
  static setNotificationSettings(includeIds, excludeIds, hubAccessLevel) {
    return this.patch(ApiUrls.ownerNotificationSettings, {
      include_ids: includeIds,
      exclude_ids: excludeIds,
      hub_access_level: hubAccessLevel
    });
  }
}
export default OwnerApi;
