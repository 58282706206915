import React from "react";
import { withRouter } from "react-router-dom";
import { FeatureFlagClientHelper } from "../feature-flags";
import { LargeLoader } from "../../loaders";
import { LinkUtils } from "@pm-shared/utils/link";
import RouteUtils from "../utils/route-utils";
import AppContext, { AppContextProvider } from "../../../../app/contexts/AppContext";
import { page } from "@pm-app/utils/analytics";
class RouteChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      forceUpdateOnRouteChanged: false
    };
  }
  render() {
    if (!FeatureFlagClientHelper.isClientReady) {
      return /* @__PURE__ */ React.createElement(LargeLoader, null);
    }
    return /* @__PURE__ */ React.createElement(AppContextProvider, null, /* @__PURE__ */ React.createElement(AppContext.Consumer, null, (context) => {
      if (context) {
        return /* @__PURE__ */ React.createElement("div", { className: context.backgroundColor }, this.props.children);
      }
    }));
  }
  componentDidMount() {
    FeatureFlagClientHelper.onClientReady(() => {
      this.forceUpdate();
    });
    FeatureFlagClientHelper.onClientChange(() => {
      this.setState({ forceUpdateOnRouteChanged: true });
    });
  }
  componentWillUpdate(nextProps) {
    if (this.props.location !== nextProps.location) {
      this.onRouteChanged(nextProps.location, nextProps.history);
    }
  }
  onRouteChanged(location, history) {
    LinkUtils.reloadIfVersionOld(location, history);
    page();
    if (this.state.forceUpdateOnRouteChanged) {
      this.setState({ forceUpdateOnRouteChanged: false });
      this.forceUpdate();
    }
  }
  componentWillReceiveProps() {
    RouteUtils.savePrevRoute(this.props.location);
  }
}
export default withRouter(RouteChange);
