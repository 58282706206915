import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";
class PaymentApi extends BaseApi {
  static makePayment(data) {
    return this.post(ApiUrls.meldInvoicesPay, data);
  }
  static fetchCoupon(coupon) {
    return this.get(ApiUrls.couponDetail(coupon));
  }
  static fetchPMCoupon(coupon) {
    return this.get(ApiUrls.pmCouponDetail(coupon));
  }
  static subscribe(data) {
    return this.post(ApiUrls.subscription, data);
  }
}
export default PaymentApi;
