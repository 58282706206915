import moment from "moment";
import CalendarUtils from "../../utils/calendar-utils";
import EventUtils from "./event-utils";
class AvailabilityUtils {
  static getDTStartMoment(avail) {
    return EventUtils.getDTStartMoment(avail.get("event"));
  }
  static getDTEndMoment(avail) {
    return EventUtils.getDTEndMoment(avail.get("event"));
  }
  static dtstartDifference(avail1, avail2) {
    return AvailabilityUtils.getDTStartMoment(avail1).diff(AvailabilityUtils.getDTStartMoment(avail2));
  }
  static dtstartMonth(avail) {
    return CalendarUtils.month(AvailabilityUtils.getDTStartMoment(avail));
  }
  static getFullDate(avail) {
    return CalendarUtils.renderFormattedDayMonthYear(AvailabilityUtils.getDTStartMoment(avail));
  }
  static dtstartDayOfMonth(avail) {
    return CalendarUtils.day(AvailabilityUtils.getDTStartMoment(avail));
  }
  static isAvailabilityScheduleable(availability) {
    return availability && AvailabilityUtils.getDTEndMoment(availability).isAfter(moment());
  }
}
export default AvailabilityUtils;
