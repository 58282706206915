import { Textarea } from "@chakra-ui/react";
import React from "react";
export const TextCondition = ({ error, onChange, value }) => {
  return /* @__PURE__ */ React.createElement(
    Textarea,
    {
      isInvalid: !!error,
      "data-test": "workflows.form.select.block_operator.condition_type.text",
      onChange,
      value,
      resize: "none"
    }
  );
};
