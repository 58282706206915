import _ from "lodash";
import ActionDropdown from "../../action-dropdown";
import Card from "../../common/components/card";
import classNames from "classnames";
import DocumentSvg from "../../svg/document-svg";
import I from "immutable";
import moment from "moment";
import styled from "@emotion/styled";
import OwnerUtils from "../../property/utils/owner-utils";
import PureCheckbox from "../../common/components/pure-checkbox";
import React, { useState } from "react";
import TenantUtils from "../../tenant/utils/tenant-utils";
import truncate from "truncate";
import FileUtils from "../utils/file-utils";
import DeleteFilesModal from "@pm-app/components/modal/delete-files-modal";
const FileContents = styled.div`
  overflow: auto;
`;
var FileModes = /* @__PURE__ */ ((FileModes2) => {
  FileModes2["DEFAULT"] = "DEFAULT";
  FileModes2["DELETE"] = "DELETE";
  return FileModes2;
})(FileModes || {});
const File = ({
  file,
  deleteFile,
  shareFile,
  selectable,
  setExpiration,
  isDownloadEnabled = true,
  isDropdownEnabled,
  children,
  onSelectionToggled,
  onThumbnailClicked,
  isSelected: _isSelected
}) => {
  const [cid] = useState(_.uniqueId("File_"));
  const [mode, setMode] = useState("DEFAULT" /* DEFAULT */);
  const classes = classNames({
    "header-col": true,
    "card-selector": true,
    hidden: !selectable
  });
  const isSelected = () => _isSelected ? _isSelected(file) : false;
  const resetMode = () => setMode("DEFAULT" /* DEFAULT */);
  const renderMode = () => {
    if (mode === "DELETE" /* DELETE */) {
      return /* @__PURE__ */ React.createElement(
        DeleteFilesModal,
        {
          files: [file.toJS()],
          isOpen: true,
          onDelete: () => deleteFile && deleteFile(file, cid),
          onClose: resetMode
        }
      );
    }
  };
  const renderActionDropdown = () => {
    let actions = [];
    if (isDownloadEnabled) {
      actions.push(
        /* @__PURE__ */ React.createElement("li", { className: "menu-item download-file", key: "download-file" }, /* @__PURE__ */ React.createElement("a", { className: "action", href: file.get("signed_url"), onClick: () => window.open(file.get("signed_url")) }, "Download"))
      );
    }
    if (shareFile) {
      actions.push(
        /* @__PURE__ */ React.createElement("li", { className: "menu-item share-item", key: "share-file" }, /* @__PURE__ */ React.createElement("span", { className: "action", onClick: () => shareFile && shareFile(file) }, "Share"))
      );
    }
    if (deleteFile) {
      actions.push(
        /* @__PURE__ */ React.createElement("li", { className: "menu-item delete-file", key: "delete-file" }, /* @__PURE__ */ React.createElement("span", { className: "action", onClick: () => setMode("DELETE" /* DELETE */) }, "Delete"))
      );
    }
    if (actions.length > 0) {
      return /* @__PURE__ */ React.createElement(ActionDropdown, { enabled: isDropdownEnabled }, actions);
    }
  };
  const renderExpiration = () => {
    if (file.has("expiration_date")) {
      let expirationDate = file.get("expiration_date");
      if (expirationDate) {
        if (moment() < moment(expirationDate)) {
          return /* @__PURE__ */ React.createElement("div", { className: "card-attributes last final-section" }, /* @__PURE__ */ React.createElement("span", { className: "card-attribute card-accent" }, /* @__PURE__ */ React.createElement("a", { className: "expiration-link", onClick: setExpiration }, "Expires ", moment(expirationDate).format("MMMM D, YYYY"))));
        } else {
          return /* @__PURE__ */ React.createElement("div", { className: "card-attributes last final-section" }, /* @__PURE__ */ React.createElement("span", { className: "card-attribute card-accent" }, /* @__PURE__ */ React.createElement("a", { className: "expiration-link danger", onClick: setExpiration }, "Expired ", moment(expirationDate).format("MMMM D, YYYY"))));
        }
      } else {
        return /* @__PURE__ */ React.createElement("div", { className: "card-attributes last final-section" }, /* @__PURE__ */ React.createElement("span", { className: "card-attribute card-accent" }, /* @__PURE__ */ React.createElement("a", { className: "expiration-link", onClick: setExpiration }, "Set an expiration date")));
      }
    }
  };
  const renderShared = () => {
    let owners = file.get("owners_shared_with") || I.List();
    let vendors = file.get("vendors_shared_with") || I.List();
    let tenants = file.get("tenants_shared_with") || I.List();
    let ownerClasses = classNames({
      "card-attributes": true,
      last: vendors.isEmpty() && tenants.isEmpty(),
      "final-section": vendors.isEmpty() && tenants.isEmpty()
    });
    let vendorClasses = classNames({
      "card-attributes": true,
      last: tenants.isEmpty(),
      "final-section": tenants.isEmpty()
    });
    return /* @__PURE__ */ React.createElement("div", null, !owners.isEmpty() && /* @__PURE__ */ React.createElement("div", { className: ownerClasses }, /* @__PURE__ */ React.createElement("span", { className: "card-attribute card-accept", title: "shared-with-owners" }, "Shared with owners: ", owners.map(OwnerUtils.getName).join(", "))), !vendors.isEmpty() && /* @__PURE__ */ React.createElement("div", { className: vendorClasses }, /* @__PURE__ */ React.createElement("span", { className: "card-attribute card-accept", title: "shared-with-vendors" }, "Shared with vendors: ", vendors.map((vendor) => vendor.get("name")).join(", "))), !tenants.isEmpty() && /* @__PURE__ */ React.createElement("div", { className: "card-attributes last final-section" }, /* @__PURE__ */ React.createElement("span", { className: "card-attribute card-accept", title: "shared-with-tenants" }, "Shared with tenants:", " ", tenants.map((tenant) => TenantUtils.getFullName(tenant)).join(", "))));
  };
  return /* @__PURE__ */ React.createElement(Card, { sizeClassName: "small-12 medium-6 large-4 file-container", isSelected: isSelected() }, /* @__PURE__ */ React.createElement("div", { className: "file-header" }, /* @__PURE__ */ React.createElement(
    PureCheckbox,
    {
      type: "checkbox",
      htmlFor: `owner-${file.get("filename")}`,
      className: classes,
      checked: isSelected(),
      onClick: (e) => {
        e.preventDefault();
        if (onSelectionToggled) {
          onSelectionToggled(file);
        }
      }
    }
  ), /* @__PURE__ */ React.createElement("span", { className: "file-name", title: file.get("filename") }, file.get("filename")), /* @__PURE__ */ React.createElement("span", { className: "header-col" }, renderActionDropdown())), /* @__PURE__ */ React.createElement(FileContents, null, /* @__PURE__ */ React.createElement("div", { className: "file-thumbnail", onClick: () => onThumbnailClicked(file) }, FileUtils.isThumbnailAvailable(file) && /* @__PURE__ */ React.createElement("img", { src: file.get("thumbnail") }), !FileUtils.isThumbnailAvailable(file) && /* @__PURE__ */ React.createElement(DocumentSvg, null)), renderExpiration(), renderShared(), renderMode(), children));
};
export default File;
export class DeleteOnlyFile extends React.Component {
  render() {
    return /* @__PURE__ */ React.createElement(Card, { sizeClassName: "small-12 medium-6 large-4" }, /* @__PURE__ */ React.createElement("div", { className: "card-header small-card-header badged-header" }, /* @__PURE__ */ React.createElement("span", { className: "header-col" }, /* @__PURE__ */ React.createElement("div", { className: "file", title: this.props.fileName }, /* @__PURE__ */ React.createElement(DocumentSvg, { classes: "file-ico" }), /* @__PURE__ */ React.createElement("span", { className: "file-name" }, truncate(this.props.fileName, 40)))), /* @__PURE__ */ React.createElement("span", { className: "header-col" }, /* @__PURE__ */ React.createElement(ActionDropdown, { enabled: true }, /* @__PURE__ */ React.createElement("li", { className: "menu-item delete-file" }, /* @__PURE__ */ React.createElement("span", { className: "action", onClick: this.props.onDelete }, "Delete"))))));
  }
}
export function Files(props) {
  return /* @__PURE__ */ React.createElement("ul", { className: "files" }, props.children);
}
