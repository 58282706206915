import { parsePhoneNumber } from "libphonenumber-js";
let PhoneUtils = {
  format(phoneNumber) {
    try {
      const parsed = parsePhoneNumber(phoneNumber, "US");
      return parsed.format("NATIONAL");
    } catch (e) {
      return phoneNumber;
    }
  },
  isValid(phoneNumber) {
    try {
      const parsed = parsePhoneNumber(phoneNumber, "US");
      return parsed.isValid();
    } catch (e) {
      return false;
    }
  }
};
export default PhoneUtils;
