const MAX_NUMBER_OF_RETRIES = 0;
import { QueryClient, onlineManager } from "@tanstack/react-query";
import Features from "@pm-assets/js/common/feature-flags";
onlineManager.setEventListener((setOnline) => setOnline(true));
class SingletonQueryClient {
  constructor() {
    if (SingletonQueryClient._instance) {
      return SingletonQueryClient._instance;
    }
    SingletonQueryClient._instance = this;
    this.queryClient = new QueryClient({
      //  can be overridden on a per-query basis
      defaultOptions: {
        queries: {
          retry: MAX_NUMBER_OF_RETRIES,
          staleTime: Features.getQueryStaleTime(),
          refetchOnWindowFocus: Features.isRefetchOnWindowFocusEnabled()
        }
      }
    });
  }
  getQueryClient() {
    return this.queryClient;
  }
}
export { SingletonQueryClient };
