import { useEffect, useRef } from "react";
const MIN_SWIPE_DISTANCE = 50;
const useSwipe = ({
  handleSwipeLeft,
  handleSwipeRight
}) => {
  const startXRef = useRef(0);
  const endXRef = useRef(0);
  const startYRef = useRef(0);
  const endYRef = useRef(0);
  const handleSwipe = () => {
    const startX = startXRef.current;
    const endX = endXRef.current;
    const distanceX = startXRef.current - endXRef.current;
    const distanceY = startYRef.current - endYRef.current;
    const isLeftSwipe = distanceX > MIN_SWIPE_DISTANCE;
    const isRightSwipe = distanceX < -1 * MIN_SWIPE_DISTANCE;
    const validSwipe = startX !== 0 && endX !== 0 && Math.abs(distanceX) > Math.abs(distanceY);
    if (validSwipe) {
      if (isRightSwipe) {
        handleSwipeRight();
      } else if (isLeftSwipe) {
        handleSwipeLeft();
      }
      startXRef.current = 0;
      endXRef.current = 0;
    }
  };
  useEffect(() => {
    const handleTouchStart = (e) => {
      startXRef.current = e.touches[0].clientX;
      startYRef.current = e.touches[0].clientY;
    };
    const handleTouchEnd = (e) => {
      endXRef.current = e.changedTouches[0].clientX;
      endYRef.current = e.changedTouches[0].clientY;
      handleSwipe();
    };
    document.addEventListener("touchstart", handleTouchStart);
    document.addEventListener("touchend", handleTouchEnd);
    return () => {
      document.removeEventListener("touchstart", handleTouchStart);
      document.removeEventListener("touchend", handleTouchEnd);
    };
  }, [handleSwipeRight, handleSwipeLeft]);
};
export { useSwipe };
