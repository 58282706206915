import I from "immutable";
import React from "react";
import AgentUtils from "@pm-assets/js/management/utils/agent-utils";
import OwnerUtils from "@pm-assets/js/property/utils/owner-utils";
import TenantUtils from "@pm-assets/js/tenant/utils/tenant-utils";
import { UserTypes } from "@pm-app/components/labels/UserTypeLabel";
import VendorAgentUtils from "@pm-assets/js/vendor/utils/agent-utils";
var CommentClasses = /* @__PURE__ */ ((CommentClasses2) => {
  CommentClasses2["ManagerComment"] = "ManagerComment";
  CommentClasses2["TenantComment"] = "TenantComment";
  CommentClasses2["VendorComment"] = "VendorComment";
  CommentClasses2["OwnerComment"] = "OwnerComment";
  return CommentClasses2;
})(CommentClasses || {});
const CommentUtils = {
  getUserType(comment) {
    switch (comment.get("clazz")) {
      case "TenantComment" /* TenantComment */:
        return UserTypes.Tenant;
      case "ManagerComment" /* ManagerComment */:
        return UserTypes.Manager;
      case "VendorComment" /* VendorComment */:
        return UserTypes.Vendor;
      case "OwnerComment" /* OwnerComment */:
        return UserTypes.Owner;
    }
  },
  getReadStatuses(readByInApp, emailStatuses, messageStatuses, readerType) {
    const read = I.Set(readByInApp).union(
      emailStatuses.filter((readerEmailStatus) => readerEmailStatus.getIn(["email_status", "opened"])).map((readerEmailStatus) => readerEmailStatus.get(readerType))
    ).union(
      messageStatuses.filter((readerMessageStatus) => readerMessageStatus.getIn(["message_status", "delivered"])).map((readerMessageStatus) => readerMessageStatus.get(readerType))
    );
    const deliveryFailed = I.Set(
      emailStatuses.filter((readerEmailStatus) => readerEmailStatus.getIn(["email_status", "delivery_failed"])).map((readerEmailStatus) => readerEmailStatus.get(readerType))
    ).union(
      messageStatuses.filter(
        (readerMessageStatus) => readerMessageStatus.getIn(["message_status", "delivery_failed"])
      ).map((readerMessageStatus) => readerMessageStatus.get(readerType))
    ).subtract(read);
    const unread = I.Set(emailStatuses.map((readerEmailStatus) => readerEmailStatus.get(readerType))).union(messageStatuses.map((readerMessageStatus) => readerMessageStatus.get(readerType))).subtract(read).subtract(deliveryFailed);
    return { read, deliveryFailed, unread };
  },
  getFormattedReadStatuses(titlePrefix, managementAgents, vendorAgents, tenants, owners) {
    let elements = I.List();
    function createStatuses(userSet, formatUserFn, userType) {
      if (!userSet.isEmpty()) {
        const usersFormatted = userSet.map(formatUserFn).sort().join(", ");
        const plural = vendorAgents.count() > 1;
        elements = elements.push(
          /* @__PURE__ */ React.createElement("span", { key: userType[0] }, /* @__PURE__ */ React.createElement("div", { className: "status-header" }, `${titlePrefix} ${userType}${plural ? "s" : ""}:`), /* @__PURE__ */ React.createElement("span", null, `${usersFormatted}`))
        );
      }
    }
    createStatuses(vendorAgents, (vendorAgent) => VendorAgentUtils.getFullName(vendorAgent), "vendor");
    createStatuses(owners, (owner) => OwnerUtils.getName(owner), "owner");
    createStatuses(tenants, (tenant) => TenantUtils.getFullName(tenant), "tenant");
    createStatuses(managementAgents, (managementAgent) => AgentUtils.getFullName(managementAgent), "manager");
    return /* @__PURE__ */ React.createElement("span", null, elements.interleave(I.Range(elements.count() - 1).map((i) => /* @__PURE__ */ React.createElement("br", { key: i }))).flatten());
  }
};
export default CommentUtils;
