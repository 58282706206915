import React from "react";
import { EuiHeaderSectionItem, EuiIcon } from "@elastic/eui";
import URL from "@pm-shared/utils/url";
const SiteSearchLogo = ({
  isOpen,
  setSearchIsOpen
}) => {
  return /* @__PURE__ */ React.createElement(EuiHeaderSectionItem, { "aria-label": "Search" }, /* @__PURE__ */ React.createElement(
    EuiIcon,
    {
      type: isOpen ? URL.getStatic("icons/search-header_blue.svg") : URL.getStatic("icons/search-header.svg"),
      size: "l",
      onClick: () => setSearchIsOpen((state) => !state),
      "data-testid": "header-search-icon"
    }
  ));
};
export { SiteSearchLogo };
