export const AuthUtils = {
  getActiveOrg() {
    var _a, _b;
    return (_b = (_a = window.PM) == null ? void 0 : _a.user) == null ? void 0 : _b.activeOrgId;
  },
  getActiveOrgType() {
    var _a, _b;
    return (_b = (_a = window.PM) == null ? void 0 : _a.user) == null ? void 0 : _b.orgType;
  },
  getUserId() {
    var _a, _b;
    return (_b = (_a = window.PM) == null ? void 0 : _a.user) == null ? void 0 : _b.id;
  },
  getUser() {
    var _a;
    return (_a = window.PM) == null ? void 0 : _a.user;
  },
  getUserOwnerHubAccessLevel() {
    var _a, _b;
    return (_b = (_a = window.PM) == null ? void 0 : _a.user) == null ? void 0 : _b.hub_access_level;
  },
  getUserSecret() {
    var _a, _b;
    return (_b = (_a = window.PM) == null ? void 0 : _a.user) == null ? void 0 : _b.secret;
  },
  getUserContact() {
    var _a, _b;
    return (_b = (_a = window.PM) == null ? void 0 : _a.user) == null ? void 0 : _b.contact;
  },
  getUserName() {
    var _a, _b;
    return (_b = (_a = window.PM) == null ? void 0 : _a.user) == null ? void 0 : _b.name;
  },
  getUserNotificationSettings() {
    var _a, _b;
    return (_b = (_a = window.PM) == null ? void 0 : _a.user) == null ? void 0 : _b.notification_settings;
  },
  isStale() {
    var _a;
    return (_a = window.PM) == null ? void 0 : _a.is_stale;
  },
  getSecretMeld() {
    var _a;
    return (_a = window.PM) == null ? void 0 : _a.secret_meld;
  },
  getSecretEstimateList() {
    var _a;
    return (_a = window.PM) == null ? void 0 : _a.secret_meld_estimates;
  },
  getUserMultitenant() {
    var _a, _b;
    return (_b = (_a = window.PM) == null ? void 0 : _a.user) == null ? void 0 : _b.multitenant;
  },
  getUserOwnerId() {
    var _a, _b;
    return (_b = (_a = window.PM) == null ? void 0 : _a.user) == null ? void 0 : _b.owner_id;
  },
  getUserEmail() {
    var _a, _b;
    return (_b = (_a = window.PM) == null ? void 0 : _a.user) == null ? void 0 : _b.email;
  },
  getManagementName() {
    var _a;
    return ((_a = window.PM) == null ? void 0 : _a.management_name) || "NA";
  },
  getCsrfToken() {
    return window.PM.csrf_token;
  },
  getAnnouncement() {
    var _a;
    return (_a = window.PM) == null ? void 0 : _a.announcement;
  },
  isAuthenticated() {
    return window.PM.user.isAuth;
  },
  hasMultiOrgs() {
    return window.PM.user.hasMultiOrgs;
  },
  getAccountKey() {
    return `${window.location.pathname.split("/").slice(1, 4).join("-")}`;
  },
  getManagementTimezone() {
    var _a;
    return (_a = window.PM) == null ? void 0 : _a.management_timezone;
  }
};
