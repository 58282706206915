import _ from "lodash";
export function pd(func) {
  return _.wrap(func, (f, e) => {
    e.preventDefault();
    if (!f) {
      throw Error("Function is not defined. Calling `pd(someFunc)`, but someFunc is not defined.");
    }
    f(e);
  });
}
export function sp(func) {
  return _.wrap(func, (f, e) => {
    e.stopPropagation();
    if (!f) {
      throw Error("Function is not defined. Calling `sp(someFunc)`, but someFunc is not defined.");
    }
    f(e);
  });
}
