import PropTypes from "prop-types";
import React, { Component } from "react";
import Link from "@pm-shared/utils/link";
import Pen from "../../svg/pen-svg";
import Tag from "../../svg/tag-svg";
class MeldSummaryTags extends Component {
  render() {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("h3", null, "Tags"), /* @__PURE__ */ React.createElement("span", { className: "info-icon" }, /* @__PURE__ */ React.createElement(Tag, null)), this.renderTags(), this.renderEdit());
  }
  renderTags() {
    let tags = this.props.meld.get("tags");
    if (tags.count()) {
      return /* @__PURE__ */ React.createElement("span", null, tags.map((tag, i) => /* @__PURE__ */ React.createElement(Link, { to: "/melds/melding/", query: { "tags[]": tag.get("id") }, className: "tag pilled square", key: i }, tag.get("name"))));
    } else {
      return "No tags";
    }
  }
  renderEdit() {
    if (this.props.editable && this.props.onEdit) {
      return /* @__PURE__ */ React.createElement("span", { className: "info-icon edit-tags", onClick: this.props.onEdit }, /* @__PURE__ */ React.createElement(Pen, null));
    }
  }
}
MeldSummaryTags.propTypes = {
  editable: PropTypes.bool,
  meld: PropTypes.object.isRequired,
  onEdit: PropTypes.func
};
export default MeldSummaryTags;
