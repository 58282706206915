import React from "react";
import ErrorUtils from "../../utils/error-utils";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  ListItem,
  ListIcon,
  List
} from "@chakra-ui/react";
import { MdRemoveCircle } from "react-icons/md";
function Errors(props) {
  let errors = [];
  if (!props.skipValidation) {
    errors = ErrorUtils.getValidationErrorList(props.data, props.constraints) || [];
  }
  errors = errors.concat(props.extraErrors || []);
  return /* @__PURE__ */ React.createElement(ErrorList, { errors });
}
export default Errors;
function ErrorList(props) {
  if (props.errors && props.errors.length) {
    let errorsHtml = props.errors.map((error, i) => {
      return /* @__PURE__ */ React.createElement("li", { className: "error", key: i }, error);
    });
    return /* @__PURE__ */ React.createElement("ul", { className: "errors" }, errorsHtml);
  }
  return null;
}
export { ErrorList };
function ChakraErrorList(props) {
  if (props.errors && props.errors.length) {
    let errorsHtml = props.errors.map((error, i) => {
      return /* @__PURE__ */ React.createElement(ListItem, { key: i }, /* @__PURE__ */ React.createElement(ListIcon, { as: MdRemoveCircle, color: "red.500" }), error);
    });
    return /* @__PURE__ */ React.createElement(Alert, { status: "error", margin: "8px 0" }, /* @__PURE__ */ React.createElement(Box, { flex: "1" }, /* @__PURE__ */ React.createElement(AlertTitle, null, "Error!"), /* @__PURE__ */ React.createElement(AlertDescription, { display: "block" }, /* @__PURE__ */ React.createElement(List, { spacing: 3 }, errorsHtml))));
  }
  return null;
}
export { ChakraErrorList };
