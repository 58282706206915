import _ from "lodash";
import CommentActions from "../actions/comment-actions";
import CommentStore from "../stores/comment-store";
import ErrorRenderingUtils from "../../utils/error-rendering-utils";
import Modal from "../../modal";
import ModalBody from "../../common/components/modal-body";
import ModalHeader from "../../common/components/modal-header";
import { ModelUpdate } from "../../utils/model-update-utils";
import createReactClass from "create-react-class";
import React from "react";
import { StatusStates } from "../../stores/base-store";
import CancelSaveButtons from "../../common/components/cancel-save-buttons";
import { FlexJustifyContent } from "../../../../app/components/utils/constants";
let UpdateCommentModal = createReactClass({
  mixins: [ErrorRenderingUtils],
  getInitialState() {
    this.markDirty = this.props.markDirty.bind(this);
    this.onChangeSet = this.props.onChangeSet.bind(this);
    const cid = _.uniqueId("updateComment_");
    return {
      cid,
      data: this.props.comment,
      status: CommentStore.getStatus(cid)
    };
  },
  render() {
    return /* @__PURE__ */ React.createElement(Modal, { isOpen: true }, /* @__PURE__ */ React.createElement(ModalHeader, null, "Edit comment"), /* @__PURE__ */ React.createElement("div", { className: "dialog-box" }, /* @__PURE__ */ React.createElement(ModalBody, null, /* @__PURE__ */ React.createElement("div", { className: "row" }, /* @__PURE__ */ React.createElement("div", { className: "columns small-12 small-centered" }, /* @__PURE__ */ React.createElement("div", { className: "row center" }, /* @__PURE__ */ React.createElement("div", { className: "columns" }, this.renderErrors())), /* @__PURE__ */ React.createElement("div", { className: "row center" }, /* @__PURE__ */ React.createElement("div", { className: "columns" }, /* @__PURE__ */ React.createElement(
      "textarea",
      {
        className: "new-comment",
        placeholder: "Enter a new message",
        value: this.state.data.get("text"),
        onChange: this.markDirty(this.onChangeSet("text"))
      }
    ))), /* @__PURE__ */ React.createElement("div", { className: "row last action-btns center" }, /* @__PURE__ */ React.createElement("div", { className: "columns" }, /* @__PURE__ */ React.createElement("div", { className: "footer" }, /* @__PURE__ */ React.createElement("div", { className: "footer-content" }, /* @__PURE__ */ React.createElement(
      CancelSaveButtons,
      {
        onCancelFn: this.props.close,
        onSaveFn: this.onSubmit,
        cancelDisabled: this.isPendingSave(),
        saveDisabled: this.isPendingSave(),
        justifyContainer: FlexJustifyContent.Center
      }
    ))))))))));
  },
  onSubmit() {
    this.initValidation();
    if (this.isValid()) {
      CommentActions.updateComment(this.state.data.toJS(), this.state.cid);
    }
  },
  isPendingSave() {
    return this.state.pendingSave;
  },
  componentWillMount() {
    CommentStore.listen(this.handleCommentStoreChange);
  },
  componentWillUnmount() {
    CommentStore.unlisten(this.handleCommentStoreChange);
  },
  handleCommentStoreChange() {
    let status = CommentStore.getStatus(this.state.cid);
    this.setState({ status });
    if (status.get("statusState") === StatusStates.DONE) {
      this.props.close();
    }
  }
});
export default ModelUpdate(UpdateCommentModal);
