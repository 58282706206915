import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";
class AgentApi extends BaseApi {
  static fetchAll() {
    return this.get(ApiUrls.agentList);
  }
  static fetchAbbreviated() {
    return this.get(ApiUrls.agentAbbrevList);
  }
  static getAgent(agentId) {
    return this.get(ApiUrls.agentDetail(agentId));
  }
  static update(agentId, data) {
    return this.patch(ApiUrls.agentDetail(agentId), data);
  }
  static updatePropertyGroups(agentId, propertyGroupIds) {
    return this.patch(ApiUrls.agentPropertyGroupsList(agentId), {
      property_groups: propertyGroupIds
    });
  }
  static updateUserGroups(agentId, userGroupIds) {
    return this.patch(ApiUrls.agentUserGroupsList(agentId), {
      user_groups: userGroupIds
    });
  }
  static updateMe(agentData) {
    return this.patch(ApiUrls.agentMeDetail, agentData);
  }
  static fetchMaintenanceAgents() {
    return this.fetchAbbreviated();
  }
  static search(query, { agentIdExclusions } = {}) {
    return this.get(ApiUrls.agentSearchList, {
      params: { query, exclude_ids: agentIdExclusions }
    });
  }
}
export default AgentApi;
