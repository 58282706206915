import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper
} from "@chakra-ui/react";
import React from "react";
export const DecimalCondition = ({ error, index, lookup_name, onChange, operator_key, value }) => {
  if ([
    "equal",
    "not_equal",
    "greater_than",
    "less_than",
    "in_range",
    "between",
    "greater_or_equal",
    "lesser_or_equal"
  ].includes(operator_key)) {
    return /* @__PURE__ */ React.createElement(
      NumberInput,
      {
        isInvalid: !!error,
        key: `decimal-${lookup_name}-${index}`,
        "data-test": "workflows.form.select.block_operator.condition_type.decimal",
        onChange,
        value,
        width: "100%"
      },
      /* @__PURE__ */ React.createElement(NumberInputField, { "data-test": "workflows.form.select.block_operator.condition_type.decimal.input" }),
      /* @__PURE__ */ React.createElement(NumberInputStepper, null, /* @__PURE__ */ React.createElement(
        NumberIncrementStepper,
        {
          "data-test": "workflows.form.select.block_operator.condition_type.decimal.stepper.up"
        }
      ), /* @__PURE__ */ React.createElement(
        NumberDecrementStepper,
        {
          "data-test": "workflows.form.select.block_operator.condition_type.decimal.stepper.down"
        }
      ))
    );
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null);
};
